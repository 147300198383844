import { useState } from "react";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

export function IngresarDatos(props){
    const [showModalExcel, setShowModalExcel] = useState(false)
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresas, setEmpresas] = useState([])
    const [datosExcel, setdatosExcel] = useState([])
    
    const handleFile = (e) => {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetname = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetname];
        const datos = XLSX.utils.sheet_to_json(sheet);
        setdatosExcel(datos);
    }

    }
    const sendDataExcel = () => {
      if(datosExcel.length === 0){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No se ha seleccionado ningun archivo',
        })
      }else{
        props.send(datosExcel)

      }
    }
    return (
        <>

        <h1>{props.leyenda}</h1>
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"80%", padding:"50px", borderRadius: "30px",  marginTop: "8%"}}>
          <div>
            {props.showButton ?  <Button onClick={props.goBack} style={{margin:"10px"}}><AiOutlineArrowLeft/></Button> : null}
           
            <Card className="text-center" style={{ width: '100%', height:"75%", maxWidth:"500px" }}>
              <Card.Header></Card.Header>
              <Card.Body>
                <Card.Title style={{color:"white"}}>{props.leyenda}</Card.Title>
                <Form.Label>Seleccionar el archivo</Form.Label>
                <Form.Control type="file" accept=" .xls .xlsx .csv" onChange={handleFile}/>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={sendDataExcel}>Enviar</Button>
              </Card.Footer>
            </Card>
          </div>
        </Container>

        

        </>
      );
      
      
    
    }