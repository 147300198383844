import Pagination from 'react-bootstrap/Pagination';

export function PaginationControls(props) {
  const { page } = props;
  const handleClickEvent = (page) => {
    if(page !== 0){

        props.onPageChange(page)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: Desplazamiento suave
    });
    };

  const renderPaginationItems = () => {
    const paginationItems = [];

    if (page === 1 || page === 2) {
      paginationItems.push(
        <Pagination.Prev onClick={() => handleClickEvent(page-1)}/>,
        <Pagination.Item active>{page}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+1)}>{page + 1}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+2)}>{page + 2}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+3)}>{page + 3}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+4)}>{page + 4}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+5)}>{page + 5}</Pagination.Item>,
        <Pagination.Next  onClick={() => handleClickEvent(page+1)}/>
      );
    } else {
      paginationItems.push(
        <Pagination.Prev  onClick={() => handleClickEvent(page-1)} />,
        <Pagination.Item  onClick={() => handleClickEvent(1)}>{1}</Pagination.Item>,
        <Pagination.Ellipsis />,
        <Pagination.Item  onClick={() => handleClickEvent(page-2)}>{page - 2}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page-1)}>{page - 1}</Pagination.Item>,
        <Pagination.Item active>{page}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+1)}>{page + 1}</Pagination.Item>,
        <Pagination.Item  onClick={() => handleClickEvent(page+2)}>{page + 2}</Pagination.Item>,
        <Pagination.Item   onClick={() => handleClickEvent(page+3)}>{page + 3}</Pagination.Item>,
        <Pagination.Ellipsis />,
        <Pagination.Item  onClick={() => handleClickEvent(page+5)}>{page + 5}</Pagination.Item>,
        <Pagination.Next  onClick={() => handleClickEvent(page+1)}/>
      );
    }

    return paginationItems;
  };

  return <Pagination>{renderPaginationItems()}</Pagination>;
}