import { useState, useEffect } from "react"
import { Container, Form, Button } from "react-bootstrap"
import { getEmpresas } from "../../services/geolocalizacion/places"
import { reporteCierresDiariosEmpresa } from "../../services/reportes/reportesCuentas"
export function Buscador (props){
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresa, setEmpresa] = useState([[]])
    const [fecha, setFecha] = useState('')
    const [nombre, setNombre] = useState('') 
    const token = localStorage.getItem('token');


    async function sendBuscar(e){
        e.preventDefault()
       
        const object = {
          empresa: idEmpresa,
          fecha: fecha,
          token: token
        }
       const result = await reporteCierresDiariosEmpresa(object)
       result.empresa = nombre
       result.fecha = fecha
       result.id_empresa = idEmpresa
       props.onFinish(result)
       
        
      }
    useEffect(() => {
        
        const fetchData = async () => {
          const object = { token }
          const tipos = await getEmpresas(object)
           
          setEmpresa([tipos])
        }

        fetchData()
     
        
     
    }, [])

    function setNombreEmpresa(e){
        setNombre(e.target.selectedOptions[0].innerHTML)
        setIdEmpresa(e.target.value)
    }

    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
           <center> <h2>Cierres diarios por empresa</h2> </center>
           

            <Form.Label>Empresa</Form.Label>
            <Form.Select aria-label="Default select example" onChange={setNombreEmpresa}>
            <option value='0'>Seleccione una opcion</option>
            {
                   empresa[0].map((props, index) => (
                    props.id_empresa !== 3 && (
                      <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                    )
                  ))
            }
            </Form.Select>
            
            <Form.Group >
          <Form.Label>Seleccione el dia</Form.Label>
          <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
          </Form.Group>

          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>

          </Form>
        </Container>
    )
}