import { NavBar } from '../../components/navbar';
import notFound from '../../css/404.css';
import {useNavigate} from 'react-router-dom'
export function NotFound() {
  const navigate = useNavigate();
  function returnHome (){
    navigate('/')
  }
  return (
    <>
      <NavBar />
    <div className='container'>
    <div class="face">
    <div class="band">
      <div class="red"></div>
      <div class="white"></div>
      <div class="blue"></div>
    </div>
    <div class="eyes"></div>
    <div class="dimples"></div>
    <div class="mouth"></div>
  </div>
  <h1>Error 404</h1>
  <h1>Oops! No deberias estar aqui</h1>
  <div class="botonNotFound" onClick={returnHome}>Regresar al inicio</div>
  </div>
  </>
  )
}