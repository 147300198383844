import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL


export const getMovimientosCentroCostos = async (data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/centroCostos`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             id_empresa: data.idEmpresa,
             id_agencia: data.idAgencia,
             cuenta: data.buscarCuenta,
             limit: data.limit,
             page: data.page,
             nivel: data.nivelCuenta,
             tipoCuenta: data.tipoCuenta
         })
   })
   const respuesta = await response.json()
   return respuesta
}