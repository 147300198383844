import { Button, Container, Form } from "react-bootstrap";
import { EmpresasSelect } from "../UTILS/EmpresasSelect";
import { EmpresaXAgenciaSelect } from "../UTILS/EmpresaXAgenciaSelect";
import { useState } from "react";
import { getPartidaDiario } from "../../services/partidas/partidas";

export function BuscadorPartidaDiario(props) {
    const [idAgencia, setIdAgencia] = useState(null)
    const [fecha, setFecha] = useState(null)
    const token = localStorage.getItem('token');
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresas, setempresa] = useState([[]])
    
    async function sendBuscar(e){
        e.preventDefault()

        const object = {
          idAgencia: idAgencia,
          fecha: fecha,
          token: token
        }
      const result = await getPartidaDiario(object)
      props.onFinish(result, fecha)
      props.empresa(idEmpresa)
      props.agencia(idAgencia)
       
        
      }
    const handleChange = (e) => {
        setIdEmpresa(e)
    }

    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
           <center> <h2>Partida diario</h2> </center>
          
          <EmpresasSelect
            onChange= {(e) => handleChange(e)}
            empresa={(e) => setempresa(e)}
          />
          
          <EmpresaXAgenciaSelect
          IdEmpresa = {idEmpresa}
          onChange= {(e) => setIdAgencia(e)}
          showlabel = {true}
          agencia = {0}
          />
          
            
            <Form.Group >
            <Form.Label>Seleccione el dia</Form.Label>
            <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
          </Form.Group>
           
          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>

          </Form>
        </Container>
       
    )
}