const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const GetCuentasGenerales = async(data) => {
    const response = await fetch(`${BASE_URL}/reportes/getCuentasGenerales/${data.page}`, {
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({
           cuenta: data.search
            
        })
    })
    const res = await response.json();
    return res
    
}
export const GetCuentasGeneralesOperativas = async(data) => {
    const response = await fetch(`${BASE_URL}/reportes/getCuentasGeneralesOperativas/${data.page}`, {
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({
           cuenta: data.search
            
        })
    })
    const res = await response.json();
    return res
    
}


export const deleteCuentaGeneral = async(data) => {
    const response = await fetch(`${BASE_URL}/reportes/eliminarCuenta/${data.id_cuenta}`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const res = await response.status;
    return res
    
}

export const insertCuentaGeneral = async(data) => {
    const response = await fetch(`${BASE_URL}/reportes/insertarCuentaGeneral`,{
        "method": "POST",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            numero_cuenta: data.numero_cuenta,
            nombre_cuenta: data.nombre_cuenta,
            tipo_empresa: data.tipo_empresa
            
        })

    })
    const res = await response.status
    return res
}

export const saveCuentaEmpresaUnica = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/saveCuentaEmpresaUnica`,{
        "method": "PATCH",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            numero_cuenta: data.numero_cuenta,
            id_empresa: data.id_empresa,
            
        })

    })
    const res = await response.status
    return res
}
export const desmarcarCuenta = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/desmarcarCuentaEmpresaUnica`,{
        "method": "PATCH",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            numero_cuenta: data.numero_cuenta,  
        })

    })
    const res = await response.status
    return res
}

export const getSaldosSubCuentas = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/getSaldosCuentas`,{
        "method": "POST",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            mes: data.mes,
            agencia: data.agencia,
            cuenta: data.cuenta,  
        })

    })
    const res = await response.json()
    return res
}

export const asignarSaldoCuentas = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/asignarSaldo_subcuenta`,{
        "method": "POST",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            mes: data.mes,
            agencia: data.agencia,
            cuenta: data.cuenta,
            saldo: data.saldo,  
        })

    })
    const res = await response.status
    return res
}

export const getRegistro = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/getOneSaldoCuenta/${data.id}`,{
        "method": "GET",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
      

    })
    const res = await response.json()
    return res
}

export const editarSaldoCuentas = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/editarSaldosCuentas`,{
        "method": "POST",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: data.id,
            saldo: data.saldo,  
        })
      

    })
    const res = await response.status
    return res
}

export const eliminarSaldoCuentas = async(data) => {
    const response = await fetch(`${BASE_URL}/CuentaContable/eliminarSaldoCuenta/${data.id}`,{
        "method": "PATCH",
        headers:{
            "Authorization": `Bearear ${data.token}`,
            "Content-Type": "application/json"
        },
      

    })
    const res = await response.status
    return res
}