import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const AceptarModal = (props) => {
    return (
    <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>¿Guardar partida?</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea guardar los cambios realizados?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={props.save} >
            Guardar cambios
          </Button>
          <Button variant="primary" onClick={props.saveExit} >
            Guardar y salir
          </Button>
        </Modal.Footer>
      </Modal>
    )
}