import { Button, Card, Container, Form, Modal, Stack, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import '../../css/Tablas.css'
import { AiOutlineArrowLeft, AiOutlineSearch } from "react-icons/ai";
import { useState } from "react";
import * as XLSX from 'xlsx';
import { eliminarCreditos } from "../../services/infoRed/infoRed";
import Swal from "sweetalert2";
export function EliminarInfoRed() {

    const [showModal, setShowModal] = useState(false)
    const [datosExcel, setdatosExcel] = useState([])

    const handleFile = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetname = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetname];
          const datos = XLSX.utils.sheet_to_json(sheet);
          setdatosExcel(datos);
          console.log(datos);
        }
    }
    const sendDataExcel = async() => {
        console.log('entre')
        if(datosExcel.length === 0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No se ha seleccionado ningun archivo',
          })
        }else{

            let i = 0;
            let j = 200
            while(j < datosExcel.length){
                if(j === datosExcel.length){
                    j = datosExcel.length+1 ;
                    break;
                }
                else if(datosExcel.length < 200){
                    j = datosExcel.length;
                }
                const object = {
                    token: localStorage.getItem("token"),
                    data: datosExcel.slice(i, j)
                }
                const res = await eliminarCreditos(object);
                console.log(res);
                i = j;
                if(j + 200 > datosExcel.length){
                    let diferencia = datosExcel.length - j;
                    j = j + diferencia;
                }else{
                    j += 200;
                
                }
                console.log(i);
                console.log(j);
            }
            

        // const object = {
        //     token: localStorage.getItem("token"),
        //     data: datosExcel
        // }
        //  const res = await eliminarCreditos(object);
        //  console.log(res);
        //  if(res === 200){
        //     Swal.fire({
        //         icon: 'success',
        //         title: 'Exito',
        //         text: 'Datos eliminados correctamente',
        //       })
        //  }else{
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Ha ocurrido un error',
        //       })
        //  }
    
        }
    }
    return (
        <>
            <NavBar />
            <div style={{ margin: '60px', padding: "10px", textAlign: 'center', backgroundColor: "#b4cbd6", borderRadius: "15px" }} className="boxShadow">
                <h1>Eliminar Informacion de Red</h1>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft: "45%" }}>
                    <Stack direction="horizontal" gap={3}>
                        <Form.Control className="Busqueda" placeholder="Buscar por credito" style={{ width: "500px" }} />
                        <Button><AiOutlineSearch /></Button>

                        <Button style={{ marginLeft: "10px" }} variant="primary" onClick={() => setShowModal(true)} >Importar de excel</Button>
                    </Stack>
                    <br />
                </div>

                <Table className="custom-table" >
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Red1</td>
                            <td><button>Eliminar</button></td>
                        </tr>
                        <tr>
                            <td>Red2</td>
                            <td><button>Eliminar</button></td>
                        </tr>
                        <tr>
                            <td>Red3</td>
                            <td><button>Eliminar</button></td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            {/* MODAL PARA AGREGAR CUENTAS  A LOS PAGOS ENTRE AGENCIAS */}
            <Modal show={showModal} onHide={() => setShowModal(false)} style={{width:'10`0%'}}>
                <Modal.Header closeButton>
                    <Modal.Title>Ingrese la cuenta pago entre agencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "80%", padding: "50px", borderRadius: "30px", marginTop: "8%" }}>
                        <div>


                            <Card className="text-center" style={{ width: '100%', height: "75%", maxWidth: "500px" }}>
                                <Card.Header></Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ color: "white" }}>Ingresar datos</Card.Title>
                                    <Form.Label>Seleccionar el archivo</Form.Label>
                                    <Form.Control type="file" accept=" .xls .xlsx .csv" onChange={handleFile} />
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="primary" onClick={sendDataExcel}>Enviar</Button>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Container>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" >
                        Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}