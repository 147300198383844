import { NavBar } from "../../components/navbar";
import '../../css/tableBalanza.css';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { useState,useEffect } from "react";
import '../../css/Tablas.css';
import Button from 'react-bootstrap/Button';
import {AiOutlineSearch} from 'react-icons/ai';
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { editarSubTipoMovBoveda, eliminarSubTipoMovBoveda, getCuentasGenerales, getSubtiposMovBoveda, getTiposMovBov, guardarSubTipoMovBoveda } from "../../services/subTiposMovBoveda/subtiposMovBoveda";
import { Modal, ModalFooter } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import { getCuentaEmpresas } from "../../services/partidas/partidas";
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {AiFillQuestionCircle} from 'react-icons/ai'
import Swal from 'sweetalert2'
import { AddCuentaContableBanco, GetBancos } from "../../services/bancos/bancos";


export function Bancos(){
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [cuentas, setCuentas] = useState([]);
    const [cuenta, setCuenta] = useState(null);
    const [contraCuenta, setContraCuenta] = useState(null);
    const [id_bancos, setIdBancos] = useState(null);
    const [helper, setHelper] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const object = {
                token: localStorage.getItem("token")
            }
            const response = await GetBancos(object);
            setData(response);
        }
        fetchData();

        const fetchCuentas = async () => {
            const object = {
                token: localStorage.getItem('token')
            }
            const res = await getCuentasGenerales(object)
            let options = []
            res.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta+"-"+index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
        fetchCuentas();

    }, [helper]);
    const handleShowModal = (id) => {
        setShowModal(true);
        setIdBancos(id);
    }
    const handleNewCuenta = async () => {
        if(cuenta === null || contraCuenta === null){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Selecciona una cuenta',
              })
        }else{
            const object = {
                token: localStorage.getItem('token'),
                id_bancos: id_bancos,
                cuenta_contable: cuenta,
                contra_cuenta_contable: contraCuenta
            }
            const res = await AddCuentaContableBanco(object)
            if(res.status === 200){
                Swal.fire({
                    icon: 'success',
                    title: 'Cuenta agregada',
                    text: 'La cuenta se agrego correctamente',
                  })
                  setShowModal(false);
                  setCuenta(null);
                  setContraCuenta(null);
                  setHelper(!helper);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrio un error',
                  })
            }
        }
    }

    return(
        <>
         <NavBar></NavBar>
         <div className="shadow" style={{ margin: '30px auto', maxWidth: '90%', textAlign: 'center' }}>
        <h1 style={{marginBottom:"20px", marginTop:"20px"}}>Bancos</h1>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft:"10%" }}>
        <Stack direction="horizontal" >
        {/* <Form.Control className="Busqueda" placeholder="Búsqueda de cuenta" style={{ width: "500px" }} value={search} onChange={e => handleSearch(e.target.value.toLowerCase())} />
        <Button onClick={handleSearch}><AiOutlineSearch /></Button> */}
        </Stack>
        <br />
        </div>
        <Table className="my-table" style={{ margin: '20px auto' }}  >
        <thead>
            <tr>
                <th >Banco</th>
                <th >Cuenta de banco</th>
                <th>Cuenta contable predeterminada</th>
                <th>Contr cuenta contable predeterminada</th>
                <th>Empresa</th>
                <th>Accion</th>
            </tr>
        </thead>
        <tbody>
            {data.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.nombre}</td>
                        <td>{item.cuenta_banco}</td>
                        <td>{item.cuenta_contable === null ? <>Sin cuenta asignada</> : <>{item.cuenta_contable}</>}</td>
                        <td>{item.contra_cuenta_contable === null ? <>Sin cuenta asignada</> : <>{item.contra_cuenta_contable}</>}</td>
                        <td>{item.Empresa.nombre_empresa}</td>
                        <td><Button onClick={() => {handleShowModal(item.id_bancos)}}>Agregar CC predeterminada</Button></td>
                    </tr>
                )
            })}

        </tbody>
    </Table>

    </div>
      {/* modal para agregar un nuevo concepto */}
      <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar cuenta contable predeterminada</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >
        <Form.Group>
        <Form.Label>Selecciona la cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setCuenta(e)}

        />
        </Form.Group>
        <Form.Group>
        <Form.Label>Selecciona la contra cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setContraCuenta(e)}

        />
        </Form.Group>



             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleNewCuenta()}>
                Guardar
                </Button>
            </ModalFooter>
        </Modal>
        {/* FIN */}
        </>
    )
}