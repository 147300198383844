import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { EmpresaXAgenciaSelect } from "../UTILS/EmpresaXAgenciaSelect";
import { getEmpresas, getEmpresasAll } from "../../services/geolocalizacion/places";
import { useNavigate } from "react-router-dom";

export function Buscador(props){
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresa, setEmpresa] = useState([[]])
    const [idAgencia, setIdAgencia] = useState(null)
    const [fecha, setFecha] = useState('')
    const [nombre, setNombre] = useState('')
    const token = localStorage.getItem('token');
    const navigate = useNavigate()
    const sendBuscar = (e) => {
        e.preventDefault()
        props.onChange(idEmpresa, idAgencia, fecha)
    }
    const setNombreEmpresa = (e) => {
        setIdEmpresa(e.target.value)
    }
    useEffect(() => {
        
        const fetchData = async () => {
          const object = { token }
          const tipos = await getEmpresasAll(object)
          setEmpresa([tipos])
        }

        fetchData()
     
        
     
    }, [])
    const gotoListado = () => {
        navigate('/listadoPartidasPlanillas')
    }



    return (
        <>
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
           <center> <h2>Buscar partida de {props.tipo}</h2> </center>
           

            <Form.Label>Empresa</Form.Label>
            <Form.Select aria-label="Default select example" onChange={setNombreEmpresa}>
            <option value='0'>Seleccione una opcion</option>
            {
                   empresa[0].map((props, index) => (
                   
                      <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                   
                  ))
            }
            </Form.Select>

            <EmpresaXAgenciaSelect
          IdEmpresa = {idEmpresa}
          onChange= {(e) => setIdAgencia(e)}
          showlabel = {true}
          agencia = {null}
          />
            
            <Form.Group >
          <Form.Label>Seleccione el dia</Form.Label>
          <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
          </Form.Group>

          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>
          <Button variant="warning" type="button" onClick={gotoListado} className='my-2 mx-2' >
            Listado de partidas
          </Button>

          </Form>
        </Container>
        </>
    )
}