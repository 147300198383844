let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getCargosAbonos = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/cuadraturaMovimientos`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             empresa: data.empresa,
             agencia: data.agencia,
             inicio: data.inicio,
             fin: data.fin,             
         })
   })
   const respuesta = await response.json()
   return respuesta
}
