import { useState, useEffect } from "react";
import { NavBar } from "../../components/navbar"
import { Table, Button } from "react-bootstrap";
import { Buscador } from "../../components/reporteCierres/buscador";
import { Container, Form} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AiOutlineArrowLeft, AiOutlineArrowDown } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal';
import { getTipos, getCuenta, getCuentaEmpresas } from "../../services/partidas/partidas";
import { AbonoMovimientoPagEntreAgencia, actualizarCuentaCierreDiario, actualizarCuentaMovBoveda, actualizarMovBanco, actualizarMovDistPago, distribucionCuotas, getMovBancos, getMovimientosBoveda, getPagosEntreAgencias, getPagosEntreAgenciasSumatoria, guardarMovimientosEntreAgencias, reporteCierresDiarios, revertirCierre } from "../../services/reportes/reportesCuentas";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { AiOutlineFileExcel, AiFillEdit, AiOutlineSearch } from 'react-icons/ai'
import { GiConfirmed } from 'react-icons/gi'
import { aprobarCierreDiario } from "../../services/reportes/reportesCuentas";
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import Nav from 'react-bootstrap/Nav';
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { FaTrash } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
export function ReporteCierresDiarios() {
    const [data, setData] = useState(null)
    const [tableRow, settableRow] = useState(null)
    const [hasData, sethasData] = useState(false)
    const [agencia, setAgencia] = useState('')
    const [fecha, setFecha] = useState('')
    const [show, setShow] = useState(false);
    const [accountPosition, setAccountPosition] = useState("")
    const [cuenta, setCuenta] = useState([])
    const [cuentaEntreAgencias, setCuentaEntreAgencias] = useState([])
    const [cuentas, setCuentas] = useState([[]])
    const [actualizarData, setActualizarData] = useState(false) //para actualizar la data
    const dispatch = useDispatch();
    const token = localStorage.getItem('token')
    const [empresa, setEmpresa] = useState(1)
    const [idAgencia, setIdAgencia] = useState(0)
    const [pagosEntreAgencia, setPagosEntreAgencia] = useState(false)
    const [dataPagosEntreAgencia, setDataPagosEntreAgencia] = useState([])
    const [showModalEntreAgencias, setModalEntreAgencias] = useState(false)
    const [comprobante, setComprobante] = useState(null)
    const [tipoCuenta, setTipoCuenta] = useState(null)
    const [egresosBoveda, setEgresosBoveda] = useState([])
    const [ingresosBoveda, setIngresosBoveda] = useState([])
    //const [movimientosBoveda, setMovimientosBoveda] = useState([])
    const [idMovimientoBoveda, setIdMovimientoBoveda] = useState(null)
    const [showMovBoveda, setShowMovBoveda] = useState(false)
    const [cuentaMovBoveda, setCuentaMovBoveda] = useState(null)
    const [validator, setValidator] = useState(null)
    const [displayCierre, setDisplayCierre] = useState("none")
    const [displayCobroCuotas, setDisplayCobroCuotas] = useState("none")
    const [displayMovBoveda, setDisplayMovBoveda] = useState("none")
    const [displayMovBanco, setDisplayMovBanco] = useState("none")
    const [tableCobros, setTableCobros] = useState(null)
    const [tableMovimientosBoveda, setTableMovimientosBoveda] = useState(null)
    const [tableMovimientosBanco, setTableMovimientosBanco] = useState(null)
    const [showDistribucion, setShowDistribucion] = useState(false)
    const [idMovimientoDistribucion, setIdMovimientoDistribucion] = useState(null)
    const [cuentaDistPagos, setCuentaDistPagos] = useState([])
    const [tipoCuentaDistPagos, setTipoCuentaDistPagos] = useState(null)
    const [idMovBancos, setIdMovimientoBanco] = useState(null)
    const [showMovBancos, SetShowMovBancos] = useState(false)
    const [cuentaMovBancos, setCuentaMovBancos] = useState([])
    const [validatorBanco, setValidatorBanco] = useState(null)
    ///////////////////////////////////////////////

    const [cuenta_prestamos, setCuenta_prestamos] = useState(null);
    const [contra_cuenta_prestamos, setContra_cuenta_prestamos] = useState(null);
    const [cuenta_refinanciamiento, setCuenta_refinanciamiento] = useState(null);
    const [contra_cuenta_refinanciamiento, setContra_cuenta_refinanciamiento] = useState(null);
    const [cuenta_transferencia, setCuenta_transferencia] = useState(null);
    const [contra_cuenta_transferencia, setContra_cuenta_transferencia] = useState(null);
    const [cuenta_empleados, setCuenta_empleados] = useState(null);
    const [contra_cuenta_empleados, setContra_cuenta_empleados] = useState(null);
    const [cuenta_refinanciamiento_empleados, setCuenta_refinanciamiento_empleados] = useState(null);
    const [contra_cuenta_refinanciamiento_empleados, setContra_cuenta_refinanciamiento_empleados] = useState(null);
    const [cuenta_empleados_transferencia, setCuenta_empleados_transferencia] = useState(null);
    const [contra_cuenta_empleados_transferencia, setContra_cuenta_empleados_transferencia] = useState(null);
    const [cuenta_cobro, setCuenta_cobro] = useState(null);
    const [contra_cuenta_cobro, setContra_cuenta_cobro] = useState(null);
    const [cuenta_faltante, setCuenta_faltante] = useState(null);
    const [contra_cuenta_faltante, setContra_cuenta_faltante] = useState(null);
    const [cuenta_faltante_sin, setCuenta_faltante_sin] = useState(null);
    const [contra_cuenta_faltante_sin_fisico, setContra_cuenta_faltante_sin_fisico] = useState(null);
    const [cuenta_sobrante, setCuenta_sobrante] = useState(null);
    const [contra_cuenta_sobrante, setContra_cuenta_sobrante] = useState(null);

    const [cuenta_remesa_banco, setCuenta_remesa_banco] = useState(null);
    const [contra_cuenta_remesa_banco, setContra_cuenta_remesa_banco] = useState(null);
    const [cuenta_retiro_banco, setCuenta_retiro_banco] = useState(null);
    const [contra_cuenta_retiro_banco, setContra_cuenta_retiro_banco] = useState(null);
    
    //BLOQUE PARA MANEJAR MOVIMIENTOS EXTRA EN EL PAGO ENTRE AGENCIAS
    const [showMovimientoExtra, setMovimientoExtra] = useState(false)
    const [datosMovimientoExtra, setDatosMovimientoExtra] = useState([])
    const [cuentaMovExtra, setCuentaMovExtra] = useState(null)
    const [tipoMovimientoExtra, setTipoMovimientoExtra] = useState(null)

    //CAMBIO 28082024
    const handlerMovExtraEntreAgencias = (datos, tipoMovimiento) => {
        console.log(datos)
        setDatosMovimientoExtra(datos)
        if(tipoMovimiento == 'Cuenta por cobrar'){
            setTipoMovimientoExtra(1)
        }else{
            setTipoMovimientoExtra(2)
        }
        setMovimientoExtra(!showMovimientoExtra)
    }

    const agregarMovimientoExtra = () => {
        if(cuentaMovExtra == null || tipoMovimientoExtra == null){
            Swal.fire({
                title: 'Error',
                text: 'Por favor complete todos los campos',
                icon: 'error',
                allowOutsideClick: false
            })
            return
        }
        const object = {
            agencia: idAgencia,
            comprobante: datosMovimientoExtra.comprobante,
            cuenta: cuentaMovExtra[0],
            empresa: empresa,
            fecha: fecha,
            token: token,
            tipo_movimiento: tipoMovimientoExtra,
            monto: datosMovimientoExtra.monto_ingresado,
            cierre: data[0].id_resumen_agencia,
    }
    console.log(object)
    Swal.fire({
        title: 'Estas seguro de agregar este movimiento?',
        showDenyButton: true,
        confirmButtonText: `Si`,
        denyButtonText: `No`,
        allowOutsideClick: false
    }).then(async (result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            });
            const res = await AbonoMovimientoPagEntreAgencia(object)
            setMovimientoExtra(!showMovimientoExtra)
            Swal.close()
            if(res === 200){
                Swal.fire({
                    title: 'Movimiento agregado!',
                    text: 'El movimiento se ha agregado correctamente',
                    icon: 'success'
                }).then(() => {
                    // Recargar la página después de que el usuario haga clic en "Aceptar"
                    // window.location.reload(false)
                    setPagosEntreAgencia(false)
                    handleChangeSearch()
                });
               
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar movimiento',
                    showConfirmButton: false,
                    timer: 1500
                })
                setPagosEntreAgencia(false)
            }
        }
    })
    }

    const traerPagosEntreAgencias = async () => {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        });
        const object = {
            token: token,
            agencia: data[0].agencia.id_agencia,
            fecha: data[0].fecha.substring(0, 10),
            id_cierre: data[0].id_resumen_agencia,
        }
        const response = await getPagosEntreAgencias(object)
        Swal.close()

        setPagosEntreAgencia(true)
        setDataPagosEntreAgencia(response)


    }



    const confirmCierreDiario = () => {
        Swal.fire({
            title: '¿Estas seguro de confirmar este cierre?',
            text: "No podras revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then(async (result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: 'Cargando...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading();
                    },
                });

                const dataObject = {


                    prestamos_otorgados: {
                        monto: data[0].prestamos_otorgados,
                        cuenta: cuenta_prestamos,
                        contra_cuenta: contra_cuenta_prestamos
                    },
                    prestamos_refinanciamiento: {
                        monto: data[0].prestamos_refinanciamiento,
                        cuenta: cuenta_refinanciamiento,
                        contra_cuenta: contra_cuenta_refinanciamiento
                    },
                    prestamos_transferencia: {
                        monto: data[0].prestamos_transferencia,
                        cuenta: cuenta_transferencia,
                        contra_cuenta: contra_cuenta_transferencia
                    },
                    prestamos_empleados: {
                        monto: data[0].prestamos_empleados,
                        cuenta: cuenta_empleados,
                        contra_cuenta: contra_cuenta_empleados
                    },
                    refinanciamiento_empleados: {
                        monto: data[0].refinanciamiento_empleados,
                        cuenta: cuenta_refinanciamiento_empleados,
                        contra_cuenta: contra_cuenta_refinanciamiento_empleados
                    },
                    prestamos_empleados_transferencia: {
                        monto: data[0].prestamos_empleados_transferencia,
                        cuenta: cuenta_empleados_transferencia,
                        contra_cuenta: contra_cuenta_empleados_transferencia
                    },
                    faltante: {
                        monto: data[0].faltante,
                        cuenta: cuenta_faltante,
                        contra_cuenta: contra_cuenta_faltante
                    },
                    faltante_sin_fisico: {
                        monto: data[0].faltante_sin_fisico,
                        cuenta: cuenta_faltante_sin,
                        contra_cuenta: contra_cuenta_faltante_sin_fisico
                    },
                    sobrante: {
                        monto: data[0].sobrante,
                        cuenta: cuenta_sobrante,
                        contra_cuenta: contra_cuenta_sobrante
                    },


                }
                const object = {
                    id_cierre: data[0].id_resumen_agencia,
                    token: token,
                    data: dataObject,
                    empresa: empresa,
                    ingresosBoveda: ingresosBoveda,
                    egresosBoveda: egresosBoveda,
                    fecha: fecha,
                }

                const response = await aprobarCierreDiario(object)

                if (response.message === 'Actualización exitosa') {

                    Swal.fire({
                        title: 'Cierre confirmado!',
                        text: 'El cierre se ha confirmado correctamente',
                        icon: 'success'
                    }).then(() => {
                        // Recargar la página después de que el usuario haga clic en "Aceptar"
                        // window.location.reload(false)
                        handleChangeSearch()
                    });

                } else {
                    let errores = "";
                    for (let index in response) {
                        errores += response[index] + "\n"; // Agregar \n para el salto de línea
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: errores,
                        footer: '<pre>Estos son los errores encontrados</pre>'
                    });
                }
            }
        }
        )

    }
    const handleDownoadexcel = () => {
        // Obtén la referencia a la tabla (puedes utilizar un ID o una referencia de React)
        const table = document.getElementById('tableData');

        // Crea un array para almacenar los datos de la tabla
        const data = [];

        // Recorre las filas de la tabla
        for (let i = 0; i < table.rows.length; i++) {
            const row = table.rows[i];
            const rowData = [];

            // Recorre las celdas de cada fila
            for (let j = 0; j < row.cells.length; j++) {
                const cellData = row.cells[j].textContent;
                if (cellData == 'Ingresar movimientos entre agencias') {
                    for (let k = 0; k < dataPagosEntreAgencia.length; k++) {
                        rowData.push(dataPagosEntreAgencia[k].monto)
                        rowData.push(dataPagosEntreAgencia[k].cuenta)
                        rowData.push(dataPagosEntreAgencia[k].contra_cuenta)
                    }
                }
                if (cellData !== 'Ingresar movimientos entre agencias' && cellData !== 'Ingresar ingresos de boveda' && cellData !== 'Ingresar egresos de boveda') {

                    rowData.push(cellData);
                }
            }

            data.push(rowData);
        }

        // Crea un libro de Excel y una hoja de cálculo
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        // Agrega la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

        // Genera el archivo Excel
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crea un objeto Blob para el archivo Excel
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Crea un objeto URL para el archivo Excel
        const excelUrl = URL.createObjectURL(blob);

        // Crea un enlace para la descarga del archivo Excel
        const link = document.createElement('a');
        link.href = excelUrl;
        link.download = 'tabla.xlsx';

        // Dispara el evento de clic para descargar el archivo
        link.click();


    }

    const handleData = (object) => {
        setDisplayCierre("block")
        setData(object)
        sethasData(true)
        dispatch({
            type: 'CHANGE', payload: {
                agencia: 0,
                fecha: ""
            }
        });
    }


    const goBack = () => {

        sethasData(false)
        setData([])
    }
    const handleChangeAccountBancos = (id_movimiento, tipo) => {
        setIdMovimientoBanco(id_movimiento)
        SetShowMovBancos(true)
        setValidatorBanco(tipo)
    }
    const handleAccountBancos = async () => {
        const object = {
            id_movimiento: idMovBancos,
            cuenta: cuentaMovBancos[0],
            token: token,
            validator: validatorBanco
        }
        const response = await actualizarMovBanco(object)
        if (response === 200) {
            SetShowMovBancos(false)
            Swal.fire({
                icon: 'success',
                title: 'Actualización exitosa',
                showConfirmButton: false,
                timer: 1500
            })
            setActualizarData(!actualizarData)
        } else {
            SetShowMovBancos(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo actualizar la cuenta',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    const handleChangeAccountDistPagos = (id_movimiento, tipo) => {
        setIdMovimientoDistribucion(id_movimiento)
        setShowDistribucion(true)
        setTipoCuentaDistPagos(tipo)
    }
    const handleAccountDistPagos = async () => {
        const object = {
            id_movimiento: idMovimientoDistribucion,
            cuenta: cuentaDistPagos[0],
            token: token,
            tipo: tipoCuentaDistPagos
        }
        const response = await actualizarMovDistPago(object)
        if (response === 200) {
            setShowDistribucion(false)
            Swal.fire({
                icon: 'success',
                title: 'Actualización exitosa',
                showConfirmButton: false,
                timer: 1500
            })
            setActualizarData(!actualizarData)
        } else {
            setShowDistribucion(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo actualizar la cuenta',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    //HANDLER PARA CAMBIAR CUENTAS DE MOVIMIENTOS DE BOVEDA
    const handleChangeAccountBoveda = (id_movimiento, tipo) => {
        setIdMovimientoBoveda(id_movimiento)
        setValidator(tipo)
        setShowMovBoveda(true)
    }
    const handleAccountMovBoveda = async () => {


        const object = {
            id_movimiento: idMovimientoBoveda,
            cuenta: cuentaMovBoveda[0],
            token: token,
            validator: validator
        }
        const response = await actualizarCuentaMovBoveda(object)
        if (response.message === 'Actualización exitosa') {
            setShowMovBoveda(false)
            Swal.fire({
                icon: 'success',
                title: 'Actualización exitosa',
                showConfirmButton: false,
                timer: 1500
            })
            setActualizarData(!actualizarData)
        } else {
            setShowMovBoveda(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo actualizar la cuenta',
                showConfirmButton: false,
                timer: 1500
            })
        }

    }
    const handleChangeAccount = (location) => {
        setAccountPosition(location)
        setShow(true)
    }
    const handleAccount = async () => {

        const object = {
            id_cierre: data[0].id_resumen_agencia,
            cuenta: cuenta[0],
            nombre_cuenta: accountPosition,
            token: token
        }
        const response = await actualizarCuentaCierreDiario(object)

        if (response.message === 'Actualización exitosa') {
            setShow(false)
            Swal.fire({
                icon: 'success',
                title: 'Actualización exitosa',
                showConfirmButton: false,
                timer: 1500
            })
            setActualizarData(!actualizarData)
            data[0][accountPosition] = cuenta[0]
        } else {
            setShow(false)
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo actualizar la cuenta',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    const handleClose = () => setShow(false);

    const editCuenta = (cuenta) => {
        console.log(cuenta)
    }

    useEffect(() => {



        const fetchDataCuenta = async () => {
            if (data.length != 0) {
                const object = {
                    token: token,
                    empresa: empresa,
                    agencia: data != null ? data[0].agencia.id_agencia : null
                }
                const cuentas = await getCuentaEmpresas(object)
                let options = []
                cuentas.map(((index, i) => {
                    options[i] =
                    {
                        label: index.nombre_cuenta + "-" + index.numero_cuenta,
                        value: index.numero_cuenta
                    }

                }))
                setCuentas([options])
            }
        }
        fetchDataCuenta()

        async function setRowFiltered() {
            
            //TRAER LOS MOVIMIENTOS DE LA BOVEDA
            if (data.length != 0) {
                //TRAER LOS PAGOS ENTRE AGENCIAS
                const objectPagoEntreAgenciaSumatoria = {
                    token: token,
                    id_resumen_operaciones: data[0].id_resumen_agencia,
                }
                const entre_agencia = await getPagosEntreAgenciasSumatoria(objectPagoEntreAgenciaSumatoria)
                let respuesta = (await entre_agencia.json())
                let pagos_recibidos = entre_agencia.status === 200 ? respuesta.cobro_recibido : 0
                
                const fetchMovimientosObject = {
                    token: token,
                    agencia: data[0].agencia.id_agencia,
                    fecha: data[0].fecha.substring(0, 10),
                }
                const response = await getMovimientosBoveda(fetchMovimientosObject)

                const egreso = []
                const ingreso = []
                response.forEach(element => {
                    if (element.Tipos_Movimiento.accion === "Restar") {

                        egreso.push(element)
                    } else {
                        setIngresosBoveda(element)
                        ingreso.push(element)
                    }

                });

                setEgresosBoveda(egreso)
                setIngresosBoveda(ingreso)

                //FINALIZA
                //TRAER LOS PAGOS ENTRE AGENCIAS
                const objectPagoEntreAgencia = {
                    token: token,
                    agencia: data[0].agencia.id_agencia,
                    fecha: data[0].fecha.substring(0, 10),
                    id_cierre: data[0].id_resumen_agencia,
                }
                const pagos_agencia = await getPagosEntreAgencias(objectPagoEntreAgencia)
              
                const cobros_recibidos_entre_agencias = []
                const cobros_recibidos_entre_empresas = []
                const pago_cuota_entre_agencias = []
                const pago_cuota_entre_empresas = []
                cobros_recibidos_entre_agencias.push(pagos_agencia.cobros_recibidos_entre_agencias)
                cobros_recibidos_entre_empresas.push(pagos_agencia.cobros_recibidos_entre_empresas)
                pago_cuota_entre_agencias.push(pagos_agencia.pago_cuota_entre_agencias)
                pago_cuota_entre_empresas.push(pagos_agencia.pago_cuota_entre_empresas)

                setDataPagosEntreAgencia(pagos_agencia)
                if (data != null) {
                    const objectDistPagos = {
                        token: token,
                        id_cierre: data[0].id_resumen_agencia,
                    }
                    const distribucionPagos = await distribucionCuotas(objectDistPagos)
                    let totalDistribucionPagos = 0
                    const objectMovBancos = {
                        token: token,
                        agencia: data[0].agencia.id_agencia,
                        fecha: data[0].fecha.substring(0, 10),
                    }
                    const movimientosBanco = await getMovBancos(objectMovBancos)
                    console.log(movimientosBanco)

                    //FINALIZA
                    const table = []
                    const tableCobros = []
                    const tableMovimientosBoveda = []
                    const tableMovimientosBanco = []



                    if (data.length != 0) {
                        setAgencia(data[0].agencia.agencia)
                        setFecha(data[0].fecha.substring(0, 10))
                        const fin_dia = data[0].saldo_inicial - data[0].prestamos_otorgados + data[0].prestamos_refinanciamiento + data[0].prestamos_transferencia - data[0].prestamos_empleados + data[0].refinanciamiento_empleados + data[0].cobro_coutas - data[0].faltante + data[0].sobrante + data[0].ingreso_boveda - data[0].egresos_boveda - data[0].remesa_banco + data[0].retiro_banco  + pagos_recibidos
                        console.log(data)
                        data[0].cobro_cuotas = (data[0].cobro_cuotas)
                      
                        tableMovimientosBoveda.push(
                            <>
                                <tr>
                                    <td>+/-</td>
                                    <td>Concepto</td>
                                    <td>Monto</td>
                                    <td>Cuenta ingreso</td>
                                    <td>Monto</td>
                                    <td>Cuenta egreso</td>
                                    <td>Comentario</td>
                                </tr>
                                {egreso.map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>{element.Subtipos_Movimiento !== null ? <>{element.Subtipos_Movimiento.nombre}</> : <>{element.Tipos_Movimiento.nombre}</>}</td>
                                            <td>{element.total}</td>
                                            <td>{element.cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBoveda(element.id_movimiento, 1) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}</td>
                                            <td>{element.total}</td>
                                            <td>{element.contra_cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBoveda(element.id_movimiento, 2) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}</td>
                                            {element.comentario=== '' ? ( 
                                                <td>No hay comentarios</td>
                                            ) : (
                                                <td>{element.comentario}</td>
                                            )}
                                        </tr>
                                    )
                                })
                                }

                                <tr className="bg-success text-white">

                                    <td><p style={{ color: "red" }}>-</p></td>
                                    <td>Egresos de boveda</td>
                                    <td colSpan={5}>
                                        Subtotal: {data[0].egresos_boveda}
                                    </td>
                                </tr>
                                {ingreso.map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>{element.Subtipos_Movimiento !== null ? <>{element.Subtipos_Movimiento.nombre}</> : <>{element.Tipos_Movimiento.nombre}</>}</td>
                                            <td>{element.total}</td>
                                            <td>{element.cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBoveda(element.id_movimiento, 1) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                            <td>{element.total}</td>
                                            <td>{element.contra_cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBoveda(element.id_movimiento, 2) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}</td>
                                            {element.comentario=== '' ? ( 
                                                <td>No hay comentarios</td>
                                            ) : (
                                                <td>{element.comentario}</td>
                                            )}
                                        </tr>
                                    )
                                })
                                }


                                <tr className="bg-success text-white">
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Ingresos de boveda</td>


                                    <td colSpan={5}>
                                        Subtotal: {data[0].ingreso_boveda}
                                    </td>

                                </tr>

                            </>
                        )
                        tableCobros.push(
                            <>
                                <tr>
                                    <td>+/-</td>
                                    <td>Concepto</td>
                                    <td>Monto</td>
                                    <td>Cuenta ingreso</td>
                                    <td>Monto</td>
                                    <td>Cuenta egreso</td>
                                </tr>
                                
                                {distribucionPagos.map((element, index) => {
                                    totalDistribucionPagos += element.monto
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>{element.movimiento}</td>
                                            <td>{element.monto.toFixed(2)}</td>
                                            <td>{element.cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountDistPagos(element.id, 1) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                            <td>{element.monto.toFixed(2)}</td>
                                            <td>{element.contra_cuenta}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountDistPagos(element.id, 2) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })
                                }

                                <tr className="bg-success text-white">
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Total cobro cuotas</td>


                                    <td colSpan={5}>
                                        Subtotal: {totalDistribucionPagos.toFixed(2)}
                                    </td>

                                </tr>
                            </>
                        )
                        tableMovimientosBanco.push(
                            <>
                                <tr>
                                    <td>+/-</td>
                                    <td>Banco</td>
                                    <td>Tipo movimiento</td>
                                    <td>Concepto</td>
                                    <td>Monto</td>
                                    <td>Cuenta</td>
                                    <td>Contra cuenta</td>
                                    <td>Comentario</td>


                                </tr>
                                {movimientosBanco.map((element, index) => {
                                    let tipo_movimiento = ''
                                    let accion = ''
                                    if (element.tipo_movimiento === 1) {
                                        tipo_movimiento = 'Remesa'
                                        accion = 'Remesa'
                                    } else if (element.tipo_movimiento === 2) {
                                        tipo_movimiento = 'Cheque'
                                        accion = 'Retiro'
                                    } else if (element.tipo_movimiento === 3) {
                                        tipo_movimiento = 'Pago por transferencia'
                                        accion = 'Remesa'
                                    }
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>{element.Banco.nombre}</td>
                                            <td>{accion}</td>
                                            <td>{tipo_movimiento}</td>
                                            <td>{element.total.toFixed(2)}</td>
                                            <td>{element.cuenta_contable}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBancos(element.id_movimiento, 1) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                            <td>{element.contra_cuenta_contable}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountBancos(element.id_movimiento, 2) }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                            <td>{element.comentario !== "" ? element.comentario:"Sin comentarios"}</td>
                                        </tr>
                                    )
                                })
                                }
                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td>Remesa a banco</td>
                                    <td colSpan={6}>{data[0].remesa_banco}</td>
                                </tr>

                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td>Retiro a banco</td>
                                    <td colSpan={6}>{data[0].retiro_banco}</td>
                                </tr>
                            </>
                        )
                        table.push(
                            <>

                                <tr>
                                    <td className="col-1"></td>
                                    <td>+/-</td>
                                    <td>Concepto</td>
                                    <td>Monto</td>
                                    <td>Cuenta ingreso</td>
                                    <td>Monto</td>
                                    <td>Cuenta egreso</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>*</td>
                                    <td>Saldo inicial</td>
                                    <td>{data[0].saldo_inicial}</td>
                                    <td>

                                    </td>
                                    <td>{data[0].saldo_inicial}</td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Prestamos otorgados</td>
                                    <td>{data[0].prestamos_otorgados}</td>
                                    <td>
                                        {data[0].cuenta_prestamos === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_prestamos") }}>Ingresar cuenta</Button>
                                        ) : (<>
                                            {data[0].cuenta_prestamos}
                                            {data[0]?.revision_contabilidad !== 2 ? (
                                                <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_prestamos") }}> <AiFillEdit /></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                        )}
                                    </td>
                                    <td>{data[0].prestamos_otorgados}</td>
                                    <td>
                                        {data[0].contra_cuenta_prestamos === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_prestamos") }}>Ingresar cuenta</Button>
                                        ) : (<>
                                            {data[0].contra_cuenta_prestamos}
                                            {data[0]?.revision_contabilidad !== 2 ? (
                                                <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_prestamos") }}> <AiFillEdit /></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "red" }}>-</p></td>
                                    <td>Prestamos de refinanciamiento</td>
                                    <td>{data[0].prestamos_refinanciamiento}</td>
                                    <td>
                                        {data[0].cuenta_refinanciamiento === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_refinanciamiento") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_refinanciamiento}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_refinanciamiento") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].prestamos_refinanciamiento}</td>
                                    <td>
                                        {data[0].contra_cuenta_refinanciamiento === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_refinanciamiento") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_refinanciamiento}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_refinanciamiento") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Prestamos de transferencia</td>
                                    <td>{data[0].prestamos_transferencia}</td>
                                    <td>
                                        {data[0].cuenta_transferencia === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_transferencia") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_transferencia}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_transferencia") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].prestamos_transferencia}</td>
                                    <td>
                                        {data[0].contra_cuenta_transferencia === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_transferencia") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_transferencia}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_transferencia") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Prestamos de empleados</td>
                                    <td>{data[0].prestamos_empleados}</td>
                                    <td>
                                        {data[0].cuenta_empleados === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_empleados") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_empleados}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_empleados") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].prestamos_empleados}</td>
                                    <td>
                                        {data[0].contra_cuenta_empleados === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_empleados") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_empleados}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_empleados") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "red" }}>-</p></td>
                                    <td>Refinanciamiento de empleados</td>
                                    <td>{data[0].refinanciamiento_empleados}</td>
                                    <td>
                                        {data[0].cuenta_refinanciamiento_empleados === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_refinanciamiento_empleados") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_refinanciamiento_empleados}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_refinanciamiento_empleados") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].refinanciamiento_empleados}</td>
                                    <td>
                                        {data[0].contra_cuenta_refinanciamiento_empleados === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_refinanciamiento_empleados") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_refinanciamiento_empleados}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_refinanciamiento_empleados") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Prestamos empleados de transferencia</td>
                                    <td>{data[0].prestamos_empleados_transferencia}</td>
                                    <td>
                                        {data[0].cuenta_empleados_transferencia === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_empleados_transferencia") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_empleados_transferencia}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_empleados_transferencia") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].prestamos_empleados_transferencia}</td>
                                    <td>
                                        {data[0].contra_cuenta_empleados_transferencia === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_empleados_transferencia") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_empleados_transferencia}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_empleados_transferencia") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>
                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Cobro cuotas</td>


                                    <td colSpan={4}>
                                        Subtotal: {data[0].cobro_coutas}
                                    </td>

                                </tr>

                                {/* CAMBIO */}
                                {cobros_recibidos_entre_agencias[0].map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "green" }}>+</p></td>
                                            <td>Pagos que otra agencia recibio {element.agencia}</td>
                                            <td>{element.monto_ingresado}</td>
                                            <td>{element.cuenta}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })}

                                {cobros_recibidos_entre_empresas[0].map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "green" }}>+</p></td>
                                            <td>Pagos que otra agencia recibio {element.agencia} (entre empresas)</td>
                                            <td>{element.monto_ingresado}</td>
                                            <td>{element.cuenta}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })}

                                {pago_cuota_entre_agencias[0].map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>Cobros recibidos para otras agencias</td>
                                            <td>{element.monto_ingresado}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{element.cuenta !== null ? <>{element.cuenta}</> : <></>}</td>
                                        </tr>
                                    )
                                })}

                                {pago_cuota_entre_empresas[0].map((element, index) => {
                                    return (
                                        <tr>
                                            <td><p style={{ color: "red" }}>-</p></td>
                                            <td>Cobros recibidos para otras agencias</td>
                                            <td>{element.monto_ingresado}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{element.cuenta}</td>
                                        </tr>
                                    )
                                })}

                                {data[0].entre_agencias !== null ? (
                                    <tr >
                                        <td></td>
                                        <td>Entre agencias</td>
                                        <td>
                                            Subtotal: {data[0].entre_agencias}
                                        </td>
                                        <td colSpan={4}>
                                            <Button onClick={traerPagosEntreAgencias}>Ingresar cuenta de pagos entre agencias</Button>
                                        </td>

                                    </tr>
                                ) : (<> </>)}

                                <tr>
                                    <td> <button className="btn btn-link"><AiOutlineArrowDown />  </button></td>
                                    <td><span style={{ color: "red" }}>-</span></td>
                                    <td>Faltante</td>
                                    <td>{data[0].faltante} </td>
                                    <td>
                                        {data[0].cuenta_faltante === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_faltante") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_faltante}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_faltante") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].faltante}</td>
                                    <td>
                                        {data[0].contra_cuenta_faltante === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_faltante") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_faltante}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_faltante") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td><p style={{ color: "red" }}>-</p></td>
                                    <td>Faltante sin fisico</td>
                                    <td colSpan={5}>{data[0].faltante_sin_fisico}</td>
                                    {/* <td>
                                    {data[0].cuenta_faltante_sin === null ? (
                                        <Button onClick={() => { handleChangeAccount("cuenta_faltante_sin") }}>Ingresar cuenta</Button>
                                    ) : (
                                        <>
                                        {data[0].cuenta_faltante_sin}
                                        {data[0]?.revision_contabilidad !== 2 ? (
                                            <Button variant={"warning"} style={{marginLeft:"10px"}} onClick={() => { handleChangeAccount("cuenta_faltante_sin") }}> <AiFillEdit/></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>{data[0].faltante_sin_fisico}</td>
                                <td>
                                    {data[0].contra_cuenta_faltante_sin_fisico === null ? (
                                        <Button onClick={() => { handleChangeAccount("contra_cuenta_faltante_sin_fisico") }}>Ingresar cuenta</Button>
                                    ) : (
                                        <>
                                        {data[0].contra_cuenta_faltante_sin_fisico}
                                        {data[0]?.revision_contabilidad !== 2 ? (
                                            <Button variant={"warning"} style={{marginLeft:"10px"}} onClick={() => { handleChangeAccount("contra_cuenta_faltante_sin_fisico") }}> <AiFillEdit/></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </td> */}
                                </tr>

                                <tr>
                                    <td> <button className="btn btn-link"><AiOutlineArrowDown />  </button> </td>
                                    <td><p style={{ color: "green" }}>+</p></td>
                                    <td>Sobrante</td>
                                    <td>{data[0].sobrante}</td>
                                    <td>
                                        {data[0].cuenta_sobrante === null ? (
                                            <Button onClick={() => { handleChangeAccount("cuenta_sobrante") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].cuenta_sobrante}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("cuenta_sobrante") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>{data[0].sobrante}</td>
                                    <td>
                                        {data[0].contra_cuenta_sobrante === null ? (
                                            <Button onClick={() => { handleChangeAccount("contra_cuenta_sobrante") }}>Ingresar cuenta</Button>
                                        ) : (
                                            <>
                                                {data[0].contra_cuenta_sobrante}
                                                {data[0]?.revision_contabilidad !== 2 ? (
                                                    <Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccount("contra_cuenta_sobrante") }}> <AiFillEdit /></Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                                <tr className="bg-success text-white">

                                    <td></td>
                                    <td>Egresos de boveda</td>
                                    <td colSpan={5}>
                                        Subtotal: {data[0].egresos_boveda}
                                    </td>
                                </tr>

                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td>Ingresos de boveda</td>


                                    <td colSpan={5}>
                                        Subtotal: {data[0].ingreso_boveda}
                                    </td>

                                </tr>
                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td>Remesa a banco</td>
                                    <td colSpan={5}>Subtotal: {data[0].remesa_banco}</td>
                                </tr>

                                <tr className="bg-success text-white">
                                    <td></td>
                                    <td>Retiro a banco</td>
                                    <td colSpan={5}>Subtotal: {data[0].retiro_banco}</td>
                                </tr>


                                <tr>
                                    <td>Total:</td>
                                    <td colSpan={6}>$ {fin_dia.toFixed(2)}</td>
                                </tr>

                            </>
                        )
                        settableRow(table)
                        setTableCobros(tableCobros)
                        setTableMovimientosBoveda(tableMovimientosBoveda)
                        setTableMovimientosBanco(tableMovimientosBanco)
                        setCuenta_prestamos(assignFalse(data[0].cuenta_prestamos))
                        setContra_cuenta_prestamos(assignFalse(data[0].contra_cuenta_prestamos))
                        setCuenta_refinanciamiento(assignFalse(data[0].cuenta_refinanciamiento))
                        setContra_cuenta_refinanciamiento(assignFalse(data[0].contra_cuenta_refinanciamiento))
                        setCuenta_transferencia(assignFalse(data[0].cuenta_transferencia))
                        setContra_cuenta_transferencia(assignFalse(data[0].contra_cuenta_transferencia))
                        setCuenta_empleados(assignFalse(data[0].cuenta_empleados))
                        setContra_cuenta_empleados(assignFalse(data[0].contra_cuenta_empleados))
                        setCuenta_refinanciamiento_empleados(assignFalse(data[0].cuenta_refinanciamiento_empleados))
                        setContra_cuenta_refinanciamiento_empleados(assignFalse(data[0].contra_cuenta_refinanciamiento_empleados))
                        setCuenta_empleados_transferencia(assignFalse(data[0].cuenta_empleados_transferencia))
                        setContra_cuenta_empleados_transferencia(assignFalse(data[0].contra_cuenta_empleados_transferencia))
                        setCuenta_cobro(assignFalse(data[0].cuenta_cobro))
                        setContra_cuenta_cobro(assignFalse(data[0].contra_cuenta_cobro))
                        setCuenta_faltante(assignFalse(data[0].cuenta_faltante))
                        setContra_cuenta_faltante(assignFalse(data[0].contra_cuenta_faltante))
                        setCuenta_faltante_sin(assignFalse(data[0].cuenta_faltante_sin))
                        setContra_cuenta_faltante_sin_fisico(assignFalse(data[0].contra_cuenta_faltante_sin_fisico))
                        setCuenta_sobrante(assignFalse(data[0].cuenta_sobrante))
                        setContra_cuenta_sobrante(assignFalse(data[0].contra_cuenta_sobrante))


                        setCuenta_remesa_banco(assignFalse(data[0].cuenta_remesa_banco))
                        setContra_cuenta_remesa_banco(assignFalse(data[0].contra_cuenta_remesa_banco))
                        setCuenta_retiro_banco(assignFalse(data[0].cuenta_retiro_banco))
                        setContra_cuenta_retiro_banco(assignFalse(data[0].contra_cuenta_retiro_banco))

                    } else {

                        table.push(
                            <tr>
                                <td >No hay datos en esta fecha</td>
                            </tr>
                        )
                        settableRow(table)
                    }
                }
            }
        }
        setRowFiltered()


    }, [data, actualizarData, hasData, pagosEntreAgencia])

    const assignFalse = (value) => (value === null ? null : value);

    const handleChangeAccountPagos = (value, tipo_cuenta) => {
        console.log(tipo_cuenta)
        setTipoCuenta(tipo_cuenta)
        setModalEntreAgencias(true)
        setComprobante(value)

    }
    const changeAccountEntreAgencias = async () => {

        const object = {
            comprobante: comprobante,
            cuenta: cuentaEntreAgencias,
            id_cierre: data[0].id_resumen_agencia,
            tipo_cuenta: tipoCuenta,
            token: token
        }
        const send = await guardarMovimientosEntreAgencias(object)

        if (send.message === "Actualización exitosa") {
            setModalEntreAgencias(false)
            traerPagosEntreAgencias()
        } else {
            alert("Error no se pudo guardar la cuenta")
        }

        traerPagosEntreAgencias()
        setModalEntreAgencias(false)
    }
    const showCierre = () => {
        setDisplayCierre("block")
        setDisplayCobroCuotas('none')
        setDisplayMovBoveda('none')
        setDisplayMovBanco('none')
    }
    const showCobroCuotas = () => {

        setDisplayCierre("none")
        setDisplayCobroCuotas('block')
        setDisplayMovBoveda('none')
        setDisplayMovBanco('none')
    }
    const showMovimientosBoveda = () => {
        setDisplayCierre("none")
        setDisplayCobroCuotas('none')
        setDisplayMovBoveda('block')
        setDisplayMovBanco('none')
    }
    const showMovBanco = () => {
        setDisplayCierre("none")
        setDisplayCobroCuotas('none')
        setDisplayMovBoveda('none')
        setDisplayMovBanco('block')
    }
    const linkStyle = {
        color: 'white',
        margin: '0 6px',
    };

    const irAlDiaAnterior = async () => {

        const fechaActual = new Date(fecha)
        fechaActual.setDate(fechaActual.getDate() - 1);
        const nuevaFechaStr = fechaActual.toISOString().split('T')[0];
        setFecha(nuevaFechaStr)
        const table = []
        settableRow(table)
        const object = {
            empresa: empresa,
            agencia: idAgencia,
            fecha: nuevaFechaStr,
            token: token
        }
        console.log(object)
        const result = await reporteCierresDiarios(object)
        console.log(result)
        setData(result)

    }
    const irAlDiaSiguiente = async () => {
        const fechaActual = new Date(fecha)
        fechaActual.setDate(fechaActual.getDate() + 1);
        const nuevaFechaStr = fechaActual.toISOString().split('T')[0];
        setFecha(nuevaFechaStr)
        const table = []
        settableRow(table)
        const object = {
            empresa: empresa,
            agencia: idAgencia,
            fecha: nuevaFechaStr,
            token: token
        }
      
        const result = await reporteCierresDiarios(object)
       
        setData(result)
    }
    const handleChangeSearch = async () => {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        });
        const table = []
        settableRow(table)
        const object = {
            empresa: empresa,
            agencia: idAgencia,
            fecha: fecha,
            token: token
        }
      
        const result = await reporteCierresDiarios(object)
        setData(result)
        Swal.close()
    }
    const handleRevertirCuadre = async () => {

        Swal.fire({
            title: '¿Está seguro de revertir el cierre?',
            showDenyButton: true,
            confirmButtonText: `Si`,
            denyButtonText: `No`,
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Cargando...',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading();
                    },
                });

        const dataObject = {


            prestamos_otorgados: {
                monto: data[0].prestamos_otorgados,
                cuenta: cuenta_prestamos,
                contra_cuenta: contra_cuenta_prestamos
            },
            prestamos_refinanciamiento: {
                monto: data[0].prestamos_refinanciamiento,
                cuenta: cuenta_refinanciamiento,
                contra_cuenta: contra_cuenta_refinanciamiento
            },
            prestamos_transferencia: {
                monto: data[0].prestamos_transferencia,
                cuenta: cuenta_transferencia,
                contra_cuenta: contra_cuenta_transferencia
            },
            prestamos_empleados: {
                monto: data[0].prestamos_empleados,
                cuenta: cuenta_empleados,
                contra_cuenta: contra_cuenta_empleados
            },
            refinanciamiento_empleados: {
                monto: data[0].refinanciamiento_empleados,
                cuenta: cuenta_refinanciamiento_empleados,
                contra_cuenta: contra_cuenta_refinanciamiento_empleados
            },
            prestamos_empleados_transferencia: {
                monto: data[0].prestamos_empleados_transferencia,
                cuenta: cuenta_empleados_transferencia,
                contra_cuenta: contra_cuenta_empleados_transferencia
            },
            faltante: {
                monto: data[0].faltante,
                cuenta: cuenta_faltante,
                contra_cuenta: contra_cuenta_faltante
            },
            faltante_sin_fisico: {
                monto: data[0].faltante_sin_fisico,
                cuenta: cuenta_faltante_sin,
                contra_cuenta: contra_cuenta_faltante_sin_fisico
            },
            sobrante: {
                monto: data[0].sobrante,
                cuenta: cuenta_sobrante,
                contra_cuenta: contra_cuenta_sobrante
            },


        }
        const object = {
            id_cierre: data[0].id_resumen_agencia,
            token: token
        }

        const res = await revertirCierre(object)
        Swal.close()
        if(res === 200){
            Swal.fire({
                icon: 'success',
                title: 'Cierre revertido',
                showConfirmButton: false,
                timer: 1500
            })
            handleChangeSearch()
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Error al revertir cierre',
                showConfirmButton: false,
                timer: 1500
            })
        }
        console.log(res)
    }
    })

        
    }



    if (hasData) {
        return (
            <>
                <NavBar></NavBar>
                <a
                    className="button plus"
                    href="#plus"
                    style={{
                        position: 'absolute',
                        top: '130px',
                        left: '100px',

                    }}
                >
                    <Button onClick={goBack} style={{ borderRadius: '40%', width: '50px', height: '50px' }}>
                        <AiOutlineArrowLeft style={{ fontSize: '12px' }} ></AiOutlineArrowLeft>
                    </Button>


                </a>
                <div class="container text-center shadow mt-5" style={{padding:"10px", textAlign: 'center', backgroundColor:"#AEAF7A", borderRadius:"15px" }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

                        <Button variant="success" style={{ margin: '10px' }} onClick={irAlDiaAnterior}>
                            Día Anterior
                        </Button>
                        <div style={{ marginLeft: '10px'}}>
                            <EmpresaXAgenciaSelect
                                IdEmpresa={empresa}
                                agencia={idAgencia}
                                showlabel={false}
                                onChange={(e) => setIdAgencia(e)}
                            />
                             
                        </div>
                        <div style={{ marginLeft: '10px', height:'10%'}}>
                        <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
                        </div>
                        <Button style={{marginLeft:"5px"}} onClick={handleChangeSearch}>  <AiOutlineSearch></AiOutlineSearch> </Button>
                        <Button variant="success" style={{ margin: '10px' }} onClick={irAlDiaSiguiente}>
                            Día Siguiente
                        </Button>
                        </div>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>

                        <Nav variant="tabs" defaultActiveKey="/home">
                            <Nav.Item>
                                <Nav.Link style={linkStyle} onClick={showCierre}>Cierre diario</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={linkStyle} onClick={showCobroCuotas}>
                                    Cobro de cuotas
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={linkStyle} onClick={showMovimientosBoveda}>
                                    Movimientos de boveda
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={linkStyle} onClick={showMovBanco}>
                                    Movimientos de banco
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item></Nav.Item>
                        </Nav>
                    </div>
                    <div style={{ display: displayCierre }}>
                        <h3 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Cierre Diario para {agencia}</h3>
                        <h4 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Fecha: {fecha}</h4>
                        <Table striped style={{ margin: 'auto', marginTop: "20px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} className='my-table-secondary' id="tableData">
                            <tbody>
                                {tableRow}
                            </tbody>



                            {data.length !== 0 && data[0]?.revision_contabilidad !== 2 ? (
                                <>
                                    <Button variant="success" style={{ margin: "10px" }} onClick={confirmCierreDiario}>
                                        <GiConfirmed /> Confirmar cierre diario
                                    </Button>
                                    <Button variant="primary" style={{ margin: "10px" }} onClick={handleDownoadexcel}><AiOutlineFileExcel></AiOutlineFileExcel> Descargar excel</Button>
                                </>
                            ) : (
                                data.length == 0 ? (
                                    <p style={{ margin: "10px" }}>NO SE GUARDO EL CIERRE DE ESTE DIA</p>
                                ) : (
                                    <>
                                    <p style={{ margin: "10px" }}>REVISADO POR CONTABILIDAD</p>
                                    <Button variant="danger" style={{ margin: "10px" }} onClick={handleRevertirCuadre}><IoReload></IoReload> Revertir cuadre</Button>
                                    </>
                                )
                            )}

                        </Table>
                    </div>

                    <div style={{ display: displayCobroCuotas }}>

                        <h3 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Cobro de cuotas para {agencia}</h3>
                        <Table striped style={{ margin: 'auto', marginTop: "20px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} className='my-table-secondary' id="tableData">
                            <tbody>
                                {tableCobros}
                            </tbody>
                        </Table>

                    </div>
                    <div style={{ display: displayMovBoveda }}>

                        <h3 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Movimientos de boveda de agencia {agencia}</h3>
                        <Table striped style={{ margin: 'auto', marginTop: "20px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} className='my-table-secondary' id="tableData">
                            <tbody>
                                {tableMovimientosBoveda}
                            </tbody>
                        </Table>

                    </div>

                    <div style={{ display: displayMovBanco }}>

                        <h3 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Movimientos de bancos de agencia {agencia}</h3>
                        <Table striped style={{ margin: 'auto', marginTop: "20px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} className='my-table-secondary' id="tableData">
                            <tbody>
                                {tableMovimientosBanco}
                            </tbody>
                        </Table>

                    </div>
                </div>

                {/* MODAL PARA AGREGAR CUENTAS */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuenta(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={handleAccount}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL PARA AGREGAR CUENTAS */}
                <Modal show={showMovBoveda} onHide={() => { setShowMovBoveda(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta para el movimiento de boveda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuentaMovBoveda(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowMovBoveda(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAccountMovBoveda}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* MODAL PARA AGREGAR CUENTAS A LOS MOVIMIENTOS DE BANCO */}

                <Modal show={showMovBancos} onHide={() => { SetShowMovBancos(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta para los movimientos de banco</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuentaMovBancos(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { SetShowMovBancos(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAccountBancos}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* MODAL PARA AGREGAR CUENTAS A LA DISTRIBUCION DE PAGOS */}

                <Modal show={showDistribucion} onHide={() => { setShowDistribucion(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta para la distribucion de pagos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuentaDistPagos(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowDistribucion(false) }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAccountDistPagos}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL PARA AGREGAR CUENTAS  A LOS PAGOS ENTRE AGENCIAS */}
                <Modal show={showModalEntreAgencias} onHide={() => setModalEntreAgencias(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta pago entre agencia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuentaEntreAgencias(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalEntreAgencias(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={changeAccountEntreAgencias}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>

        {/* MODAL PARA AGREGAR EL MOVIMIENTO EXTRA DE PAGOS ENTRE AGENCIAS */}
                <Modal
            show={showMovimientoExtra}
            onHide={() => setMovimientoExtra(false)}
            backdropClassName="modal-backdrop-blur"
            style={{
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }}
        >
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta  de movimiento extra entre agencia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={empresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuentaMovExtra(e)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setMovimientoExtra(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={agregarMovimientoExtra}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL PARA AGREGAR CUENTAS A PAGOS ENTRE AGENCIA */}
                <Modal show={pagosEntreAgencia} onHide={() => { setPagosEntreAgencia(false) }} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {/* PAGO RECIBIDOS */}
                            <h5>Cobros recibidos de otras agencias</h5>
                            {dataPagosEntreAgencia.length === 0 ? (<>No hay datos</>) : (<>

                                {dataPagosEntreAgencia.cobros_recibidos_entre_agencias.length !== 0 ? (<>
                                    {dataPagosEntreAgencia.cobros_recibidos_entre_agencias.map((item, index) => (<>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr >
                                                    <th>Monto ingresado</th>
                                                    <th>Comprobante</th>
                                                    <th>Agencia origen</th>
                                                    <th>Cuenta</th>
                                                    <th>Accion</th>
                                                    <th>Accion extra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>${item.monto_ingresado}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>{item.comprobante}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>{item.agencia}</p>
                                                    </td>
                                                    <td>
                                                        {item.cuenta === null ? (
                                                            <p style={{ color: "black", fontSize: "14px" }}>Sin cuenta ingresada</p>) : (<p style={{ color: "black", fontSize: "14px" }}>{item.cuenta}</p>)}
                                                    </td>
                                                    <td>
                                                        {item.estado_movimiento === 1 ?
                                                            (<Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountPagos(item.comprobante, "Cuenta por pagar") }}> <AiFillEdit /></Button>) : (<p style={{ color: "black", fontSize: "14px" }}>Cuenta ingresada</p>)}
                                                    </td>
                                                    <td>
                                                    {item.movimiento_adicional === 0 ?
                                                        <Button variant={"success"} style={{ marginLeft: "10px" }} onClick={() => { handlerMovExtraEntreAgencias(item, "Cuenta por cobrar") }}> Agregar movimiento de cuenta a boveda</Button>
                                                        :                                           <p style={{ color: "black", fontSize: "14px" }} >Movimiento registrado a la cuenta {item.cuenta_movimiento_adicional}</p>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </>))}
                                </>) : (<>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Monto ingresado</th>
                                                <th>Comprobante</th>
                                                <th>Agencia origen</th>
                                                <th>Cuenta</th>
                                                <th>Accion</th>
                                                <th>Accion extra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    <p style={{ color: "black", fontSize: "14px" }} >No se registraron datos en esta fecha</p>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </Table>
                                </>)}
                                {/* PAGOS REALIZADOS */}
                                <hr />
                                <h5>Pagos realizados a otras agencias:</h5>
                                {dataPagosEntreAgencia.pago_cuota_entre_agencias.length !== 0 ? (<>
                                    {dataPagosEntreAgencia.pago_cuota_entre_agencias.map((item, index) => (<>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Monto ingresado</th>
                                                    <th>Comprobante</th>
                                                    <th>Agencia destino</th>
                                                    <th>Cuenta</th>
                                                    <th>Accion</th>
                                                    <th>Accion extra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>${item.monto_ingresado}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>{item.comprobante}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>Agencia origen: {item.agencia}</p>
                                                    </td>
                                                    <td>
                                                        {item.cuenta === null ? (
                                                            <p style={{ color: "black", fontSize: "14px" }}>Sin cuenta ingresada</p>) : (<p style={{ color: "black", fontSize: "14px" }}>{item.cuenta}</p>)}

                                                    </td>
                                                    <td>
                                                        {item.estado_movimiento === 1 ?
                                                            (<Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountPagos(item.comprobante, "Cuenta por cobrar") }}> <AiFillEdit /></Button>) : (<p style={{ color: "black", fontSize: "14px" }}>Cuenta ingresada</p>)}
                                                    </td>
                                                    <td>
                                                    <td>
                                                    {item.movimiento_adicional === 0 ?
                                                        <Button variant={"success"} style={{ marginLeft: "10px" }} onClick={() => { handlerMovExtraEntreAgencias(item, "Cuenta por pagar") }}> Agregar movimiento de cuenta a boveda</Button>
                                                    :                                         
                                                    <p style={{ color: "black", fontSize: "14px" }} >Movimiento registrado a la cuenta {item.cuenta_movimiento_adicional}</p>}

                                                    </td>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>))}
                                </>) : (<>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Monto ingresado</th>
                                                <th>Comprobante</th>
                                                <th>Agencia destino</th>
                                                <th>Cuenta</th>
                                                <th>Accion</th>
                                                <th>Accion extra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    <p style={{ color: "black", fontSize: "14px" }}>No se registraron datos en esta fecha</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </>)}
                                {/* COBROS REALIZADOS */}
                                <hr />
                                <h5>Cobros recibidos de otras empresas:</h5>
                                {dataPagosEntreAgencia.cobros_recibidos_entre_empresas.length !== 0 ? (<>
                                    {dataPagosEntreAgencia.cobros_recibidos_entre_empresas.map((item, index) => (<>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Monto ingresado</th>
                                                    <th>Comprobante</th>
                                                    <th>Agencia origen</th>
                                                    <th>Cuenta</th>
                                                    <th>Accion</th>
                                                    <th>Accion extra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>${item.monto_ingresado}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>{item.comprobante}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>Agencia origen: {item.agencia}</p>
                                                    </td>
                                                    <td>
                                                        {item.cuenta === null ? (
                                                            <p style={{ color: "black", fontSize: "14px" }}>Sin cuenta ingresada</p>) : (<p style={{ color: "black", fontSize: "14px" }}>{item.cuenta}</p>)}

                                                    </td>
                                                    <td>
                                                        {item.estado_movimiento === 1 ?
                                                            (<Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountPagos(item.comprobante, "Cuenta por pagar") }}> <AiFillEdit /></Button>) : (<p style={{ color: "black", fontSize: "14px" }}>Cuenta ingresada</p>)}
                                                    </td>
                                                    <td>
                                                    {item.movimiento_adicional === 0 ?
                                                    <Button variant={"success"} style={{ marginLeft: "10px" }} onClick={() => { handlerMovExtraEntreAgencias(item, "Cuenta por cobrar") }}> Agregar movimiento de cuenta a boveda</Button>
                                                    :              
                                                    <p style={{ color: "black", fontSize: "14px" }} >Movimiento registrado a la cuenta {item.cuenta_movimiento_adicional}</p>}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>))}
                                </>) : (<>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Monto ingresado</th>
                                                <th>Comprobante</th>
                                                <th>Agencia origen</th>
                                                <th>Cuenta</th>
                                                <th>Accion</th>
                                                <th>Accion extra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <p style={{ color: "black", fontSize: "14px" }}>No se registraron datos en esta fecha</p>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </Table>
                                </>)}
                                {/* COBROS ENVIADOS */}
                                <hr />
                                <h5>Pagos realizados a otras empresas:</h5>
                                {dataPagosEntreAgencia.pago_cuota_entre_empresas.length !== 0 ? (<>
                                    {dataPagosEntreAgencia.pago_cuota_entre_empresas.map((item, index) => (<>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Monto ingresado</th>
                                                    <th>Comprobante</th>
                                                    <th>Agencia destino</th>
                                                    <th>Cuenta</th>
                                                    <th>Accion</th>
                                                    <th>Accion extra</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>${item.monto_ingresado}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>{item.comprobante}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "black", fontSize: "14px" }}>Agencia origen: {item.agencia}</p>
                                                    </td>
                                                    <td>
                                                        {item.cuenta === null ? (
                                                            <p style={{ color: "black", fontSize: "14px" }}>Sin cuenta ingresada</p>) : (<p style={{ color: "black", fontSize: "14px" }}>{item.cuenta}</p>)}

                                                    </td>
                                                    <td>
                                                        {item.estado_movimiento === 1 ?
                                                            (<Button variant={"warning"} style={{ marginLeft: "10px" }} onClick={() => { handleChangeAccountPagos(item.comprobante, "Cuenta por cobrar") }}> <AiFillEdit /></Button>) : (<p style={{ color: "black", fontSize: "14px" }}>Cuenta ingresada</p>)}
                                                    </td>
                                                    <td>
                                                    {item.movimiento_adicional === 0 ?
                                                    <Button variant={"success"} style={{ marginLeft: "10px" }} onClick={() => { handlerMovExtraEntreAgencias(item, "Cuenta por pagar") }}> Agregar movimiento de cuenta a boveda</Button>
                                                    :                                          
                                                    <p style={{ color: "black", fontSize: "14px" }} >Movimiento registrado a la cuenta {item.cuenta_movimiento_adicional}</p>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>))}
                                </>) : (<>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Monto ingresado</th>
                                                <th>Comprobante</th>
                                                <th>Agencia destino</th>
                                                <th>Cuenta</th>
                                                <th>Accion</th>
                                                <th>Accion extra</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={6}>
                                                    <p style={{ color: "black", fontSize: "14px" }}>No se registraron datos en esta fecha</p>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </Table>
                                </>)}


                            </>)}


                        </div>


                        {/* <AutocompleteAlter
                        cuentas={cuentas[0]}
                        empresa={empresa}
                        onChange={(e) => setCuenta(e)}
                        /> */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setPagosEntreAgencia(false) }}>
                            Cerrar
                        </Button>
                        {/* <Button variant="primary" >
                            Guardar cambios
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else {

        return (
            <>
                <NavBar></NavBar>
                <Buscador
                    onFinish={handleData}
                    handler={actualizarData}
                    empresa={(e) => { setEmpresa(e) }}
                    agencia={(e) => { setIdAgencia(e) }}
                ></Buscador>
            </>
        )
    }
}