import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const UpdateCuentas = async(data,id) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/CuentaContable/update/${id}`,{
        method: "PATCH",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            cuenta: data.CuentaContable,
            nombre: data.NombreCuenta,
            nivel: data.nivel,
            tipo: data.tipo
        })
    })
    
    const res = await response;
    return res
}
