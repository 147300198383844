import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const GetCuentas = async(object)=>{
    const response = await fetch(`${BASE_URL}/CuentaContable/`, {
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${object.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
           page: object.page,
           cuenta: object.cuenta,
        })
    })
    const data = await response.json();
    return data
}





