let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL


export const login = async(data) => {
    console.log(BASE_URL)
    const response = await fetch(`${BASE_URL}/login`,{
        method: "POST",
        headers:{
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            username: data.username,
            password: data.password,
            recuerdame: data.recuerdame

        })
    })
    const respuesta = await response;
    if(response.status === 200){
        return respuesta.json()
    }else{
    return respuesta
    }
}
export const register = async(data) => {
    const response = await fetch(`${BASE_URL}/login/register`,{
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: data.username,
            password: data.password,
            rol: data.rol,
            fecha_ingreso: data.fechaSQL
        })
    })
    const respuesta = await response
    return respuesta.status
}

export const changePassword = async(data) => {
    const response = await fetch(`${BASE_URL}/users/changepass`,{
        "method": 'PUT',
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id_usuario: data.id_usuario,
            password: data.newPassword
        })
    })
    const respuesta = await response
    return respuesta
}