
import React from 'react';
import icono from '../../resources/icono.png'

export const Logo = (props) => {
  return (
    <img
    
      src={icono}
      alt="Logo"
      style={{
        borderRadius: '50%',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
        width: `${props.width}`,
        height: `${props.heigth}`,
      }}
    />
  );
};

export default Logo;