import { NavBar } from "../../components/navbar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import {GuardarConcepto} from './../../services/concepto/concepto';
import { getConceptos } from "../../services/concepto/getConceptos";
import { eliminarConcepto } from "../../services/concepto/eliminarConcepto";
import { updateConcepto} from "../../services/concepto/updateConcepto";
import '../../css/Tablas.css';
import '../../css/Modal.css';



export function Concepto(){
  const token = localStorage.getItem('token');
  const [post, setPost]=useState([]);
  const [concepto, setConcepto]=useState('');
  const [modalShow, setModalShow]=useState(false);
  const [showModal, setShowModal]=useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idConcepto, setIdConcepto] = useState(0);
  const [showEdit, setShowEdit] = useState(false)
  const [helper, setHelper] = useState(true)
  const [search, setSearch] = useState('')
  

  // funcion de llenado de tabla para crud de conceptos
  useEffect(() => {
    const principal = async () => {
      try{
        const data = await getConceptos(token);
        setPost(data);
      }
      catch(error){
      
      }
    };
    principal();
  },[modalShow]);

  // funcion de insertar nuevo concepto
  const insertConcepto = async() =>{
    setModalShow(true)
    const fecha = new Date();
    var fechaActual = fecha.toLocaleDateString();
    const SQlFecha = fechaActual.split('/').reverse().join('-');
    var object = {concepto, SQlFecha, token}
    const data = await GuardarConcepto(object)
    if(data === 200){
      setHelper(true)
      setShowModal(false)
      Swal.fire({
          title: 'Concepto Agregado',
          text: 'Se ha agregado su concepto correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        setModalShow(false)
        
  }else{
      setShowModal(false)
      Swal.fire({
          title: 'Concepto fallido',
          text: 'No se ha podido completar ',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
        setModalShow(false)
  }
}

// funcion para almacenar id en setConcepto
const Eliminar = async(id) => {
 
  setIdConcepto(id)
  setShowDeleteModal(true)
}

// funcion para eliminar concepto
const conceptoEliminar = async() => {
  setModalShow(true)
  const token = localStorage.getItem('token');
  var object = {token, idConcepto}
  var data = await eliminarConcepto(object)
  if(data.status == 200){
    setModalShow(false)
    Swal.fire({
        title: 'Operacion completada',
        text: 'Se ha eliminado el concepto con exito',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      setShowDeleteModal(false)
  }else{
    setModalShow(false)
    Swal.fire({
        title: 'Operacion fallida',
        text: 'No se elimino correctamente',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      setShowDeleteModal(false)
  }
}

// funcion para enviar datos al cuadro de edita<Button variant="secondary">Busqueda</Button>r
const Editar = (object) =>{
  setConcepto(object.descripcion)
  setIdConcepto(object.id_concepto)
  setShowEdit(true)
}

// funcion para editar concepto
const conceptoEditar = async() => {
  setModalShow(true)
  const token = localStorage.getItem('token');
  var object = {token,concepto}
  const data = await updateConcepto(object,idConcepto)
  if(data.status == 200){
    setModalShow(false)
    Swal.fire({
        title: 'Operacion completada',
        text: 'El concepto ha sido editado',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      setShowEdit(false)
}else{
    setModalShow(false)
    Swal.fire({
        title: 'Operacion incompleta',
        text: 'La operacion no pudo completarse',
        icon: 'danger',
        confirmButtonText: 'Aceptar'
      });
      setShowEdit(false)
}
  
}
  


  return(
    // fragmento de crud de conceptos
    <>
    
    <NavBar></NavBar>
    
      <br/><br/>
      <div className="Encabezado" style={{textAlign: "center"}}>
          <h1 style={{color: "white"}}>Control de Conceptos</h1>
      </div><br/>

      <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center"}}>
        <br/>
        <div style={{marginLeft: "24px"}}>
        <Stack direction="horizontal" gap={2}>
          <Form.Control className="Busqueda" placeholder="Busqueda de Concepto" style={{width: "700px", }} value={search} onChange={e => setSearch(e.target.value.toLowerCase())}/>
          <div style={{marginLeft: "50%"}}/>
          <Button variant="warning" onClick={() => setShowModal(true)} >Agregar</Button>
          </Stack>
        <br/>
        <Table className="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Descripcion</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {post
              .filter(props => props.descripcion.toLowerCase().includes(search.toLowerCase()))
              .map((props) => (
                <tr>
                  <td>{props.id_concepto}</td>
                  <td >{props.descripcion}</td>
                  <td >
                    <Button variant="primary" onClick={() => Editar(props)} >Editar</Button>
                    <span style={{ margin: '0 10px' }}></span>
                    <Button variant="danger" onClick={() => Eliminar(props.id_concepto)}>Eliminar</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> 
          
        </div>
      </div>
      
      {/* modal para agregar un nuevo concepto */}
      <Modal show={showModal} onHide={() => setShowModal(false)} >
        <ModalHeader closeButton>
          <Modal.Title>Agregar un nuevo concepto</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <div >
            <label>Nuevo Concepto:</label>
            <input type="text" placeholder="Ingrese un nuevo concepto" className="form-control" style={{width: "90%"}} onChange={(e) => setConcepto(e.target.value)}/>
            <br/>
          </div>
          <div >
            <Button variant="primary" style={{float: "right", marginRight: "10px"}} onClick={insertConcepto}>Guardar</Button>
          </div>
        </ModalBody>
      </Modal>

      {/* modal para eliminar un concepto */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>¿ Desea eliminar este concepto ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
          <Button variant="danger" onClick={conceptoEliminar}>Si, eliminar</Button>
        </Modal.Footer>
      </Modal>

      {/* modal para editar concepto */}
      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header >
          <Modal.Title>Cambiar Concepto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Concepto:</label>
            <input type="text" className="form-control" placeholder="Debe contener un concepto" value={concepto} onChange={(e) => setConcepto(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowEdit(false)}>Cancelar</Button>
          <Button variant="success" onClick={conceptoEditar}>Aceptar</Button>
        </Modal.Footer>
      </Modal>

    
      
    </>

    
    
  );

}