import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteRegistro } from '../../services/partidas/partidas';
import Swal from 'sweetalert2';
export const EliminarModal = (props) => {
  async function handleEliminar(){
    const object = {
      agencia: props.agencia,
      idPartida: props.partida,
      idRegistro: props.id,
      token: props.token
    }
    const query = await deleteRegistro(object)
    if(query === 200){
      Swal.fire({
        title: 'Exito',
        text: 'Se elimino exitosamente',
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
         props.save()
        };
      });;
    }else{
      Swal.fire({
        title: 'Error',
        text: 'Se produjo un error',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }
    return (
    <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>¿Desea eliminar este registro?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esta accion no puede deshacerse</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={handleEliminar} >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    )
}