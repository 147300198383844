import { ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import { BsFillClockFill, BsFillClipboard2PlusFill } from 'react-icons/bs'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import { insertPartidas } from "../../services/partidas/partidas";
import { Modals } from '../../components/partidasComponentes/modalsAuto'
import { Modal_load } from '../../components/UTILS/modal_load';
import { getTipos } from "../../services/partidas/partidas";
import { getEmpresas, getAgenciasXEmpresa, getEmpresasOperativas } from "../../services/geolocalizacion/places";
import '../../css/Tablas.css';
import { saveRegistro, saveRegistroOperativo } from '../../services/partidas/saveRegistro'
import { BiSave } from 'react-icons/bi'
import { AceptarModal } from "../../components/partidasComponentes/modalAceptar";
import { getAllRegistros } from "../../services/partidas/partidas";
import { BuscarModal } from "../../components/partidasComponentes/modalBuscar";
import { AiOutlineFolderOpen } from 'react-icons/ai'
import { EditarModal } from "../../components/partidasComponentes/modalEditar";
import { EliminarModal } from "../../components/partidasComponentes/modalEliminar";
import { guardarPartida } from "../../services/partidas/partidas";
import { getGrupo } from "../../services/partidas/partidas"
import { ModalsOperativos } from "../../components/partidasComponentes/partidasOperativos/modalsAuto";

export function PartidasOperativas(){

/* El código anterior está escrito en JavaScript utilizando la biblioteca React. Declara múltiples
variables de estado usando el hook useState, incluyendo token, partida, tiposSelect, fecha, show,
modalShow, allData, empresas, agencias, idEmpresa, idAgencias, idPartida, grupo, showGuardar,
getData, dataTable, showBuscar, totalEgreso, totalIngreso, idRegistro , showEditar y showEliminar.
Estas variables se utilizan para administrar el estado de la aplicación y almacenar datos. El código
también usa localStorage para recuperar un valor de token. */
const token = localStorage.getItem('token');
const [partida, setPartida] = useState('')
const [tiposSelect, setTipos] = useState([[]])
const [fecha, setFecha] = useState('')
const [show, setShow] = useState(false)
const [modalShow, setModalShow] = useState(false)
const [allData, setAllData] = useState([])
const [empresas, setEmpresas] = useState([[]])
const [agencias, setAgencias] = useState([[]])
const [idEmpresa, setIdEmpresa] = useState(0)
const [idAgencias, setIdAgencia] = useState(0)
const [idPartida, setIdPartida] = useState(0)
const [grupo, setGrupo] = useState(0)
const [showGuardar, setShowGuardar] = useState(false)
const [getData, setGetData] = useState(false)
const [dataTable, setDataTable] = useState([[]])
const [showBuscar, setShowBuscar] = useState(false)
const [totalEgreso, setTotalEgreso] = useState(0)
const [totalIngreso, setTotalIngreso] = useState(0)
const [idRegistro, setIdRegistro] = useState(0)
const [showEditar, setShowEditar] = useState(false)
const [showEliminar, setShowEliminar] = useState(false)
let egreso = 0
let ingreso = 0
/* El código anterior usa el gancho `useEffect` en un componente de React para obtener datos de un
punto final de API usando la función `getAgenciasXEmpresa`. La variable `idEmpresa` se usa como
una dependencia en el gancho `useEffect`, por lo que el código se volverá a ejecutar cada vez que
cambie `idEmpresa`. Si `idEmpresa` no es igual a 0, se llama a la función `fetchData`, que envía
una solicitud a la API con un objeto que contiene un `token` e `idEmpresa`. Luego, la respuesta de
la API se usa para actualizar `agencias */
useEffect(() => {
  if (idEmpresa != 0) {
    const fetchData = async () => {
      const object = { token, idEmpresa }
      const tipos = await getAgenciasXEmpresa(object)
      setAgencias([tipos])
    }
    fetchData()
  } else {
    const tipos = []
    setAgencias([tipos])
  }
}, [idEmpresa])

/* El código anterior usa el gancho `useEffect` en un componente React para obtener datos de forma
asíncrona desde un punto final de la API usando la función `getTipos`. Luego establece los datos
obtenidos en la variable de estado `tipos` usando la función `setTipos`. El `[]` como segundo
argumento de `useEffect` indica que este efecto solo debe ejecutarse una vez, cuando se monta el
componente. */
useEffect(() => {
  const fetchData = async () => {
    const tipos = await getTipos(token);
    setTipos([tipos])

  };
  fetchData();
}, []);

/* El código anterior usa el gancho `useEffect` en un componente React para obtener datos de una API
usando la función `getEmpresas`. Está pasando un objeto con una propiedad `token` a la función.
Una vez que se obtienen los datos, se establece el estado del componente mediante la función
`setEmpresas`. El `[]` como segundo argumento de `useEffect` significa que el efecto solo se
ejecutará una vez, cuando se monte el componente. */
useEffect(() => {
  const fetchData = async () => {
    const object = { token }
    const empresas = await getEmpresasOperativas(object)
    setEmpresas([empresas])

  }
  fetchData()
}, [])

/* El código anterior usa el enlace useEffect en un componente React para obtener datos de una API
usando las funciones getAllRegistros y getGrupo. Está pasando un objeto con token, idPartida e
idAgencias como parámetros a estas funciones. Luego, los datos obtenidos se utilizan para
actualizar las variables de estado dataTable, totalEgreso, totalIngreso y grupo. El enlace
useEffect se activa cada vez que cambian idPartida, idAgencias o getData. El código también incluye
algunas declaraciones condicionales para manejar diferentes escenarios, como cuando los datos
obtenidos están vacíos o cuando hay un mensaje de error. */
useEffect(() => {
  
  const fetchData = async () => {
    const object = { token, idPartida, idAgencias }
    const data = await getAllRegistros(object)
   
    if (data.message == "Algo salio mal") {
      setDataTable([[]])
    
    } else if(data.length === 0){
        const data = ([[]])
        setDataTable([[]])
        setTotalEgreso(0)
        setTotalIngreso(0)
      }
     else {
      const grupoObject = { idPartida, idAgencias, token }
      const grupoHex = await getGrupo(grupoObject)
      let convert = parseInt((grupoHex[0].grupo),16)
      convert +=1
      let hex = convert.toString(16)
      while (hex.length < 4) {
        hex = "0" + hex;
      }

      setGrupo(hex)
      setDataTable(data)

      for (let i = 0; i < data.length; i++) {
       
        if (data[i].id_tipos_movimientos == 1) {
          egreso = egreso + data[i].monto
        } else {
          ingreso = ingreso + data[i].monto
        };

      }
      setTotalEgreso(egreso)
      setTotalIngreso(ingreso)
    }

  }
  fetchData()
 
}, [idPartida, idAgencias, getData])

/**
 * La función envía un nuevo objeto "partida" al servidor con una descripción, fecha, token, agencia
 * y empresa, y actualiza la interfaz de usuario si tiene éxito.
 */
async function sendPartida() {
  if (partida != "" && fecha != "" && idEmpresa != 0 && idAgencias != 0) {
    const [dia, mes, anio] = fecha.split('-');
    const fechaNueva = `${anio}-${mes}-${dia}`;


    const object = { descripcion: partida, fecha: fecha, token, agencia: idAgencias, empresa: idEmpresa }
    const result = await insertPartidas(object)
    const idPartida = await result.json()
    setIdPartida(idPartida)
    if(result.status == 200){
    setGetData(!getData)
    setShow(!show)
    setTotalEgreso(0)
    setTotalIngreso(0)
    setDataTable([[]])
    }
  } else {

    Swal.fire({
      title: 'Error',
      text: 'Hay campos vacios',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }
}
function handleShowModal() {
  setModalShow(true)
}
function handleShowGuardar() {
  setShowGuardar(true)

}
/**
 * Esta función formatea la fecha actual en una cadena con el formato "AAAA-MM-DD".
 */
function changeFecha() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  const formattedDate = `${year}-${month}-${day}`;
  setFecha(formattedDate);
}
/**
 * Esta función recibe datos, los procesa, los guarda y actualiza el estado en consecuencia.
 */
const receiveData = async (registro) => {
  const hexGrupo = grupo.toString(16).padStart(4, "0")
  const long = registro.montos.length
  let concepto = []
  let cuenta = []
  let movimientos = []
  let monto = []
  let estado = []
  for (let i = 0; i < long; i++) {
    concepto[i] = registro.concepto[i]
    cuenta[i] = registro.cuenta[i]
    movimientos[i] = registro.movimientos[i]
    monto[i] = registro.montos[i]
    estado[i] = 1
  }
  registro.estado = estado
  registro.grupo = hexGrupo
  registro.id_partidas = idPartida
  registro.agencia = idAgencias
  registro.fecha = fecha
  const object = { registro, token }
  const data = await saveRegistroOperativo(object)
  if (data === 200) {
    setGetData(!getData)
    setModalShow(false)
  } else {
    Swal.fire({
      title: 'Error',
      text: 'Ocurrio un error inesperado',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }
}

/**
 * Esta función guarda una partida y verifica cualquier discrepancia en el ingreso total
 * (ingreso) y el egreso total (gasto).
 */
const savePartida = async () => {
  if (totalEgreso != totalIngreso) {

    Swal.fire({
      title: 'Error',
      text: 'Se ha detectado descuadre',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  } else {
    const object = { idPartida: idPartida, token: token}

    const savePartida = await guardarPartida(object)
    if (savePartida === 200) {


      Swal.fire({
        text: 'Guardado con exito',
        icon: 'success',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          
          setShowGuardar(false)
        };
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error inesperado',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  }
}
/**
 * La función comprueba si hay un equilibrio entre el total de ingresos y gastos, y si lo hay, guarda
 * los datos y reinicia el formulario.
 */
const saveExit = async () => {
  if (totalEgreso != totalIngreso) {

    Swal.fire({
      title: 'Error',
      text: 'Se ha detectado descuadre',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  } else {
    const object = { idPartida: idPartida, token: token }
    const savePartida = await guardarPartida(object)
    if (savePartida === 200) {


      Swal.fire({
        text: 'Guardado con exito',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          setShowGuardar(false)
          setDataTable([[]])
          setTotalEgreso(0)
          setTotalIngreso(0)
          setIdAgencia(0)
          setIdEmpresa(0)
          setIdPartida(0)
          setShow(!show)

        };
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error inesperado',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  }
}
/**
* La función "buscarPartida" establece el estado de "showBuscar" a verdadero.
*/
const buscarPartida = async () => {
  setShowBuscar(true)
}

/**
* Esta función establece las variables de estado para la identificación de un juego y la
* identificación de la agencia, y alterna la visibilidad de ciertos componentes.
*/
const cargarPartida = async (registro) => {
  setIdPartida(registro.idPartida)
  setIdAgencia(registro.idAgencia)
  setIdEmpresa(registro.empresa)
  setShowBuscar(false)
  setShow(true)
}
/**
* La función establece las variables de estado "showEditar" e "idRegistro" en verdadero y el valor
* pasado de "idRegistro", respectivamente.
*/
const loadEditar = (idRegistro) => {
  setShowEditar(true)
  setIdRegistro(idRegistro)
}
/**
 * La función "editarRegistro" establece el estado de "showEditar" en falso y activa una nueva
 * recuperación de datos al establecer el estado de "getData" en verdadero.
 */
const editarRegistro = () => {
  setShowEditar(false)
  setGetData(!getData)

}
/**
* La función establece las variables de estado "showEliminar" e "idRegistro" en verdadero y el valor
* "idRegistro" pasado, respectivamente.
*/
const loadEliminar = (idRegistro) => {
  setShowEliminar(true)
  setIdRegistro(idRegistro)
}
/**
 * La función "eliminarRegistro" establece "showEliminar" en falso y activa una actualización de
 * recuperación de datos.
 */
const eliminarRegistro = () => {
  setShowEliminar(false)
  setGetData(!getData)
}


/* El código anterior es un componente de React que genera un formulario para crear y administrar
asientos contables (partidas). Incluye varios modos para editar, eliminar y buscar entradas, así
como una tabla para mostrar las entradas existentes. El componente también calcula y muestra los
ingresos y gastos totales de cada entrada. */
return (
  <>

    <NavBar></NavBar>
    <ModalsOperativos
      show={modalShow}
      onHide={() => setModalShow(false)}
      onChange={receiveData}
      partida={idPartida}
      agencia={idAgencias}
      empresa={idEmpresa}
      />
      
    <AceptarModal
      show={showGuardar}
      onHide={() => setShowGuardar(false)}
      save={savePartida}
      saveExit={saveExit}
    />
    <BuscarModal
      show={showBuscar}
      onHide={() => setShowBuscar(false)}
      buscar={cargarPartida}
      tipo_empresa = {2}
    ></BuscarModal>
    <EditarModal
      show={showEditar}
      onHide={() => setShowEditar(false)}
      id={idRegistro}
      agencia={idAgencias}
      save={editarRegistro}
    ></EditarModal>
    <EliminarModal
      show={showEliminar}
      onHide={() => setShowEliminar(false)}
      id={idRegistro}
      agencia={idAgencias}
      partida={idPartida}
      save={eliminarRegistro}
      token={token}
    ></EliminarModal>
    {!show && (
      <div className="m-4" >

        <Form >
          <Row className="d-flex align-items-center">
            <Col>
              <Form.Label>Descripcion de la partida</Form.Label>
              <Form.Control placeholder="Descripcion" value={partida} onChange={(e) => setPartida(e.target.value)}
                disabled={show ? true : false}
              />
            </Col>
            <Col>
              <Form.Label>Fecha de creacion</Form.Label>
              <InputGroup>
              <Form.Control placeholder="Last name" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)}
                disabled={show ? true : false}/>
               <Button variant="primary" onClick={changeFecha}><BsFillClockFill></BsFillClockFill></Button>
               </InputGroup>
            </Col>
            
            <Col>
              <Form.Label>Empresa</Form.Label>
              <Form.Select placeholder="Empresa" type="Text" onChange={(e) => setIdEmpresa(e.target.value)}
                disabled={show ? true : false}
              >
                <option value="0">Seleccione una opcion</option>
                {
                  empresas[0].map((props, index) => (
                    <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                  ))
                }
              </Form.Select>
            </Col>

            <Col>
              <Form.Label>Agencia</Form.Label>
              <InputGroup>
              <Form.Select placeholder="Agencia" type="text" onChange={(e) => setIdAgencia(e.target.value)}
                disabled={show ? true : false}
               
                
              >
                <option value='0'>Seleccione una opcion</option>
                {
                  agencias[0].map((props, index) => (
                    <option key={props.id_agencia} value={props.id_agencia}>{props.agencia}</option>
                  ))
                }
              </Form.Select>
             
              </InputGroup>
            </Col>

            <Col>
           <br />
            <InputGroup>
            {!show && (<Button variant="success" onClick={sendPartida} className="my-1">Crear partida</Button>)}
            </InputGroup>
             
            </Col>
          </Row>
        </Form>
      </div>
    )}

    {show && (
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
        <Button className="mx-4" variant="primary" onClick={handleShowModal}><BsFillClipboard2PlusFill></BsFillClipboard2PlusFill></Button>

      </div>
    )}

    {show && (
      <>
       <h2 style={{ textAlign:"center", color:"white"}}>Partida #{idPartida}</h2>
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          
          <Table striped bordered hover size="sm" className="my-3 mx-4 my-table" style={{}}>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-sm-2">Cuenta contable</th>
                <th className="col-sm-4">Concepto</th>
                <th>Egreso</th>
                <th>Ingreso</th>
                <th>Grupo</th>
                <th>Tipo registro</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>

              <>
                {dataTable.length != 0 && dataTable[0].length !=0 ?
                  dataTable.map((props, index) => (

                    <tr>

                      <td>{index + 1}</td>
                      <td>{props.nombre_cuenta}</td>
                      <td>{props.concepto}</td>
                      {props.id_tipos_movimientos === 1 ?
                        <>

                          <td>${props.monto}</td> </> : <td>$0</td>
                      }
                      {props.id_tipos_movimientos === 2 ?
                        <>

                          <td>${props.monto}</td></> : <td>$0</td>
                      }

                      <td>{props.grupo}</td>
                      <td>{props.tipo}</td>





                      <td>
                        
                        {props.editable === 1 ? 
                        <>
                        <Button className="m-1" variant="danger" onClick={() => loadEliminar(props.id_registro)}>Eliminar</Button>
                        <Button className="m-1" onClick={() => loadEditar(props.id_registro)}>Editar</Button>
                        </> : <></> 
                      }
                        
                      </td>
                    </tr>
                  ))
                  : <></>}
              </>



            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td>${totalEgreso}</td>
                <td>${totalIngreso}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </Table>


        </div>

      </>
    )}
    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "right", justifyContent: "right" }}>

      {show && (<>
        <Button className="mx-2" variant="success" onClick={handleShowGuardar}> <BiSave></BiSave> Guardar partida</Button></>)}
      <Button className="mx-2" onClick={buscarPartida}><AiOutlineFolderOpen></AiOutlineFolderOpen> Buscar partida</Button>
    </div>


  </>
)
}