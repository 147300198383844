import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getEmpresas, getAgenciasXEmpresa, getEmpresasOperativas, getEmpresasAll } from "../../services/geolocalizacion/places";
import { Form } from "react-bootstrap";
import { getPartidaByid, getPartidasFiltradas } from '../../services/partidas/partidas';
import Swal from 'sweetalert2';

export const BuscarModal = (props) => {
    const token = localStorage.getItem('token');
    const [idEmpresa, setIdEmpresa] = useState(0)
    const [idAgencias, setIdAgencia] = useState(null)
    const [idPartida, setIdPartida] = useState(0)
    const [empresas, setEmpresas] = useState([[]])
    const [agencias, setAgencias] = useState([[]])
    const [partidas, setPartidas] = useState([[]])
    const [fecha, setFecha] = useState(null)
    const [tipoBusqueda, setTipoBusqueda] = useState(true)
    const [numeroPartida, setNumeroPartida] = useState(0)
    
    useEffect(() => {
        const fetchData = async () => {
          const object = { token }
          const empresas = await getEmpresasAll(object)
          setEmpresas([empresas])
    
        }
        fetchData()
      }, [])
/////////////////////////////////////////////////////////
      useEffect(() => {
        if (idEmpresa != 0) {
          const fetchData = async () => {
            const object = { token, idEmpresa }
            const tipos = await getAgenciasXEmpresa(object)
            setAgencias([tipos])
          }
          fetchData()
        } else {
          const tipos = []
          setAgencias([tipos])
        }
      }, [idEmpresa])
/////////////////////////////////////////////////////////
      useEffect(() => {
        if (idEmpresa != 0 && idAgencias != null && fecha != null) {
          const fetchData = async () => {
            const object = { token, idEmpresa, idAgencias, fecha}
            const tipos = await getPartidasFiltradas(object)
            setPartidas([tipos])
          }
          fetchData()
        } else {
          const tipos = []
          setPartidas([tipos])
        }
      }, [idAgencias, fecha])


    async function handleBuscar(){
      if(tipoBusqueda){
      const search = {token, numeroPartida: idPartida}
      const res = await getPartidaByid(search)
      const object = {idPartida: idPartida, idAgencia: idAgencias, empresa: idEmpresa, fecha: res[0].fecha_creacion, agencia: res[0].nombre_agencia.agencia, empresaNombre: res[0].empresaModel.nombre_empresa, concepto: res[0].descripcion, comprobante: res[0].comprobante_mensual}
      
      props.buscar(object)
    
      }else{
       
        const data = {token, numeroPartida}
        const object = await getPartidaByid(data)
       
        if(object[0] != undefined){
     
          const returnData = {idPartida: idPartida, idAgencia: idAgencias, empresa: idEmpresa, fecha: object[0].fecha_creacion, agencia: object[0].nombre_agencia.agencia, empresaNombre: object[0].empresaModel.nombre_empresa, concepto: object[0].descripcion}
          props.buscar(returnData)
          setTipoBusqueda(!tipoBusqueda)
        }else{
          Swal.fire({
            title: 'Error',
            text: 'No se ha encontrado ninguna partida con este ID',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
        
      }
    }
    function handleChangeSearch(){
     setTipoBusqueda(!tipoBusqueda)
    }

    return (
    <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Busqueda de partida</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <Form.Check 
        type="switch"
        id="custom-switch"
        label="Busqueda por numero de partida"
        onChange={handleChangeSearch}
      /> */}
        
 
      {tipoBusqueda ? (
        <>
               <Form.Label>Mes</Form.Label>
               <Form.Control type="month" onChange={(e) => setFecha(e.target.value)} />
              <Form.Label>Empresa</Form.Label>
              <Form.Select placeholder="Empresa" type="Text"  onChange={(e) => setIdEmpresa(e.target.value)}>
              <option value="0">Seleccione una opcion</option>
                {
                  empresas[0].map((props, index) => (
                    <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                  ))
                }

              </Form.Select>
        <Form.Label>Agencia</Form.Label>
            <Form.Select placeholder="Empresa" type="Text" onChange={(e) => setIdAgencia(e.target.value)}>
            <option value='0'>Seleccione una opcion</option>
                {
                  agencias[0].map((props, index) => (
                    <option key={props.id_agencia} value={props.id_agencia}>{props.agencia}</option>
                  ))
                }
            </Form.Select>
        <Form.Label>Partida</Form.Label>
        <Form.Select placeholder="Empresa" type="Text" onChange={(e) => setIdPartida(e.target.value)}>
        <option value='0'>Seleccione una opcion</option>
                {
                  partidas[0].map((props, index) => (
                    <option key={props.id_partida} value={props.id_partida}>#{props.comprobante_mensual} {props.descripcion}</option>
                  ))
                }
        </Form.Select>
        </>
        ) : (
          <>
          <Form.Label htmlFor="numero">Numero de partida</Form.Label>
          <Form.Control
            type="number"
            id="numero"
            aria-describedby="passwordHelpBlock"
            onChange={(e)=> setNumeroPartida(e.target.value)}
          />
          <Form.Text id="passwordHelpBlock" muted>
           Ingrese el numero de la partida que desea buscar
          </Form.Text>
          </>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleBuscar} >
            Abrir
          </Button>
        </Modal.Footer>
      </Modal>
    )
}