import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getRegistrosPartidas = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/getRegistroPartidas`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             empresa: data.empresa,
             fechaInicio: data.fechaInicio,
             fechaFinal: data.fechaFinal,
             agencia: data.agencia,
             
         })
   })
   const respuesta = await response.json()
   return respuesta
}

export const eliminarPartida = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/eliminarPartida`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             id_partida: data.id_partida,
             id_agencia: data.id_agencia,
             
         })
   })
   const respuesta = await response
   return respuesta
}