import { useEffect, useState } from "react";
import { NavBar } from "../../components/navbar";
import { Buscador } from "../../components/reporteCuentasComponent/buscador";
import { Table } from "react-bootstrap";
import '../../css/Tablas.css';
export function ReporteCuentas(){
    const [hasData, sethasData] = useState(false)
    const [data, setData] =  useState([])
    const [datafiltrado, setDataFiltrado ] = useState([])
    const [tableRow, settableRow] = useState([])
    const [nombrecuenta, setnombreCuenta] = useState('')
    const [filtered, setFiltered] = useState(true)

    useEffect(() =>{
        function setRow(){
            const table = []
            for (let i = 0; i < data.length; i++) {
               if(data[i].length > 0){
                    data[i].forEach(element => {
                        table.push(
                            <tr>
                               
                            <td>{element.concepto}</td>
                            <td>{element.grupo}</td>
                            <td>{element.partidas_contable.fecha_creacion}</td>
                            <td>{element.id_partida}</td>
                            <td>{element.tipos_movimiento.tipo}</td>
                            <td>$ {element.monto}</td>

                            
                          </tr>
                        )
                        setnombreCuenta(element.cuentum.nombre_cuenta)
                    });
               }
                
            }
            settableRow(table)
        }
        setRow()
    }, [data])


    useEffect(() => {
        function setRowFiltered(){
           
            const table = []
            for (let i = 0; i < datafiltrado.length; i++) {
               if(datafiltrado[i].length > 0){
                datafiltrado[i].forEach(element => {
                        table.push(
                          element
                        )
                        setnombreCuenta("Filtrado por cuentas")
                    });
               }
                
            }
           table.sort(function(a,b){
            return a.cuentum.numero_cuenta - b.cuentum.numero_cuenta
           })
           const showTable = []
           table.forEach(element => {
            showTable.push(
                <tr>
                   
                <td>{element.concepto}</td>
                <td>{element.grupo}</td>
                <td>{element.partidas_contable.fecha_creacion}</td>
                <td>{element.id_partida}</td>
                <td>{element.cuentum.numero_cuenta}</td>
                <td>{element.tipos_movimiento.tipo}</td>
                <td>$ {element.monto}</td>

                
              </tr>
           
            )
           })
          
            settableRow(showTable)
        }
        setRowFiltered()
    }, [datafiltrado])


    const handleData = (object) => {
        console.log(object)
        if(object.filtrado === 0 ){
            setData(object)
            sethasData(true)
        }else{
            setDataFiltrado(object)
            sethasData(true)
            setFiltered(false)
        }
        
    }
    const verData = () => {
        console.log(data)
    }
    if(hasData){
    return (
       
        <div>    
        <NavBar></NavBar>
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", marginTop:"2%" }}>
            <h1>{nombrecuenta}</h1>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            {filtered ? (
        <Table striped style={{marginTop: "2%" ,boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} className='my-table'>
      <thead>
        <tr>
        
          <th>Concepto.</th>
          <th>Grupo</th>
          <th>Fecha creacion</th>
          <th>Numero partida</th>
          <th>Tipo movimiento</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        {tableRow}
      </tbody>
    </Table>
            ) : (<>
                    <Table striped style={{marginTop: "2%" ,boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} className='my-table'>
      <thead>
        <tr>
        
          <th>Concepto.</th>
          <th>Grupo</th>
          <th>Fecha creacion</th>
          <th>Numero partida</th>
          <th>Cuenta</th>
          <th>Tipo movimiento</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        {tableRow}
      </tbody>
    </Table>
            
            </>)}
       </div>
        </div>

    )
    }else{
        return (
            <div>    
            <NavBar></NavBar>
        
            <Buscador onFinish={handleData} style={{ marginTop: "20%"}}></Buscador>
        
            </div>
        )
    }
}