// store/reducers/index.js
import { combineReducers } from 'redux';
import exampleReducer from './exampleReducer';
import agenciaReducer from './setAgenciaCierreReducer';

const rootReducer = combineReducers({
  example: exampleReducer,
  agencia: agenciaReducer
  // Agrega otros reducers aquí
});

export default rootReducer;