import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
// if(MODE == "production"){
   
//      BASE_URL = API_production()
// }else if(MODE == "development"){

//      BASE_URL = API_DEV()
// }
BASE_URL = process.env.REACT_APP_API_URL

export const getRegistrosCuenta = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             cuenta: data.cuenta,
             inicio: data.inicio,
             fin: data.fin,
             
         })
   })
   const respuesta = await response.json()
   return respuesta
}
export const actualizarCuentaCierreDiario = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/actualizarCuentasCierre`,{
      "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id_cierre: data.id_cierre,
          cuenta: data.cuenta,
          nombre_cuenta: data.nombre_cuenta,
        })
  })
  const respuesta = await response.json()
  return respuesta
}

export const reporteCuentasRango = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/reporteRange`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
           cuentaInicio: data.cuentaInicio,
           cuentaFin: data.cuentaFin,
           inicio: data.inicio,
           fin: data.fin,
           
       })
 })


 const respuesta = await response.json()
 return respuesta
} 

export const reporteCierresDiarios = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/reporteCierres`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
           empresa: data.empresa,
           agencia: data.agencia,
           fecha: data.fecha,
           
           
       })
 })
 const respuesta = await response.json()
 return respuesta
}

export const reporteCierresDiariosEmpresa = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/reporteCierresEmpresa`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
           empresa: data.empresa,
         
           fecha: data.fecha,
           
           
       })
 })
 const respuesta = await response.json()
 return respuesta
}

export const aprobarCierreDiario = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/confirmarCierre`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_cierre: data.id_cierre,
      data: data.data,
      empresa: data.empresa,
      ingresosBoveda: data.ingresosBoveda,
      egresosBoveda: data.egresosBoveda,
      fecha: data.fecha,
    })
  })
  const respuesta = await response.json()
  return respuesta
}

export const getPagosEntreAgencias = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/pagosEntreAgencias`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    fecha: data.fecha,
    agencia: data.agencia,
    id_cierre: data.id_cierre,
  })
})
const respuesta = await response.json()
return respuesta
}

export const guardarMovimientosEntreAgencias = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/guardarEntreAgencias`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    comprobante: data.comprobante,
    cuenta: data.cuenta,
    id_cierre: data.id_cierre,
    tipo_cuenta: data.tipo_cuenta,
  })
})
const respuesta = await response.json()
return respuesta
}

export const  getMovimientosBoveda = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/movimientosBoveda`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    fecha: data.fecha,
    agencia: data.agencia,
  })
})
const respuesta = await response.json()
return respuesta
}

export const actualizarCuentaMovBoveda = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/actualizarMovBoveda`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    id_movimiento: data.id_movimiento,
    cuenta: data.cuenta,
    validator: data.validator,
  })
})
const respuesta = await response.json()
return respuesta
}

export const distribucionCuotas = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/distribucionCuotas/${data.id_cierre}`,{
  "method": "GET",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
})
const respuesta = await response.json()
return respuesta
}

export const actualizarMovDistPago = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/guardarCuentasDistPago`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    id_movimiento: data.id_movimiento,
    cuenta: data.cuenta,
    tipo: data.tipo,
  })
})
const respuesta = await response
return respuesta.status
}

export const getMovBancos = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/movimientosBanco`,{
  "method": "POST",
  headers: {
    "Authorization": `Bearer ${data.token}`,
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    agencia: data.agencia,
    fecha: data.fecha,
  })
})
const respuesta = await response.json()
return respuesta
}
export const actualizarMovBanco = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/actualizarCuentaBanco`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_movimiento: data.id_movimiento,
      cuenta: data.cuenta,
      validator: data.validator,
    })
  })
  const respuesta = await response
  return respuesta.status
}

export const getPagosEntreAgenciasSumatoria = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/getPagosEntreAgencias/${data.id_resumen_operaciones}`,{
    "method": "GET",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
   
  })
  const respuesta = await response
  return respuesta
}

export const movimientosCuentas = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/reporteMovimientosCuentas`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cuenta: data.cuenta,
      fechaInicio: data.fechaInicio,
      fechaFinal: data.fechaFinal,
      empresa: data.empresa,
    })
  })
  const respuesta = await response
  return respuesta.json()
}
export const movimientosCuentasRango = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/reporteMovimientosRango`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cuenta: data.cuenta,
      fechaInicio: data.fechaInicio,
      fechaFinal: data.fechaFinal,
      empresa: data.empresa,
    })
  })
  const respuesta = await response
  return respuesta.json()
}
export const movimientosCuentasAgencia = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/reporteMovimientosAgencia`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cuenta: data.cuenta,
      fechaInicio: data.fechaInicio,
      fechaFinal: data.fechaFinal,
      agencia: data.agencia,
      empresa: data.empresa,
    })
  })
  const respuesta = await response
  return respuesta.json()
}
export const getCuentasenRango = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/traerCuentasenRango`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cuentaInicial: data.cuentaInicial,
      cuentaFinal: data.cuentaFinal,
      empresa: data.empresa,
      page: data.page,
    })
  })
  const respuesta = await response
  return respuesta.json()
}

export const revertirCierre = async(data) => {
  const response = await fetch (`${BASE_URL}/reportes/revertirCierre`,{
    method:"POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_cierre: data.id_cierre,
      data: data.data,
      empresa: data.empresa,
      ingresosBoveda: data.ingresosBoveda,
      egresosBoveda: data.egresosBoveda,
      fecha: data.fecha,
      idAgencia: data.idAgencia,
    })
  })
  const respuesta = await response.status
  return respuesta
}

export const guardarPagosEntreAgencia = async (data) => {
  const response = await fetch (`${BASE_URL}/reportes/guardarMovimientosPagosEntreAgencia`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_movimiento: data.id,
      fecha: data.fecha,
      agencia: data.agencia,
      empresa: data.empresa,
    })
  })
  const respuesta = await response
  return respuesta.status
}

export const AbonoMovimientoPagEntreAgencia = async (data) => {
  console.log(data)
  const response = await fetch (`${BASE_URL}/reportes/AbonoMovimientoPagEntreAgencia`,{
    "method": "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      agencia: data.agencia,
      fecha: data.fecha,
      datos: data.datos,
      empresa: data.empresa,
      monto: data.monto,
      tipo_movimiento: data.tipo_movimiento,
      cierre: data.cierre,
      comprobante: data.comprobante,
      cuenta: data.cuenta,

    })
  })
  const respuesta = await response
  return respuesta.status
}