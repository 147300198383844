let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const GetBancos = async(data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getBancos`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }, 
     
    })
    const res = await response.json();
    return res
    
}
export const AddCuentaContableBanco = async(data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/addCuentaContableBanco`, {
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({
            id_banco: data.id_bancos,
            cuenta_contable: data.cuenta_contable,
            contra_cuenta_contable: data.contra_cuenta_contable
        })
    })
    const res = await response;
    return res
    
}
export const getConciliaciodosXMes = async(data) => {
    const response = await fetch(`${BASE_URL}/bancos/getMovimientosAconciliar`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            mes: data.mes,
            id_empresa: data.id_empresa
        })
        
    })
    const res = await response
    return res
}

export const guardarConciliacionBancos = async(data) => {
    const response = await fetch(`${BASE_URL}/bancos/guardarMovimientosAconciliar`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            mes: data.mes,
            id_empresa: data.id_empresa,
            data: data.data
        })
        
    })
    const res = await response
    return res
}