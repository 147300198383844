import { NavBar } from "../../components/navbar";
import '../../css/tableBalanza.css';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { useState,useEffect } from "react";
import '../../css/Tablas.css';
import Button from 'react-bootstrap/Button';
import { addCuentaDistriCuotas, getDistribucionCuotas } from "../../services/cuentasPredeterminada/distCuotas";
import { Modal, ModalFooter } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import { getCuentasGenerales } from "../../services/subTiposMovBoveda/subtiposMovBoveda";
import Swal from "sweetalert2";
export function Cuotas(){
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [cuentas, setCuentas] = useState([]);
    const [cuenta, setCuenta] = useState(null) 
    const [contraCuenta, setContraCuenta] = useState(null)
    const [id_cuotas, setIdCuotas] = useState(null);
    const [helper, setHelper] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const object = {
                token: localStorage.getItem('token')
            }
            const res = await getDistribucionCuotas(object);
            console.log(res)
            setData(res);
        }
        fetchData();
        const fetchCuentas = async () => {
            const object = {
                token: localStorage.getItem('token')
            }
            const res = await getCuentasGenerales(object)
            let options = []
            res.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta+"-"+index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
        fetchCuentas();
    }, [helper]);


    const handleShowModal = (id) => {
        setShowModal(true);
        setIdCuotas(id);
    }

    const handleNewCuenta = async () => {
        if(cuenta === null || contraCuenta === null ){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Selecciona ambas cuentas',
                })
        }else{
            const object = {
                token: localStorage.getItem('token'),
                id: id_cuotas,
                cuenta_contable: cuenta[0],
                contra_cuenta_contable: contraCuenta[0]
            }
            console.log(object)
            const res = await addCuentaDistriCuotas(object)
            if(res.status === 200){
                Swal.fire({
                    icon: 'success',
                    title: 'Cuenta agregada correctamente',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  setShowModal(false);
                  setCuenta(null);
                  setHelper(!helper);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo salio mal',
                  })
            }
        }
    }
    return(
        <>
        <NavBar/>        
        <div className="shadow" style={{ margin: '30px auto', maxWidth: '90%', textAlign: 'center' }}>
        <h1 style={{marginBottom:"20px", marginTop:"20px"}}>Cuentas predeterminadas para la distribución de cuotas</h1>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft:"10%" }}>
        <Stack direction="horizontal" >
        {/* <Form.Control className="Busqueda" placeholder="Búsqueda de cuenta" style={{ width: "500px" }} value={search} onChange={e => handleSearch(e.target.value.toLowerCase())} />
        <Button onClick={handleSearch}><AiOutlineSearch /></Button> */}
      
       
        </Stack>
        <br />
        </div>
        <Table className="my-table" style={{ margin: '20px auto' }}  >
        <thead>
            <tr>
                <th >Movimiento</th>
                <th >Cuenta contable predeterminada</th>
                <th>Contra cuenta contable predeterminada</th>
                <th>Accion</th>
                
              
            </tr>
        </thead>
        <tbody>
            {data.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.concepto}</td>
                        <td>{item.cuenta_contable === null ? <>Sin cuenta predeterminada</>: <>{item.cuenta_contable}</>}</td>
                        <td>{item.contra_cuenta_contable === null ? <>Sin contra cuenta predeterminada</>: <>{item.contra_cuenta_contable}</>}</td>
                        <td><Button variant="primary" onClick={() => handleShowModal(item.id)}>Editar</Button></td>
                    </tr>
                )
            })}
        </tbody>
    </Table>

    </div>
      {/* modal para agregar un nuevo concepto */}
      <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar cuenta contable predeterminada</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >
        <Form.Group>
        <Form.Label>Selecciona la cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setCuenta(e)}

        />
        </Form.Group>
        <Form.Group>
        <Form.Label>Selecciona la contra cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setContraCuenta(e)}

        />
        </Form.Group>



             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleNewCuenta()}>
                Guardar
                </Button>
            </ModalFooter>
        </Modal>
        {/* FIN */}

        </>

    )
}