import { BuscadorLibroMayor } from "../../components/LibroMayorComponentes/buscador";
import { NavBar } from "../../components/navbar";
import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import {AiFillFileExcel, AiFillFilePdf} from 'react-icons/ai';
import '../../css/Tablas.css'
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { getMovimientosCuenta } from "../../services/reportes/reporteLibroMayor";
import Swal from "sweetalert2";
export function LibroMayor(){
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [fecha, setFecha] = useState(null)
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [nombreEmpresa, setNombreEmpresa] = useState(null)
    const [showPagination, setShowPagination] = useState(true)
    
    // useEffect(() => {
    //     const sendBuscar = async() => {

    //         const object = {
    //             empresa: idEmpresa,
    //             mes: fecha,
    //             token: localStorage.getItem('token'),
    //             page: page
    //         }
    //         const result = await getMovimientosCuenta(object)
    //         console.log(object)
    //         console.log(result)
    //         setData(result)
    //     }
    //     sendBuscar()
    // }, [page])

    const handleDownloadPDF = () => {
        window.print();
      }

    const changePage = async(e) => {
        Swal.fire({
            title: 'Cargando',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        setPage(e)
        const object = {
        empresa: idEmpresa,
        mes: fecha,
        token: localStorage.getItem('token'),
        page: e,
        cuenta: ''
        }
        const result = await getMovimientosCuenta(object)
        Swal.close()
        setData(result)

        console.log(result)
                

    }


    if(data.length === 0){
        return(
            <>
            <NavBar/>
            <div>
                <BuscadorLibroMayor
                page = {page}
                data={(e) => {setData(e)}}
                fecha={(e) => {setFecha(e)}}
                empresa={(e) => {setIdEmpresa(e)}}
                nombreEmpresa={(e) => {setNombreEmpresa(e)}}
                showPagination={(e) => {setShowPagination(e)}}
                />
            </div>
            </>
        )

    }else{

    
    return(
        <>
        <NavBar/>
        <div style={{ margin: '60px',padding:"10px", textAlign: 'center', backgroundColor:"#b4cbd6", borderRadius:"15px" }} className="boxShadow">
            <h1 style={{marginTop:"15px", color:"black"}}>Libro mayor</h1>
            <h3>{nombreEmpresa}</h3>
     
      <Table className="custom-table" bordered  hover>
        <thead>
            <tr>
                <th>CUENTA</th>
                <th>NOMBRE DE LA CUENTA</th>
                <th>FECHA</th>
                <th>SALDO ANTERIOR</th>
                <th>SALDO ACTUAL</th>
                
            </tr>
        </thead>
        <tbody>
        {data.map((item) => (
            <>
           
                    <tr>
                        <td>{item.cuenta}</td>
                        <td>{item.nombre}</td>
                        <td>{fecha}</td>
                        <td>{item.saldo_inicial != null ? item.saldo_inicial.toFixed(2): 0}</td>
                        <td>{item.saldo_final.toFixed(2)}</td>
                   
                        </tr>
                          
                    <tr>
                            
                            <td colSpan={6}>
                            <Table  className="custom-table">
                            <thead>
                            <tr>
                            <th>FECHA</th>
                            <th>CONCEPTO</th>
                            <th>CUENTA</th>
                            <th>MONTO</th>
                            <th>TIPO DE MOVIMIENTO</th>
                            </tr>
                             </thead>
                        
                        {(item.movimientos.length === 0 ) ?   
                                <tbody>
                                    <tr>
                                   <td colSpan={5}>NO SE REGISTRARON MOVIMIENTOS EN ESTA FECHA</td>
                                    </tr>    
                                </tbody>  : <>
                                
                        {item.movimientos.map((movimiento) => (
                            <>  
                                <tbody>
                                    <tr>
                                   
                                    <td>Movimiento del dia: {movimiento.fecha_ingreso.slice(0,10)}</td>
                                    <td>{movimiento.movimiento}</td>
                                    <td>{movimiento.cuenta}</td>
                                    <td>{movimiento.monto.toFixed(2)}</td>

                                    <td>{movimiento.tipo_movimiento === 1 ? <>Cargo</> : <>Abono</>}</td>
                                    </tr>    
                                </tbody> 
                            </>
                        ))}
                                
                                </>}
                       
                            </Table>
                            </td>
                          
          
                    </tr>
            </>
               
                ))}

        </tbody>
      </Table>

    
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      {showPagination ? <div className="mx-auto unshow">
            <PaginationControls page={page} onPageChange={(e) => changePage(e)} />
        </div> : <></>}
          
      </div>
      
      
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="unshow">
          <Button variant="danger" type="submit" className='my-2' onClick={handleDownloadPDF} >
                <AiFillFilePdf/> Descargar PDF
          </Button>
          
          </div>
    </div>
    
        </>
    )
    }
}