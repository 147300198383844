import { NavBar } from "../../components/navbar";
import '../../css/tableBalanza.css';
import Table from 'react-bootstrap/Table';
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { useState,useEffect } from "react";
import '../../css/Tablas.css';
import Button from 'react-bootstrap/Button';
import { GetCuentasGenerales, GetCuentasGeneralesOperativas, deleteCuentaGeneral, insertCuentaGeneral } from "../../services/CuentaContable/CuentaGeneral";
import Form from 'react-bootstrap/Form';
import { Modal, ModalFooter } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Stack from 'react-bootstrap/Stack';
import {AiOutlineSearch} from 'react-icons/ai';

export function CuentasGeneralesOperativas(){
    const [page, setPage] = useState(1)
    const [cuentas, setCuenta] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedCuenta, setSelectedCuenta] = useState(null)
    const [search, setSearch] = useState('')
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [nombreCuenta, setNombreCuenta] = useState('')

    useEffect(() => {
        const fetchCuentas = async () => {
            const object = {
                page: page,
                token: localStorage.getItem('token'),
                search: search
            }
            const getCuentas = await GetCuentasGeneralesOperativas(object)
            console.log(getCuentas)
            setCuenta(getCuentas)
        }

        fetchCuentas()
    }, [page])
    
    const showDelete = async (e) => {
      
        Swal.fire({
            title: '¿Estas seguro que deseas eliminar esta cuenta?',
            text: "No podras revertir esta accion",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async(result) => {
            if (result.isConfirmed) {
               const object = {
                     id_cuenta:e.id_cuenta,
                     token:localStorage.getItem('token')
               }
            
               const deleteCuenta = await deleteCuentaGeneral(object)
              
               if(deleteCuenta === 200){
                Swal.fire({
                    title: 'Cuenta eliminada con exito',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
                }else{
                    Swal.fire({
                        title: 'Error al eliminar la cuenta',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        })
    }

    const handleSearch = async (value) => {
     setSearch(value)
            const object = {
                page: page,
                token: localStorage.getItem('token'),
                search: value
            }
            const getCuentas = await GetCuentasGeneralesOperativas(object)
            setCuenta(getCuentas)
    

    }
    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
    
        // Verificar si el valor coincide con el patrón (en este caso, solo dígitos)
        const pattern = /^[0-9]+$/;
        setIsValid(pattern.test(value));
       
      };
    
    const handleGuardarCuenta = async () => {
        if(isValid && nombreCuenta !== ''){
            const object = {
                numero_cuenta: inputValue,
                token: localStorage.getItem('token'),
                nombre_cuenta: nombreCuenta,
                tipo_empresa: 2 //tipo de empresa operativa

            }
           const result = await insertCuentaGeneral(object)
           if(result === 200){
            Swal.fire({
                title: 'Cuenta agregada con exito',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }else if(result === 403){
            Swal.fire({
                title: 'Cuenta ya existe',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        }else{
            Swal.fire({
                title: 'Error al agregar la cuenta',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        }
           
        }else{
            Swal.fire({
                title: 'Campos vacios o invalidos',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        }
    }



    return (
        <>
        <NavBar/>
        <div className="shadow" style={{ margin: '30px auto', maxWidth: '90%', textAlign: 'center' }}>
        <h1 style={{marginBottom:"20px", marginTop:"20px"}}>Cuentas Generales para empresas operativas</h1>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft:"10%" }}>
        <Stack direction="horizontal" gap={3}>
        <Form.Control className="Busqueda" placeholder="Búsqueda de cuenta" style={{ width: "500px" }} value={search} onChange={e => handleSearch(e.target.value.toLowerCase())} />
        <Button onClick={handleSearch}><AiOutlineSearch /></Button>
      
        <Button style={{marginLeft:"50px"}} variant="warning" onClick={() => setShowModal(true)}>Agregar</Button>
        </Stack>
        <br />
        </div>


    <Table className="my-table" style={{ margin: '0 auto' }}>
        <thead>
            <tr>
                <th style={{ width: "200px" }}>Cuenta Contable</th>
                <th style={{ width: "300px" }}>Nombre de cuenta</th>
                <th>Tipo de Cuenta</th>
                <th>Nivel</th>
              
            </tr>
        </thead>
        <tbody>
        {cuentas
             
             .map((props) => (
               <tr>
               
                 <td>{props.numero_cuenta}</td>
                 <td>{props.nombre_cuenta}</td>
                 <td>{props.tipo_cuenta}</td>
                 <td>{props.nivel_cuenta}</td>
                 
   
                
                {/* <Button variant="warning" style={{marginRight:'5px', marginTop:"5px"}} onClick={() => {handleShowModal(props)}}>Editar</Button>     */}

              
               </tr>
             ))}
        </tbody>
    </Table>
    <div className="d-flex justify-content-center" style={{marginTop:"10px"}}>
        <PaginationControls
          
            page={page}
            onPageChange={(e) => { setPage(e) }}
        ></PaginationControls>
    </div>
    
    </div>
    {/* modal para agregar un nuevo concepto */}
    <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar una nueva Cuenta Contable</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >
            <Form.Label>Digita el nombre de la cuenta</Form.Label>
            <Form.Control type="text" onChange={(e) => {setNombreCuenta(e.target.value)}}/>
            <Form.Group>
        <Form.Label>Digita el número</Form.Label>
        <Form.Control
          className="form-control"
          type="text"
          value={inputValue}
          onChange={handleChange}
          isInvalid={!isValid} // Agrega la clase 'is-invalid' si no es válido
          pattern="[0-9]+" // Patrón para permitir solo dígitos
          inputMode="numeric"
          size={5}
          mobile
        />
        <Form.Control.Feedback type="invalid">
          Ingresa solo números
        </Form.Control.Feedback>
      </Form.Group>




             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleGuardarCuenta()}>
                Guardar
                </Button>
            </ModalFooter>
</Modal>


        </>
    )
}