import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const DeleteCuentas = async(data) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/CuentaContable/delete/${data.idCuenta}`,{
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          }
    })
    const respuesta = await response
    return respuesta
}