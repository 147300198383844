import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
// if(MODE == "production"){
   
//      BASE_URL = API_production()
// }else if(MODE == "development"){

//      BASE_URL = API_DEV()
// }
BASE_URL = process.env.REACT_APP_API_URL

export const getFavoritos = async(token)=>{
    const response = await fetch(`${BASE_URL}/Menu_favoritos/getFavoritosPaginas`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    })
    const data = await response.json();
    return data
}