import { Container, Form, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { EmpresasSelect } from '../UTILS/EmpresasSelect';
import { EmpresaXAgenciaSelect } from '../UTILS/EmpresaXAgenciaSelect';
import { AutocompleteAlter } from '../UTILS/AutoCompleteCuentasEmpresas';
import { getCuentaEmpresas } from '../../services/partidas/partidas';
import { getMovimientosCuenta } from '../../services/reportes/reporteLibroMayor';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';


export function BuscadorLibroMayor(props) {
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresa, setempresa] = useState(null)
    const [fecha, setFecha] = useState(null)
    const [cuenta, setCuenta] = useState('')
    const [cuentas, setCuentas] = useState([[]])
    const [busquedaCuenta, setBusquedaCuenta] = useState(false)
    const [idAgencia, setIdAgencia] = useState(null)    

    useEffect(() => {
        async function getCuentas() {
            const token = localStorage.getItem('token')
            const object = {
                token: token,
                empresa: idEmpresa,
                agencia: ''
            }
            const cuentas = await getCuentaEmpresas(object)
            let options = []
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta + "-" + index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
        getCuentas()

    }, [])

    const sendBuscar = async (e) => {
        Swal.fire({
            title: 'Cargando',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        e.preventDefault()
        if(fecha !== null && idEmpresa !== null){
          
        const formattedDate = fecha.replace(/-0/, '-');

        const object = {
            empresa: idEmpresa,
            mes: formattedDate,
            token: localStorage.getItem('token'),
            page: props.page,
            cuenta: cuenta,
            agencia: idAgencia
        }
      
        const result = await getMovimientosCuenta(object)
        Swal.close()
        props.data(result)
        props.fecha(formattedDate)
        props.empresa(idEmpresa)
        props.nombreEmpresa(empresa)
        cuenta != '' ? props.showPagination(false) : props.showPagination(true)
        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Faltan campos por llenar',
              })

        }
    }
    const handleChangeCuenta = (e, value) => {
        setCuenta(value.value)
      
    }


    return (
        <>
            <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
                <Form onSubmit={sendBuscar}>
                    <center> <h2>Generar libro mayor</h2> </center>

                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Busqueda por numero de cuenta"
                        onChange={()=>{setBusquedaCuenta(!busquedaCuenta)}}
                    />
                    <EmpresasSelect
                        onChange={(e) => setIdEmpresa(e)}
                        empresa={(e) => setempresa(e)}
                    />
                    <EmpresaXAgenciaSelect
                      IdEmpresa = {idEmpresa}
                      onChange= {(e) => setIdAgencia(e)}
                      showlabel = {true}
                      showTodas = {true}
                    />


                    <Form.Group >
                        <Form.Label>Seleccione el mes</Form.Label>
                        <Form.Control type="month" onChange={(e) => setFecha(e.target.value)} />
                    </Form.Group>
                    {busquedaCuenta ? (
                        <>
                    <Form.Label className='mt-2'>Cuenta contable </Form.Label>

                    <Autocomplete

                        disablePortal
                        onChange={handleChangeCuenta}
                        id='0'
                        options={cuentas[0]}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Cuenta"
                            id='0'
                            style={{ width: '100%' }}


                        />}
                    />
                    </>
                    ) : (
                        <></>
                    )}

                    <Button variant="primary" type="submit" className='my-2' >
                        Buscar
                    </Button>




                </Form>
            </Container>
        </>
    )
}