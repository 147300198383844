import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
// if(MODE == "production"){
   
//      BASE_URL = API_production()
// }else if(MODE == "development"){

//      BASE_URL = API_DEV()
// }
BASE_URL = process.env.REACT_APP_API_URL

export const getRegistrosBalance = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/balance`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             empresa: data.empresa,
             fechaInicio: data.fechaInicio,
             fechaFinal: data.fechaFinal,
             page: data.page,
             
         })
   })
   const respuesta = await response.json()
   return respuesta
}

export const getRegistrosBalanceAgencia = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/reporteBalanzaxAgencia`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
        
      },
      body: JSON.stringify({
           empresa: data.empresa,
           fechaInicio: data.fechaInicio,
           fechaFinal: data.fechaFinal,
           page: data.page,
           agencia: data.agencia
           
       })
 })
 const respuesta = await response.json()
 return respuesta
}


export const getRegistrosBalanceAnual = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/getBalanceAnual`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
        
      },
      body: JSON.stringify({
           empresa: data.empresa,
           fecha: data.fecha,
           page: data.page,
           
       })
 })
 const respuesta = await response.json()
 return respuesta
}