import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { EmpresaXAgenciaSelect } from "../UTILS/EmpresaXAgenciaSelect";
import { getEmpresasAll } from "../../services/geolocalizacion/places";
import { reporteCierresDiarios } from '../../services/reportes/reportesCuentas';
export function BuscadorReporteCierreUnico(props){
  
    const [fecha, setFecha] = useState('')
    const [empresa, setEmpresa] = useState([[]])
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [idAgencia, setIdAgencia] = useState(null)
    const sendBuscar = async(e) => {
      e.preventDefault()
      const object = { token: localStorage.getItem('token'), fecha: fecha, agencia: idAgencia }
      const data = await reporteCierresDiarios(object)
      props.sendData(data)
    }
    const setNombreEmpresa = (e) => {
        setIdEmpresa(e.target.value)
    }

    useEffect(() => {
        const fetchData = async () => {
          const object = { token: localStorage.getItem('token') }
          const empresas = await getEmpresasAll(object)
          setEmpresa([empresas])
    
        }
        fetchData()
      }, [])
    

    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
           <center> <h2>Reporte de cierres diarios</h2> </center>
           

            <Form.Label>Empresa</Form.Label>
            <Form.Select aria-label="Default select example" onChange={setNombreEmpresa}>
            <option value='0'>Seleccione una opcion</option>
            {
                   empresa[0].map((props, index) => (
                    props.id_empresa !== 3 && (
                      <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                    )
                  ))
            }
            </Form.Select>

            <EmpresaXAgenciaSelect
          IdEmpresa = {idEmpresa}
          onChange= {(e) => setIdAgencia(e)}
          showlabel = {true}
          agencia = {0}
          />

            <Form.Group >
          <Form.Label>Seleccione el dia</Form.Label>
          <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
          </Form.Group>

          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>

          </Form>
        </Container>
    )
}