import { NavBar } from "../../components/navbar";
import { useEffect, useState, useRef } from "react";
import { Container, Form, Button, Nav } from "react-bootstrap";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import Logo from "../../components/UTILS/logo";
import Table from 'react-bootstrap/Table';
import '../../css/tableBalanza.css';
import '../../css/Modal.css';
import '../../css/printTable.css';
import { getRegistrosBalance, getRegistrosBalanceAnual } from "../../services/reportes/reportesBalanza";
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from "react-to-print";
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { convertirLetraAMes } from "../../components/UTILS/convertirLetraMes";

export function BalanzaAnual() {
  const [hasdata, setHasdata] = useState(false)
  const fechaInicial = new Date();
  const formatoFecha = `${fechaInicial.getFullYear()}-${(fechaInicial.getMonth() + 1).toString().padStart(2, '0')}`;
  const [fecha, setFecha] =  useState(formatoFecha);
  
  const [fechaFinal, setFechaFinal] = useState(null)
  const [page, setPage] = useState(1)
  const [empresa, setEmpresa] = useState(null)
  const [datos, setDatos] = useState([])
  const [nombreEmpresa, setNombreEmpresa] = useState(null)
  const tableRef = useRef();
  const [isTableReady, setIsTableReady] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [diferenciasTableData, setDiferenciasTableData] = useState([]);
  const [numeroMes, setNumeroMes] = useState(null)
  const [displayConsolidado, setDisplayConsolidado] = useState('flex')
  const [displayDiferencias, setDisplayDiferencias] = useState('none')
  const handleDownloadPDF = () => {
    window.print();
  }
  const linkStyle = {
    color: 'black',
    margin: '0 6px',
    backgroundColor: '#f8f9fa',
  };




  const handleDownloadCSV = async () => {
    const object = {
      empresa: empresa,
      fecha: fecha,
      token: localStorage.getItem("token")
    }
    const result = await getRegistrosBalanceAnual(object)
    // Crear un libro de trabajo
    const workbook = XLSX.utils.book_new();
    const sheetName = 'CarteraGeneral';
    // Crear una hoja de cálculo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(result);
    // Agrega la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

    // Genera el archivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crea un objeto Blob para el archivo Excel
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Crea un objeto URL para el archivo Excel
    const excelUrl = URL.createObjectURL(blob);

    // Crea un enlace para la descarga del archivo Excel
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = `BALANZA.xlsx`;

    // Dispara el evento de clic para descargar el archivo
    link.click();

  };

  const fechaActualEnMilisegundos = Date.now();

  // Crear una instancia del objeto Date con la fecha actual
  const fechaActual = new Date(fechaActualEnMilisegundos);

  // Obtener los componentes de la fecha
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1; // Los meses en JavaScript empiezan desde 0 (enero = 0)
  const anio = fechaActual.getFullYear();

  // Formatear la fecha en un string
  const fechaFormateada = `${dia}/${mes}/${anio}`;

  const sendBuscar = async (e) => {
    console.log(fecha)
    var mes = fecha.split("-")
    mes = parseInt(mes[1])
    setNumeroMes(mes)
    var table = []
    var diferenciasTable = []
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    e.preventDefault()
    const object = {
      empresa: empresa,
      fecha: fecha,
      page: page,
      token: localStorage.getItem("token")
    }
    const setData = await getRegistrosBalanceAnual(object)
    setData.forEach(element => {
      table.push(
        <>
          <tr>
            <td>{element.cuenta}</td>
            <td>{element.nombre}</td>
            {element.saldos.map((saldo, index) => {
              return <td>{saldo.saldo_final}</td>

            }
            )}
          </tr>
        </>
      );
      diferenciasTable.push(
        <>
          <tr>
            <td>{element.cuenta}</td>
            <td>{element.nombre}</td>
            {element.diferencias.map((saldo, index) => {
              return <td>{saldo.diferencia}</td>

            }
            )}
          </tr>
        </>
      );
    }
    )
    setTableData(table)
    setDiferenciasTableData(diferenciasTable)
    console.log(table)
    setHasdata(true)
    setDatos(setData)
    Swal.close()
    console.log(setData)
    console.log(object)

  }
  const changePagina = async (e) => {
    console.log(e)
    await setPage(e)
    const object = {
      empresa: empresa,
      fecha: fecha,
      page: e,
      token: localStorage.getItem("token")
    }

    const setData = await getRegistrosBalanceAnual(object)
    console.log(setData)
    setHasdata(true)
    setDatos(setData)




  }


  const handleChange = (e) => {
    console.log(e)
    setEmpresa(e)

  }

  const changeConsolidado = () => {
    setDisplayConsolidado('flex')
    setDisplayDiferencias('none')
  }
  const changeDiferencias = () => {
    setDisplayConsolidado('none')
    setDisplayDiferencias('flex')
  }


  if (hasdata) {
    return (
      <>
        <NavBar />
        <div className="shadow" style={{ margin: "30px" }}>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-12 mx-auto text-center">

                <h1>Reporte de balanza de comprobacion</h1>
                <h3>Empresa:{nombreEmpresa}</h3>
              </div>
            </div>

            <div className="row mt-4 mx-auto text-center unshow">
              <div className="col-md-6">

              </div>

              <div className="col-md-12">
                <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Hasta:</Form.Label>
                    <p>{fecha}</p>
                  </Form.Group>
                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <Button variant="success" type="submit" className='my-2' onClick={handleDownloadCSV} >
                    <AiFillFileExcel /> Descargar excel
                  </Button>

                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <Button variant="danger" type="submit" className='my-2' onClick={handleDownloadPDF} >
                    <AiFillFilePdf /> Descargar PDF
                  </Button>

                </div>
              </div>





            </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>

            <Nav variant="tabs">
            <Nav.Item style={{ display: 'flex'}}>
                <Nav.Link style={linkStyle} onClick={changeConsolidado}>Consolidado</Nav.Link>
                <Nav.Link style={linkStyle} onClick={changeDiferencias}>Diferencias</Nav.Link>
              </Nav.Item>

            </Nav>
          </div>

          {/* DIV TABLA */}
          <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20, }}></div>

          <div className="Busqueda" style={{ marginTop: "15px", display: displayConsolidado }}  ><br />
           
            <Table className="my-table printTable" ref={tableRef}>
              <thead>

                <tr>
                  <th>Cuenta-saldos</th>
                  <th>Nombre</th>
                  <th>Diciembre {parseInt(fecha.substr(0, 4)) - 1}</th>
                  {[...Array(numeroMes).keys()].map((month) => (
                    <th key={month + 1}>{convertirLetraAMes(month + 1)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>

          </div>
          {/* SEGUNDA TABLA */}
          <div className="Busqueda" style={{ marginTop: "15px", display: displayDiferencias, maxWidth: "100%" }}  ><br />
            
            <Table className="my-table printTable" ref={tableRef} style={{maxWidth: "70%"}}>
              <thead>

                <tr>
                  <th>Cuenta-diferencias</th>
                  <th>Nombre</th>
                  <th>Diciembre {parseInt(fecha.substr(0, 4)) - 1}</th>
                  {[...Array(numeroMes).keys()].map((month) => (
                    <th key={month + 1}>{convertirLetraAMes(month + 1)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {diferenciasTableData}
              </tbody>
            </Table>

          </div>

        </div>
      </>
    )
  } else {
    return (
      <>
        <NavBar />
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
          <Form onSubmit={sendBuscar}>

            <center> <h2>Reporte de balance anual</h2> </center>

            <EmpresasSelect
              onChange={(e) => handleChange(e)}
              empresa={(e) => setNombreEmpresa(e)}
            />

            <Form.Group >
              <Form.Label style={{ marginTop: "10px" }}>Seleccione el mes final</Form.Label>
              <Form.Control type="month" onChange={(e) => setFecha(e.target.value)} value={fecha} />
            </Form.Group>


            <Button variant="primary" type="submit" className='my-2' >
              Buscar
            </Button>

          </Form>
        </Container>
      </>

    )
  }

}