import { useState, useEffect, useRef } from 'react';
import { Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { getTipos, getCuenta, getCuentaEmpresas, getCuentaEmpresasOperativas } from "../../services/partidas/partidas";
import { getConceptos } from '../../services/concepto/getConceptos'
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';
import { CompraGlobal } from './movimientos/compraGlobal';
import { getTipoEmpresa } from '../../services/geolocalizacion/places';

export const Modals = (props, { onChange }) => {
    const token = localStorage.getItem('token');
    const [cuenta, setCuenta] = useState([])
    const [concepto, setConcepto] = useState([])
    const [monto, setMonto] = useState([])
    const [modal, setModals] = useState([])
    const [tipo, setTipo] = useState(0)
    const [tiposSelect, setTipos] = useState([[]])
    const [cantidadModals, setCantidadModals] = useState(0)
    const [cantidadModalsOriginal, setCantidadModalsOriginal] = useState(0)
    const [idCantidad, setIdCantidad] = useState(0)
    const [lastMonto, setLastMonto] = useState(0)
    const [tipoMovimiento, setTipoMoviemiento] = useState('')
    const [idTipoMovimiento, setIdTipoMovimiento] = useState(0)
    const [tipoCompuesto, setTipoCompuesto] = useState([])
    const [tipoConcepto, setTipoConcepto] = useState([[]])
    const [option, setOptions] = useState([[]])
    const [selectedOption, setSelectedOption] = useState(null)
    const [showMonto, setShowMonto] = useState(false)
    const inputRef = useRef(null);
    const [cuentas, setCuentas] = useState([[]])
    const [conceptoSeleccionado, setconceptoSeleccionado] = useState(null);
    const [tipo_empresa, setTipoEmpresa] = useState(1)
    const [notificacionCuentaContable, setNotificacionCuentaContable] = useState([])
    const [notificacionConcepto, setNotificacionConcepto] = useState([])
    const [notificacionMonto, setNotificacionMonto] = useState([])
    const [notificacionTipo, setNotificacionTipo] = useState([])
    const [eliminar, setEliminar] = useState(false)


    /* The above code is using the `useEffect` hook in a React component to fetch data asynchronously
    from an API using three different functions (`fetchData`, `fetchDataconcepto`, and
    `fetchDataCuenta`). The fetched data is then used to update state variables (`tipos`,
    `tipoConcepto`, `options`, and `cuentas`) using the `setTipos`, `setTipoConcepto`, `setOptions`,
    and `setCuentas` functions respectively. The `useEffect` hook is triggered whenever the `tipo`
    variable changes. */
    useEffect(() => {
        const fetchData = async () => {
            const tipos = await getTipos(token);
            setTipos([tipos])
        };
        fetchData();

        const fetchDataconcepto = async () => {
            const conceptos = await getConceptos(token)
            setTipoConcepto([conceptos])
            let options = []
            conceptos.map(((index, i) => {
                options[i] =
                    { label: index.descripcion }

            }))
            setOptions([options])

        }

        fetchDataconcepto()

        const fetchDataCuenta = async () => {
            const objectEmpresa = {
                token: token,
                empresa: props.empresa,
            }
            const tipo_empresa = await getTipoEmpresa(objectEmpresa)
          
            const object = {
                token: token,
                empresa: props.empresa,
                agencia: props.agencia
            }
           


                const cuentas = await getCuentaEmpresas(object)
                let options = []
                cuentas.map(((index, i) => {
                    options[i] =
                    {
                        label: index.nombre_cuenta + "-" + index.numero_cuenta,
                        value: index.numero_cuenta
                    }

                }))
                setCuentas([options])
           
        }
        fetchDataCuenta()

    }, [tipo, props.show]);

    /**
     * This function updates a state variable called "tipoCompuesto" based on the user's input.
     */
    const handleChangeTipoCompuesto = (e) => {
        const previewTipoCompuesto = [...tipoCompuesto]

        const index = e.target.getAttribute('itemid')
        previewTipoCompuesto[index] = e.target.value
        setTipoCompuesto(previewTipoCompuesto)

    }
    /**
     * This function updates a specific element in an array of "cuenta" based on the key and value passed
     * as parameters.
     */
    const handleChangeCuenta = (key, value) => {

        const previewCuentas = [...cuenta]
        const id = (key.target.id)
        const index = id.split('-')
        previewCuentas[index[0]] = value.value

        setCuenta(previewCuentas)

    }
    /**
     * This function updates the state of a "concepto" array based on the value of an input field.
     */
    const handleChangeConcepto = (e) => {
        const previewConcepto = [...concepto]
        const index = e.target.getAttribute('id')
        previewConcepto[index] = e.target.value
        setConcepto(previewConcepto)

    }

    /**
     * This function updates an array of values and calculates the difference between the first value
     * and the sum of the rest.
     */
    const handleChangeMonto = (e, itemID) => {

        const previewMonto = [...monto]
        const index = itemID
        previewMonto[index] = e



        let suma = 0
        for (let i = 1; i < previewMonto.length; i++) {
            suma += parseFloat(previewMonto[i], 10)

        }
        suma = previewMonto[0] - suma

        setMonto(previewMonto)
        if (!isNaN(suma)) {
            setLastMonto(suma.toFixed(2))
        } else {
            setLastMonto(0)
        }

    }
    /////
    /**
     * Esta función actualiza el estado de "tipo" y restablece el estado de "concepto", "cuenta" e
     * "importe"
     */
    const handleChangeTipo = (e) => {
        setTipo(e.target.value)
        const previewConcepto = [...concepto]
        const previewCuentas = [...cuenta]
        previewCuentas.length = 0;
        previewConcepto.length = 0;
        setLastMonto(0)
        monto[0] = 0
        setShowMonto(true)
    }
    /**
     * Esta función maneja un cambio en el tipo de movimiento y actualiza las variables de estado
     * correspondientes.
     */
    const handleChangeTipoMovimiento = (e) => {
        var valor = e.target.value
        setIdTipoMovimiento(valor)
        if (valor == 1) {
            setTipoMoviemiento('Abono')
        } else if (valor == 2) {
            setTipoMoviemiento('Cargo')
        }
    }


    const handleLastMonto = (e) => {
        const previewMonto = [...monto]
        const index = e.target.getAttribute('itemid')
        previewMonto[index] = e.target.value
        setMonto(previewMonto)
    }


    /**
     * Esta función agrega un objeto a una matriz e incrementa un contador de modales.
     */
    function handleSegmentar() {
        setCantidadModals(cantidadModals + 1)
        var object = { id: cantidadModals + 1 }
        setModals([...modal, object])

    }

    /**
     * La función maneja el guardado de datos y verifica los campos vacíos antes de guardar.
     */
    const handleGuardar = (e) => {
        console.log(cuenta)
        const montos = [...monto]

        let movimientos = []
        let editable = []
        let agencias = []
        let erroresTipo = []
        let erroresMonto = []
        let erroresConcepto = []
        let erroresCuenta = []

        movimientos[0] = idTipoMovimiento
        editable[0] = 1
        montos[montos.length] = lastMonto

        let flag = true

        if (idTipoMovimiento == 0) {
            erroresTipo[0] = "block"
            flag = (false)
        }
        for (let i = 1; i < cantidadModals + 1; i++) {
            editable[i] = 1
            if (idTipoMovimiento == 1) {
                erroresTipo[i] = ""
                movimientos[i] = "2"
            } else if (idTipoMovimiento == 2) {
                erroresTipo[i] = ""
                movimientos[i] = "1"
            } else {
                erroresTipo[i] = "block"
                flag = (false)
            }
        }

        for (let i = 0; i < cantidadModals + 1; i++) {

            if (montos[i] === '' || montos[i] === undefined || montos[i] === '0' || montos[i] === 0) {
                erroresMonto[i] = "block"
                flag = (false)
            } else {
                erroresMonto[i] = "none"
            }
            if (concepto[i] === undefined || concepto[i] === '') {
                erroresConcepto[i] = "block"
                flag = (false)
            } else {
                erroresConcepto[i] = "none"
            }
            if (cuenta[i] === undefined || cuenta[i] === '') {
                erroresCuenta[i] = "block"
                flag = (false)

            } else {
                erroresCuenta[i] = "none"
            }

            agencias[i] = props.agencia
        }

        setNotificacionTipo(erroresTipo)
        setNotificacionMonto(erroresMonto)
        setNotificacionConcepto(erroresConcepto)
        setNotificacionCuentaContable(erroresCuenta)


        if (flag === true) {

            var object = { montos, cuenta, concepto, movimientos, tipo, editable: editable, agencias: agencias }
            props.onChange(object)
            Swal.fire({
                title: 'Exito',
                text: 'Registro añadido exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            // cuenta[0] = ""
            inputRef.current.value = '';
            setTipo(0)
            setIdCantidad(0)
            setCantidadModals(0)
            setIdTipoMovimiento(0)
            setShowMonto(false)
            setLastMonto(0)
            setCantidadModals(0)
            setShowMonto(false)
            e.setState(setModals([]))

        } else if (flag === false) {
            Swal.fire({
                title: 'Error',
                text: 'Hay campos vacios',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }

    }
    ///////////////////////////////////////////////////////////////////////////////////
    /**
     * Esta función maneja el guardado de una transacción compuesta y verifica los campos vacíos.
     */
    const handleGuardarCompuesta = (e) => {
        const montos = [...monto]
        let movimientos = [...tipoCompuesto]
        let editable = []
        let flag = true
        let agencias = []
        let erroresTipo = []
        let erroresMonto = []
        let erroresConcepto = []
        let erroresCuenta = []
        for (let i = 0; i < cantidadModals + 1; i++) {

            if (movimientos[i] === "0" || movimientos[i] === undefined || movimientos[i].length === 0) {
                erroresTipo[i] = "block"
                flag = (false)
            }
        }

        for (let i = 0; i < cantidadModals + 1; i++) {

            if (montos[i] === '' || montos[i] === undefined || montos[i] === 0) {
                erroresMonto[i] = "block"
                flag = (false)
            }
            if (concepto[i] === undefined || concepto[i] === '') {
                erroresConcepto[i] = "block"
                flag = (false)
            }
            if (cuenta[i] === undefined || cuenta[i] === '') {
                erroresCuenta[i] = "block"
                flag = (false)

            }
            editable[i] = 1
            agencias[i] = props.agencia
        }

        setNotificacionTipo(erroresTipo)
        setNotificacionMonto(erroresMonto)
        setNotificacionConcepto(erroresConcepto)
        setNotificacionCuentaContable(erroresCuenta)

        if (flag === true) {


            var object = { montos, cuenta, concepto, idCantidad, tipoMovimiento, movimientos, tipo, editable: editable, agencias: agencias }
            props.onChange(object)
            Swal.fire({
                title: 'Exito',
                text: 'Registro añadido exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    cuenta[0] = ""
                    setTipo(0)
                    setTipoCompuesto([[]])
                    setIdCantidad(0)
                    setCantidadModals(0)
                    setIdTipoMovimiento(0)
                    setShowMonto(false)
                    setLastMonto(0)
                    setCantidadModals(0)
                    setShowMonto(false)
                    e.setState(setModals([]))
                }
            });


        } else if (flag === false) {
            Swal.fire({
                title: 'Error',
                text: 'Hay campos vacios',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }

    }
    ///////////////////////////////////////////////////////////////////////////////////////////
    /* El código usa el gancho useEffect en un componente de React para actualizar las variables de
    estado `cuenta`, `concepto` y `monto` en función de sus primeros elementos. También establece las
    variables de estado `cantidadModals` e `idCantidad` en función de la propiedad `tipo` pasada al
    componente. La matriz `tiposSelect` se itera para encontrar el objeto con una propiedad `id_tipo`
    que coincida con la propiedad `tipo`, y luego se configuran las variables de estado
    `cantidadModals` e `idCantidad` en función de las propiedades de ese objeto. este codigo */
    useEffect(() => {
        console.log(cantidadModals)
        const previewCuentas = cuenta[0]
        const previewConcepto = concepto[0]
        const previewMonto = monto[0]
        setCuenta([])
        setConcepto([])
        setMonto([])

        tiposSelect[0].forEach(e => {
            if (tipo == e.id_tipo) {
                setCantidadModals(e.cantidad_registros)
                setCantidadModalsOriginal(e.cantidad_registros)
                setIdCantidad(e.id_tipo)
            }
        });
        console.log(cantidadModals)
    }, [tipo]);

    /* El código anterior es un enlace useEffect de React que crea una matriz de modales basada en el
    valor de `cantidadModals` y lo establece en la variable de estado `modals`. También registra el
    valor de `lastMonto` si `modalsArray` no está vacío. El gancho useEffect se activa cada vez que
    cambia el valor de `idCantidad`. */
    useEffect(() => {
        const modalsArray = []
        for (let i = 0; i < cantidadModals; i++) {
            modalsArray.push({ id: i + 1 })
        }
        if (modalsArray.length != 0) {

            setModals(modalsArray)
        }
    }, [idCantidad, eliminar]);

    /* El código anterior es un enlace `useEffect` de React que establece la propiedad de estilo
    `overflow` del elemento `document.body` en `auto` si la matriz `modal` tiene una longitud mayor
    que 0. Es probable que esto se use para controlar el comportamiento de desplazamiento de la
    página cuando un modal está abierto o cerrado. */
    useEffect(() => {
        document.body.style.overflow = modal.length > 0 ? 'auto' : 'auto';
    }, [modal]);

    /**
     * Esta función actualiza la opción seleccionada y modifica una matriz de conceptos según el índice
     * del elemento de destino.
     */
    function handleChange(e, newValue) {

        const id = (e.target.id)
        const index = id.split('-')
        const conceptoArr = []
        if (index[0] == 0) {

            setSelectedOption(newValue)
            for (let i = 0; i < cantidadModals + 1; i++) {
                conceptoArr[i] = newValue.label
            }

            setConcepto(conceptoArr)
        } else {
            const previewConcepto = [...concepto]
            previewConcepto[index[0]] = newValue.label
            setConcepto(previewConcepto)
        }

    }
    /**
     * La función `hideModal` restablece varias variables de estado y llama a `props.onHide()` para
     * ocultar un modal.
     */
    const hideModal = () => {
        setIdTipoMovimiento(0)
        setIdCantidad(0)
        setCantidadModals(0)
        setShowMonto(false)
        setLastMonto(0)
        setTipo(0)
        setTipoCompuesto([[]])
        setSelectedOption(null)
        setModals([])
        setConcepto([])
        //nuevo
        setCuenta([])
        setNotificacionCuentaContable([])
        setNotificacionConcepto([])
        setNotificacionMonto([])
        setNotificacionTipo([])
        cuenta[0] = ""
        props.onHide()
    }
    const eliminarSegmentar = (id) => {
        const previewCuentas = cuenta[0]
        const previewConcepto = concepto[0]
        const previewMonto = monto[0]
        setCuenta([])
        setConcepto([])
        setMonto([])
        let modales_nuevos = 0
        tiposSelect[0].forEach(e => {
            if (tipo == e.id_tipo) {
                modales_nuevos = e.cantidad_registros
                setCantidadModals(e.cantidad_registros)
                setCantidadModalsOriginal(e.cantidad_registros)
                setIdCantidad(e.id_tipo)
            }
        });

        const modalsArray = []
        for (let i = 0; i < modales_nuevos; i++) {
            modalsArray.push({ id: i + 1 })
        }
        if (modalsArray.length != 0) {

            setModals(modalsArray)
        }

    }
    /**
     * Esta función recibe un objeto como parámetro y llama a una función pasada como accesorio con ese
     * objeto como argumento.
     */
    const receiveData = (object) => {
        props.onChange(object)
    }
    const show = () => {
        console.log(notificacionCuentaContable)
    }

    /* El código anterior es un componente de React que representa un modal con un formulario para
    agregar un registro financiero. El formulario incluye campos para seleccionar el tipo de registro,
    la cuenta, el concepto, el monto y el tipo de movimiento. El componente también incluye lógica
    para segmentar el registro en múltiples subregistros y guardar el registro como un registro
    compuesto. El comportamiento exacto del componente depende del valor de la variable `idCantidad`. */
    if (idCantidad !== 4 && idCantidad !== 3) {
        return (
            <Modal show={props.show} onHide={hideModal} size="xl" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar registro</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>

                        <Col className="mb-3 border-end border-dark border-bottom">
                            <Form>
                                <Form.Group className="mb-3">

                                    <Form.Label className='mt-3'>Tipo de registro</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleChangeTipo} itemID="0" required value={tipo} >
                                        <option value={0}>Seleccione una opcion</option>
                                        {tiposSelect[0].map((index) => (
                                            <option value={index.id_tipo}>{index.tipo}</option>
                                        ))}

                                    </Form.Select>
                                    {showMonto && (
                                        <>

                                            <Form.Label className='mt-2' onClick={show}>Cuenta contable </Form.Label>
                                            {(notificacionCuentaContable[0] !== undefined && notificacionCuentaContable[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionConcepto[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                                            <Autocomplete

                                                disablePortal
                                                onChange={handleChangeCuenta}
                                                id='0'
                                                options={cuentas[0]}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Cuenta"
                                                    id='0'
                                                    style={{ width: '100%' }}
                                                    inputRef={inputRef}

                                                />}
                                            />

                                            <Form.Label>Concepto</Form.Label>
                                            {(notificacionConcepto[0] !== undefined && notificacionConcepto[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionConcepto[0] }} > ¡Campo vacio!🚨​</span>
                                            )}

                                            <Autocomplete

                                                freeSolo={true}
                                                disablePortal
                                                onChange={handleChange}
                                                id='0'
                                                options={option[0]}

                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Conceptos"
                                                    id='0'
                                                    style={{ width: '100%' }}
                                                    inputRef={inputRef}
                                                    onChange={handleChangeConcepto}
                                                />}
                                            />
                                            <Form.Label>Monto</Form.Label>
                                            {(notificacionMonto[0] !== undefined && notificacionMonto[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionMonto[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            <InputGroup>
                                                <CurrencyInput
                                                    id="0"
                                                    name="0"
                                                    className='input-bootstrap'
                                                    placeholder="$0.00"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleChangeMonto(value, name)}
                                                    decimalSeparator="."
                                                />
                                            </InputGroup>
                                            <Form.Label className='mt-3'>Tipo de movimiento</Form.Label>
                                            {(notificacionTipo[0] !== undefined && notificacionTipo[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionTipo[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            <Form.Select onChange={handleChangeTipoMovimiento} value={idTipoMovimiento}>
                                                <option value={0}>Seleccione una opcion</option>
                                                <option value={1}>Cargo</option>
                                                <option value={2}>Abono</option>
                                            </Form.Select>
                                        </>
                                    )}
                                </Form.Group>
                            </Form>
                        </Col>



                        {modal.map((modalMap, id) => (
                            <>
                                {id != modal.length - 1 && (
                                    <Col className="mb-3 border-end border-dark border-bottom" key={id}>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='mt-2' style={{ borderColor: "red" }} >Cuenta contable</Form.Label>
                                                {(notificacionCuentaContable[id + 1] !== undefined && notificacionCuentaContable[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionCuentaContable[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                                                <Autocomplete


                                                    disablePortal
                                                    onChange={handleChangeCuenta}
                                                    id={id + 1}
                                                    options={cuentas[0]}

                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Cuenta"

                                                        id='0'
                                                        style={{ width: '100%' }}
                                                        inputRef={inputRef}

                                                    />}
                                                />


                                                <Form.Label>Concepto</Form.Label>
                                                {(notificacionConcepto[id + 1] !== undefined && notificacionConcepto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionConcepto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Autocomplete

                                                    freeSolo={true}
                                                    disablePortal
                                                    id={id + 1}
                                                    onChange={handleChange}
                                                    options={option[0]}
                                                    value={selectedOption}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Conceptos"
                                                        style={{ width: '100%' }}
                                                        onChange={handleChangeConcepto}
                                                    />}
                                                />
                                                <Form.Label>Monto</Form.Label>
                                                {(notificacionMonto[id + 1] !== undefined && notificacionMonto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionMonto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <CurrencyInput
                                                    id={id + 1}
                                                    name={id + 1}
                                                    className='input-bootstrap'
                                                    placeholder="$0.00"
                                                    decimalsLimit={2}
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => handleChangeMonto(value, name)}
                                                />
                                                <Form.Label>Tipo de movimiento</Form.Label>
                                                {(notificacionTipo[id + 1] !== undefined && notificacionTipo[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionTipo[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Form.Control type='text' name='tipo_movimiento' disabled value={tipoMovimiento}></Form.Control>
                                            </Form.Group>

                                        </Form>
                                    </Col>
                                )}
                                {id === modal.length - 1 && (
                                    <Col className="mb-3 border-end border-dark border-bottom">
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                                                {(notificacionCuentaContable[id + 1] !== undefined && notificacionCuentaContable[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionCuentaContable[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                                                <Autocomplete


                                                    disablePortal
                                                    onChange={handleChangeCuenta}
                                                    id={id + 1}
                                                    options={cuentas[0]}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Cuenta"
                                                        id='0'
                                                        style={{ width: '100%' }}
                                                        inputRef={inputRef}

                                                    />}
                                                />
                                                <Form.Label>Concepto</Form.Label>
                                                {(notificacionConcepto[id + 1] !== undefined && notificacionConcepto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionConcepto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Autocomplete

                                                    freeSolo={true}
                                                    disablePortal
                                                    id={id + 1}
                                                    onChange={handleChange}
                                                    options={option[0]}
                                                    value={selectedOption}
                                                    getOptionLabel={(option) => option.label}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Conceptos"
                                                        itemID={id + 1}
                                                        onChange={handleChangeConcepto}
                                                        style={{ width: '100%' }}
                                                    />}
                                                />
                                                <Form.Label>Monto</Form.Label>
                                                {(notificacionMonto[id + 1] !== undefined && notificacionMonto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionMonto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <CurrencyInput
                                                    name={id + 1}
                                                    className='input-bootstrap'
                                                    placeholder="$0.00"
                                                    decimalsLimit={2}
                                                    value={lastMonto}
                                                    decimalSeparator="."
                                                />
                                                <Form.Label>Tipo de movimiento</Form.Label>
                                                {(notificacionTipo[id + 1] !== undefined && notificacionTipo[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionTipo[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Form.Control type='text' name='tipo_movimiento' disabled value={tipoMovimiento}></Form.Control>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                )}
                            </>
                        ))
                        }




                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {showMonto && (
                        <>
                            {cantidadModalsOriginal != modal.length && (
                                <Button variant="danger" onClick={() => hideModal()}>Cancelar</Button>
                            )}
                            <Button onClick={handleSegmentar} className="mx-3">Segmentar</Button>
                            <Button variant='success' onClick={handleGuardar}>Guardar</Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>


        )
    } else if (idCantidad == 4) {
        return (

            <Modal show={props.show} onHide={hideModal} size="xl" backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar registro</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>

                        <Col className="mb-3 border-end border-bottom border-dark">
                            <Form>
                                <Form.Group className="mb-3">


                                    <Form.Label className='mt-3'>Tipo de registro</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleChangeTipo} itemID="0" value={tipo}>
                                        <option value={0}>Seleccione una opcion</option>
                                        {tiposSelect[0].map((index) => (
                                            <option value={index.id_tipo}>{index.tipo}</option>
                                        ))}

                                    </Form.Select>
                                    {showMonto && (
                                        <>
                                            <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                                            {(notificacionCuentaContable[0] !== undefined && notificacionCuentaContable[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionCuentaContable[0] }} > ¡Campo vacio!🚨​</span>
                                            )}

                                            {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                                            <Autocomplete


                                                disablePortal
                                                onChange={handleChangeCuenta}
                                                id={0}
                                                options={cuentas[0]}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Cuenta"
                                                    id='0'
                                                    style={{ width: '100%' }}
                                                    inputRef={inputRef}

                                                />}
                                            />
                                            <Form.Label>Concepto</Form.Label>
                                            {(notificacionConcepto[0] !== undefined && notificacionConcepto[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionConcepto[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            <Autocomplete

                                                freeSolo={true}
                                                disablePortal
                                                id={0}
                                                onChange={handleChange}
                                                options={option[0]}

                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Conceptos"
                                                    onChange={handleChangeConcepto}
                                                    style={{ width: '100%' }}
                                                />}
                                            />
                                            <Form.Label>Monto</Form.Label>
                                            {(notificacionMonto[0] !== undefined && notificacionMonto[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionMonto[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            <CurrencyInput
                                                id="0"
                                                name="0"
                                                className='input-bootstrap'
                                                placeholder="$0.00"
                                                decimalsLimit={2}
                                                onValueChange={(value, name) => handleChangeMonto(value, name)}
                                                decimalSeparator="."
                                            />
                                            <Form.Label className='mt-3'>Tipo de movimiento</Form.Label>
                                            {(notificacionTipo[0] !== undefined && notificacionTipo[0] == 'block') && (
                                                <span style={{ color: "red", display: notificacionTipo[0] }} > ¡Campo vacio!🚨​</span>
                                            )}
                                            <Form.Select onChange={handleChangeTipoCompuesto} itemID='0'>
                                                <option value={0}>Ingrese una opcion</option>
                                                <option value={1}>Cargo</option>
                                                <option value={2}>Abono</option>
                                            </Form.Select>
                                        </>
                                    )}
                                </Form.Group>
                            </Form>
                        </Col>

                        {modal.map((modalMap, id) => (
                            <>
                                {id != modal.length - 1 && (
                                    <Col className="mb-3 border-end border-dark border-bottom">
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                                                {(notificacionCuentaContable[id + 1] !== undefined && notificacionCuentaContable[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionCuentaContable[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}

                                                <Autocomplete


                                                    disablePortal
                                                    onChange={handleChangeCuenta}
                                                    id={id + 1}
                                                    options={cuentas[0]}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Cuenta"
                                                        id='0'
                                                        style={{ width: '100%' }}
                                                        inputRef={inputRef}

                                                    />}
                                                />
                                                <Form.Label>Concepto</Form.Label>
                                                {(notificacionConcepto[id + 1] !== undefined && notificacionConcepto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionConcepto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Autocomplete

                                                    freeSolo={true}
                                                    disablePortal
                                                    id={id + 1}
                                                    onChange={handleChange}
                                                    options={option[0]}

                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Conceptos"
                                                        onChange={handleChangeConcepto}
                                                        style={{ width: '100%' }}
                                                    />}
                                                />
                                                <Form.Label>Monto</Form.Label>
                                                {(notificacionMonto[id + 1] !== undefined && notificacionMonto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionMonto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <CurrencyInput
                                                    id={id + 1}
                                                    name={id + 1}
                                                    className='input-bootstrap'
                                                    placeholder="$0.00"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleChangeMonto(value, name)}
                                                    decimalSeparator="."
                                                />
                                                <Form.Label>Tipo de movimiento</Form.Label>
                                                {(notificacionTipo[id + 1] !== undefined && notificacionTipo[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionTipo[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Form.Select onChange={handleChangeTipoCompuesto} itemID={id + 1}>
                                                    <option value={0}>Ingrese una opcion</option>
                                                    <option value={1}>Cargo</option>
                                                    <option value={2}>Abono</option>
                                                </Form.Select>
                                            </Form.Group>

                                        </Form>
                                    </Col>
                                )}
                                {id === modal.length - 1 && (
                                    <Col className="mb-3 border-end border-dark border-bottom">
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                                                {(notificacionCuentaContable[id + 1] !== undefined && notificacionCuentaContable[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionCuentaContable[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                                                <Autocomplete


                                                    disablePortal
                                                    onChange={handleChangeCuenta}
                                                    id={id + 1}
                                                    options={cuentas[0]}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Cuenta"
                                                        id='0'
                                                        style={{ width: '100%' }}
                                                        inputRef={inputRef}

                                                    />}
                                                />
                                                <Form.Label>Concepto</Form.Label>
                                                {(notificacionConcepto[id + 1] !== undefined && notificacionConcepto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionConcepto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Autocomplete

                                                    freeSolo={true}
                                                    disablePortal
                                                    id={id + 1}
                                                    onChange={handleChange}
                                                    options={option[0]}

                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Conceptos"
                                                        onChange={handleChangeConcepto}
                                                        style={{ width: '100%' }}
                                                    />}
                                                />
                                                <Form.Label>Monto</Form.Label>
                                                {(notificacionMonto[id + 1] !== undefined && notificacionMonto[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionMonto[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <CurrencyInput
                                                    id={id + 1}
                                                    name={id + 1}
                                                    className='input-bootstrap'
                                                    placeholder="$0.00"
                                                    decimalsLimit={2}
                                                    onValueChange={(value, name) => handleChangeMonto(value, name)}
                                                    decimalSeparator="."
                                                />
                                                <Form.Label>Tipo de movimiento</Form.Label>
                                                {(notificacionTipo[id + 1] !== undefined && notificacionTipo[id + 1] == 'block') && (
                                                    <span style={{ color: "red", display: notificacionTipo[id + 1] }} > ¡Campo vacio!🚨​</span>
                                                )}
                                                <Form.Select onChange={handleChangeTipoCompuesto} itemID={id + 1}>
                                                    <option value={0}>Ingrese una opcion</option>
                                                    <option value={1}>Cargo</option>
                                                    <option value={2}>Abono</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                )}
                            </>
                        ))}




                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {cantidadModalsOriginal != modal.length && (
                        <Button variant="danger" onClick={() => hideModal()}>Cancelar</Button>
                    )}
                    <Button onClick={handleSegmentar} className="mx-3">Segmentar</Button>
                    <Button variant='success' onClick={handleGuardarCompuesta}>Guardar</Button>
                </Modal.Footer>
            </Modal>
        );
    }/* El código anterior es un bloque de código JavaScript React que devuelve un componente llamado
    "CompraGlobal" con varios accesorios y controladores pasados como parámetros. El componente se
    representa condicionalmente en función del valor de la variable "idCantidad". El componente
    recibe props como "option", "show", "tiposSelect", "modal", "tipo", "token", "partida",
    "agencia" y "cuentas". También recibe handlers como "handleChangeCuenta", "handleChange",
    "handleChangeConcepto", "handleChangeTipo", "handleChangeMonto", "handleChangeTipoCompuesto", y */
    else if (idCantidad === 3) {
        return (
            <CompraGlobal
                option={option}
                handleChangeCuenta={handleChangeCuenta}
                show={props.show}
                onHide={hideModal}
                handleChange={handleChange}
                handleChangeConcepto={handleChangeConcepto}
                handleChangeTipo={handleChangeTipo}
                tiposSelect={tiposSelect}
                showMonto={showMonto}
                handleChangeMonto={handleChangeMonto}
                handleChangeTipoCompuesto={handleChangeTipoCompuesto}
                modal={modal}
                handleGuardarCompuesta={handleGuardarCompuesta}
                tipo={tipo}
                token={token}
                tipo_empresa={1}
                onChange={receiveData}
                hideModal={props.hideModal}
                partida={props.partida}
                agencia={props.agencia}
                cuentas={cuentas}
            ></CompraGlobal>
        )
    }
}