import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Container, Form } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { getTipos, getCuenta } from "../../services/partidas/partidas";
import { getRegistrosCuenta, reporteCuentasRango } from '../../services/reportes/reportesCuentas';
export function Buscador(props){
    const [cuentas, setCuentas] = useState([])
    const token = localStorage.getItem('token');
    const [cuenta, setCuenta] = useState('')
    const [inicio, setInicio] = useState('')
    const [fin, setFin] = useState('')
    const [check, setCheck] = useState(true)
    const [cuentaInicio, setCuentaInicio] = useState('')
    const [cuentaFin, setCuentaFin] = useState('')

    useEffect(() => {
    
        const fetchDataCuenta = async () => {
            const cuentas = await getCuenta(token)
            let options = []
          
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta+"-"+index.numero_cuenta,
                    value: index.id_cuenta
                }

            }))
            setCuentas([options])
        }
        fetchDataCuenta()
    }, [props.handler])


    async function sendBuscar(e){
      e.preventDefault()
      
      if(check){
        const object = {
          token: token,
          cuenta: cuenta,
          inicio: inicio,
          fin: fin
        }
        const result = await getRegistrosCuenta(object)
         result.filtrado = 0
         props.onFinish(result)
      }else{
        const object = {
          token: token,
          cuentaInicio: cuentaInicio,
          cuentaFin: cuentaFin,
          inicio: inicio,
          fin: fin
        }
        const result = await reporteCuentasRango(object)
         result.filtrado = 1
         props.onFinish(result)
      }
     
      
    }


    const handleChangeCuenta = (key, value)=>{
      setCuenta(value.value)
    }
    const handleChangeCuentaInicio = (key, value)=>{
    
      let number = key.target.innerText.split('-')
     
     
      setCuentaInicio(number[number.length-1])
    }
    const handleChangeCuentafin = (key, value) => {
      setCuentaFin(value.value)
    }
    const handleChangeTipoFiltrado = (e) => {
     
      setCheck(!e.target.checked)
    }
   
    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
          <Form.Group >
          
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Filtrar por rango"
            onChange={handleChangeTipoFiltrado}
          />
           
            {check ? (
               <>
            <Form.Label>Cuenta</Form.Label>
            <Autocomplete                               
                disablePortal
                onChange={handleChangeCuenta}
              
                options={cuentas[0]}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Cuenta"
                    
                style={{ width: '100%' }}
                   
                    
                />}
            />
            </>
            ) : (
            <>
            <Form.Group >
            <Form.Label>Cuenta inicio</Form.Label>
            <Autocomplete                               
                disablePortal
                onChange={handleChangeCuentaInicio}
              
                options={cuentas[0]}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Cuenta"
                    
                style={{ width: '100%' }}
                   
                    
                />}
            />
          </Form.Group>

          <Form.Group >
            <Form.Label>Cuenta final</Form.Label>
            <Autocomplete                               
                disablePortal
                onChange={handleChangeCuentafin}
              
                options={cuentas[0]}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Cuenta"
                    
                style={{ width: '100%' }}
                   
                    
                />}
            />
          </Form.Group>
            
            </>)} 


            </Form.Group>
         
          <Form.Group >
            <Form.Label>Desde</Form.Label>
            <Form.Control type="date" onChange={(e) => setInicio(e.target.value)} />
          </Form.Group>

          <Form.Group >
            <Form.Label>Hasta</Form.Label>
            <Form.Control type="date" onChange={(e) => setFin(e.target.value)} />
          </Form.Group>
  
          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>
        </Form>
      </Container>
      );
}