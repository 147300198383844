import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getMesContable = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/getMesContable`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
   })
   const respuesta = await response.json()
   return respuesta
}

export const cerrarMesContable = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/cerrarMes/${data.id_mes}`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
 })
 const respuesta = await response.status
 return respuesta
}

export const revertirMesContable = async (data) => {
  const response = await fetch(`${BASE_URL}/reportes/revertirMes/${data.id_mes}`,{
      "method": "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({justificacion: data.justificacion})
 })
 const respuesta = await response.status
 return respuesta
}

