import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
// if(MODE == "production"){
   
//      BASE_URL = API_production()
// }else if(MODE == "development"){

//      BASE_URL = API_DEV()
// }
BASE_URL = process.env.REACT_APP_API_URL




export const deleteUsuarios = async(data) => {
    const response = await fetch(`${BASE_URL}/users/delete/${data.id_usuario}`,{
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          }
    })
    const respuesta = await response
    return respuesta
}