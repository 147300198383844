import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from './UTILS/logo';
import { useNavigate } from 'react-router-dom'
import { BsPersonCircle, BsFillPatchQuestionFill, BsFillFileEarmarkPlusFill, BsFillPersonFill, BsFillFileEarmarkPersonFill } from 'react-icons/bs'
import { MdOutlineLibraryBooks, MdAccountBalanceWallet, MdFavoriteBorder } from 'react-icons/md'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaBalanceScaleLeft } from 'react-icons/fa'
import { CiVault } from 'react-icons/ci'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { BsFillPiggyBankFill } from 'react-icons/bs'
import { MdAttachMoney, MdOutlineAccountBalance,MdArrowDropDown  } from 'react-icons/md'
import { useEffect, useState } from 'react';
import { GiBrain } from 'react-icons/gi'
import { getRol } from '../services/users/rolesXPermisos';
import { FaChessKnight, FaBook } from 'react-icons/fa'
import { BiMoveHorizontal } from 'react-icons/bi'
import { GiPiggyBank } from 'react-icons/gi'
import { BsCurrencyDollar, BsFillFileSpreadsheetFill, BsPersonWorkspace } from 'react-icons/bs'
import { AiFillCalendar } from 'react-icons/ai'
import { IoManSharp } from 'react-icons/io5'
import { GiDialPadlock } from 'react-icons/gi'
import { HiArrowsRightLeft } from "react-icons/hi2";
import { FaMoneyBillWaveAlt, FaCalendarCheck } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
//import '../css/printTable.css'

import '../css/navbar.css';


export function NavBar() {
  const token = localStorage.getItem('token')
  const [rol, setRol] = useState(0)

  useEffect(() => {

    const rol_fetch = async () => {
      const res = await getRol(token)
      setRol(res)
    }
    rol_fetch()


  }, [])
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token')
    navigate('/login')

  }
  const concepto = () => {
    navigate('/Concepto')
  }

  const CuentaContable = () => {
    navigate('/CuentasGenerales')
  }
  const CierreDiarios = () => {
    navigate('/cierreDiario')
  }
  const CierreDiariosEmpresa = () => {
    navigate('/cierreDiarioEmpresa')
  }
  const reporteCuentas = () => {
    navigate('/reporteCuentas')
  }

  const Favoritos = () => {
    navigate('/Menu_favoritos')
  }
  const Balanza = () => {
    navigate('/Balanza')
  }
  const CuentaPredeterminada = () => {
    navigate('/CuentaPredeterminada')
  }
  const LibroMayor = () => {
    navigate('/LibroMayor')
  }
  const CentroCostos = () => {
    navigate('/centroCostos')
  }
  const InfoRed = () => {
    navigate('/InfoRed')
  }
  const Subtipos = () => {
    navigate('/Subtipos')
  }
  const Bancos = () => {
    navigate('/Bancos')
  }
  const Cuotas = () => {
    navigate('/Cuotas')
  }
  const CuentaContableOperativas = () => {
    navigate('/CuentasGeneralesOperativas')
  }
  const CuentaPredeterminadaOperativas = () => {
    navigate('/CuentaPredeterminadaOperativas')
  }
  const SubtiposOperativos = () => {
    navigate('/SubtiposOperativos')
  }
  const GraficoBalanza = () => {
    navigate('/GraficoBalanza')
  }
  const BalanzaAgencia = () => {
    navigate('/BalanzaAgencia')
  }
  return (
    <>

      <Navbar className='navbar unshow' variant="dark" sticky="top">
        <Navbar.Brand href="/" className='navbar.Brand'>

          <Logo height="50px" width="60px" /> Contabilidad Integral Altercredit
        </Navbar.Brand>

        <Navbar.Toggle className='navbar.Toggle' />

        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavDropdown title={"Partidas"} drop='down-centered' className='mx-2'>
               <NavDropdown.Item href="/partidas"><BsFillFileSpreadsheetFill /> Partidas contables </NavDropdown.Item>
              <NavDropdown.Item >
                <IoManSharp className='me-2' />
                <span onClick={() => { navigate('/planilla') }}>Partida de planillas</span>
              </NavDropdown.Item>
              <NavDropdown.Item >
                <IoManSharp className='me-2' />
                <span onClick={() => { navigate('/viaticos') }}>Partida de viaticos</span>
              </NavDropdown.Item>
              <NavDropdown.Item >
                <FaCalendarCheck className='me-2' />
                <span onClick={() => { navigate('/PartidaDiario') }}>Partida de diario</span>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={"Reportes"} drop='down-centered' className='mx-2'>

            <NavDropdown title={
                <span style={{ color: "black" }}>
                  <FaBook /> Reportes <MdArrowDropDown />
                </span>
              } 
              drop='down-start' 
              className='mx-2'
              >
              <NavDropdown.Item >
                <BsFillPiggyBankFill className='me-2' href='/cierreDiario' />
                <span onClick={CierreDiarios}>Cierres diarios</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <CiVault className='me-2' href='/cierreDiarioEmpresa' />
                <span onClick={CierreDiariosEmpresa}>Resumenes de boveda</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <FaBook className='me-2' />
                <span onClick={LibroMayor}>Libro mayor</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <BsFillFileSpreadsheetFill className='me-2' />
                <span onClick={() => navigate('/reportePartidas')}>Reporte de partidas</span>
              </NavDropdown.Item>
              
              <NavDropdown.Item >
                <GiDialPadlock className='me-2' />
                <span onClick={() => navigate('/reporteCierres')}>Reporte de cierres diarios</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <CiVault className='me-2' />
                <span onClick={() => navigate('/cuadraturaMovimientos')}>Cuadratura de movimientos diarios</span>
              </NavDropdown.Item>

              </NavDropdown>

             



             

              <NavDropdown title={
                <span style={{ color: "black" }}>
                  <FaBalanceScaleLeft /> Balanzas <MdArrowDropDown />
                </span>
              } 
              drop='down-start' 
              className='mx-2'
              >

              <NavDropdown.Item >
                <FaBalanceScaleLeft className='me-2' />
                <span onClick={Balanza}>Balanza</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <FaBalanceScaleLeft className='me-2' />
                <span onClick={BalanzaAgencia}>Balanza por agencia</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <FaBalanceScaleLeft className='me-2' />
                <span onClick={() => navigate('/balanzaAnual')}>Balanza anual</span>
              </NavDropdown.Item>
                
              </NavDropdown>

              {/* <NavDropdown.Item >
                <BiMoneyWithdraw className='me-2' />
                <span onClick={CentroCostos}>Centro de costos</span>
              </NavDropdown.Item> */}

             
              <NavDropdown title={
                <span style={{ color: "black" }}>
                  <HiArrowsRightLeft /> Movimientos de cuenta <MdArrowDropDown />
                </span>
              } 
              drop='down-start' 
              className='mx-2'
              >
                
              <NavDropdown.Item >
                <HiArrowsRightLeft className='me-2' />
                <span onClick={() => navigate('/reporteMovimientosCuentas')}>Reporte de movimientos de cuentas por empresa</span>
              </NavDropdown.Item>
              <NavDropdown.Item >

              <HiArrowsRightLeft className='me-2' />
                <span onClick={() => navigate('/reporteMovimientosCuentasAgencia')}>Reporte de movimientos de cuentas por agencia</span>
              </NavDropdown.Item>

              </NavDropdown>
              <NavDropdown title={
                <span style={{ color: "black" }}>
                  <MdOutlineAccountBalance /> Infored <MdArrowDropDown />
                </span>
              } 
              drop='down-start' 
              className='mx-2'
              >

              <NavDropdown.Item >
                <MdOutlineAccountBalance className='me-2' />
                <span onClick={InfoRed}>InfoRed</span>
              </NavDropdown.Item>

              <NavDropdown.Item >
                <IoIosCloseCircleOutline  className='me-2' />
                <span onClick={() => navigate('/eliminarInfoRed')}>Sacar creditos de reporte InfoRed</span>
              </NavDropdown.Item>

              </NavDropdown>

              <NavDropdown.Item >
                <MdOutlineAccountBalance  className='me-2' />
                <span onClick={() => navigate('/conciliacion')}>Conciliacion bancaria</span>
              </NavDropdown.Item>



            </NavDropdown>



            <NavDropdown title={"Mantenimiento"} drop='down-centered' className='mx-2'>

              <NavDropdown.Item href='/MesContable' >
                <AiFillCalendar className='me-2' />
                <span onClick={concepto}>Mes contable</span>
              </NavDropdown.Item>

              <NavDropdown.Item href='/Concepto' >
                <GiBrain className='me-2' />
                <span onClick={concepto}>Concepto</span>
              </NavDropdown.Item>


              <NavDropdown title={
                <span style={{ color: "black" }}>
                  <MdAccountBalanceWallet /> Cuentas <MdArrowDropDown />
                </span>
              } 
              drop='down-start' 
              className='mx-2'
              >

                <NavDropdown.Item href='/CuentasGenerales'>
                  <MdAccountBalanceWallet className='me-2' />
                  <span onClick={CuentaContable}>Catalogo general financiero</span>
                </NavDropdown.Item>

                <NavDropdown.Item href='/CuentasGeneralesOperativas'>
                  <MdAccountBalanceWallet className='me-2' />
                  <span onClick={CuentaContableOperativas}>Catalogo general operativo</span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <MdOutlineAccountBalance className='me-2' />
                  <span onClick={CuentaPredeterminada}>Predeterminadas financieras<br />
                  </span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <MdOutlineAccountBalance className='me-2' />
                  <span onClick={CuentaPredeterminadaOperativas}>Predeterminadas operativas <br /></span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <FaMoneyBillWaveAlt className='me-2' />
                  <span onClick={()=> navigate('/asignarSaldos')}>Asignar saldos <br /></span>
                </NavDropdown.Item>

              </NavDropdown>

              <NavDropdown title={<span style={{ color: "black" }}><BiMoveHorizontal></BiMoveHorizontal> Movimientos <MdArrowDropDown /></span>} drop='down-start' className='mx-2'>

                <NavDropdown.Item >
                  <CiVault className='me-2' />
                  <span onClick={Subtipos}>Subtipos de movimientos de boveda</span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <CiVault className='me-2' />
                  <span onClick={SubtiposOperativos}>Subtipos de movimientos de boveda operativos</span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <GiPiggyBank className='me-2' />
                  <span onClick={Bancos}>Movimientos de bancos</span>
                </NavDropdown.Item>

                <NavDropdown.Item >
                  <BsCurrencyDollar className='me-2' />
                  <span onClick={Cuotas}>Movimientos de cuotas</span>
                </NavDropdown.Item>



              </NavDropdown>



              <NavDropdown.Item >
                <MdFavoriteBorder className='me-2' />
                <span onClick={Favoritos}>Favoritos</span>
              </NavDropdown.Item>

           



              {/* SOLO PARA ADMINISTRADORES */}


            </NavDropdown>
            <NavDropdown title={"Graficos"} drop='down-centered' className='mx-2'>
              <NavDropdown.Item >
                <FiActivity className='me-2' />
                <span onClick={GraficoBalanza}>Grafico de balanza</span>
              </NavDropdown.Item>
             </NavDropdown> 
            {/* {rol === 1 ? (
        
       <NavDropdown title={"Administrar"} drop='down-centered' className='mx-2'>
          <NavDropdown.Item  href='/solicitudesSaldo'>
          <MdAttachMoney className='me-2' />
          <span >Cambio de saldo</span>
        </NavDropdown.Item>

        </NavDropdown>
      ): (<></>)} */}
            {/* SOLO PARA ADMINISTRADORES */}
            {rol === 1 ? (
              <NavDropdown title={"Control de usuarios"} drop='down-centered' className='mx-2'>
                <NavDropdown.Item href='/usuarios_control' >

                  <span><BsFillFileEarmarkPersonFill /> Control de usuarios</span>

                </NavDropdown.Item>

                <NavDropdown.Item href='/controlRoles'>
                  <FaChessKnight className='me-2' />
                  <span>Control de roles</span>
                </NavDropdown.Item>

                <NavDropdown.Item href='/controlCambioSaldo'>
                  <BiMoneyWithdraw className='me-2' />
                  <span>Cambio de saldos</span>
                </NavDropdown.Item>


              </NavDropdown>
            ) : (<></>)}

            <NavDropdown title={<BsFillPersonFill></BsFillPersonFill>} drop='start' className='mx-2'>
              <NavDropdown.Item onClick={logout}>
                <BsPersonCircle className='me-2' />
                <span>Cerrar sesion</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </>

  );
}

