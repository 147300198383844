import React, { useEffect, useState } from "react";
import { NavBar } from "../../components/navbar";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { cerrarMesContable, getMesContable, revertirMesContable } from "../../services/reportes/reporteMesContable";
import { numeroAmes } from "../../utils/numeroAmes";
import Swal from "sweetalert2";
import Table from 'react-bootstrap/Table';

export const MesContable = () => {
    const [change, setChange] = useState(false);
    const [meses, setMeses] = useState([]);


    useEffect(() => {
        mesContable()
    }, []);

    const mesContable = async () => {
        const data = {
            token: localStorage.getItem("token"),
        }
        const response = await getMesContable(data)
        console.log(response)
        for (let i = 0; i < response.length; i++) {
            let mes = numeroAmes(response[i].mes_contable.substring(5, 7))
            response[i].mes_contable = mes+"-"+response[i].mes_contable.substring(0, 4)
        }
        setMeses(response)

    }

    const revertirMes = async (id_mes) => {
        const confirmacion = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esto revertará el mes actual. ¿Deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, revertir mes',
            cancelButtonText: 'Cancelar'
        });
    
        if (confirmacion.isConfirmed) {
            const { value: justificacion } = await Swal.fire({
                title: 'Justificación de la reversión',
                input: 'textarea',
                inputLabel: 'Por favor, proporciona una justificación para la reversión del mes:',
                inputPlaceholder: 'Escribe tu justificación aquí...',
                inputAttributes: {
                    'aria-label': 'Escribe tu justificación aquí'
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            });
    
            if (justificacion) {
                Swal.fire({
                    title: 'Cargando...',
                    allowOutsideClick: false,
                    didOpen: () => {
                      Swal.showLoading()
                    }
                });
    
                const object = {
                    id_mes: id_mes,
                    justificacion: justificacion, // Añadimos la justificación al objeto
                    token: localStorage.getItem("token")
                };
    
                const res = await revertirMesContable(object);
                Swal.close();
    
                if(res == 200) {
                    Swal.fire({
                        title: 'Mes revertido con éxito!',
                        text: 'El mes se ha revertido con éxito',
                        icon: 'success'
                    }).then(() => {
                        mesContable();
                    });
                } else {
                    Swal.fire({
                        title: 'Error al revertir mes',
                        text: 'Ocurrió un error al revertir el mes, por favor intente de nuevo',
                        icon: 'error'
                    });
                }
            } else {
                Swal.fire({
                    title: 'Justificación requerida',
                    text: 'Debe proporcionar una justificación para revertir el mes.',
                    icon: 'warning'
                });
            }
        }
    };
    

    const EstiloCentrado = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        
    };

    const EstiloEncabezado = {
        backgroundColor: 'white', // Fondo blanco
        color: 'black',           // Texto negro
    };

    const cerrarMes = async (id_mes) => {
        const confirmacion = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esto cerrará el mes actual y abrira el siguiente. ¿Deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar mes',
            cancelButtonText: 'Cancelar'
        });
    
        if (confirmacion.isConfirmed) {
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading()
                }
              })
            const object = {
                id_mes: id_mes,
                token: localStorage.getItem("token")
            }
            const res = await cerrarMesContable(object)
            Swal.close()
            if(res == 200){
               
                Swal.fire({
                    title: 'Mes cerrado con exito!',
                    text: 'El mes se ha cerrado con exito y se aperturo el siguiente',
                    icon: 'success'
                }).then(() => {
                    // Recargar la página después de que el usuario haga clic en "Aceptar"
                    // window.location.reload(false)
                    mesContable()
                });
            }else{
                Swal.fire({
                    title: 'Error al cerrar mes',
                    text: 'Ocurrio un error al cerrar el mes, por favor intentelo de nuevo',
                    icon: 'error'
                })
            }
        } 
    };
    
    if(meses.length == 0){
        return (
            <>
                <NavBar />
                <div style={EstiloCentrado} >
                    <Card style={{ maxWidth: "50%" }} className="text-center">
                        <Card.Header style={EstiloEncabezado}>Mes contable en curso</Card.Header>
                        <Card.Body className="d-flex align-items-center">
                        <Table className="my-table printTable" >
                            <thead>
                                <tr>
                                    <td>Ultimo mes trabajado:</td>
                                    <td>Empresa:</td>
                                    <td>Accion</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>No hay mes contable en curso</td>
                                    <td>No hay mes contable en curso</td>
                                    <td>No hay mes contable en curso</td>
                                </tr>
                            </tbody>
                        </Table>
                        </Card.Body>
                        <Card.Footer className="text-muted" style={{ marginTop: "200px" }}>
                        <Button variant="primary" onClick={cerrarMes}>Cerrar mes contable</Button> 
                        </Card.Footer>
                    </Card>
    
    
                </div>
            </>
        );
    }else{
    return (
        <>
            <NavBar />
            <div style={EstiloCentrado} >
                <Card style={{ minWidth: "70%", minHeight: "500px" }} className="text-center">
                    <Card.Header style={EstiloEncabezado}>Mes contable en curso</Card.Header>
                    <Card.Body className="d-flex align-items-center">
                    <Table className="my-table printTable" >
                        <thead>
                            <tr>
                                <td>Ultimo mes trabajado:</td>
                                <td>Empresa:</td>
                                <td>Accion</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                               {meses.map((mes) => {
                                   return(
                                          <tr>
                                        <>
                                        <td>{mes.mes_contable}</td>
                                        <td>{mes.empresaModel.nombre_empresa}</td>
                                        </>
                                        <td>
                                            <Button variant="primary" onClick={() => {cerrarMes(mes.id_mes)}}>Cerrar mes</Button>
                                            <Button variant="danger" className="mx-3" onClick={() => {revertirMes(mes.id_mes)}}>Revertir mes</Button>
                                        </td>
                                        </tr>
                                      )
                               })}
                        </tbody>
                    </Table>
                    </Card.Body>
                    <Card.Footer className="text-muted" style={{ marginTop: "200px" }}>
                       <Button variant="primary" onClick={cerrarMes}>Cerrar mes contable</Button> 
                    </Card.Footer>
                </Card>


            </div>
        </>
    );
}
}

