
import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;
// if(MODE == "production"){
   
//      BASE_URL = API_production()
// }else if(MODE == "development"){

//      BASE_URL = API_DEV()
// }
BASE_URL = process.env.REACT_APP_API_URL

export const GuardarConcepto = async(data) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/conceptos/insertConcepto`,{
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            concepto: data.concepto,
            SQlFecha: data.SQlFecha
        })
    })
    const respuesta = await response.status
    return respuesta
}