import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GIF from '.././../resources/gif.gif'

export function Modal_load(props) {
    return (
        <Modal
          {...props}
          size="sm"
          centered
        >
          <Modal.Body className="text-center">
            <h4>{props.texto}...</h4>
            <img
              src={GIF}
              alt="Loading..."
              height='40px'
              width='40px'
              className="mx-auto mt-4"
            />
          </Modal.Body>
        </Modal>
      );
}

