const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const solicitarCambio = async(data) => {
   
    const response = await fetch(`${BASE_URL}/cambiosaldo/solicitarCambio`,{
        method: "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            id_cuenta: data.id_cuenta,
            saldo_solicitando: data.saldo_solicitando
          })
    })
    const respuesta = await response
    return respuesta
}
export const verificarSoclitud = async(data) => {
  const response = await fetch(`${BASE_URL}/cambiosaldo/verificarSolicitud`,{
    method: "POST",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id_cuenta: data.id_cuenta,
        
      })
})
const respuesta = await response
return respuesta

}

export const traer_usuarios = async(data) => {
 
  const response = await fetch(`${BASE_URL}/cambiosaldo/getUsuarios`,{
    method: "GET",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    }
  })
  const respuesta = await response
  return respuesta
}

export const insert_usuarios = async(data) => {
 
  const response = await fetch(`${BASE_URL}/cambiosaldo/agregarUsuario`,{
    method: "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_usuario: data.idUsuario,
     
    })
  })
  const respuesta = await response
  return respuesta
}
export const deleteUsuario = async(data) => {
 
  const response = await fetch(`${BASE_URL}/cambiosaldo/eliminarUsuarios`,{
    method: "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id_usuario: data.id_usuario,
     
    })
  })
  const respuesta = await response
  return respuesta
}

export const notificacion = async(data) => {
 
  const response = await fetch(`${BASE_URL}/cambiosaldo/getSolicitudes`,{
    method: "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    }
  })
  const respuesta = await response
  return respuesta
}

export const getSolicitudes = async(data) => {
  const response = await fetch(`${BASE_URL}/cambiosaldo/traerSolicitudes`,{
    method: "GET",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    }
  })
  const respuesta = await response
  return respuesta
}

export const setAprobar = async(data) => {
  const response = await fetch(`${BASE_URL}/cambiosaldo/aprobarSolicitud`,{
    method: "POST",
    headers: {
      "Authorization": `Bearer ${data.token}`,
      "Content-Type": "application/json"
    },body: JSON.stringify({
      id_solicitud: data.id_solicitud,
     
    })
  })
  const respuesta = await response
  return respuesta
}
