import { Buscador } from "../../components/reporteCierreDiarioEmpresa/buscador"
import { NavBar } from "../../components/navbar"
import { useEffect, useState } from "react"
import { Table, Button, Badge, Form } from "react-bootstrap"
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import store from '../../store';
import { AiOutlineArrowLeft, AiOutlineSearch } from 'react-icons/ai'
import '../../css/button.css'
import { reporteCierresDiariosEmpresa } from "../../services/reportes/reportesCuentas";

export function ReporteCierresEmpresa() {
  const [hasData, sethasData] = useState(false)
  const [data, setData] = useState([])
  const [tableRow, setTable] = useState([])
  const [nombreEmpresa, setNombreEmpresa] = useState('')
  const [idEmpresa, setIdEmpresa] = useState(null)
  const [fecha, setFecha] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token')

  useEffect(() => {
    function setTableRow() {
      const table = []
      if (data != null) {
        if (data.length != 0) {
          console.log(data)
          data.forEach(element => {

            if (element.resumen_agencia.length == - 0) {
              table.push(
                <tr>
                  <td>{element.agencia}</td>
                  <td colSpan={4}>No se ha cargado el cierre de este dia</td>
                </tr>
              )
            } else {
              const saldo_inicial = element.resumen_agencia[0].saldo_inicial
              const fin_dia = element.resumen_agencia[0].saldo_inicial - (element.resumen_agencia[0].prestamos_otorgados - element.resumen_agencia[0].prestamos_refinanciamiento) - (element.resumen_agencia[0].prestamos_empleados - element.resumen_agencia[0].refinanciamiento_empleados) + element.resumen_agencia[0].cobro_coutas - element.resumen_agencia[0].faltante + element.resumen_agencia[0].ingreso_boveda - element.resumen_agencia[0].egresos_boveda - element.resumen_agencia[0].remesa_banco + element.resumen_agencia[0].retiro_banco
              let estado = ''
              if (element.resumen_agencia[0].revision_contabilidad == 2) {
                estado = <Badge bg="success">Cierre guardado</Badge>
              } else {
                estado = <Badge bg="danger">Cierre no guardado</Badge>
              }
              table.push(
                <tr>
                  <td>{element.agencia}</td>
                  <td>$ {saldo_inicial}</td>
                  <td>$ {fin_dia.toFixed(2)}</td>
                  <td>{estado}</td>
                  <td><Button onClick={() => goToCierre(element.id_agencia, element.resumen_agencia[0].fecha)}>Ir a cierre</Button></td>

                </tr>
              )
            }
          });
          setTable(table)
        }
      }
    }
    setTableRow()

  }, [data])


  async function change() {

    const object = {
      empresa: idEmpresa,
      fecha: fecha,
      token: token
    }
    const result = await reporteCierresDiariosEmpresa(object)
    setData(result)
  }




  function handleData(object) {

    sethasData(true)
    setData(object)
    setNombreEmpresa(object.empresa)
    setFecha(object.fecha)
    setIdEmpresa(object.id_empresa)

  }

  const goToCierre = (agencia, fecha) => {
    dispatch({
      type: 'CHANGE', payload: {
        agencia: agencia,
        fecha: fecha,
        id_empresa: idEmpresa
      }
    });
    navigate('/cierreDiario')
  }
  const goBack = () => {
    console.log('click')
    sethasData(false)
    setData([])
  }
  if (hasData) {
    return (
      <>
        <NavBar></NavBar>
        <a
          className="button plus"
          href="#plus"
          style={{
            position: 'absolute',
            top: '100px',
            left: '50px',
            // Otros estilos que desees aplicar
          }}
        >
          <Button onClick={goBack} style={{ borderRadius: '40%', width: '50px', height: '50px' }}>
            <AiOutlineArrowLeft style={{ fontSize: '12px' }} ></AiOutlineArrowLeft>
          </Button>
        </a>
        <h2 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Cierres para la empresa: {nombreEmpresa}</h2>
        <h3 style={{ margin: "auto", marginTop: '20px', textAlign: "center", color: "white" }}>Fecha: {fecha}</h3>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
          <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Form.Label style={{ color: 'white' }}>Cambiar de fecha</Form.Label>
            <div style={{ display: 'flex' }}>
              <Form.Control value={fecha} type="date" onChange={(e) => setFecha(e.target.value)} />
              <Button style={{marginLeft:"10px"}} onClick={() => { change() }}><AiOutlineSearch /></Button>
            </div>
          </Form.Group>
        </div>
        <Table striped style={{ margin: "auto", marginTop: "5%", boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} className='my-table'>
          <thead>
            <tr>
              <th>Agencia</th>
              <th>Saldo inicial</th>
              <th>Saldo final</th>
              <th>Estado</th>
              <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            {tableRow}
          </tbody>
        </Table>
      </>
    )
  } else {
    return (
      <>
        <NavBar></NavBar>
        <Buscador onFinish={handleData}></Buscador>

      </>
    )
  }
}