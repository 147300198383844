import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom"
import { Login } from './pages/users/login';
import { Inicio } from './pages/inicio/inicio';
import { ControlUsuario } from './pages/users/ControlUsuarios';
import {ProtectedRoute} from './services/general/validateToken'
import { Partidas } from './pages/partidas/partidas';
import {Concepto} from './pages/Concepto/Concepto';
import {LoginRedirect} from './services/general/validateTokenLogin'
import { NotFound } from './pages/notfound/NotFound';
import { CuentaContable } from './pages/CuentaContable/CuentaContable';
import { ReporteCuentas } from './pages/Reportes/reporteCuentas';

import { Menu_favoritos } from './pages/menu_favoritos/Menu_favoritos';
import { Balanza } from './pages/Reportes/Balanza';
import { ReporteCierresDiarios } from './pages/Reportes/reporteCierresDiarios';
import {ReporteCierresEmpresa} from './pages/Reportes/reporteCierresEmpresa'
import React, { useState } from 'react';
import { ControlRoles } from './pages/users/ControlRoles';
import CambioSaldo from './pages/CuentaContable/CambioSaldo';
import RevisarCambioSaldo from './pages/CuentaContable/RevisarCambioSaldo';

import { LibroMayor } from './pages/Reportes/LibroMayor';
import { CentroCostos } from './pages/centroCostos/centroCostos';
import CuentasGenerales from './pages/CuentaContable/CuentasGenerales';
import InfoRed from './pages/Reportes/InfoRed';
import { SubTiposBov } from './pages/cuentasPredeterminadas/subtiposBov';
import { Bancos } from './pages/cuentasPredeterminadas/Bancos';
import { Cuotas } from './pages/cuentasPredeterminadas/Cuotas';
import { CuentaContablePredeterminadas } from './pages/cuentasPredeterminadas/CuentaContablePredeterminadas';
import { CuentasGeneralesOperativas } from './pages/CuentaContable/CuentasGeneralesOperativas';
import { PartidasOperativas } from './pages/partidas/partidasOperativas';
import { SubTiposBovOp } from './pages/cuentasPredeterminadas/subtiposBovOp';
import { MesContable } from './pages/Reportes/mesContable';
import { ReportePartidas } from './pages/Reportes/ReportePartidas';
import { ReporteCierreUnico } from './pages/Reportes/reporteCierreUnico';
import { Planilla } from './pages/partidas/Planillas';
import { Viaticos } from './pages/partidas/Viaticos';
import { EliminarInfoRed } from './pages/Reportes/EliminarInfoRed';
import { MovimientosCuentas } from './pages/Reportes/reporteMovimientosCuentas';
import { AsignarSaldo } from './pages/CuentaContable/AsignarSaldo';
import { PartidaDiario } from './pages/partidas/partidaDiario';
import { BalanzaAnual } from './pages/Reportes/BalanzaAnual';
import { ListadoPlanillas } from './pages/partidas/ListadoPlanillas';
import { GraficoBalanza } from './pages/Graficos/GraficosBalanza';
import { ReporteMovimientosRanged } from './pages/Reportes/reporteMovimientosRanged';
import { BalanzaAgencia } from './pages/Reportes/BalanzaXAgencia';
import { MovimientosCuentasAgencia } from './pages/Reportes/reporteMovimientosAgencia';
import { Conciliacion } from './pages/Bancos/conciliacion';
import { CuadraturaMovimientos } from './pages/Reportes/CuadraturaMovimientos';



function App() {
  
  return (

        <>
        <Routes>
          
          <Route path="*" Component={NotFound} />
          <Route path='/login' element = {<LoginRedirect component={Login} ></LoginRedirect>}/>
          <Route path='/' element = {<ProtectedRoute component={Inicio}/>}/>
          <Route path='/usuarios_control' element = {<ProtectedRoute component={ControlUsuario} rolesPermitidos={1}/>}/>
          <Route path='/partidas' element = {<ProtectedRoute component={Partidas}/>}/>
          <Route path='/partidasOperativas' element = {<ProtectedRoute component={PartidasOperativas}/>}/>
          <Route path='/Concepto' element = {<ProtectedRoute component={Concepto}/>}/>
          <Route path='/CuentaContable' element = {<ProtectedRoute component={CuentaContable}/>}/>
          <Route path='/CuentaPredeterminada' element = {<ProtectedRoute component={CuentaContablePredeterminadas}/>}/>
          <Route path='reporteCuentas' element = {<ProtectedRoute component={ReporteCuentas}/>}></Route>

          <Route path='/Menu_favoritos' element = {<ProtectedRoute component={Menu_favoritos}/>}></Route>
         

          <Route path='controlRoles' element={<ProtectedRoute component={ControlRoles} rolesPermitidos={1}/>}></Route>
          
          <Route path='/cierreDiario' element={ <ProtectedRoute component={ReporteCierresDiarios}/>}></Route>

          <Route path='/cierreDiarioEmpresa' element={ <ProtectedRoute component={ReporteCierresEmpresa}/>}></Route>

          <Route path='/controlCambioSaldo' element={<ProtectedRoute component={CambioSaldo} rolesPermitidos={1} />}></Route>
          
          <Route path='/solicitudesSaldo' element={<ProtectedRoute component={RevisarCambioSaldo} rolesPermitidos={1}/>}></Route>

          <Route path='/Balanza' element={<ProtectedRoute component={Balanza} />}></Route>
          <Route path='LibroMayor' element={<ProtectedRoute component={LibroMayor} />}></Route>

          <Route path = '/centroCostos' element={<ProtectedRoute component={CentroCostos} rolesPermitidos={1}/>}></Route>

          <Route path='/CuentasGenerales' element={<ProtectedRoute component={CuentasGenerales} />}></Route>

          <Route path='/InfoRed' element={<ProtectedRoute component={InfoRed} rolesPermitidos={1}/>}></Route>

          <Route path='/Subtipos' element={<ProtectedRoute component={SubTiposBov} rolesPermitidos={1}/>}></Route>

          <Route path='/Bancos' element={<ProtectedRoute component={Bancos} rolesPermitidos={1}/>}></Route> 

          <Route path='/Cuotas' element={<ProtectedRoute component={Cuotas} rolesPermitidos={1}/>}></Route>

          <Route path='/CuentasGeneralesOperativas' element={<ProtectedRoute component={CuentasGeneralesOperativas}/>}></Route>
          
          <Route path='/SubtiposOperativos' element={<ProtectedRoute component={SubTiposBovOp} rolesPermitidos={1}/>}></Route>

          <Route path='/MesContable' element={<ProtectedRoute component={MesContable} rolesPermitidos={1}/>}></Route>

          <Route path='/ReportePartidas' element={<ProtectedRoute component={ReportePartidas}/>}></Route>

          <Route path='/reporteCierres' element={<ProtectedRoute component={ReporteCierreUnico}/>}></Route>
          <Route path='/planilla' element={<ProtectedRoute component={Planilla}/>}></Route>
          <Route path='/viaticos' element={<ProtectedRoute component={Viaticos}/>}></Route>
          <Route path='/eliminarInfoRed' element={<ProtectedRoute component={EliminarInfoRed}/>}></Route>
          <Route path='/reporteMovimientosCuentas' element={<ProtectedRoute component={MovimientosCuentas}/>}></Route>
          <Route path='/asignarSaldos' element={<ProtectedRoute component={AsignarSaldo} />}></Route>
          <Route path='/PartidaDiario' element={<ProtectedRoute component={PartidaDiario}/>}></Route>
          <Route path='/balanzaAnual' element={<ProtectedRoute component={BalanzaAnual}/>}></Route>
          <Route path='/listadoPartidasPlanillas' element={<ProtectedRoute component={ListadoPlanillas}/>}></Route>
          <Route path='/GraficoBalanza' element={<ProtectedRoute component={GraficoBalanza}/>}></Route>
          <Route path='/movimientosCuentasRanged' element={<ProtectedRoute component={ReporteMovimientosRanged}/>}></Route>
          <Route path='/BalanzaAgencia' element={<ProtectedRoute component={BalanzaAgencia} />}></Route>
          <Route path='/reporteMovimientosCuentasAgencia' element={<ProtectedRoute component={MovimientosCuentasAgencia} />}></Route>
          <Route path='/conciliacion' element={<ProtectedRoute component={Conciliacion} />}></Route>
          <Route path='/cuadraturaMovimientos' element={<ProtectedRoute component={CuadraturaMovimientos} />}></Route>
          </Routes>

        </>
      
  );
}

export default App;
