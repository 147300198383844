import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const getCuentasPredeterminadas = async(data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getcuentasPredeterminadas`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const res = await response.json();
    return res
    
}

export const addCuentaContablePredeterminada = async(data) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/addCuentaMovimientosGenerales`, {
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id_movimiento: data.id_movimiento,
            cuenta: data.cuenta_contable,
            contra_cuenta: data.contra_cuenta_contable
        })
    })
    const res = await response;
    return res
}


    export const GetTipo = async(token)=>{
        const response = await fetch(`${BASE_URL}/CuentaContable/getTipo`, {
            "method": "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
              }
        })
        const data = await response.json();
        return data
    }

    export const updateCuentaPredeterminada = async(data) => {
        const response = await fetch(`${BASE_URL}/CuentaContable/updateCuentaPredeterminada`,{
            "method": "POST",
            headers:{
                "Authorization": `Bearear ${data.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: data.id,
                cuenta_predeterminada: data.cuenta,
                contra_cuenta_predeterminada: data.contraCuenta
            })

        })
        const res = await response.status
        return res
    }
