import { Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import '../../css/titulo.css'
import '../../css/Tablas.css';
import { useEffect, useState } from "react";
import { getAgencias } from "../../services/geolocalizacion/places";
import { addCuentaContablePredeterminada, getCuentasPredeterminadas } from "../../services/CuentaContable/CuentaPredeterminada";
import { useNavigate } from "react-router-dom";
import { Modal, ModalFooter } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import Button from 'react-bootstrap/Button';
import { getCuentasGenerales } from "../../services/subTiposMovBoveda/subtiposMovBoveda";
import Swal from "sweetalert2";


export function CuentaContablePredeterminadas(){
    const [agencias, setAgencias] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [agencia, setAgencia] = useState('')
    const [idAgencia, setIdAgencia] = useState(0)
    const [data, setData] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [cuenta, setCuenta] = useState([])
    const [contraCuenta, setContraCuenta] = useState([])
    const [id_movimiento, setIdMovimiento] = useState(0)
    const [helper, setHelper] = useState(false)
    const navigate = useNavigate();
        useEffect(() => { 
              const fetchData = async () => {
                const object = {
                  token: localStorage.getItem('token')
                }
                const res = await getCuentasPredeterminadas(object)
                setData(res)
              }
              fetchData()

        const fetchCuentas = async () => {
                const object = {
                    token: localStorage.getItem('token')
                }
                const res = await getCuentasGenerales(object)
                let options = []
                res.map(((index, i) => {
                    options[i] =
                    {
                        label: index.nombre_cuenta+"-"+index.numero_cuenta,
                        value: index.numero_cuenta
                    }
    
                }))
                setCuentas([options])
            }
            fetchCuentas();
        
            }, [helper])

    function verCuentas(id){ 
        setIdMovimiento(id)
        setShowModal(true)
    }

    const gotoDistribucion = () => {
      navigate('/Cuotas')
    }
    const gotoMovBoveda = () => {
      navigate('/Subtipos')
    }
    const gotoMovBancos = () => {
      navigate('/Bancos')
    }
    const handleNewCuenta = async () => {
      console.log(cuenta)
      console.log(contraCuenta)
      if(cuenta === null || contraCuenta === null){
        alert('Selecciona ambas cuentas')
      }else{
        const object = {
          token: localStorage.getItem('token'),
          cuenta_contable: cuenta[0],
          contra_cuenta_contable: contraCuenta[0],
          id_movimiento: id_movimiento

        }
        console.log(object)
        const res = await addCuentaContablePredeterminada(object)
        if(res.status === 200){
         Swal.fire({

          icon: 'success',
          title: 'Cuenta agregada correctamente',
          showConfirmButton: false,
          timer: 1500
        })
          setShowModal(false)
          setIdMovimiento(0)
          setHelper(!helper)
          setCuenta(null)
          setContraCuenta(null)
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error',
        })
      }
    }
    }

    return(
        <>
       
        <NavBar/>
        <h1 className="centered-container">Elección de cuentas predeterminadas en cierres diarios</h1>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Table bordered className="my-table">
        <thead>
          <tr>
            <td>Movimiento</td>
            <td>Cuenta contable</td>
            <td>Contra cuenta contable</td>
            <td>Accion</td>
          </tr>
        </thead>
        <tbody>
          {data.map((index, i) => (
            <tr key={i}>
              <td>{index.movimiento}</td>
              <td>{index.cuenta_predeterminada === null ? <>Sin cuenta asignada</>: <>{index.cuenta_predeterminada}</>}</td>
              <td>{index.contra_cuenta_predeterminada === null ? <>Sin cuenta asignada</>: <>{index.contra_cuenta_predeterminada}</>}</td>
              <td>
                <button className="btn btn-primary" onClick={() => verCuentas(index.id)}>Agregar cuentas</button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={3}>Distribucion de cuotas</td>
            <td>
              <button className="btn btn-success" onClick={() => gotoDistribucion()}>Ir a cuentas</button>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>Movimientos de boveda</td>
            <td>
              <button className="btn btn-success" onClick={() => gotoMovBoveda({ agencia: 'Distribucion de cuotas', id_agencia: 0 })}>Ir a cuentas</button>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>Movimientos de banco</td>
            <td>
              <button className="btn btn-success" onClick={() => gotoMovBancos({ agencia: 'Distribucion de cuotas', id_agencia: 0 })}>Ir a cuentas</button>
            </td>
          </tr>

        </tbody>
      </Table>
    </div>

          {/* modal para agregar un nuevo concepto */}
          <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar cuenta contable predeterminada</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >
        <Form.Group>
        <Form.Label>Selecciona la cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setCuenta(e)}

        />
        </Form.Group>
        <Form.Group>
        <Form.Label>Selecciona la contra cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setContraCuenta(e)}

        />
        </Form.Group>



             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleNewCuenta()}>
                Guardar
                </Button>
            </ModalFooter>
        </Modal>
        {/* FIN */}
        </>
    )
}