import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL
export const insertPartidas = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/insertPartidas`,{
          "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
               descripcion: data.descripcion,
               fecha: data.fecha,
               empresa: data.empresa,
               agencia: data.agencia
   
           })
     })
     const respuesta = await response
     return respuesta
}
export const getTipos = async(token) => {
     const response = await fetch(`${BASE_URL}/partidas/getPartidas`,{
          'method': "GET",
          headers:{
               'Authorization': `Bearer ${token}`,
               'Content-Type': "application/json"
          },
     })
     const respuesta = await response.json()
     return respuesta
}

export const getAllRegistros = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/getAllRegistros/${data.idPartida}/${data.idAgencias}`,{
          'method': "GET",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },     
     })
     const respuesta = await response.json()
     return respuesta
}
export const getPartidasFiltradas = async (data) => {
     const response = await fetch (`${BASE_URL}/partidas/getPartidas/${data.idEmpresa
     }/${data.idAgencias}/${data.fecha}`,{
          method: "GET",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },    
     })
     const respuesta = await response.json()
     return respuesta
}

export const getsingleRegistro = async (data) =>{
     const response = await fetch (`${BASE_URL}/partidas/getSingleRegistro/${data.idAgencia}/${data.idRegistro}`,{
          method: 'GET',
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          }
     })
     const respuesta = await response.json()
     return respuesta
}

export const updateRegistro = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/updateRegistro`,{
          method: "PATCH",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               registro: data.object
           })
     })
     const respuesta = await response.status
     return respuesta
}
export const deleteRegistro = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/deleteRegistro`,{
          method: "PUT",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idAgencia: data.agencia,
               idPartida: data.idPartida,
               idRegistro: data.idRegistro
           })
     })
     const respuesta = await response.status
     return respuesta
}
export const guardarPartida = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/savePartidas/${data.idPartida}`,{
          method: "PATCH",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"  
          }
     })
     const respuesta = await response.status
     return respuesta
}
export const getGrupo = async(data)=>{
     const response = await fetch(`${BASE_URL}/partidas/getGrupo/${data.idPartida}/${data.idAgencias}`,{
          method: "GET",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          }
     })
     const respuesta = await response.json()
     return respuesta
}
export const getEmpresaByPartida = async(data)=>{
     const {agencia, partida, token } = data
     const response = await fetch(`${BASE_URL}/partidas/getEmpresaByPartida/${partida}/${agencia}`,{
          method: "GET",
          headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': "application/json"
          }
     })
     const respuesta = await response.json()
     return respuesta
}
export const  getCuenta= async (token) =>{
     
     const response = await fetch(`${BASE_URL}/partidas/getCuenta`,{
          method: "GET",
          headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': "application/json"
          }
          
     })
     const respuesta = await response.json()
     return respuesta
}
///////////////////////////////////////////////////////
export const  getCuentaEmpresas = async (data) =>{
     
     const response = await fetch(`${BASE_URL}/partidas/getCuentaEmpresa`,{
          method: "POST",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idEmpresa: data.empresa,
               idAgencia: data.agencia,
          })    
          
     })
     const respuesta = await response.json()
     return respuesta
}
///////////////////////////////////////////////////////
export const  getCuentaEmpresasMayor = async (data) =>{
     
     const response = await fetch(`${BASE_URL}/partidas/getCuentasEmpresasMayor`,{
          method: "POST",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idEmpresa: data.empresa,
               idAgencia: data.agencia,
          })    
          
     })
     const respuesta = await response.json()
     return respuesta
}
///////////////////////////////////////////////////////
export const  getCuentaEmpresasOperativas = async (data) =>{
     
     const response = await fetch(`${BASE_URL}/partidas/getCuentaEmpresaOperativas`,{
          method: "POST",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idEmpresa: data.empresa,
               idAgencia: data.agencia,
          })    
          
     })
     const respuesta = await response.json()
     return respuesta
}
///////////////////////////////////////////////////////
export const  getPartidaByid = async (data) =>{
     
     const response = await fetch(`${BASE_URL}/partidas/getCuentaByid`,{
          method: "POST",
          headers: {
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               partidaID: data.numeroPartida,
              
           })
          
     })
     const respuesta = await response.json()
     return respuesta
}

///////////////////////////////////////////////////////
export const getPartidaPlanilla = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/getPartidasPlanilla/${data.idAgencia}/${data.idEmpresa}/${data.fecha}/${data.tipo}`,{
          method: "GET",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          }
     })
     const respuesta = await response.json()
     return respuesta
}

///////////////////////////////////////////////////////
export const savePartidaPlanilla = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/savePartidaPlanilla`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idAgencia: data.idAgencia,
               idEmpresa: data.idEmpresa,
               fecha: data.fecha,
               data: data.data,
               tipo: data.tipo
          })
     })
     const respuesta = await response.status
     return respuesta
}

///////////////////////////////////////////////////////////////
export const updateAccount = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/updatePlanillaAccount`,{
          method: "PATCH",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               id: data.id,
               cuenta: data.cuenta,
               tipo: data.tipo,
               tipo_registro: data.tipo_registro,
          })
     })
     const respuesta = await response.status
     return respuesta
}
///////////////////////////////////////////////////////////////
export const savePlanilla = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/savePlanilla`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idAgencia: data.idAgencia,
               idEmpresa: data.idEmpresa,
               fecha: data.fecha,
               tipo: data.tipo,
               
          })
     })
     const respuesta = await response.status
     return respuesta
}
export const eliminarPlanilla = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/eliminarPartidaPlanilla`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idAgencia: data.idAgencia,
               idEmpresa: data.idEmpresa,
               fecha: data.fecha,
               tipo: data.tipo,
               
          })
     })
     const respuesta = await response.status
     return respuesta
}

export const agregarRegistroUnico = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/guardarRegistroUnico`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idPartida: data.idPartida,
               idCuenta: data.idCuenta,
               abono: data.abono,
               cargo: data.cargo,
               idEmpresa: data.idEmpresa,
               idAgencia: data.idAgencia,
               concepto: data.concepto,
               fecha: data.fecha,
               
          })
     })
     const respuesta = await response.status
     return respuesta
}

export const revertirRegistro = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/revertirRegistroPartida`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
              idRegistro: data.idRegistro,
              idAgencia: data.idAgencia,
              idEmpresa: data.idEmpresa,
          })
     })
     const respuesta = await response.status
     return respuesta
}

export const getPartidaDiario = async(data) => {
     const response = await fetch(`${BASE_URL}/partidas/getPartidaDiario`,{
          method: "POST",
          headers:{
               'Authorization': `Bearer ${data.token}`,
               'Content-Type': "application/json"
          },
          body: JSON.stringify({
               idAgencia: data.idAgencia,
               idEmpresa: data.idEmpresa,
               fecha: data.fecha
          })
     })
     const respuesta = await response.json()
     return respuesta
}