import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAgenciasXEmpresa } from "../../services/geolocalizacion/places";
export function EmpresaXAgenciaSelect(props){
    const [agencias, setAgencias] = useState([[]])
    const agencia = props.agencia
    useEffect(() => {
       
        if (props.IdEmpresa != undefined && props.IdEmpresa != null && props.IdEmpresa != '') {
          const fetchData = async () => {
            const token = localStorage.getItem('token');
            const idEmpresa = props.IdEmpresa
            const object = { token, idEmpresa }
            const tipos = await getAgenciasXEmpresa(object)
            setAgencias([tipos])
          }
          fetchData()
        } else {
          const tipos = []
          setAgencias([tipos])
        }
      }, [props.IdEmpresa])

      const handleChange = (e) => {
        var nombre_agencia = agencias[0].find(x => x.id_agencia == e.target.value)
        props.onChange(e.target.value)
        if(props.getNombreAgencia == true){
          props.setNombreAgencia(nombre_agencia.agencia)
        }
    }
    return (
        <>
          {props.showlabel === false ? null : <Form.Label style={{marginTop:"10px"}}>Selecciona la agencia</Form.Label>}
           <Form.Select aria-label="Default select example"  onChange={handleChange}>
            <option >Selecciona una agencia</option>      
            {props.showTodas === true ? <option value="todas">Todas las agencias</option> : <></>}
                        {
                    agencias[0].map((props, index) => (
                      <option 
                      key={props.id_agencia}
                      value={props.id_agencia}
                      selected={props.id_agencia == agencia ? true : false}
                      >{props.agencia}</option>
                    ))
                  }
        </Form.Select>
        </>
    )
}