import { NavBar } from "../../components/navbar"
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardGroup from "react-bootstrap/CardGroup";
import {BsFillFileEarmarkPlusFill} from 'react-icons/bs';
import '../../css/home.css';
import conta from '../../resources/Contabilidad.jpg';
import React, { useEffect, useState } from 'react';
import { CardComponent } from "../../components/UTILS/CardComponent";
import { inicio } from "../../services/inicio/inicio";
import toast, { Toaster } from 'react-hot-toast';
import { notificacion } from "../../services/CuentaContable/SolicitarCambioSaldo";
import * as confetti from 'confettis';
export function Inicio() {

  const [card1, setCard1] = useState([])
  const [card2, setCard2] = useState([])
  const [card3, setCard3] = useState([])
  const [card4, setCard4] = useState([])
  
   const token = localStorage.getItem('token')
   const [show, setShow] = useState(false)

  useEffect(() => {
    // const verificar = async() => {
    //   const object = {
    //     token: token
    //   }
    //   const verificarNotificaciones = await notificacion(object)
    //   const response = await verificarNotificaciones
    //   const array = (await response.json())
    //   if(response.status === 200 && show === false && array.length !== 0 ){
        
    //     notify()
    //     setShow(true)
    //   }
    // }
    // verificar()

  }, []);
  
  
  
  const notify = () =>toast('Tienes peticiones de cambio de saldo pendientes!',
  {
    icon: '📬',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
      
    },
    duration: 1700,
  }
);



 

  useEffect(() => {
    const PageInit = async() =>{
      try{
        const data = await inicio(token)
        setCard1(data[0].pagina1)
        setCard2(data[0].pagina2)
        setCard3(data[0].pagina3)
        setCard4(data[0].pagina4)
      
        
      }catch(error){
      
      }
    }
    
    PageInit()
    //QUITARLO DESPUES DE DICIEMBRE
  //   const emojis = ['🎅🏻​', '🎄', '🌟​']
  //   confetti.create({
  //     count:25,
  //     speed: 30,
  //     decay: 0.96,
  //     spread: 360,
  //     shapes: [ 'emoji' ],
  //     emojis: emojis
  // })

  },[])

    return (
        <>

        <NavBar></NavBar>

        <div className="div_card">
        <CardComponent cardImagen ={card1.url_imagen} cardProfile={card1.url_imagen_perfil} cardName={card1.nombre} cardBio={card1.descripcion} URL={card1.url}/>
        <CardComponent cardImagen ={card2.url_imagen} cardProfile={card2.url_imagen_perfil} cardName={card2.nombre} cardBio={card2.descripcion} URL={card2.url}/>
        <CardComponent cardImagen ={card3.url_imagen} cardProfile={card3.url_imagen_perfil} cardName={card3.nombre} cardBio={card3.descripcion} URL={card3.url}/>
        <CardComponent cardImagen ={card4.url_imagen} cardProfile={card4.url_imagen_perfil} cardName={card4.nombre} cardBio={card4.descripcion} URL={card4.url}/>
        </div>



     
      <Toaster
        position="top-right"
        reverseOrder={false}
      />


        

        </>
    )
}
