import React, { useRef, useEffect } from 'react';

const LineChart = ({ datasets, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Limpiar el canvas
    ctx.clearRect(0, 0, width, height);

    // Dibujar ejes
    ctx.beginPath();
    ctx.moveTo(40, 10); // Posición del eje Y
    ctx.lineTo(40, height - 20); // Trazar línea vertical
    ctx.moveTo(40, height - 20); // Posición del eje X
    ctx.lineTo(width - 10, height - 20); // Trazar línea horizontal
    ctx.strokeStyle = '#000';
    ctx.stroke();

    // Dibujar líneas de referencia y etiquetas de escala en el eje Y
    ctx.font = '12px Arial';
    ctx.textAlign = 'right';
    ctx.fillStyle = '#000';
    for (let i = 0; i <= 4; i++) {
      const y = height - 20 - (height - 40) * (i / 4); // Posición y de la línea de referencia
      const label = (i * 25).toString(); // Etiqueta de escala
      ctx.beginPath();
      ctx.moveTo(40, y);
      ctx.lineTo(35, y);
      ctx.stroke();
      ctx.fillText(label, 30, y + 4);
    }

    // Dibujar líneas de referencia y etiquetas de escala en el eje X
    ctx.textAlign = 'center';
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']; // Nombres de los meses
    const numMonths = months.length;
    for (let i = 0; i < numMonths; i++) {
      const x = 40 + (i / (numMonths - 1)) * (width - 50); // Posición x de la etiqueta
      ctx.beginPath();
      ctx.moveTo(x, height - 20);
      ctx.lineTo(x, height - 15);
      ctx.stroke();
      ctx.fillText(months[i], x, height - 5);
    }

    // Configurar estilo de línea de los conjuntos de datos
    const colors = ['blue', 'red', 'green', 'orange', 'purple']; // Colores para cada conjunto de datos
    datasets.forEach((data, index) => {
      ctx.strokeStyle = colors[index]; // Color de la línea
      ctx.lineWidth = 2; // Grosor de la línea
      ctx.beginPath();

      // Dibujar línea de tendencia para cada conjunto de datos
      data.forEach((point, dataIndex) => {
        const x = 40 + (dataIndex / (numMonths - 1)) * (width - 50); // Coordenada x
        const y = height - 20 - (point * (height - 40)) / 100; // Coordenada y (asumiendo que los datos están en porcentajes)
        if (dataIndex === 0) {
          ctx.moveTo(x, y); // Mover el punto inicial
        } else {
          ctx.lineTo(x, y); // Dibujar una línea al siguiente punto
        }
      });

      // Dibujar la línea
      ctx.stroke();
    });
  }, [datasets, width, height]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default LineChart;
