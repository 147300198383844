const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const getDistribucionCuotas = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getDistribucionCuotas`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
    });
    const res = await response.json();
    return res
  };

  export const addCuentaDistriCuotas = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/addCuentaDistriCuotas`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },

      body: JSON.stringify({
        id: data.id,
        cuenta_contable: data.cuenta_contable,
        contra_cuenta_contable: data.contra_cuenta_contable,
      })
    });
    const res = await response
    return res
  };