import { NavBar } from "../../components/navbar"
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { getUsuarios } from '../../services/users/getUsuarios'
import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {register} from './../../services/users/login'
import { deleteUsuarios } from "../../services/users/deleteUsuarios";
import Swal from 'sweetalert2';
import { Modal_load } from '../../components/UTILS/modal_load';
import { changePassword } from "./../../services/users/login";
import { updateUsuarios } from "../../services/users/updateUsuarios";



export function ControlUsuario() {
    const token = localStorage.getItem('token');
    const [post, setPost] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [username, setUsuario] = useState('');
    const [password, setContrasena] = useState('');
    const [rol, setRol] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [helper, setHelper] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [id_usuario, setId] = useState(0);
    const [showPassword, setShowPassword] = useState(false)
    const [newpassword, setNewPassword] = useState("");
    const [showEdit, setShowEdit] = useState(false)
    
    useEffect(() => {
        const onSubmitHandler = async () => {
            try {
                const data = await getUsuarios(token);

                setPost(data);
              
            } catch (error) {
                console.log("error" + error);

            }
        };
        onSubmitHandler();
    }, [modalShow]);


    const sendEliminar = (id) =>{
        setId(id)
        setShowDeleteModal(true)
    }
    const sendChangePwd = (id) =>{
        setId(id)
        setShowPassword(true)
    }
    const sendShowEdit =  (object) =>{
        setUsuario(object.username)
        setRol(object.id_rol)
        setId(object.id_usuario)
        setShowEdit(true)
       
    }
    function generateRandomPassword() {
        const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        
        const getRandomElement = array => array[Math.floor(Math.random() * array.length)];
        
        const specialChar = getRandomElement(specialCharacters);
        const uppercaseChar = getRandomElement(uppercaseLetters);
        
        let password = specialChar + uppercaseChar;
        
        for (let i = 0; i < 6; i++) {
          password += getRandomElement(lowercaseLetters);
        }
        
        // Shuffle the password characters to ensure randomness
        const shuffledPassword = password.split('').sort(() => Math.random() - 0.5).join('');
        
        return shuffledPassword;
      }


    const handleGuardar = async() => {
        setModalShow(true)
        const fecha = new Date();
        var fechaActual = fecha.toLocaleDateString()
        const fechaSQL = fechaActual.split('/').reverse().join('-');
        const contra = generateRandomPassword()
        setContrasena(contra)
        const token = localStorage.getItem('token');
        var object ={username,password,rol,fechaSQL,token}
        const data = await register(object)
       
        if(data === 200){
            setHelper(true)
            setShowModal(false)
            Swal.fire({
                title: 'Registro completado',
                text: 'Se ha registrado el usuario con exito, su contraseña es: '+contra,
                icon: 'success',
                confirmButtonText: 'Aceptar'
              });
              setModalShow(false)
              
        }else{
            setShowModal(false)
            Swal.fire({
                title: 'Registro fallido',
                text: 'No se ha podido completar el registro',
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              setModalShow(false)
        }
      }


      const handleEliminar = async() =>{
        setModalShow(true)
        const token = localStorage.getItem('token');

        const object = {token, id_usuario}
        const data = await deleteUsuarios(object)
        if(data.status == 200){
            setModalShow(false)
            Swal.fire({
                title: 'Operacion completada',
                text: 'Se ha eliminado el usuario con exito',
                icon: 'success',
                confirmButtonText: 'Aceptar'
              });
              setShowDeleteModal(false)
        }else{
            setModalShow(false)
            Swal.fire({
                title: 'Operacion fallida',
                text: 'La operacion no pudo completarse',
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              setShowDeleteModal(false)
        }
        
      }
   
        
      
        const generatePassword = async() => {
            setModalShow(true)
            const token = localStorage.getItem('token');
          const chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let newPassword = "";
          for (let i = 0; i < 6; i++) {
            newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
          }
          setNewPassword(newPassword);
          const object = {id_usuario, newPassword,token}
          const data = await changePassword(object)
          if(data.status == 200){
            setModalShow(false)
            Swal.fire({
                title: 'Operacion completada',
                text: 'Su nueva contraseña es: '+newPassword,
                icon: 'success',
                confirmButtonText: 'Aceptar'
              });
              setShowPassword(false)
        }else{
           
            setModalShow(false)
            Swal.fire({
                title: 'Operacion fallida',
                text: 'La operacion no pudo completarse',
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              setShowPassword(false)
        }
        }
        const handleEditar = async() => {
            setModalShow(true)
            const token = localStorage.getItem('token');
            var object = {username, rol, token}
            const data = await updateUsuarios(object, id_usuario);
           
            if(data.status == 200){
                setModalShow(false)
                Swal.fire({
                    title: 'Operacion completada',
                    text: 'El usuario ha sido editado',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                  });
                  setShowEdit(false)
            }else{
                setModalShow(false)
                Swal.fire({
                    title: 'Operacion completada',
                    text: 'La operacion no pudo completarse',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                  });
                  setShowEdit(false)
            }
        }
        
       
    return (
        <>
                     <Modal_load
        show={modalShow}
        texto="Comprobando informacion"
        onHide={() => setModalShow(false)}
        />
            <NavBar />
            <div className="mt-4 mx-4" >
                <Button onClick={() => setShowModal(true)} className="m-4">Agregar usuario</Button>
            </div>
            {/* MOSTRAR TODOS LOS USUARIOS ACTIVOS */}
            <div className="d-flex justify-content-center">
                <Table striped bordered hover className="table-secondary mx-5 shadow-lg ">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Cargo</th>
                            <th>Agencia</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {post.map((props) => (
                            <tr>
                                <td>{props.username}</td>
                                <td>{props.rol}</td>
                                <td>Administracion</td>
                                <td>
                                    <Button variant="danger" className="mx-2" onClick={() => sendEliminar(props.id_usuario)} >Eliminar</Button>
                                    <Button className="mx-4" onClick={()=>sendShowEdit(props)}>Editar</Button>
                                    <Button variant="success" onClick={()=>sendChangePwd(props.id_usuario) }>Cambiar contraseña</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {/* AGREGAR UN NUEVO USUARIO */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar un nuevo usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control type="text" placeholder="P.E NO2321"  onChange={(e) => setUsuario(e.target.value)}/>

                            <Form.Label>Rol</Form.Label>
                            <Form.Select aria-label="Default select example" value={rol} onChange={(e) => setRol(e.target.value)}>
                                <option>Seleccione un rol para este usuario</option>
                                <option value="2">Contador</option>
                                <option value="1">Administrador</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleGuardar}>
                    Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
                
            {/* ELIMINAR USUARIO */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar a este usuario</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleEliminar}>
                    Si, eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* CAMBIAR CONTRA */}
            <Modal show={showPassword} onHide={() => setShowPassword(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Desea cambiar la contraseña de este usuario?</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPassword(false)}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={generatePassword}>
                    Si, cambiar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* EDITAR USUARIO */}
            <Modal show={showEdit} onHide={() => setShowEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar informacion del usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control type="text" placeholder="P.E NO2321"  value={username} onChange={(e) => setUsuario(e.target.value)}/>
                           
                            <Form.Label>Rol</Form.Label>
                            <Form.Select aria-label="Default select example"  value={rol} onChange={(e) => setRol(e.target.value)}>
                                <option>Seleccione un rol para este usuario</option>
                                <option value="2">Contador</option>
                                <option value="1">Administrador</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEdit(false)}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={handleEditar}>
                    Si, cambiar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}