import { useEffect, useState } from "react";
import { NavBar } from "../../components/navbar";
import { Button, Container, Form } from "react-bootstrap";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { getEmpresasAll } from "../../services/geolocalizacion/places";


export function ListadoPlanillas() {
    const [showData, setShowData] = useState(false);
    const [empresa, setEmpresa] = useState([[]])
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [idAgencia, setIdAgencia] = useState(null)
    const [fecha, setFecha] = useState(null)
    const token = localStorage.getItem('token');

    useEffect(() => {

        const fetchData = async () => {
            const object = { token }
            const tipos = await getEmpresasAll(object)
            setEmpresa([tipos])
        }

        fetchData()



    }, [])


    const sendBuscar = (e) => {
        e.preventDefault()
        setShowData(true)
    }
    const setNombreEmpresa = (e) => {
        setIdEmpresa(e.target.value)
    }

    const gotoListado = () => {

    }


    if (showData) {
        return (
            <h1>Planillas</h1>
        )
    } else {

        return (
            <div>
                <NavBar />
                <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
                    <Form onSubmit={sendBuscar}>
                        <center> <h2>Buscar partida de planilla</h2> </center>


                        <Form.Label>Empresa</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={setNombreEmpresa}>
                            <option value='0'>Seleccione una opcion</option>
                            {
                                empresa[0].map((props, index) => (

                                    <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>

                                ))
                            }
                        </Form.Select>

                        <EmpresaXAgenciaSelect
                            IdEmpresa={idEmpresa}
                            onChange={(e) => setIdAgencia(e)}
                            showlabel={true}
                            agencia={0}
                        />

                        <Form.Group >
                            <Form.Label>Seleccione el mes</Form.Label>
                            <Form.Control type="month" onChange={(e) => setFecha(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="submit" className='my-2' >
                            Buscar
                        </Button>

                    </Form>
                </Container>
            </div>
        )
    }
}

