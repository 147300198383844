import { Container, Form } from 'react-bootstrap';
import { NavBar } from '../../components/navbar';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import {AiOutlineFileExcel, AiFillEdit} from 'react-icons/ai'
import { CarteraGeneral, CarteraGeneralVencidos, CarteraGeneralEmpleados } from '../../services/infoRed/infoRed';
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
export default function InfoRed() {
    const [inicio, setInicio] = useState(null)
    const [fin, setFin] = useState(null)
    const [option, setOption] = useState(1)
    const [datos, setDatos] = useState([])
    const sendBuscar = async (e) => {

      e.preventDefault()
      
        if(option == 1){
          Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
          })
          let response = []
          const intervals = []
          let currentDate = new Date(inicio)
          currentDate.setDate(currentDate.getDate()+1)
          
          let endDate = new Date(fin)
          endDate.setDate(endDate.getDate()+1)

          //currentDate.setMonth(intervalEnd.getMonth())
          while( currentDate <= endDate){
            const intervalEnd = new Date(currentDate)
            intervalEnd.setMonth(intervalEnd.getMonth() + 1)
          
            if (intervalEnd >= endDate) {
              intervalEnd.setTime(endDate.getTime());
              //intervalEnd.setDate(intervalEnd.getDate() +1)
            }
            intervals.push({start: currentDate, end: intervalEnd})
            currentDate = new Date(intervalEnd)
            currentDate.setDate(currentDate.getDate() +1 )
          }
       
          for(const index in intervals){
            console.log("cargando")
            const fechaInicial = new Date(intervals[index].start)
            const fechaFinal = new Date(intervals[index].end)
            const año = fechaInicial.getFullYear();
            const mes = String(fechaInicial.getMonth() + 1).padStart(2, '0'); // Agrega ceros al mes si es necesario
            const dia = String(fechaInicial.getDate()).padStart(2, '0'); // Agrega ceros al día si es necesario
            const inicio = `${año}-${mes}-${dia}`;
            const añoF = fechaFinal.getFullYear();
            const mesF = String(fechaFinal.getMonth() + 1).padStart(2, '0'); // Agrega ceros al mes si es necesario
            const diaF = String(fechaFinal.getDate()).padStart(2, '0'); // Agrega ceros al día si es necesario
            const final = `${añoF}-${mesF}-${diaF}`;
            console.log(fechaInicial)
            console.log(fechaFinal)
            
            const object = {
              inicio: inicio,
              fin: final,
              finReal: fin,
              token: localStorage.getItem('token'),
             
            }

            const data = await CarteraGeneral(object)
            console.log(data)
            console.log("termino...")
            response = response.concat(data.response)

            const data2 = await CarteraGeneralEmpleados(object)
            if (data2 && data2.response) {
                response = response.concat(data2.response);
            } else {

                console.error("Error: No se recibió una respuesta válida de CarteraGeneralEmpleados");
                return;
            }
        }
          console.log(response)
          console.log("termino...")
          Swal.close()
         
          // Crear un libro de trabajo
          const workbook = XLSX.utils.book_new();
          const sheetName = 'CarteraGeneral';
          // Crear una hoja de cálculo y agregar los datos
          const worksheet = XLSX.utils.json_to_sheet(response, { header: ['Año', 'mes', 'nombre', 'tipo_per', 'Num_ptmo', 'inst', 'fec_otor', 'monto', 'plazo', 'saldo', 'mora', 'forma_pag', 'tipo_rel', 'linea_cre', 'dias', 'ult_pago', 'tipo_gar', 'tipo_mon', 'valcuota', 'dia', 'fechanac', 'dui', 'nit', 'fecha_can', 'fecha_ven', 'ncuotascre', 'calif_act', 'activi_eco','sexo', 'estcredito'] });
              // Agrega la hoja de cálculo al libro de Excel
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

            // Genera el archivo Excel
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Crea un objeto Blob para el archivo Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crea un objeto URL para el archivo Excel
            const excelUrl = URL.createObjectURL(blob);

            // Crea un enlace para la descarga del archivo Excel
            const link = document.createElement('a');
            link.href = excelUrl;
            link.download = `Cartera ${fin}.xlsx`;

            // Dispara el evento de clic para descargar el archivo
            link.click();
       

        }else if(option == 2){
          let response = []

          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Suma 1 al mes, ya que los meses en JavaScript van de 0 a 11
          const day = String(currentDate.getDate()).padStart(2, '0');

          const fechaReal = `${year}-${month}-${day}`;
          const object = {
            inicio: inicio,
            fin: fin,
            finReal: fechaReal,
            token: localStorage.getItem('token'),
            tipo: "Vencidos"
        }
          const data = await CarteraGeneralVencidos(object)
          console.log(data)
          console.log("termino...")

         
          // Crear un libro de trabajo
          const workbook = XLSX.utils.book_new();
          const sheetName = 'CarteraGeneral';
          // Crear una hoja de cálculo y agregar los datos
          const worksheet = XLSX.utils.json_to_sheet(data.responseVencidos, { header: ['Año', 'mes', 'nombre', 'tipo_per', 'Num_ptmo', 'inst', 'fec_otor', 'monto', 'plazo', 'saldo', 'mora', 'forma_pag', 'tipo_rel', 'linea_cre', 'dias', 'ult_pago', 'tipo_gar', 'tipo_mon', 'valcuota', 'dia', 'fechanac', 'dui', 'nit', 'fecha_can', 'fecha_ven', 'ncuotascre', 'calif_act', 'activi_eco','sexo', 'estcredito'] });
              // Agrega la hoja de cálculo al libro de Excel
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

            // Genera el archivo Excel
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Crea un objeto Blob para el archivo Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crea un objeto URL para el archivo Excel
            const excelUrl = URL.createObjectURL(blob);

            // Crea un enlace para la descarga del archivo Excel
            const link = document.createElement('a');
            link.href = excelUrl;
            link.download = `Cartera ${fin}.xlsx`;

            // Dispara el evento de clic para descargar el archivo
            link.click();
        }else if(option == 3){
          let response = []

          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Suma 1 al mes, ya que los meses en JavaScript van de 0 a 11
          const day = String(currentDate.getDate()).padStart(2, '0');

          const fechaReal = `${year}-${month}-${day}`;
          const object = {
            inicio: inicio,
            fin: fin,
            finReal: fechaReal,
            token: localStorage.getItem('token'),
            tipo: "Morosos"
        }
          const data = await CarteraGeneralVencidos(object)
          console.log(data)
          console.log("termino...")

         
          // Crear un libro de trabajo
          const workbook = XLSX.utils.book_new();
          const sheetName = 'CarteraGeneral';
          // Crear una hoja de cálculo y agregar los datos
          const worksheet = XLSX.utils.json_to_sheet(data.responseVencidosMorosos, { header: ['Año', 'mes', 'nombre', 'tipo_per', 'Num_ptmo', 'inst', 'fec_otor', 'monto', 'plazo', 'saldo', 'mora', 'forma_pag', 'tipo_rel', 'linea_cre', 'dias', 'ult_pago', 'tipo_gar', 'tipo_mon', 'valcuota', 'dia', 'fechanac', 'dui', 'nit', 'fecha_can', 'fecha_ven', 'ncuotascre', 'calif_act', 'activi_eco','sexo', 'estcredito'] });
              // Agrega la hoja de cálculo al libro de Excel
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

            // Genera el archivo Excel
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Crea un objeto Blob para el archivo Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crea un objeto URL para el archivo Excel
            const excelUrl = URL.createObjectURL(blob);

            // Crea un enlace para la descarga del archivo Excel
            const link = document.createElement('a');
            link.href = excelUrl;
            link.download = `Cartera ${fin}.xlsx`;

            // Dispara el evento de clic para descargar el archivo
            link.click();
        }
    }



    return (
        <>
        <NavBar></NavBar>

        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
            <h4>Reporte CSV para INFORED</h4>
        <Form.Group >
            <Form.Label>Desde</Form.Label>
            <Form.Control type="date" onChange={(e) => setInicio(e.target.value)} />
          </Form.Group>

          <Form.Group >
            <Form.Label>Hasta</Form.Label>
            <Form.Control type="date" onChange={(e) => setFin(e.target.value)} />
          </Form.Group>

          <Form.Group >
          <Form.Label style={{marginTop:"10px"}}>Selecciona el tipo de documento</Form.Label>
          <Form.Select aria-label="Default select example" onChange = {(e) => {setOption(e.target.value)}}>
                    <option value={1}>Cartera general</option>
                    <option value={2}>Cartera cancelados</option>
                    <option value={3}>Cartera cancelados con mora</option>
            </Form.Select>
        </Form.Group>
  
          <Button variant="primary" type="submit" className='my-2' >
          <AiOutlineFileExcel></AiOutlineFileExcel>  Descargar excel
          </Button>
          
         
        </Form>
        </Container>
        </>
    )   
}