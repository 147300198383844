import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const SaveCuentas = async(data) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/CuentaContable/insertCuenta`,{
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            CuentaContable: data.CuentaContable,
            NombreCuenta: data.NombreCuenta,
            Nivel: data.nivelSeleccionado,
            Tipo: data.tipoSeleccionado,
            SQlFecha: data.SQlFecha,
            empresa: data.idEmpresaInsertar,
        })
    })
    const respuesta = await response.status
    return respuesta
}

export const saveCuentaExcel = async(data) => {
   
    const response = await fetch(`${BASE_URL}/CuentaContable/iniciarSubcuentaExcel`,{
        "method": "POST",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: data.data,
            id_empresa: data.id_empresa,
        })
    })
    const respuesta = await response
    return respuesta
}