const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const inicio = async(token)=>{
    const response = await fetch(`${BASE_URL}/Menu_favoritos/getFavoritePage`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    })
    const data = await response.json();
    return data
}