import { getEmpresas, getEmpresasAll } from "../../services/geolocalizacion/places";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { propTypes } from "react-bootstrap/esm/Image";



export function EmpresasSelect(props){
    const [empresas, Setempresa] = useState([[]])
    const [idEmpresa, setIdEmpresa] = useState('')

    useEffect(() => {
    const traerEmpresas = async () => {
        const token = localStorage.getItem('token');
        const object = {
          token: token
        }
        const res = await getEmpresasAll(object);
        
        Setempresa(res)
  
      }
  
      traerEmpresas()
    }, [])

    const handleChange = (e) => {
     
        setIdEmpresa(e.target.value);
        props.onChange(e.target.value)
        props.empresa(e.target.options[e.target.selectedIndex].text)
    }

    return (
        <>
        {props.showTitle == false ?  <Form.Label style={{marginTop:"5px"}} key={0}>Selecciona la empresa</Form.Label> : null}
        <Form.Label style={{marginTop:"10px"}}>Selecciona la empresa</Form.Label>
           <Form.Select aria-label="Default select example" value={ idEmpresa}  onChange={handleChange}>
                        <option key={0}>Seleccione una empresa</option>
                       {empresas.map((item) => (
                        <option value={item.id_empresa} key={item.id_empresa}>{item.nombre_empresa}</option>  
                      ))}
            </Form.Select>
        </>
        )
}