import { Button, Container, Form, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import { useEffect, useState } from "react";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { Autocomplete, TextField } from "@mui/material";
import { getCuentaEmpresas, getCuentaEmpresasMayor } from "../../services/partidas/partidas";
import { getCuentasenRango, movimientosCuentas, movimientosCuentasAgencia, movimientosCuentasRango } from "../../services/reportes/reportesCuentas";
import '../../css/botonRefresh.css'
import Swal from "sweetalert2";
import { AiFillFileExcel, AiOutlineArrowLeft } from "react-icons/ai";
import * as XLSX from 'xlsx';
import { GIFcarga } from "../../utils/GIFcarga";
import { useNavigate } from "react-router-dom";
export function MovimientosCuentasAgencia() {
    const [fechaInicio, setFechaInicio] = useState(null)
    const [fechaFinal, setFechaFinal] = useState(null)
    const [nombreEmpresa, setNombreEmpresa] = useState(null)
    const [idAgencia, setIdAgencia] = useState(null)
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [cuenta, setCuenta] = useState(null)
    const [cuentas, setCuentas] = useState([[]])
    const [data, setData] = useState([])
    const [showData, setShowData] = useState(false)
    const [totalCargos, setTotalCargos] = useState(0)
    const [totalAbonos, setTotalAbonos] = useState(0)
    const navigate = useNavigate()
    


    useEffect(() => {
        async function getCuentas() {
            const token = localStorage.getItem('token')
            const object = {
                token: token,
                empresa: idEmpresa,
                agencia: ''
            }
            const cuentas = await getCuentaEmpresas(object)
            let options = []
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta + "-" + index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
       
        getCuentas()
       
    }, [idEmpresa])

    
  const handleDownloadCSV = async () => {
    const resultado = data.map((index) => {
      return {
        Agencia: index.agencia.agencia,
        Cuenta: index.cuenta,
        Fecha: index.fecha_ingreso,
        Movimiento: index.movimiento,
        Cargo: index.tipo_movimiento == 1 ? index.monto.toFixed(2) : 0.00,
        Abono: index.tipo_movimiento == 2 ? index.monto.toFixed(2) : 0.00,
        Saldo: index.saldo.toFixed(2)
      }
    })
    // Crear un libro de trabajo
    const workbook = XLSX.utils.book_new();
    const sheetName = 'CarteraGeneral';
    // Crear una hoja de cálculo y agregar los datos
    const worksheet = XLSX.utils.json_to_sheet(resultado);
    // Agrega la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

    // Genera el archivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crea un objeto Blob para el archivo Excel
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Crea un objeto URL para el archivo Excel
    const excelUrl = URL.createObjectURL(blob);

    // Crea un enlace para la descarga del archivo Excel
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = `MovimientosCuenta.xlsx`;

    // Dispara el evento de clic para descargar el archivo
    link.click();

  };

    const sendBuscar = async (e) => {
        e.preventDefault()

        Swal.fire({
          title: 'Cargando...',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
              Swal.showLoading();
          },
      });
        if(cuenta == null){
            alert('Seleccione una cuenta')
            Swal.close()
            return
        }else{
          if(idAgencia == 'todas'){
              setIdAgencia(null)
          }
        const object = {
            cuenta: cuenta.toString(),
            fechaInicio: fechaInicio,
            fechaFinal: fechaFinal,
            agencia: idAgencia,
            empresa: idEmpresa,
            token: localStorage.getItem('token')
        }
        const result = await movimientosCuentasAgencia(object)
        let totalCargos = 0
        let totalAbonos = 0
        result.map((index) => {
            if(index.tipo_movimiento == 1){
                totalCargos += index.monto
            }else{
                totalAbonos += index.monto
            }
        })
        setTotalCargos(totalCargos)
        setTotalAbonos(totalAbonos)
        setData(result)
        Swal.close()
        setShowData(true)
        console.log(result)
      
      
    }

    }
    const handleChange = (e) => {
        console.log(e)
        if(!isNaN(e)){
        setIdEmpresa(e)
        }else{
        setIdEmpresa(0)
        }
    }

    const handleChangeCuenta = (e, value) => {
        setCuenta(value.value)
      
    }


    const changeAgencia = (e) => {
      console.log(e)
      if(e == 'todas'){
        setIdAgencia(null)
      }else{
        setIdAgencia(e)
      }
    }
    const reload = () => {
      sendBuscar()
    }
    const goBack = () => {
      setShowData(false)
     
      
    }
    const handleChangeSearch = (e) => {
        navigate('/movimientosCuentasRanged')
    }


    if(showData){
        return(
        <>
        <NavBar />
        
        <div className="shadow" style={{ margin: "30px" }}>
          
          <div className="container mt-4">
            
          <Button onClick={goBack} style={{ borderRadius: '40%', width: '50px', height: '50px' }}>
                        <AiOutlineArrowLeft style={{ fontSize: '12px' }} ></AiOutlineArrowLeft>
                    </Button>
            <div className="row">
              
              <div className="col-md-12 mx-auto text-center">
                <div className="col-md-1 text-right">
                  <button type="button" className="btn btn-success" onClick={sendBuscar}>Recargar</button>
                </div>
                
                <h1>Reporte de movimientos de cuentas</h1>
                <h3>Empresa:{nombreEmpresa}</h3>
                
              </div>
            </div>

            <div className="row mt-4 mx-auto text-center unshow">
              <div className="col-md-6">
                <div className="">
                  {/* Contenido para la primera columna */}
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Desde:</Form.Label>
                    <p>{fechaInicio}</p>
                  </Form.Group>


                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Hasta:</Form.Label>
                    <p>{fechaFinal}</p>
                  </Form.Group>
                </div>
             
              </div>

              <div className="col-md-6">
                <div className="">
                  <Button variant="success" type="submit" className='my-2' onClick={handleDownloadCSV} >
                    <AiFillFileExcel /> Descargar excel
                  </Button>

                </div>
              </div>
             





            </div>
          </div>
          <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
          <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
            <Table className="my-table printTable" >
              <thead>
                <tr>
                  <th>Agencias</th>
                  <th style={{ width: "200px" }}>Cuenta</th>
                  <th >Fecha</th>
                  <th>Movimiento</th>
                  <th>Cargo</th>
                  <th>Abono</th>
                  <th>Saldo</th>

                </tr>
              </thead>
              <tbody>

                {data
                  .map((props) => (
                    <tr>
                      <td>{props.agencia.agencia}</td>
                      <td>{props.cuenta}</td>
                      <td>{props.fecha_ingreso}</td>
                      <td>{props.movimiento}</td>
                      <td>{props.tipo_movimiento == 1 ? props.monto.toFixed(2) : 0.00}</td>
                      <td>{props.tipo_movimiento == 2 ? props.monto.toFixed(2) : 0.00}</td>
                      <td>{props.saldo.toFixed(2)}</td>

                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totalCargos.toFixed(2)}</td>
                  <td>{totalAbonos.toFixed(2)}</td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>

          </div>

        </div>
        </>
        )
    }else{
    return (
        <>
              <NavBar />
            <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
                <Form onSubmit={sendBuscar}>
                    <center> <h2>Reporte de movimientos de cuentas por agencia</h2> </center>

                    <EmpresasSelect
                        onChange={(e) => handleChange(e)}
                        empresa={(e) => setNombreEmpresa(e)}
                    />
                  
                  
                      <>
                       <Form.Label style={{ marginTop: "10px" }}>Cuenta contable</Form.Label>
                        <Autocomplete
                            disablePortal
                            onChange={(e, value) => handleChangeCuenta(e, value)}
                            id='cuenta-unica'
                            options={cuentas[0]}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Cuenta" style={{ width: '100%' }} />}
                        />
                      <EmpresaXAgenciaSelect
                        IdEmpresa={idEmpresa}
                        onChange={(e) => changeAgencia(e)}
                        showlabel={true}
                        agencia={0}
                    />
                        </>
        

                    
                    <Form.Group>
                        <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia de inicio</Form.Label>
                        <Form.Control type="date" onChange={(e) => setFechaInicio(e.target.value)} />
                        <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia final</Form.Label>
                        <Form.Control type="date" onChange={(e) => setFechaFinal(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit" className='my-2'>
                        Buscar
                    </Button>
                </Form>
            </Container>
        </>
    )
    }
}