import { NavBar } from "../../components/navbar";
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import '../../css/Tablas.css';
import '../../css/Modal.css';

import { traer_usuarios } from "../../services/CuentaContable/SolicitarCambioSaldo";
import { ModalAgregarUsuario } from "../../components/cambioSaldoComponentes/modalAgregarUsuario";
import { deleteUsuario } from "../../services/CuentaContable/SolicitarCambioSaldo";
export default function CambioSaldo(){
  const token = localStorage.getItem('token')
  const [usuarios, setUsuarios] = useState([])
  const [modalAgregar, setModalAgregar] = useState(false)
  const [helper, setHelper] = useState(false)
  const [cantidadUsuarios, setCantidadUsuarios] = useState(null)
  useEffect(() => {
    
    const get_usuarios = async()=>{
      const object = {
        token: token
      }
      const res = await traer_usuarios(object)
      const resultado = await res.json()
      setUsuarios(resultado)
      setCantidadUsuarios(resultado.length)
    }
    get_usuarios()



  }, [modalAgregar, helper])

  const handleEliminar = (id_usuario) => {
    Swal.fire({
      title: 'Alerta',
      text: 'Se eliminara este usuario',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const object = {
          token: token,
          id_usuario: id_usuario
        }
       const eliminarUsuario = await deleteUsuario(object)
       const respuesta = await eliminarUsuario
       if(respuesta.status === 200){
        Swal.fire(
          'Exito',
          'Usuario eliminado con exito!',
          'success'
        )
        setHelper(!helper)
       }else if(respuesta.status === 401){
        Swal.fire(
          'Usuario no encontrado',
          '',
          'error'
        )
       }else{
        Swal.fire(
          '',
          'Ocurrio un error inesperado',
          'error'
        )
       }
      }
    });
  }

    return (
        <>
        <NavBar></NavBar>
        <ModalAgregarUsuario
        show={modalAgregar}
        onHide = {() => {setModalAgregar(false)}}
        cantidadUsuarios={cantidadUsuarios}
        />
        
        <div className="Encabezado" style={{textAlign: "center"}}>
          <h1 style={{color: "white", marginTop: "35px"}}>Usuarios que pueden aprobar cambio saldo</h1>
        </div><br/>
        <div className="Busqueda"><br/>
          <div style={{marginLeft: "24px"}}>
            <Stack direction="horizontal" gap={2}>

                <Button variant="warning" onClick={() => {setModalAgregar(true)}} >Agregar</Button>
            </Stack><br/>
            <Table className="my-table">
              <thead>
                <tr>
                  <th>Usuario</th>
                 
                  <th style={{width: "300px"}}>Acciones</th>
                 
                </tr>
              </thead>
              <tbody>
                {usuarios.map((props) => (
                  <tr>
                    <td>{props.login.username}</td>
                 
                    <td >
                    <Button variant="danger"  onClick={() => {handleEliminar(props.id_usuario)}}>Eliminar</Button>
                    <span style={{ margin: '0 5px' }}></span>
                   
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> 
          </div>
        </div>
        </>
    )
}