import { ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import { BsFillClockFill, BsFillClipboard2PlusFill } from 'react-icons/bs'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import { agregarRegistroUnico, getCuentaEmpresas, getCuentaEmpresasOperativas, getPartidaByid, insertPartidas, revertirRegistro } from "../../services/partidas/partidas";
import { Modals } from '../../components/partidasComponentes/modalsAuto'
import { Modal_load } from '../../components/UTILS/modal_load';
import { getTipos } from "../../services/partidas/partidas";
import { getEmpresas, getAgenciasXEmpresa, getEmpresasAll, getTipoEmpresa } from "../../services/geolocalizacion/places";
import '../../css/Tablas.css';
import { saveRegistro, saveRegistroOperativo } from '../../services/partidas/saveRegistro'
import { BiSave } from 'react-icons/bi'
import { AceptarModal } from "../../components/partidasComponentes/modalAceptar";
import { getAllRegistros } from "../../services/partidas/partidas";
import { BuscarModal } from "../../components/partidasComponentes/modalBuscar";
import { AiOutlineFolderOpen } from 'react-icons/ai'
import { EditarModal } from "../../components/partidasComponentes/modalEditar";
import { EliminarModal } from "../../components/partidasComponentes/modalEliminar";
import { guardarPartida } from "../../services/partidas/partidas";
import { getGrupo } from "../../services/partidas/partidas"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getConceptos } from "../../services/concepto/getConceptos";
import CurrencyInput from "react-currency-input-field";
import { convertirNumeroADinero } from "../../utils/numeroAdinero";


export function Partidas() {

  /* El código anterior está escrito en JavaScript utilizando la biblioteca React. Declara múltiples
  variables de estado usando el hook useState, incluyendo token, partida, tiposSelect, fecha, show,
  modalShow, allData, empresas, agencias, idEmpresa, idAgencias, idPartida, grupo, showGuardar,
  getData, dataTable, showBuscar, totalEgreso, totalIngreso, idRegistro , showEditar y showEliminar.
  Estas variables se utilizan para administrar el estado de la aplicación y almacenar datos. El código
  también usa localStorage para recuperar un valor de token. */
  const token = localStorage.getItem('token');
  const [partida, setPartida] = useState('')
  const [tiposSelect, setTipos] = useState([[]])
  const [fecha, setFecha] = useState('')
  const [show, setShow] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [allData, setAllData] = useState([])
  const [empresas, setEmpresas] = useState([[]])
  const [agencias, setAgencias] = useState([[]])
  const [idEmpresa, setIdEmpresa] = useState(0)
  const [idAgencias, setIdAgencia] = useState(0)
  const [idPartida, setIdPartida] = useState(0)
  const [grupo, setGrupo] = useState(0)
  const [showGuardar, setShowGuardar] = useState(false)
  const [getData, setGetData] = useState(false)
  const [dataTable, setDataTable] = useState([[]])
  const [showBuscar, setShowBuscar] = useState(false)
  const [totalEgreso, setTotalEgreso] = useState(0)
  const [totalIngreso, setTotalIngreso] = useState(0)
  const [idRegistro, setIdRegistro] = useState(0)
  const [showEditar, setShowEditar] = useState(false)
  const [showEliminar, setShowEliminar] = useState(false)
  const [helper, setHelper] = useState(false)
  const [newRow, setNewRow] = useState(false)
  const [cuentas, setCuentas] = useState([[]])
  const [cuenta, setCuenta] = useState(0)
  const [option, setOptions] = useState([[]])
  const [concepto, setConcepto] = useState('')
  const [cargo, setCargo] = useState(0)
  const [abono, setAbono] = useState(0)
  const [color, setColor] = useState('black')
  const [empresaNombre, setEmpresaNombre] = useState('')
  const [nombreAgencia, setNombreAgencia] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [habilitarBoton, setHabilitarBoton] = useState(true)
  const [comprobante_mensual, setComprobante_mensual] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  let egreso = 0
  let ingreso = 0
  /* El código anterior usa el gancho `useEffect` en un componente de React para obtener datos de un
  punto final de API usando la función `getAgenciasXEmpresa`. La variable `idEmpresa` se usa como
  una dependencia en el gancho `useEffect`, por lo que el código se volverá a ejecutar cada vez que
  cambie `idEmpresa`. Si `idEmpresa` no es igual a 0, se llama a la función `fetchData`, que envía
  una solicitud a la API con un objeto que contiene un `token` e `idEmpresa`. Luego, la respuesta de
  la API se usa para actualizar `agencias */
  useEffect(() => {
    if (idEmpresa != 0) {
      const fetchData = async () => {
        const object = { token, idEmpresa }
        const tipos = await getAgenciasXEmpresa(object)
        setAgencias([tipos])
      }
      fetchData()
    } else {
      const tipos = []
      setAgencias([tipos])
    }

    const fetchDataCuenta = async () => {
      const objectEmpresa = {
        token: token,
        empresa: idEmpresa,
      }
      const tipo_empresa = await getTipoEmpresa(objectEmpresa)

      const object = {
        token: token,
        empresa: idEmpresa,
        agencia: idAgencias,
      }
      


        const cuentas = await getCuentaEmpresas(object)
        let options = []
        cuentas.map(((index, i) => {
          options[i] =
          {
            label: index.nombre_cuenta + "-" + index.numero_cuenta,
            value: index.numero_cuenta
          }

        }))
        setCuentas([options])
      
    }
    fetchDataCuenta()
    /////////////////////////////////////////
    const fetchDataconcepto = async () => {
      const conceptos = await getConceptos(token)
      let options = []
      conceptos.map(((index, i) => {
        options[i] =
          { label: index.descripcion }

      }))
      setOptions([options])

    }
    fetchDataconcepto()
  }, [idEmpresa])


  const handleChangeCuenta = (key, value) => {

    setCuenta(value.value)

  }

  const handleChangeConcepto = (e) => {
    setConcepto(e.target.value)

  }
  const handleChangeCargo = (e, itemID) => {
    setCargo(e)
    if (e == undefined) {
      setCargo(0)
    }
  }

  const handleChangeAbono = (e, itemID) => {
    setAbono(e)
    console.log(e)
    if (e == undefined) {
      setAbono(0)
    }
  }

  function handleChange(e, newValue) {

   setConcepto(newValue.label)

}
  /* El código anterior usa el gancho `useEffect` en un componente React para obtener datos de forma
  asíncrona desde un punto final de la API usando la función `getTipos`. Luego establece los datos
  obtenidos en la variable de estado `tipos` usando la función `setTipos`. El `[]` como segundo
  argumento de `useEffect` indica que este efecto solo debe ejecutarse una vez, cuando se monta el
  componente. */
  useEffect(() => {
    const fetchData = async () => {
      const tipos = await getTipos(token);
      setTipos([tipos])

    };
    fetchData();
  }, []);

  /* El código anterior usa el gancho `useEffect` en un componente React para obtener datos de una API
  usando la función `getEmpresas`. Está pasando un objeto con una propiedad `token` a la función.
  Una vez que se obtienen los datos, se establece el estado del componente mediante la función
  `setEmpresas`. El `[]` como segundo argumento de `useEffect` significa que el efecto solo se
  ejecutará una vez, cuando se monte el componente. */
  useEffect(() => {
    const fetchData = async () => {
      const object = { token }
      const empresas = await getEmpresasAll(object)
      setEmpresas([empresas])

    }
    fetchData()
  }, [])

  /* El código anterior usa el enlace useEffect en un componente React para obtener datos de una API
  usando las funciones getAllRegistros y getGrupo. Está pasando un objeto con token, idPartida e
  idAgencias como parámetros a estas funciones. Luego, los datos obtenidos se utilizan para
  actualizar las variables de estado dataTable, totalEgreso, totalIngreso y grupo. El enlace
  useEffect se activa cada vez que cambian idPartida, idAgencias o getData. El código también incluye
  algunas declaraciones condicionales para manejar diferentes escenarios, como cuando los datos
  obtenidos están vacíos o cuando hay un mensaje de error. */
  useEffect(() => {

    const fetchData = async () => {
      const object = { token, idPartida, idAgencias }
      const data = await getAllRegistros(object)
      console.log("data")
      console.log(data)
      if (data.message == "Algo salio mal") {
        setDataTable([[]])

      } else if (data.length === 0) {
        const data = ([[]])
        setDataTable([[]])
        setTotalEgreso(0)
        setTotalIngreso(0)
      }
      else {
        const grupoObject = { idPartida, idAgencias, token }
        const grupoHex = await getGrupo(grupoObject)
        let convert = parseInt((grupoHex[0].grupo), 16)
        convert += 1
        let hex = convert.toString(16)
        while (hex.length < 4) {
          hex = "0" + hex;
        }

        setGrupo(hex)
        setDataTable(data)

        for (let i = 0; i < data.length; i++) {

          if (data[i].id_tipos_movimientos == 1) {
            egreso = egreso + data[i].monto
          } else {
            ingreso = ingreso + data[i].monto
          };

        }
        if (egreso.toFixed(2) != ingreso.toFixed(2)) {
          setColor('red')
        }else{
          setColor('black')
        }
        setTotalEgreso(egreso)
        setTotalIngreso(ingreso)
      }

    }
    fetchData()

  }, [idPartida, idAgencias, getData, helper])

  /**
   * La función envía un nuevo objeto "partida" al servidor con una descripción, fecha, token, agencia
   * y empresa, y actualiza la interfaz de usuario si tiene éxito.
   */
  async function sendPartida() {
    if (isLoading) return;

    setIsLoading(true);

    if (partida != "" && fecha != "" && idEmpresa != 0) {
      const [dia, mes, anio] = fecha.split('-');
      const fechaNueva = `${anio}-${mes}-${dia}`;


      const object = { descripcion: partida, fecha: fecha, token, agencia: idAgencias, empresa: idEmpresa }
      const result = await insertPartidas(object)
      const idPartida = await result.json()
      const search = {token, numeroPartida: idPartida}
      const res = await getPartidaByid(search)
      
      const response = {idPartida: idPartida, idAgencia: idAgencias, empresa: idEmpresa, fecha: res[0].fecha_creacion, agencia: res[0].nombre_agencia.agencia, empresaNombre: res[0].empresaModel.nombre_empresa, concepto: res[0].descripcion, comprobante: res[0].comprobante_mensual}

      setIdPartida(idPartida)
      setIdAgencia(response.idAgencia)
      setIdEmpresa(response.empresa)
      setFecha(response.fecha)
      setNombreAgencia(response.agencia)
      setEmpresaNombre(response.empresaNombre)
      setDescripcion(response.concepto)
      setComprobante_mensual(response.comprobante)
      if (result.status == 200) {
        setGetData(!getData)
        setShow(!show)
        setTotalEgreso(0)
        setTotalIngreso(0)
        setDataTable([[]])
      }

      setIsLoading(false);
    } else {

      Swal.fire({
        title: 'Error',
        text: 'Hay campos vacios',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });

      setIsLoading(false);
    }
  }
  function handleShowModal() {
    // console.log(idempresa)
    // const tipoEmpresa = await 
    setModalShow(true)

  }
  function handleShowGuardar() {
    setShowGuardar(true)

  }
  /**
   * Esta función formatea la fecha actual en una cadena con el formato "AAAA-MM-DD".
   */
  function changeFecha() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    const formattedDate = `${year}-${month}-${day}`;
    setFecha(formattedDate);
  }
  /**
   * Esta función recibe datos, los procesa, los guarda y actualiza el estado en consecuencia.
   */
  const receiveData = async (registro) => {
    const hexGrupo = grupo.toString(16).padStart(4, "0")
    const long = registro.montos.length
    let concepto = []
    let cuenta = []
    let movimientos = []
    let monto = []
    let estado = []
    for (let i = 0; i < long; i++) {
      concepto[i] = registro.concepto[i]
      cuenta[i] = registro.cuenta[i]
      movimientos[i] = registro.movimientos[i]
      monto[i] = registro.montos[i]
      estado[i] = 1


    }
    registro.estado = estado
    registro.grupo = hexGrupo
    registro.id_partidas = idPartida
    registro.agencia = idAgencias
    registro.fecha = fecha
    const object = { registro, token }
    const objectEmpresa = {
      token: token,
      empresa: idEmpresa,
    }
    const tipo_empresa = await getTipoEmpresa(objectEmpresa)
    if (tipo_empresa[0].tipo_empresa === 1) {

      var data = await saveRegistro(object)
    } else {

      var data = await saveRegistroOperativo(object)
    }
    if (data === 200) {
      setGetData(!getData)
      setModalShow(false)
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ocurrio un error inesperado',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  }

  /**
   * Esta función guarda una partida y verifica cualquier discrepancia en el ingreso total
   * (ingreso) y el egreso total (gasto).
   */
  const savePartida = async () => {
    if(!habilitarBoton) return

    setHabilitarBoton(false)
    if (totalEgreso.toFixed(2) != totalIngreso.toFixed(2)) {

      Swal.fire({
        title: 'Error',
        text: 'Se ha detectado descuadre',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } else {
      const object = { idPartida: idPartida, token: token }
      Swal.fire({
        title: 'Guardando',
        html: 'Espere un momento',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      const savePartida = await guardarPartida(object)
      Swal.close()
      if (savePartida === 200) {


        Swal.fire({
          text: 'Guardado con exito',
          icon: 'success',
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            setShowGuardar(false)
            setHelper(!helper)
          };
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Ocurrio un error inesperado',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    }
    setTimeout(() => {
      setHabilitarBoton(true)
    }, 2000)
  }
  /**
   * La función comprueba si hay un equilibrio entre el total de ingresos y gastos, y si lo hay, guarda
   * los datos y reinicia el formulario.
   */
  const saveExit = async () => {

    if (totalEgreso.toFixed(2) != totalIngreso.toFixed(2)) {

      Swal.fire({
        title: 'Error',
        text: 'Se ha detectado descuadre',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } else {
      const object = { idPartida: idPartida, token: token }
      const savePartida = await guardarPartida(object)
      if (savePartida === 200) {


        Swal.fire({
          text: 'Guardado con exito',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            setShowGuardar(false)
            setDataTable([[]])
            setTotalEgreso(0)
            setTotalIngreso(0)
            setIdAgencia(0)
            setIdEmpresa(0)
            setIdPartida(0)
            setShow(!show)

          };
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Ocurrio un error inesperado',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  }
  /**
   * La función "buscarPartida" establece el estado de "showBuscar" a verdadero.
   */
  const buscarPartida = async () => {
    setShowBuscar(true)
  }

  /**
   * Esta función establece las variables de estado para la identificación de un juego y la
   * identificación de la agencia, y alterna la visibilidad de ciertos componentes.
   */
  const cargarPartida = async (registro) => {
    console.log(registro)
    setComprobante_mensual(registro.comprobante)
    setIdPartida(registro.idPartida)
    setIdAgencia(registro.idAgencia)
    setIdEmpresa(registro.empresa)
    setFecha(registro.fecha)
    setNombreAgencia(registro.agencia)
    setEmpresaNombre(registro.empresaNombre)
    setDescripcion(registro.concepto)
    setShowBuscar(false)
    setShow(true)
  }
  /**
   * La función establece las variables de estado "showEditar" e "idRegistro" en verdadero y el valor
   * pasado de "idRegistro", respectivamente.
   */
  const loadEditar = (idRegistro) => {
    setShowEditar(true)
    setIdRegistro(idRegistro)
  }
  /**
   * La función "editarRegistro" establece el estado de "showEditar" en falso y activa una nueva
   * recuperación de datos al establecer el estado de "getData" en verdadero.
   */
  const editarRegistro = () => {
    setShowEditar(false)
    setGetData(!getData)

  }
  /**
   * La función establece las variables de estado "showEliminar" e "idRegistro" en verdadero y el valor
   * "idRegistro" pasado, respectivamente.
   */
  const loadEliminar = (idRegistro) => {
    setShowEliminar(true)
    setIdRegistro(idRegistro)
  }
  /**
   * La función "eliminarRegistro" establece "showEliminar" en falso y activa una actualización de
   * recuperación de datos.
   */
  const eliminarRegistro = () => {
    setShowEliminar(false)
    setGetData(!getData)
  }
  const addRow = async () => {
    setNewRow(true)
    console.log(newRow)
  }
  const sendRegistro = async () => {
   if(abono != 0 && cargo != 0){
    Swal.fire({
      title: 'Error',
      text: 'Solo puede haber un cargo o un abono',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
   }else if(cuenta == 0 || concepto == '' || (cargo == 0 && abono == 0)){
    Swal.fire({
      title: 'Error',
      text: 'Hay campos vacios',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
   
   }
   else{
  
    const object = {
      idPartida: idPartida,
      idAgencia: idAgencias < 10 ? idAgencias.slice(1): idAgencias, 
      idEmpresa: idEmpresa,
      idCuenta: cuenta,
      concepto: concepto,
      cargo: cargo,
      abono: abono,
      grupo: '0000',
      token: token,
      tipo: abono != 0 ? 2 : 1,
      fecha: fecha
    }
    const result = await agregarRegistroUnico(object)  
    if(result === 200){
      setNewRow(false)
      setHelper(!helper)
      Swal.fire({
        text: 'Guardado con exito',
        icon: 'success',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
      })
      setAbono(0)
      setCargo(0)
      setCuenta(0)
      setConcepto('')

  }else{
    Swal.fire({
      title: 'Error',
      text: 'Ocurrio un error inesperado',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }
  }
  }
  const loadRevertir = async (idRegistro) => {
    Swal.fire({
      title: '¿Estas seguro?',
      text: "¡Se revertira el movimiento de cuenta!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const object = {
          token: token,
          idRegistro: idRegistro,
          idAgencia: idAgencias < 10 ? idAgencias.slice(1): idAgencias,
          idEmpresa: idEmpresa,
          
        }
        const result = await revertirRegistro(object)
        if (result === 200) {
          Swal.fire({
            text: 'Movimiento revertido con exito',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setHelper(!helper)
            };
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Ocurrio un error inesperado',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      }
    })
  }

  /* El código anterior es un componente de React que genera un formulario para crear y administrar
  asientos contables (partidas). Incluye varios modos para editar, eliminar y buscar entradas, así
  como una tabla para mostrar las entradas existentes. El componente también calcula y muestra los
  ingresos y gastos totales de cada entrada. */
  return (
    <>

      <NavBar></NavBar>
      <Modals
        show={modalShow}
        onHide={() => setModalShow(false)}
        onChange={receiveData}
        partida={idPartida}
        agencia={idAgencias}
        empresa={idEmpresa}
      />
      <AceptarModal
        show={showGuardar}
        onHide={() => setShowGuardar(false)}
        save={savePartida}
        saveExit={saveExit}
      />
      <BuscarModal
        show={showBuscar}
        onHide={() => setShowBuscar(false)}
        buscar={cargarPartida}
        tipo_empresa={1}
      ></BuscarModal>
      <EditarModal
        show={showEditar}
        onHide={() => setShowEditar(false)}
        id={idRegistro}
        agencia={idAgencias}
        save={editarRegistro}
      ></EditarModal>
      <EliminarModal
        show={showEliminar}
        onHide={() => setShowEliminar(false)}
        id={idRegistro}
        agencia={idAgencias}
        partida={idPartida}
        save={eliminarRegistro}
        token={token}
      ></EliminarModal>
      <div style={{ backgroundColor: "#" }}>
        {!show && (
          <div className="m-5" >

            <Form >
              <Row className="d-flex align-items-center">
                <Col>
                  <Form.Label>Descripcion de la partida</Form.Label>
                  <Form.Control placeholder="Descripcion" value={partida} onChange={(e) => setPartida(e.target.value)}
                    disabled={show ? true : false}
                  />
                </Col>
                <Col>
                  <Form.Label>Fecha de creacion</Form.Label>
                  <InputGroup>
                    <Form.Control placeholder="Last name" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)}
                      disabled={show ? true : false} />
                    <Button variant="primary" onClick={changeFecha}><BsFillClockFill></BsFillClockFill></Button>
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Label>Empresa</Form.Label>
                  <Form.Select placeholder="Empresa" type="Text" onChange={(e) => setIdEmpresa(e.target.value)}
                    disabled={show ? true : false}
                  >
                    <option value="0">Seleccione una opcion</option>
                    {
                      empresas[0].map((props, index) => (
                        <option key={props.id_empresa} value={props.id_empresa}>{props.nombre_empresa}</option>
                      ))
                    }
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Label>Agencia</Form.Label>
                  <InputGroup>
                    <Form.Select placeholder="Agencia" type="text" onChange={(e) => setIdAgencia(e.target.value)}
                      disabled={show ? true : false}


                    >
                      <option value='0'>Seleccione una opcion</option>
                      {
                        agencias[0].map((props, index) => (
                          <option key={props.id_agencia} value={props.id_agencia}>{props.agencia}</option>
                        ))
                      }
                    </Form.Select>

                  </InputGroup>
                </Col>

                <Col>
                  <br />
                  <InputGroup>
                    {!show && (<Button variant="success" onClick={sendPartida} disabled={isLoading} className="my-1"> {isLoading ? 'Cargando...' : 'Crear partida'}</Button>)}
                  </InputGroup>

                </Col>
              </Row>
            </Form>
          </div>
        )}
        <div style={{ margin: "auto", marginLeft: "2%", marginRight: "2%", backgroundColor: "#dae7e4", borderRadius: "10px" }} >
          <div style={{ marginTop: "2%", marginBottom: "2%", width: "50%", margin: "0 auto", textAlign: "center" }}>

            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", margin: "2%" }}>
              {show && (
                <Button className="mx-2 my-3" variant="success" onClick={handleShowGuardar}>
                  <BiSave></BiSave> Guardar partida
                </Button>
              )}
              <Button className="mx-2 my-3" onClick={buscarPartida}>
                <AiOutlineFolderOpen></AiOutlineFolderOpen> Buscar partida
              </Button>
              {show && (
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                  <Button className="mx-2" variant="warning" onClick={handleShowModal}>
                    <BsFillClipboard2PlusFill></BsFillClipboard2PlusFill> Crear registro
                  </Button>
                </div>
              )}
            </div>

          </div>
          <hr />


          {show && (
            <>
              
              <h2 style={{ textAlign: "center", color: "black",marginLeft: "-150px" }}>Partida #{comprobante_mensual}</h2>
              <Table>
                <tr>
                  <td style={{width: "600px"}}>
                    <h5 style={{ textAlign: "left", color: "black", marginLeft: "200px"}}>Fecha: {fecha}</h5>
                  </td>
                  <td>
                    <h5 style={{ textAlign: "left", color: "black" }}>Agencia: {nombreAgencia}</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5 style={{ textAlign: "left", color: "black", marginLeft: "200px" }}>Empresa: {empresaNombre}</h5>
                  </td>
                  <td>
                    <h5 style={{ textAlign: "left", color: "black" }}>Descripcion: {descripcion}</h5>
                  </td>
                </tr>
              </Table>
             

              <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>

                <Table striped bordered hover size="sm" className="my-3 mx-4 my-table table-rounded">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="col-sm-3">Cuenta contable</th>
                      <th className="col-sm-3">Concepto</th>
                      <th>Cargo</th>
                      <th>Abono</th>
                      <th>Tipo registro</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>

                    <>
                      {dataTable.length != 0 && dataTable[0].length != 0 ?
                        dataTable.map((props, index) => (

                          <tr>

                            <td>{index + 1}</td>
                            <td>{props.id_cuenta_contable}</td>
                            <td>{props.concepto}</td>
                            {props.id_tipos_movimientos === 1 ?
                              <>

                                <td>{convertirNumeroADinero(props.monto)}</td> </> : <td>$0</td>
                            }
                            {props.id_tipos_movimientos === 2 ?
                              <>

                                <td>{convertirNumeroADinero(props.monto)}</td></> : <td>$0</td>
                            }

                          
                            <td>{props.tipo}</td>





                            <td>

                              {(props.editable === 1 && props.estado !== 3) ?
                                <>
                                  <Button className="m-1" variant="danger" onClick={() => loadEliminar(props.id_registro)}>Eliminar</Button>
                                  <Button className="m-1" onClick={() => loadEditar(props.id_registro)}>Editar</Button>
                                </> : <>
                                <Button className="m-1" variant="danger" onClick={() => loadRevertir(props.id_registro)}>Revertir movimiento</Button>
                                </>
                              }
                            </td>
                            {index === dataTable.length - 1 ?

                              <td><Button className="m-1" variant="primary" onClick={addRow}>+</Button></td> : <></>
                            }
                          </tr>
                        ))

                        :
                        <tr>
                          <td colSpan={9} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <p style={{ color: "black" }}>¡Partida vacía!</p>
                          </td>
                          <td><Button className="m-1" variant="primary" onClick={addRow}>+</Button></td> 
                        </tr>

                      }
                      {newRow ?
                        <tr>
                          <td></td>
                          <td>
                            <Autocomplete

                              disablePortal
                              onChange={handleChangeCuenta}
                              id='0'
                              options={cuentas[0]}
                              fullWidth
                              renderInput={(params) => <TextField {...params} label="Cuenta"
                                id='0'
                                style={{ width: '100%' }}


                              />}
                            />
                          </td>
                          <td>
                            <Autocomplete

                              freeSolo={true}
                              disablePortal
                              onChange={handleChange}
                              id='0'
                              options={option[0]}

                              fullWidth
                              renderInput={(params) => <TextField {...params} label="Conceptos"
                                id='0'
                                style={{ width: '100%' }}

                                onChange={handleChangeConcepto}
                              />}
                            />
                          </td>
                          <td>
                            <InputGroup>
                              <CurrencyInput
                                id="0"
                                name="0"
                                className='input-bootstrap'
                                placeholder="$0.00"
                                decimalsLimit={2}
                                onValueChange={(value, name) => handleChangeCargo(value, name)}
                                decimalSeparator="."
                              />
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup>
                              <CurrencyInput
                                id="0"
                                name="0"
                                className='input-bootstrap'
                                placeholder="$0.00"
                                decimalsLimit={2}
                                onValueChange={(value, name) => handleChangeAbono(value, name)}
                                decimalSeparator="."
                              />
                            </InputGroup>
                          </td>
                          <td>000</td>
                          <td>Registro unico</td>
                          <td>
                            <Button variant="success" onClick={() => sendRegistro()}>Guardar</Button>
                          </td>
                        </tr>
                        : <></>
                      }
                    </>



                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td style = {{color}}>{convertirNumeroADinero(totalEgreso)}</td>
                      <td style = {{color}}>{convertirNumeroADinero(totalIngreso)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>


              </div>

            </>
          )}
        </div>
      </div>

    </>
  )
}