import { useState } from "react";
import { NavBar } from "../../components/navbar";
import { BuscadorPartidaDiario } from "../../components/partidasComponentes/BuscadorPartidaDiario";
import { Button, Table } from "react-bootstrap";

export function PartidaDiario() {
    const [partidaDiario, setPartidaDiario] = useState([]);
    const [show, setShow] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [idAgencia, setIdAgencia] = useState(null);
    const [total, setTotal] = useState(0);
    const [fecha, setFecha] = useState(null);
    const handleData = (data, fecha) => {
        setFecha(fecha);
        let total = 0;
        data.forEach((element) => {
            total += parseFloat(element.monto);
        });
        setTotal(total);
        setPartidaDiario(data);
        setShow(true);
        console.log(data);
    }
    const actualizarData = () => {
        setShow(false);
    }

    if (show) {
        return (
            <div>
                <NavBar />
                <>

                    <h2 style={{ textAlign: "center", color: "black", marginTop:"20px" }}>Partida del dia {fecha}</h2>
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <Table striped bordered hover size="sm" className="my-3 mx-4 my-table table-rounded">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th className="col-sm-3">Concepto</th>
                                    <th className="col-sm-3">Cuenta contable</th>
                                    <th className="col-sm-3">Contra cuenta</th>
                                    <th>Monto</th>
                                </tr>
                            </thead>
                            <tbody>

                                <>
                                    {partidaDiario.length != 0 ?
                                        partidaDiario.map((props, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{props.concepto}</td>
                                                <td>{props.cuenta}</td>
                                                <td>{props.contra_cuenta}</td>
                                                <td>{props.monto}</td>
                                            </tr>
                                        ))
                                        : null}
                                </>


                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>${total.toFixed(2)}</td>

                                </tr>
                            </tfoot>
                        </Table>


                    </div>

                </>
            </div>
        );
    } else {
        return (
            <>
                <NavBar></NavBar>
                <BuscadorPartidaDiario
                    onFinish={handleData}
                    handler={actualizarData}
                    empresa={(e) => { setEmpresa(e) }}
                    agencia={(e) => { setIdAgencia(e) }}
                ></BuscadorPartidaDiario>
            </>
        )
    }
}