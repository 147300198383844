import { useEffect, useState } from "react";
import { Buscador } from "../../components/Planillas/Buscador";
import { NavBar } from "../../components/navbar";
import { eliminarPlanilla, getCuentaEmpresas, getPartidaPlanilla, savePartidaPlanilla, savePlanilla, updateAccount } from "../../services/partidas/partidas";
import { IngresarDatos } from "../../components/Planillas/IngresarDatos";
import Swal from "sweetalert2";
import { Badge, Button, Form, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import { AiFillQuestionCircle } from "react-icons/ai";

export function Viaticos(){
    const [show, setShow] = useState(false);
    const [idEmpresa, setIdEmpresa] = useState("");
    const [idAgencia, setIdAgencia] = useState("");
    const [fecha, setFecha] = useState("");
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [cuentas, setCuentas] = useState([])
    const [cuenta, setCuenta] = useState("")
    const [idCuenta, setIdCuenta] = useState("")
    const [tipo, setTipo] = useState("")
    const [showExcel, setShowExcel] = useState(false)
    const [showGoBack, setShowGoBack] = useState(false)
    const [totalCargo, setTotalCargo] = useState(0)
    const [totalAbono, setTotalAbono] = useState(0)

    const handleData = (idEmpresa, idAgencia, fecha) => {
        setShow(true)
        setIdEmpresa(idEmpresa)
        setIdAgencia(idAgencia)
        setFecha(fecha)
    }

    useEffect(() => {
        const getData = async () => {
            if (show) {
                const object = {
                    idEmpresa: idEmpresa,
                    idAgencia: idAgencia,
                    fecha: fecha,
                    token: localStorage.getItem("token"),
                    tipo: 2
                }
                const response = await getPartidaPlanilla(object)

                let totalCargo = 0
                let totalAbono = 0
                response.map((item) => {
                    totalCargo = totalCargo + item.valor_cargo
                    totalAbono = totalAbono + item.valor_abono
                  
                })
                setTotalCargo(totalCargo.toFixed(2))
                setTotalAbono(totalAbono.toFixed(2))
               if(response.length === 0){
                setShowExcel(true)
                }else{
                setShowExcel(false)
                }
                setData(response)
            }
        }
        getData()

        const fetchDataCuenta = async () => {

            const object = {
                token: localStorage.getItem("token"),
                empresa: idEmpresa,
                agencia: idAgencia
            }
            const cuentas = await getCuentaEmpresas(object)

            let options = []
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta + "-" + index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }

        fetchDataCuenta()


    }, [show, reload])

    const recieveData = async (data) => {
        const object = {
            idEmpresa: idEmpresa,
            idAgencia: idAgencia,
            fecha: fecha,
            data: data,
            token: localStorage.getItem("token"),
            tipo: 2
        }
        const saveData = await savePartidaPlanilla(object)

        if (saveData === 200) {
            Swal.fire({
                title: 'Datos guardados',
                text: 'Los datos se guardaron correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    setReload(!reload)
                    setShowExcel(false)
                }
            })
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Ocurrio un error al guardar los datos',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    const changeCuenta = (id) => {
        console.log(id)
        setIdCuenta(id)
        setShowModal(true)
    }
    const savePartida = async () => {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const object = {
            idAgencia: idAgencia,
            idEmpresa: idEmpresa,
            fecha: fecha,
            token: localStorage.getItem("token"),
            tipo: 2
        }
        const save = await savePlanilla(object)
        Swal.close()
        if (save === 200) {
            Swal.fire({
                title: 'Datos guardados',
                text: 'Los datos se guardaron correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    setReload(!reload)
                }
            })
        } else if (save === 401) {
            Swal.fire({
                title: 'Advertencia',
                text: 'No hay datos para guardar',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            })
        }
        else {
            Swal.fire({
                title: 'Error',
                text: 'Ocurrio un error al guardar los datos',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })

        }
    }
    const rejectPartida = async () => {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const object = {
            idAgencia: idAgencia,
            idEmpresa: idEmpresa,
            fecha: fecha,
            token: localStorage.getItem("token"),
            tipo: 2
        }
        const save = await eliminarPlanilla(object)
        console.log(save)
        Swal.close()
        if (save === 200) {
            Swal.fire({
                title: 'Datos eliminados',
                text: 'Los datos se eliminaron correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    setReload(!reload)
                }
            })
        } 
        else {
            Swal.fire({
                title: 'Error',
                text: 'Ocurrio un error al guardar los datos',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })

        }
    }
    const changeAccount = async () => {
        const object = {
            id: idCuenta,
            cuenta: cuenta[0],
            token: localStorage.getItem("token"),
            tipo: tipo,
            tipo_registro: 2
        }
        if (tipo !== "") {
            const response = await updateAccount(object)
            console.log(response)
            if (response === 200) {
                Swal.fire({
                    title: 'Datos guardados',
                    text: 'Los datos se guardaron correctamente',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setReload(!reload)
                        setTipo("")
                        setShowModal(false)
                        setIdCuenta("")
                    }
                })
            } else {
                setTipo("")

                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrio un error al guardar los datos',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Debe seleccionar el tipo de cuenta',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })

        }
    }


    if (show) {
        return (
            <>
                <NavBar />
                <div>
                    {showExcel  === true ?
                        <IngresarDatos
                            fecha={fecha}
                            send={recieveData}
                            leyenda={`Ingresar datos para la fecha ${fecha}` }
                            showButton={showGoBack}
                            goBack = {() => setShowExcel(false)}
                        />
                        :

                        <>
                            <div style={{ margin: '60px', padding: "10px", textAlign: 'center', backgroundColor: "#b4cbd6", borderRadius: "15px" }} className="boxShadow">
                                <h1 style={{ marginTop: "15px", color: "black" }}>Partida de viaticos para la fecha {fecha}</h1>
                                <Button style={{ margin: "10px" }} variant="success" onClick={savePartida}>Guardar partida</Button>
                                <Button style={{ margin: "10px" }} variant="warning" onClick={() => {setShowExcel(true); setShowGoBack(true)}}>Subir excel</Button>

                                <Button variant="danger" onClick={rejectPartida}>Rechazar partida</Button>        <OverlayTrigger
                                    style={{ margin: "10px" }}
                                    placement="top"
                                    overlay={<Tooltip id="tooltip">Se rechazaran unicamente las partidas que no tengan movimiento ingresado</Tooltip>}
                                //show={showTooltip}
                                >
                                    <Button variant="danger"> <AiFillQuestionCircle /> </Button>
                                </OverlayTrigger>

                                <Table className="custom-table" bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Valor cargo</th>
                                            <th>Cuenta cargo</th>
                                            <th>Valor abono</th>
                                            <th>Cuenta abono</th>
                                            <th>Acciones</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                         
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.concepto}</td>
                                                        <td>{item.valor_cargo.toFixed(2)}</td>
                                                        <td>{item.cuenta_cargo !== null ? item.cuenta_cargo : 'Sin cuenta asignada'}</td>
                                                        <td>{item.valor_abono.toFixed(2)}</td>
                                                        <td>{item.cuenta_abono !== null ? item.cuenta_abono : 'Sin cuenta asignada'}</td>
                                                        {item.estado === 1 ? <td><Button onClick={() => changeCuenta(item.id_viaticos)}>Actualizar cuenta contable</Button></td> : <td><Badge bg="success">Movimiento ingresado</Badge></td>}

                                                    </tr>
                                                </>
                                            )

                                        })}
                                        <tr>
                                            <td>Total:</td>
                                            <td>{totalCargo}</td>
                                            <td></td>
                                            <td>{totalAbono}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                        </>}
                </div>
                {/* MODAL PARA AGREGAR CUENTAS  A LOS PAGOS ENTRE AGENCIAS */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingrese la cuenta pago entre agencia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <AutocompleteAlter
                            cuentas={cuentas[0]}
                            empresa={idEmpresa}
                            agencia={data.length != 0 ? data[0].agencia.id_agencia : 0}
                            onChange={(e) => setCuenta(e)}
                        />

                        <Form.Label>Tipo de cuenta</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setTipo(e.target.value)} value={tipo}>
                            <option value=''>Tipo de cuenta</option>
                            <option value="1">Cargo</option>
                            <option value="2">Abono</option>
                        </Form.Select>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={changeAccount}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    else {
        return (
            <>
                <NavBar />
                <Buscador
                    onChange={handleData}
                    tipo={"viaticos"} />
            </>
        )
    }
}