import { Button, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { asignarSaldoCuentas, editarSaldoCuentas, eliminarSaldoCuentas, getRegistro, getSaldosSubCuentas } from "../../services/CuentaContable/CuentaGeneral";
import CurrencyInput from "react-currency-input-field";
import { getCuentaEmpresas } from "../../services/partidas/partidas";
import { AgenciasSelect } from "../../components/UTILS/AgenciasSelect";
import Swal from "sweetalert2";

export function AsignarSaldo() {
    const [cuentaAgregar, setCuentaAgregar] = useState(null)
    const [cuentas, setCuentas] = useState([[]])
    const [saldos, setSaldos] = useState([])
    const [show, setShow] = useState(false);
    const [fechaAgregar, setFechaAgregar] = useState(null)
    const [monto, setMonto] = useState(null)
    const [agenciaBuscar, setAgenciaBuscar] = useState(null)
    const [agenciaAgregar, setAgenciaAgregar] = useState(null)
    const [fechaBuscar, setFechaBuscar] = useState(null)
    const [cuentaBuscar, setCuentaBuscar] = useState(null)

    const [agenciaEditar, setAgenciaEditar] = useState(null)
    const [idCuentaEditar, setIdCuentaEditar] = useState(null)
    const [cuentaEditar, setCuentaEditar] = useState(null)
    const [fechaEditar, setFechaEditar] = useState(null)
    const [montoEditar, setMontoEditar] = useState(null)
    const [showEditar, setShowEditar] = useState(false)
    const [aux, setAux] = useState(false)

    useEffect(() => {
        async function getSaldos() {
            const token = localStorage.getItem('token')
            const fechaActual = new Date();
            const añoMes = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1).toString().padStart(2, '0')}`;
            const object = {
                token: token,
                mes: añoMes,
                agencia: null,
                cuenta: null
            }
            const saldos = await getSaldosSubCuentas(object)
            
            setSaldos(saldos)
        }
        getSaldos()

        async function getCuentas() {
            const token = localStorage.getItem('token')
            const object = {
                token: token,
                empresa: 1,
                agencia: ''
            }
            const cuentas = await getCuentaEmpresas(object)
            let options = []
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta + "-" + index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
        getCuentas()

    }, [aux])
    const handleChangeCuenta = (e, value) => {
        setCuentaAgregar(value.value)

    }
    const handleChangeMonto = (e, value) => {
        
        setMonto(e)

    }
    const editarMonto = (e, value) => {
        
        setMontoEditar(e)
    }
    const handleChangeCuentaBuscar = (e, value) => {
        setCuentaBuscar(value.value)
    }
    const handleBuscar = async () => {
        const token = localStorage.getItem('token')
        const fechaActual = new Date();
        const añoMes = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1).toString().padStart(2, '0')}`;
        const object = {
            token: token,
            mes: fechaBuscar,
            agencia: agenciaBuscar,
            cuenta: cuentaBuscar
        }
        
        const saldos = await getSaldosSubCuentas(object)
        
        setSaldos(saldos)
    }
    const agregarSaldoCuenta = async () => {
        const token = localStorage.getItem('token')
        const fechaActual = new Date();
        const añoMes = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1).toString().padStart(2, '0')}`;
        const object = {
            token: token,
            mes: fechaAgregar,
            agencia: agenciaAgregar,
            cuenta: cuentaAgregar,
            saldo: monto
        }
        const res = await asignarSaldoCuentas(object)
        if (res === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Saldo asignado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            handleBuscar()
            setShow(false)
            setAux(!aux)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error al asignar saldo',
                showConfirmButton: false,
                timer: 1500
            })
        }
        
    }
    const obtenerRegistro = async (id) => {
        const object = {
            token: localStorage.getItem('token'),
            id: id
        }
        const res = await getRegistro(object)
        setIdCuentaEditar(res.id)
        setAgenciaEditar(res.agenciaFK.agencia)
        setCuentaEditar(res.cuenta)
        setFechaEditar(res.mes)
        setMontoEditar(res.saldo)
        setShowEditar(true)
        
    }

    const editarSaldoCuenta = async () => {
        const object = {
            token: localStorage.getItem('token'),
            id: idCuentaEditar,
            saldo: montoEditar
        }
        const res = await editarSaldoCuentas(object)
        
        if (res === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Saldo editado correctamente',
                showConfirmButton: true,
                timer: 1500
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error al editar saldo',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const handlerEliminarSaldo = async (id) => {
        const object = {
            token: localStorage.getItem('token'),
            id: id
        }
        Swal.fire({
            title: '¿Está seguro de eliminar el saldo?',
            showDenyButton: true,
            confirmButtonText: `Eliminar`,
            denyButtonText: `No eliminar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
        const res = await eliminarSaldoCuentas(object)
        if (res === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Saldo eliminado correctamente',
                showConfirmButton: true,
                timer: 1500
            }).then((result) => {
                if (result.isConfirmed) {
                  setAux(!aux)
                }
            })

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error al eliminar saldo',
                showConfirmButton: false,
                timer: 1500
            })
        }
            }else{
                return
            }
        })
       
    }

    return (
        <>
            <NavBar />
            <div style={{ margin: '60px', padding: "10px", textAlign: 'center', backgroundColor: "#b4cbd6", borderRadius: "15px" }} className="boxShadow">
                <h1>Asignar Saldo</h1>
                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}>
                    <div className="row">
                        <div className="col-md-4 mt-4" >
                            <AgenciasSelect
                                onChange={(e) => { setAgenciaBuscar(e) }}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <Autocomplete
                                style={{ backgroundColor: "#fff" }}
                                disablePortal
                                onChange={handleChangeCuentaBuscar}
                                id='0'
                                options={cuentas[0]}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Cuenta"
                                    id='0'
                                    style={{ width: '100%' }}


                                />}
                            />
                        </div>
                        <div className="col-md-4 mt-1">
                            <Form.Label>Seleccione el mes</Form.Label>
                            <Form.Control type="month" onChange={(e) => setFechaBuscar(e.target.value)} />
                        </div>
                        <div className="col-md-1 mt-2">
                            <button className="btn btn-primary mt-4" style={{ width: "100px", height: "40px", marginTop: "30px", marginLeft: "10px" }} type="submit" onClick={handleBuscar}>Buscar</button>
                        </div>
                        <div className="col-md-1 mt-2">
                            <button className="btn btn-success mt-4" style={{ width: "100px", height: "40px", marginTop: "30px", marginLeft: "10px" }} onClick={() => { setShow(true) }}>Agregar</button>
                        </div>
                    </div>
                </div>
                <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
                    <Table className="my-table printTable" >
                        <thead>
                            <tr>
                                <th>Agencia</th>
                                <th style={{ width: "200px" }}>Cuenta</th>
                                <th >Fecha</th>
                                <th>Saldo</th>
                                <th>Acciones</th>

                            </tr>
                        </thead>
                        <tbody>

                            {saldos

                                .map((props) => (
                                    <tr key={props.id}>
                                        <td>{props.agenciaFK.agencia}</td>
                                        <td>{props.cuenta}</td>
                                        <td>{props.mes}</td>
                                        <td>{props.saldo}</td>
                                        <td>
                                            <button className="btn btn-success" style={{ marginRight: "5px" }} onClick={() => obtenerRegistro(props.id)}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => handlerEliminarSaldo(props.id)}>Eliminar</button>
                                        </td>

                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* MODAL PARA AGREGAR */}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar saldo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Agencia</label>
                            <AgenciasSelect
                                onChange={(e) => { setAgenciaAgregar(e) }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>Cuenta</label>
                            <Autocomplete
                                style={{ backgroundColor: "#fff" }}
                                disablePortal
                                onChange={handleChangeCuenta}
                                id='0'
                                options={cuentas[0]}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Cuenta"
                                    id='0'
                                    style={{ width: '100%' }}


                                />}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Label>Seleccione el mes</Form.Label>
                            <Form.Control type="month" onChange={(e) => setFechaAgregar(e.target.value)} />
                        </div>
                        <div className="col-md-12">
                            <label>Saldo</label>
                            <InputGroup>
                                <CurrencyInput
                                    id="0"
                                    name="0"
                                    className='input-bootstrap'
                                    placeholder="$0.00"
                                    decimalsLimit={2}
                                    onValueChange={(value, name) => handleChangeMonto(value, name)}
                                    decimalSeparator="."
                                />
                            </InputGroup>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={agregarSaldoCuenta}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL PARA EDITAR */}
            <Modal show={showEditar} onHide={() => setShowEditar(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar saldo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Agencia</label>
                            <p style={{ color: "black" }}>{agenciaEditar}</p>
                        </div>
                        <div className="col-md-12">
                            <label>Cuenta</label>
                            <p style={{ color: "black" }}>{cuentaEditar}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Label>Seleccione el mes</Form.Label>
                            <Form.Control type="month" value={fechaEditar} disabled />
                        </div>
                        <div className="col-md-12">
                            <label>Saldo</label>
                            <InputGroup>
                                <CurrencyInput
                                    id="0"
                                    name="0"
                                    className='input-bootstrap'
                                    placeholder="$0.00"
                                    decimalsLimit={2}
                                    value={montoEditar}
                                    onValueChange={(value, name) => editarMonto(value, name)}
                                    decimalSeparator="."
                                />
                            </InputGroup>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditar(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={editarSaldoCuenta}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}