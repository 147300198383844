import { Button, Container, Form, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import { useEffect, useState } from "react";
import { getAgenciasXEmpresa } from "../../services/geolocalizacion/places";
import { eliminarPartida, getRegistrosPartidas } from "../../services/reportes/reportePartidas";
import Swal from "sweetalert2";

export function ReportePartidas(){
    const [hasData, sethasData] = useState(false)
    const [data, setData] = useState([])
    const [nombreEmpresa, setNombreEmpresa] = useState('')
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFinal, setFechaFinal] = useState('')
    const [idAgencia, setIdAgencia] = useState('')
    const [agencias, setAgencias] = useState([[]])
    const [idEmpresa, setIdEmpresa] = useState(null)
    const token = localStorage.getItem('token')
    useEffect(() => {
        if (idEmpresa != 0) {
            const fetchData = async () => {
              const object = { token, idEmpresa }
              const tipos = await getAgenciasXEmpresa(object)
              setAgencias([tipos])
            }
            fetchData()
          } else {
            const tipos = []
            setAgencias([tipos])
          }
    }, [idEmpresa])


    const sendBuscar = async(e) => {
        e.preventDefault()
        
        const object = {
            empresa: idEmpresa,
            fechaInicio: fechaInicio,
            fechaFinal: fechaFinal,
            agencia: idAgencia == '00' ? 0: idAgencia,
            token: token
        }
        const data = await getRegistrosPartidas(object)
        setData(data)
        sethasData(true)
        console.log(data)
        console.log(object)

    }
    

    const handleChange = (e) => {
        setIdEmpresa(e)
    }
    const handlerEliminar = async(id) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esta accion",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

            confirmButtonText: 'Si, eliminar'
        }).then(async (result) => {
            if (result.isConfirmed) {
              
                const object = {
                    id_partida: id,
                    id_agencia: idAgencia == '00' ? 0: idAgencia,
                    token: token
                }
              const result = await eliminarPartida(object)
              if(result.status == 400){
                Swal.fire(
                  'ERROR',
                  'No se puede eliminar esta partida porque tiene registros',
                  'error'
              )
              }else if(result.status == 200){
                Swal.fire(
                  'Eliminado!',
                  'La partida ha sido eliminada',
                  'success'
              )
              }else{
                Swal.fire(
                  'ERROR',
                  'Error interno del servidor',
                  'error'
              )
              }
              console.log(result)
              const objectData = {
                  empresa: idEmpresa,
                  fechaInicio: fechaInicio,
                  fechaFinal: fechaFinal,
                  agencia: idAgencia == '00' ? 0: idAgencia,
                  token: token
              }
              const data = await getRegistrosPartidas(objectData)
              setData(data)
            }
        })
    }


    if(hasData){
    return(
        <>
        <NavBar/>
        <h1>Reporte de partidas creadas</h1>
          <div style={{ margin: '60px',padding:"10px", textAlign: 'center', backgroundColor:"#b4cbd6", borderRadius:"15px" }} className="boxShadow">
          <Table  className="custom-table" bordered  hover>
              <thead>
                <tr>
                  <th># partida</th>
                  <th style={{ width: "200px" }}>Descripcion</th>
                  <th >Fecha de creacion</th>
                  <th>Cantidad de registros dentro de esta partida</th>
                  <th>Acciones</th>


                </tr>
              </thead>
              <tbody>

                {data

                  .map((props) => (
                    <tr>
                      <td>{props.id_partida}</td>
                      <td>{props.descripcion}</td>
                      <td>{props.fecha_creacion}</td>
                      <td>{props.registros}</td>
                     <td><Button variant="danger" onClick={() => {handlerEliminar(props.id_partida)}}>Eliminar</Button> </td>

                    </tr>
                  ))}
              </tbody>
            </Table>
            </div>
        </>
    )
    }else{
        return (
            <>
            <NavBar />
            <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
              <Form onSubmit={sendBuscar}>
    
                <center> <h2>Reporte de partidas</h2> </center>
    
                <EmpresasSelect
                  onChange={(e) => handleChange(e)}
                  empresa={(e) => setNombreEmpresa(e)}
                />
            <Form.Label>Agencia</Form.Label>
            <Form.Select placeholder="Empresa" type="Text" onChange={(e) => setIdAgencia(e.target.value)}>
            <option value=''>Seleccione una opcion</option>
                {
                  agencias[0].map((props, index) => (
                    <option key={props.id_agencia} value={props.id_agencia}>{props.agencia}</option>
                  ))
                }
            </Form.Select>
    
    
    
    
    
                <Form.Group >
                  <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia de inicio</Form.Label>
                  <Form.Control type="date" onChange={(e) => setFechaInicio(e.target.value)} />
    
                  <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia final</Form.Label>
                  <Form.Control type="date" onChange={(e) => setFechaFinal(e.target.value)} />
                </Form.Group>
    
    
                <Button variant="primary" type="submit" className='my-2' >
                  Buscar
                </Button>
    
              </Form>
            </Container>
          </>
    
        )
    }
}