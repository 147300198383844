import { NavBar } from "../../components/navbar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Swal from "sweetalert2";
import '../../css/Tablas.css';
import '../../css/Modal.css';
import { GetCuentas } from "../../services/CuentaContable/GetCuentas";
import { DeleteCuentas } from "../../services/CuentaContable/DeleteCuentas";
import { SaveCuentas } from  "../../services/CuentaContable/SaveCuentas";
import { UpdateCuentas } from "../../services/CuentaContable/UpdateCuentas";
import { GetTipo } from "../../services/CuentaContable/GetTipo";
import { GetNivel } from "../../services/CuentaContable/GetNivel";
import { redirect } from "react-router-dom";
import { solicitarCambio } from "../../services/CuentaContable/SolicitarCambioSaldo";
import { verificarSoclitud } from "../../services/CuentaContable/SolicitarCambioSaldo";
import {AiFillFileExcel} from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { saveCuentaExcel } from "../../services/CuentaContable/SaveCuentas";
import {AiOutlineSearch} from 'react-icons/ai';
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { getEmpresas, getEmpresasAll } from "../../services/geolocalizacion/places";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";


export function CuentaContable(){
  const token = localStorage.getItem('token');
  const [post, setPost]=useState([]); 
  const [modalShow, setModalShow]=useState(false);
  const [showModal, setShowModal]=useState(false);
  const [showEditCuenta, setShowEditCuenta] = useState(false);
  const [showDeleteCuenta, setShowDeleteCuenta] = useState(false);
  const [idCuenta, setIdCuenta] = useState(0);
  const [CuentaContable, setCuentaContable] = useState('');
  const [NombreCuenta, setNombreCuenta] = useState('')
  const [search, setSearch] = useState('')
  const [helper, setHelper] = useState(true)
  const [IdNivel, setIdNivel] = useState('')
  const [nivel, setNivel] = useState('')
  const [IdTipo, setIdTipo] = useState('')
  const [tipo, setTipo] = useState('')
  const [nivelSeleccionado, setNivelSeleccionado] = useState('')
  const [tipoSeleccionado, setTipoSeleccionado] = useState('')
  const [digito, setDigito] = useState('')
  const [cambiarSaldo, setCambiarSaldo] = useState(false)
  const [saldo, setSaldo] = useState(0)
  const [cuenta, setCuenta] = useState(0)
  const [showModalExcel, setShowModalExcel] = useState(false)
  const [datosExcel, setdatosExcel] = useState([])
  const [page, setPage] = useState(1)
  const [helperSearch, setHelperSearch] = useState(false)
  const [selectLimit, setSelectLimit] = useState(10);
  const [empresas, Setempresa] = useState([])
  const [idEmpresa, setIdEmpresa] = useState('')
  const [idEmpresaInsertar, setIdEmpresaInsertar] = useState('')


  const nextPage = () =>{
    setPage(page + 1)
  }
  const previousPage = () =>{
    if(page > 1){
      setPage(page - 1)
    }
  }
  const handleSelectChange = (event) => {
    const selectedLimit = parseInt(event.target.value);
    setSelectLimit(selectedLimit);
  };
  const handleFile = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetname = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetname];
      const datos = XLSX.utils.sheet_to_json(sheet);
    
      setdatosExcel(datos);
  }
}
const sendDataExcel = async() => {
 
  if(idEmpresa === ''){
    Swal.fire({
      title: 'Error',
      text: 'Debe seleccionar una empresa',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }else{
  Swal.fire({
    title: 'Cargando',
    text: 'Espere por favor...',
    icon: 'info',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading();
    }
  });
  
  const object = {
    "token": token,
    "data": datosExcel,
    "id_empresa": idEmpresa
  }
  console.log(datosExcel)
  const res = await saveCuentaExcel(object)
  
  if(res.status === 200){
    Swal.fire({
      title: 'Operacion completada',
      text: 'Se ha importado la data correctamente',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });
   // setShowModalExcel(false)
  }else{
    Swal.fire({
      title: 'Operacion fallida',
      text: 'No se ha podido importar la data',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
    //setShowModalExcel(false)
  }
}
}
  useEffect(() => {
    const Home = async() =>{
      try{
        const object = {
          token: token,
          page: page,
          cuenta: search
        }
        const data = await GetCuentas(object);
 
        // const nivelesUnicos = [...new Set(data.map((item) => item.nivel_cuenta))];
        setPost(data.data)

        const dataTipo = await GetTipo(token)
        const Tipo = dataTipo.map((item) => ({id_tipo: item.id_tipo_cuenta, tipo: item.tipo}))
        // const tipoData = dataTipo.map((item) => item.tipo)
        setTipo(Tipo)
  

        
        const dataNivel = await GetNivel(token)
        const Nivel = dataNivel.map((item) => ({ id_nivel: item.id_nivel, nivel: item.nivel, digitos:item.digito}))
        setNivel(Nivel)
        
        
      }
      catch(error){
        
      }
    };
    Home()
    const traerEmpresas = async () => {
      const token = localStorage.getItem('token');
      const object = {
        token: token
      }
      const res = await getEmpresasAll(object);
   
      Setempresa(res)

    }

    traerEmpresas()
  },[modalShow, page, helperSearch])

  const handleNivelChange = (event, value) => {
    setNivelSeleccionado(value.id_nivel)
    setDigito(value.digitos)
    // console.log(nivelSeleccionado)
  };

  const handleTipoChange = (event, value) => {
    setTipoSeleccionado(value.id_tipo)
    // console.log(tipoSeleccionado)
  };
  const handleSearch = () =>{
    setHelperSearch(!helperSearch)
    setPage(1)
  }

  useEffect(() => {
    if (tipoSeleccionado && nivelSeleccionado ) {
      let segment = "";
    if(nivelSeleccionado == undefined){
      segment = ''
    }else if (nivelSeleccionado == 1) {
      segment = tipoSeleccionado;
    }else if(nivelSeleccionado == 2){
      segment = `${tipoSeleccionado}1`
    }else if(nivelSeleccionado == 3){
      segment = `${tipoSeleccionado}11`
    }else if(nivelSeleccionado == 4){
      segment = `${tipoSeleccionado}1101`
    }else if(nivelSeleccionado == 5){
      segment = `${tipoSeleccionado}110101`
    }
      const nuevaCuentaContable = `${segment}`;
      setCuentaContable(nuevaCuentaContable);
    } else {
      setCuentaContable('');
    }
  }, [tipoSeleccionado, nivelSeleccionado]);



  const insertCuentaContable = async() =>{
    if(idEmpresaInsertar !== ''){
    setModalShow(true)
    const fecha = new Date();
    var fechaActual = fecha.toLocaleDateString();
    const SQlFecha = fechaActual.split('/').reverse().join('-');
    if(CuentaContable.length != digito){
      Swal.fire({
        title: 'Error',
        text: 'La longitud de los digitos de la cuenta no concuerda',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }else{
      var object = {CuentaContable, NombreCuenta,tipoSeleccionado,nivelSeleccionado,SQlFecha, token, idEmpresaInsertar}
      const data = await SaveCuentas(object)
      if(data === 200){
        setHelper(true)
        setShowModal(false)
        Swal.fire({
            title: 'Cuenta Agregada',
            text: 'Se ha agregado su cuenta contable correctamente',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          setModalShow(false)
          setCuentaContable('')
          setIdNivel('')
          setIdTipo('')
          setNombreCuenta('')
          
      }else if(data === 400){
        Swal.fire({
            title: 'Fallido',
            text: 'Rellene los campos vacios',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
      }else if(data == 401){
        Swal.fire({
            title: 'Fallido',
            text: 'Cuenta Contable ya existe',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
      }
      else if(data == 409){
        Swal.fire({
            title: 'Fallido',
            text: 'No puede tener ceros la cuenta contable',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
      }
      else{      
        setShowModal(false)
        Swal.fire({
            title: 'Fallido',
            text: 'No se ha podido completar ',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
          setModalShow(false)
      }
    }
  }else{
    Swal.fire({
      title: 'Error',
      text: 'Debe seleccionar una empresa',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
    }
  }

  const Delete = async(id_cuenta) => {
    setIdCuenta(id_cuenta)
    setShowDeleteCuenta(true)
  }

  const DeleteCuenta = async() => {
    setModalShow(true)
    const token = localStorage.getItem('token');
    var object = {token, idCuenta}
    var data = await DeleteCuentas(object)
    if(data.status == 200){
      setModalShow(false)
      Swal.fire({
          title: 'Operacion completada',
          text: 'Se ha eliminado la cuenta con exito',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        setShowDeleteCuenta(false)
    }else{
      setModalShow(false)
      Swal.fire({
          title: 'Operacion fallida',
          text: 'No se elimino correctamente',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
        setShowDeleteCuenta(false)
    }
  }

  const Edit = async(object) =>{
    const data = {
      id_cuenta: object.id_cuenta,
      saldo_solicitando: saldo,
      token: token
    }
    const res = await verificarSoclitud(data)
    const status =  (await res.status)
    if(status === 406){
      setIdCuenta(object.id_cuenta)
      setCuentaContable(object.numero_cuenta)
      setNombreCuenta(object.nombre_cuenta)
      setNivel(object.nivel_cuenta)
      setTipo(object.tipo_cuenta)
      setShowEditCuenta(true)

    }else if(status === 200){
      Swal.fire({
        title: 'Alerta',
        text: 'Existe una solicitud de cambio de saldo para esta cuenta, no puede editarse',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
    }else{
      Swal.fire({
        title: 'La operacion no pudo completarse',
        text: 'Error',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
   
  }
  
  // funcion para editar concepto
  const EditCuenta = async() => {
    setModalShow(true)
    let nivelEdit
    let valorTipo = CuentaContable[0]

    if(CuentaContable.length == 1){
      nivelEdit = 1
    }else if(CuentaContable.length == 2){
      nivelEdit = 2
    }else if(CuentaContable.length == 3){
      nivelEdit = 3
    }else if(CuentaContable.length == 5){
      nivelEdit = 4
    }else if(CuentaContable.length == 7){
      nivelEdit = 5
    }
    if(nivelEdit != nivel){
      Swal.fire({
          title: 'Error',
          text: 'La logitud de la cuenta no es correcta',
          icon: 'danger',
          confirmButtonText: 'Aceptar'
        });
    }else if(tipo != valorTipo){
      Swal.fire({
        title: 'Error',
        text: 'El tipo de cuenta no concuerda con la cuenta contable',
        icon: 'danger',
        confirmButtonText: 'Aceptar'
      });
    }else{
      const token = localStorage.getItem('token');
      var object = {token,CuentaContable,NombreCuenta,nivel:nivelEdit,tipo}
    
      const data = await UpdateCuentas(object,idCuenta)
      if(data.status == 200){
        setModalShow(false)
        Swal.fire({
          title: 'Operacion completada',
          text: 'La cuenta ha sido editado',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        setShowEditCuenta(false)
      }else{
        setModalShow(false)
        Swal.fire({
          title: 'Operacion incompleta',
          text: 'La operacion no pudo completarse',
          icon: 'danger',
          confirmButtonText: 'Aceptar'
        });
        setShowEditCuenta(false)
      }
    } 
  }

  const Cancel = async() =>{
    setCuentaContable('')
    setIdNivel('')
    setIdTipo('')
    setNombreCuenta('')
    setShowModal(false)
  }
  const changeSaldo = (e) => {
    
    setCuenta(e.id_cuenta)
    setSaldo(e.saldo)
    setCambiarSaldo(true)
    
  }
  const EditSaldo = async() => {

    const object = {
      id_cuenta: cuenta,
      saldo_solicitando: saldo,
      token: token
    }
    const res = await solicitarCambio(object)
    const status =  (await res.status)
    if(status === 200){
      Swal.fire({
        title: 'Operacion completada',
        text: 'Se ha solicitado un cambio de saldo',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    }else if(status === 406){
      Swal.fire({
        title: 'La operacion no pudo completarse',
        text: 'Ya existe una solicitud de cambio de saldo',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
    }else{
      Swal.fire({
        title: 'La operacion no pudo completarse',
        text: 'Error',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
    setCambiarSaldo(false)

  }

  return(
    <>
      <NavBar></NavBar>
      <body>
        <br/><br/>

        <div className="Encabezado" style={{textAlign: "center"}}>
          <h1 style={{color: "white"}}>Catalogo de Cuentas Contables</h1>

        </div><br/>

        <div className="Busqueda"><br/>
          <div style={{marginLeft: "24px"}}>
           
            <Stack direction="horizontal" gap={2}>
              <Form.Control className="Busqueda" placeholder="Busqueda de Concepto" style={{width: "700px", }} 
              value={search} onChange={e => setSearch(e.target.value.toLowerCase())}/>
              <Button onClick={handleSearch}><AiOutlineSearch></AiOutlineSearch></Button>

              

            <div style={{marginLeft: "50%"}}/>
              <AiOutlineSearch style={{marginTop: "10px"}}/>
                <Button variant="warning" onClick={() => setShowModal(true)} >Agregar</Button>
                <Button  variant="primary" onClick={() => setShowModalExcel(true)} ><AiFillFileExcel></AiFillFileExcel>Importar de excel</Button>
            </Stack><br/>
            
            <div style={{ display: "flex", justifyContent: "space-between", width: "40%", margin: "10px" }}>


           
            </div>

            <Table className="my-table">
              <thead>
                <tr>
                
                  <th style={{width: "200px"}}>Cuenta Contable</th>
                  <th style={{width: "300px"}}>Nombre de cuenta</th>
                  <th>Descripcion</th>
                  <th>Empresa</th>
                  <th>Agencia</th>
                  <th>Cuenta general</th>
                  <th>Cuenta padre</th>

                  <th>Tipo de Cuenta</th>
                  <th>Nivel</th>
                  <th>Saldo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {post
             
                .map((props) => (
                  <tr>
                  
                    <td>{props.numero_cuenta}</td>
                    <td>{props.nombre_cuenta} {props.agencia.agencia}</td>
                    <td>{props.descripcion}</td>
                    <td>{props.empresaModel.nombre_empresa}</td>
                    <td>{props.agencia.agencia}</td>
                    <td>{props.numero_cuenta}{props.empresaModel.id_empresa}{props.id_agencia}</td>
                    <td>PENDIENTE</td>
                    <td >{props.tipo_cuenta}</td>
                    <td>{props.nivel_cuenta}</td>
                    <td>{props.saldo}</td>
                    <td >
                    <Button variant="primary" onClick={() => Edit(props)} >Editar</Button>
                    <span style={{ margin: '0 5px' }}></span>
                    <Button  style={{ marginTop: '5px' }} variant="success" onClick={() => changeSaldo(props)} >Cambiar Saldo</Button>
                    <span style={{ margin: '10px 5px' }}></span>
                    <Button variant="danger" style={{ margin: '5px' }} onClick={() => Delete(props.id_cuenta)}>Eliminar</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> 
            <div className="d-flex justify-content-center">
                  <PaginationControls
                  page={page}
                  onPageChange={(e) => {setPage(e)}}
                  ></PaginationControls>
          </div>
          </div>
        </div>

          {/* modal para agregar un nuevo concepto */}
        <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar una nueva Cuenta Contable</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >
              <label>Tipo:</label>
              <Autocomplete
              disablePortal id="combo-box-tipo" 
              options={tipo} 
              getOptionLabel={(tipo) => tipo.tipo}
              onChange={handleTipoChange} 
              sx={{ width: 300}} renderInput={(params) => <TextField {...params} label="Tipo" />}
              />
              <br/>
              <label>Nivel:</label>
              <Autocomplete
                disablePortal id="combo-box-nivel" 
                options={nivel} 
                getOptionLabel={(nivel) => nivel.nivel}
                onChange={handleNivelChange} 
                sx={{ width: 300}} renderInput={(params) => <TextField {...params} label="Nivel" />}
                />
              <br/>
              <label>Cuenta contable:</label>
              <input type="text" placeholder="esta es su cuenta contable" 
              className="form-control" style={{width: "90%"}}
              value={CuentaContable}
              onChange={(e) => setCuentaContable(e.target.value)}/>
              <br/>
              <Form.Label style={{marginTop:"10px"}}>Selecciona la empresa</Form.Label>
              <Form.Select aria-label="Default select example" value={ idEmpresaInsertar}  onChange={(e) => {setIdEmpresaInsertar(e.target.value)}}>
                        <option>Seleccione una empresa</option>
                       {empresas.map((item) => (
                        <option value={item.id_empresa}>{item.nombre_empresa}</option>  
                      ))}
            </Form.Select>

              <label style={{marginTop:"10px"}}>Nombre de cuenta:</label>
              <input type="text" placeholder="debe introducir un nombre a su cuenta a crear"
              className="form-control"
              onChange={(e) => setNombreCuenta(e.target.value)}/>
              <br/>
            </div>
            <div >
              <Button variant="secondary" onClick={Cancel}>Cancelar</Button>
              <Button variant="primary" style={{float: "right", marginRight: "10px"}} onClick={insertCuentaContable}>Guardar</Button>
            </div>
          </ModalBody>
        </Modal>

          {/* modal para eliminar un concepto */}
        <Modal show={showDeleteCuenta} onHide={() => setShowDeleteCuenta(false)}>
          <Modal.Header >
            <Modal.Title>¿ Desea eliminar esta Cuenta ?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteCuenta(false)}>Cancelar</Button>
            <Button variant="danger" onClick={DeleteCuenta}>Si, eliminar</Button>
          </Modal.Footer>
        </Modal>

          {/* modal para editar concepto */}
        <Modal show={showEditCuenta} onHide={() => setShowEditCuenta(false)}>
          <Modal.Header >
            <Modal.Title>Cambiar Cuenta Contable</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Tipo:</label>
              <input type="text" className="form-control" value={tipo} onChange={(e) => setTipo(e.target.value)}/>        
              <br/>
              <label>nivel:</label>
              <input type="text" className="form-control" value={nivel} onChange={(e) => setNivel(e.target.value)}/>        
              <br/>
              <label>Cuenta Contable:</label>
              <input type="text" className="form-control" placeholder="Debe contener una cuenta" value={CuentaContable} onChange={(e) => setCuentaContable(e.target.value)} />
              <br/>
              <label>Descripcion:</label>
              <input type="text" className="form-control" placeholder="Escriba una descripcion" value={NombreCuenta} onChange={(e) => setNombreCuenta(e.target.value)} />
                            
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setShowEditCuenta(false)}>Cancelar</Button>
            <Button variant="success" onClick={EditCuenta}>Aceptar</Button>
          </Modal.Footer>
        </Modal>

          {/* modal para editar concepto */}
        <Modal show={cambiarSaldo} onHide={() => setCambiarSaldo(false)}>
          <Modal.Header >
            <Modal.Title>Cambiar saldo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Saldo:</label>
              <input type="number" className="form-control" value={saldo} onChange={(e) => setSaldo(e.target.value)}/>        
              <br/>         
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setCambiarSaldo(false)}>Cancelar</Button>
            <Button variant="success" onClick={EditSaldo}>Solicitar</Button>
          </Modal.Footer>
        </Modal>
        {/* MODAL PARA IMPORTAR DATA DE EXCEL */}
        <Modal show={showModalExcel} onHide={() => setShowModalExcel(false)}>
          <Modal.Header >
            <Modal.Title>Importar datos de excel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
           <Form.Label>Selecciona el archivo</Form.Label>
           <Form.Control type="file" accept=" .xls .xlsx .csv" onChange={handleFile}/>
           <Form.Label style={{marginTop:"10px"}}>Selecciona la empresa</Form.Label>
           <Form.Select aria-label="Default select example" value={ idEmpresa}  onChange={(e) => {setIdEmpresa(e.target.value)}}>
                        <option>Seleccione una empresa</option>
                       {empresas.map((item) => (
                        <option value={item.id_empresa}>{item.nombre_empresa}</option>  
                      ))}
            </Form.Select>
          </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalExcel(false)}>Cancelar</Button>
            <Button variant="success" onClick={sendDataExcel}>Enviar</Button>
          
          </Modal.Footer>
        </Modal>


      </body>
    </>
  );
}