import { useEffect, useState } from "react";
import { NavBar } from "../../components/navbar";
import { BuscadorReporteCierreUnico } from "../../components/reporteCierreDiarioUnico/buscador";
import { Table } from "react-bootstrap";

export function ReporteCierreUnico(){
    const [hasData, setHasdata] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if(data.length > 0){
            console.log(data)
            setHasdata(true)
        }
    }, [data])
    if(hasData){
    return (
        <>
        <NavBar/>
        <div style={{ margin: '60px',padding:"10px", textAlign: 'center', backgroundColor:"#b4cbd6", borderRadius:"15px" }} className="boxShadow">
            <h1>Cierre del dia {data[0].fecha}</h1>
          <Table  className="custom-table" bordered  hover>
              <thead>
                <tr>
                  <th>Agencia</th>
                  <th style={{ width: "200px" }}>Descripcion</th>
                  <th >Fecha de creacion</th>
                  <th>Cantidad de registros dentro de esta partida</th>
                  <th>Acciones</th>


                </tr>
              </thead>
              <tbody>

                {data

                  .map((props) => (
                    <tr>
                      <td>{props.id_partida}</td>
                      <td>{props.descripcion}</td>
                      <td>{props.fecha_creacion}</td>
                      <td>{props.registros}</td>


                    </tr>
                  ))}
              </tbody>
            </Table>
            </div>
        </>
    )
    }else{
        return(
            <>
            <NavBar/>
            <div>
                <BuscadorReporteCierreUnico
                sendData={(data) => setData(data)}
                />
            </div>
            </>
        )
    }
}