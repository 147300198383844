import { NavBar } from "../../components/navbar";
import Table from 'react-bootstrap/Table';
import { useState,useEffect } from "react";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import Swal from "sweetalert2";
import { getMovimientosCentroCostos } from "../../services/reportes/reporteCentroCostos";
import '../../css/tableBalanza.css';
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { Form } from "react-bootstrap";

export function CentroCostos () {

    const [idEmpresa, setIdEmpresa] = useState('')
    const [idAgencia, setIdAgencia] = useState('')
    const [cuentas, setCuentas] = useState([])
    const [buscarCuenta, setBuscarCuenta] = useState(null)
    const [page, setPage] = useState(1)
    const [tipoCuenta, setTipoCuenta] = useState(null)
    const [nivelCuenta, setNivelCuenta] = useState(null)
    const estiloDiv = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Opcional: centrar horizontalmente
        height: '100vh', // Añadir altura para centrar verticalmente en la pantalla
      };

      const changePagina = async (e) => {
       
        await setPage(e);
        const token = localStorage.getItem('token');

        if(tipoCuenta == '0'){
            setTipoCuenta(null)
        }
        if(nivelCuenta == '0'){
            setNivelCuenta(null)
        }

        const object = {token, idEmpresa, idAgencia, buscarCuenta, limit:25, page:e, tipoCuenta,nivelCuenta}
        if(idAgencia == '' || idEmpresa == ''){
            Swal.fire({
                title: 'Error',
                text: 'Debe seleccionar una empresa y una agencia',
                icon: 'error',
                confirmButtonText: 'Ok'
                })
        }else{
            const data = await getMovimientosCentroCostos(object)
          
            setCuentas(data)
        }
      };


    const sendData = async() => {
        const token = localStorage.getItem('token');

        if(tipoCuenta == '0'){
            setTipoCuenta(null)
        }
        if(nivelCuenta == '0'){
            setNivelCuenta(null)
        }
        const object = {token, idEmpresa, idAgencia, buscarCuenta, limit:25, page:page, tipoCuenta,nivelCuenta}
        if(idAgencia == '' || idEmpresa == ''){
            Swal.fire({
                title: 'Error',
                text: 'Debe seleccionar una empresa y una agencia',
                icon: 'error',
                confirmButtonText: 'Ok'
                })
        }else{
            const data = await getMovimientosCentroCostos(object)
          
            setCuentas(data)
        }
        
    }

    return (
        <>
        <NavBar />
        <div class="container text-center shadow mt-5" style={{backgroundColor:"#b4cbd6", borderRadius:"15px", filter:"brightness(0.9)"}}>
        <h1 class="mt-4" style={{color:"black"}}>Centro de Costos</h1>
        <div class="row mt-4">
        <div class="col-md-2" style={{margin:"auto"}}>
            <label for="filtro1">Busqueda por numero de cuenta:</label>
            <input type="text" class="form-control" id="buscador" placeholder="Buscar..." onChange={(e) => {setBuscarCuenta(e.target.value)}}/>
        </div>
            <div class="col-md-3">
            <EmpresasSelect
                onChange= {(e) => setIdEmpresa(e)}
             
              />
            </div>
            <div class="col-md-3">
            <EmpresaXAgenciaSelect
            IdEmpresa={idEmpresa}
            onChange = {(e) => setIdAgencia(e)}
             />
            </div>

            <div class="col-md-2">
            <Form.Label style={{marginTop:"10px", fontSize:"15px"}}>Seleccionar el tipo de cuenta</Form.Label>
           <Form.Select aria-label="Default select example"  onChange={(e) => {setTipoCuenta(e.target.value)}}>
                        <option value='0'>Todos</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>    
                  
            </Form.Select>
            </div>

            <div class="col-md-2">
            <Form.Label style={{marginTop:"10px", fontSize:"14px"}}>Seleccionar el nivel de cuenta</Form.Label>
           <Form.Select aria-label="Default select example"  onChange={(e) => {setNivelCuenta(e.target.value)}}>
                        <option value = '0'>Todos</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                       
                  
            </Form.Select>
            </div>

            <div class="col-md-2"  style={{margin:"auto", marginTop:"10px"}}>
                    <button type="submit" class="btn btn-primary" onClick={sendData}>Buscar</button>
                </div>
            
        </div>
        <div class="mx-auto" style={{ width: "80%", marginTop:"45px" }}>
        <Table className="my-table">
              <thead>
                <tr>
                     <th>Cuenta contable</th>
                     <th>Nombre de cuenta</th>
                    
                    
                     <th>Empresa</th>
                     <th>Agencia</th>
                     <th>Cuenta general</th>
                     <th>Cuenta padre</th>
                     <th>Tipo de cuenta</th>
                     <th>Nivel</th>
                     <th></th>
                     <th>Saldo</th>
                  
                 
                </tr>
              </thead>
              <tbody>
              {cuentas.map((props) => (
                  <tr>
                    <td>{props.cuenta}</td>
                    <td>{props.nombre}-{props.agencia}</td>
                    <td>{props.empresa}</td>
                    <td>{props.agencia}</td>
                    <td>{props.cuenta}</td>
                    <td>{props.cuenta_padre !== null ? <>{props.cuenta_padre}</>: <>Sin cuenta padre</>}</td>
                    <td>{props.tipo}</td>
                    <td>{props.nivel}</td>
                    <td style={{borderLeft:"1px solid #000", borderRight:"1px solid #000"}}> $</td>
                    <td>{props.saldo.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  </tr>
                ))}

              </tbody>
            </Table> 

            <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="mx-auto unshow">
            <PaginationControls page={page} onPageChange={(e) => changePagina(e)} />
          </div>
        </div>
        
            </div>
    </div>
        </>
    )
}