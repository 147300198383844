import { Container, Form, Button} from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { getAgencias, getEmpresas } from "../../services/geolocalizacion/places";
import { getAgenciasXEmpresa } from "../../services/geolocalizacion/places";
import { reporteCierresDiarios } from '../../services/reportes/reportesCuentas';
import store from '../../store';
import { EmpresasSelect } from '../UTILS/EmpresasSelect';
import { EmpresaXAgenciaSelect } from '../UTILS/EmpresaXAgenciaSelect';

export  function Buscador(props){




    const [idAgencia, setIdAgencia] = useState(null)
    const [fecha, setFecha] = useState(null)
    const token = localStorage.getItem('token');
    const state = store.getState();
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [empresas, setempresa] = useState([[]])


      useEffect(() => {
        
         

       async function ver_data(){
        if(state.agencia.agencia != 0){
          const object = {
         
            agencia: state.agencia.agencia,
            fecha: state.agencia.fecha.substring(0,10),
            token: token
          }
          const result = await reporteCierresDiarios(object)
          props.empresa(state.agencia.id_empresa)
          props.agencia(state.agencia.agencia)
          props.onFinish(result)
        }
      }
      ver_data()
       
      }, [])

      async function sendBuscar(e){
        e.preventDefault()

        const object = {
          empresa: idEmpresa,
          agencia: idAgencia,
          fecha: fecha,
          token: token
        }
      const result = await reporteCierresDiarios(object)
      props.onFinish(result)
      props.empresa(idEmpresa)
      props.agencia(idAgencia)
       
        
      }
      const handleChange = (e) => {
     
        setIdEmpresa(e)

      }

    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width:"800px", padding:"50px", borderRadius: "30px",  marginTop: "5%"}}>
        <Form onSubmit = {sendBuscar}>
           <center> <h2>Cierres diarios</h2> </center>
          
          <EmpresasSelect
            onChange= {(e) => handleChange(e)}
            empresa={(e) => setempresa(e)}
          />
          
          <EmpresaXAgenciaSelect
          IdEmpresa = {idEmpresa}
          onChange= {(e) => setIdAgencia(e)}
          showlabel = {true}
          agencia = {0}
          />
          
            
            <Form.Group >
            <Form.Label>Seleccione el dia</Form.Label>
            <Form.Control type="date"  onChange={(e) => setFecha(e.target.value)}/>
          </Form.Group>
           
          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>

          </Form>
        </Container>
       
    )
}