import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL


export const getSubtiposMovBoveda = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getSubtiposMovimientoBoveda`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
    
   })
   const respuesta = await response.json()
   return respuesta
}
export const getSubtiposMovBovedaOp = async (data) => {
  const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getSubtiposMovimientoBovedaOperativos`,{
      "method": "GET",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
        
      },
  
 })
 const respuesta = await response.json()
 return respuesta
}
export const getCuentasGenerales = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getcuentasGenerales`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
    
   })
   const respuesta = await response.json()
   return respuesta
}
export const getCuentasGeneralesOP = async (data) => {
  const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getcuentasGeneralesOperativas`,{
      "method": "GET",
    headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json",
        
      },
  
 })
 const respuesta = await response.json()
 return respuesta
}
export const getTiposMovBov = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/getTiposMovimientoBoveda`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
    
   })
   const respuesta = await response.json()
   return respuesta
}
export const guardarSubTipoMovBoveda = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/guardarSubtiposMovimientoBoveda`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
                nombre: data.nombre,
                cuenta: data.cuenta,
                contra_cuenta: data.contra_cuenta,
                id_movimiento: data.id_tipo_movimiento,
                tipo_empresa: data.tipo_empresa

        })
    
   })
   const respuesta = await response
   return respuesta
}
export const editarSubTipoMovBoveda = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/editSubtipoMovimientoBoveda`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
                nombre: data.nombre,
                cuenta: data.cuenta,
                id_movimiento: data.id_tipo_movimiento,
                id_subtipo_movimiento: data.id_subtipo_movimiento,
                contra_cuenta: data.contra_cuenta

        })
    
   })
   const respuesta = await response
   return respuesta
}
export const eliminarSubTipoMovBoveda = async (data) => {
    const response = await fetch(`${BASE_URL}/cuentasPredeterminadas/eliminarSubtipoMovimientoBoveda`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_subtipo_movimiento: data.id_subtipo_movimiento
      })
    })
    const respuesta = await response
    return respuesta
}