import { Button, Col, InputGroup, Modal, Row, Form } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import CurrencyInput from 'react-currency-input-field';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { getEmpresas, getAgenciasXEmpresa, getTipoEmpresa } from "../../../services/geolocalizacion/places";
import Swal from 'sweetalert2';
import { getEmpresaByPartida, getCuenta, getCuentaEmpresas, getCuentaEmpresasOperativas } from '../../../services/partidas/partidas';

export const CompraGlobal = (props) => {
    const token = props.token
    const [idEmpresa, setIdEmpresa] = useState(0)
    const [empresas, setEmpresas] = useState([])
    const [agencias, setAgencias] = useState([[]])
    const [monto, setMonto] = useState(0)
    const [nombreEmpresa, setNombreEmpresa] = useState('')
    const [concepto, setConcepto] = useState([])
    const [movimiento, setMovimiento] = useState(0)
    const [movimientos, setMovimientos] = useState([[]])
    const [firstMonto, setFirstMonto] = useState(0)
    const [cuentas, setCuentas] = useState([])
    const [cuenta, setCuenta] = useState([[]])
    const [notificacionCuenta, setNotificacionCuenta] = useState([])
    const [notificacionConcepto, setNotificacionConcepto] = useState([])
    const [notificacionMonto, setNotificacionMonto] = useState([])
    const [notificacionMovimiento, setNotificacionMovimiento] = useState([])
    const [montos, setMontos] = useState([])


    /* The above code is a React useEffect hook that fetches data from an API using the
    getEmpresaByPartida function. It sets the fetched data to the state variables empresas,
    nombreEmpresa, and idEmpresa. The useEffect hook runs only once when the component mounts, as the
    dependency array is empty. If there is an error in fetching the data, it sets the empresas state
    variable to an empty array with an empty nombre_empresa property. */
    useEffect(() => {
        async function fetchData() {
            const { partida, agencia } = props
            const object = { partida, agencia, token }

            try {
                const response = await getEmpresaByPartida(object)

                setEmpresas(response)
                setNombreEmpresa(response[0].nombre_empresa)
                setIdEmpresa(response[0].id_empresa)
            } catch (error) {

                const empresas = []
                empresas[0].nombre_empresa = ''
                setEmpresas(empresas)
            }
        }
        fetchData()
    }, [])

    /* The above code is using the useEffect hook in a React component to fetch data asynchronously
    from an API using the getCuenta function. It then maps over the fetched data to create an array
    of objects with label and value properties, and sets the state of cuentas to this array. The
    useEffect hook is triggered only once when the component mounts, as the dependency array is
    empty. */
    useEffect(() => {
        const fetchDataCuenta = async () => {
            let arrCuentas = []
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
              })
            //   console.log(agencias)
            for (let i = 0; i < agencias[0].length; i++) {
                const object = {
                    token: token,
                    empresa: idEmpresa,
                    agencia: agencias[0][i].id_agencia
                }
                const tipo_empresa = await getTipoEmpresa(object)

                


                    const cuentas = await getCuentaEmpresas(object)
                    let options = []

                    cuentas.map(((index, i) => {
                        options[i] =
                        {
                            label: index.nombre_cuenta + "-" + index.numero_cuenta,
                            value: index.numero_cuenta
                        }

                    }))
                    arrCuentas.push(options)



            }
            Swal.close()
            setCuentas([arrCuentas])




        }
        fetchDataCuenta()
    }, [idEmpresa, props.show, agencias])

    /* The above code is using the useEffect hook in a React component to fetch data from an API
    endpoint using the getAgenciasXEmpresa function. The function is called with an object
    containing a token and an idEmpresa parameter. If the idEmpresa is not equal to 0, the
    function is called and the response is stored in the agencias state variable using the
    setAgencias function. If the idEmpresa is equal to 0, an empty array is stored in the
    agencias state variable. The  */
    useEffect(() => {

        if (idEmpresa != 0) {
            const fetchData = async () => {
                // console.log(props)
                const object = { token, idEmpresa }
                const tipos = await getAgenciasXEmpresa(object)
                setAgencias([tipos])
            }
            fetchData()
        } else {
            const tipos = []
            setAgencias([tipos])
        }




    }, [idEmpresa])

    /**
     * This function sets the state of a variable called "concepto" based on the outer text of an event
     * target.
     */
    const handleChange = (e) => {
        const previewConcepto = [...concepto];
        const id = parseInt(e.target.id.split('-')[0], 10);
        const newValue = e.target.outerText + '-' + agencias[0][id].agencia;
        
        previewConcepto[id] = newValue;
    
        // Propagate to all other fields except the first one
        if (id === 0) {
            for (let i = 1; i < agencias[0].length; i++) {
                // Aquí se aumenta el id para cada agencia.
                const newId = i;
                const updatedValue = e.target.outerText + '-' + agencias[0][newId].agencia;
                previewConcepto[newId] = updatedValue;
            }
        }
    
        setConcepto(previewConcepto);
        console.log(previewConcepto);  // Nota: Cambié 'concepto' por 'previewConcepto' para reflejar el estado actual.
    }
    
    /**
     * This function changes the value of a state variable based on the value of an input field.
     */
    const hanldeChangeTipoMovimiento = (e) => {
        const previewTipoMovimiento = [...movimientos];
        const id = e.target.id || 0;
        const newValue = e.target.value;
    
        previewTipoMovimiento[id] = newValue;
    
        // Propagate to all other fields except the first one
        if (id == 0) {
            for (let i = 1; i < agencias[0].length; i++) {
                previewTipoMovimiento[i] = newValue;
            }
        }
    
        setMovimientos(previewTipoMovimiento);
        console.log(movimientos)
    }

    /**
     * This function calculates the amount to be distributed equally among a given number of agencies.
     */

    /**
     * The function handles the saving of a financial transaction and performs validation checks before
     * saving.
     */
    const handleSave = () => {
        const lenght = agencias[0].length
       
       
   
        let editable = []
        let movimientoArr = []
        const tipo = props.tipo
        let flag = true
        let descripcion = ''
        let id_agencias = []
        let erroresCuenta = []
        let erroresConcepto = []
        let erroresMonto = []
        let erroresMovimiento = []

      
      
        // console.log(concepto)

        for (let i = 0; i < lenght; i++) {



            if (concepto[i] == '' || concepto[i] == undefined) {
                flag = false
                descripcion = " Falta un concepto, "
                erroresConcepto[i] = "block"
            }
           
           
            editable[i] = 1


            if (cuenta[i] === undefined || cuenta[i] === '') {
                flag = false
                descripcion = " Falta una o mas cuentas, "
                erroresCuenta[i] = "block"

            } else {
                erroresCuenta[i] = "none"
            }

            id_agencias[i] = agencias[0][i].id_agencia


            if (movimientos[i] == 0 || movimientos[i] == undefined) {
                flag = false
                descripcion += " falta definir el tipo de movimientos, "
                erroresMovimiento[i] = "block"
             
            } else {
                erroresMovimiento[i] = "none"
            }

            if (montos[i] === "" || montos[i] === undefined) {
                flag = false
                descripcion += " falta definir un monto "
                erroresMonto[i] = "block"
            } else {
                erroresMonto[i] = "none"
            }
           
          
        }
       
      
       
        setNotificacionConcepto(erroresConcepto)
        setNotificacionCuenta(erroresCuenta)
        setNotificacionMonto(erroresMonto)
        setNotificacionMovimiento(erroresMovimiento)
        if (flag) {
            const object = { montos: montos, cuenta: cuenta, concepto: concepto, tipo: tipo, movimientos: movimientos, editable: editable, agencias: id_agencias }
          
            props.onChange(object)
            Swal.fire({
                title: 'Exito',
                text: 'Registro añadido exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            })
            props.onHide()
        } else {
            Swal.fire({
                title: 'Error',
                text: descripcion,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })

        }
    }
    /**
     * This function updates a specific element in an array of "cuenta" based on the key and value passed
     * as parameters.
     */
    const handleChangeCuenta = (key, value) => {
        const previewCuentas = [...cuenta];
        const index = key.target.id.split('-')[0];
        const newValue = value.value;
    
        previewCuentas[index] = newValue;
    
        // Propagate to all other fields except the first one
        if (index == 0) {
            for (let i = 1; i < agencias[0].length; i++) {
                previewCuentas[i] = newValue;
            }
        }
    
        setCuenta(previewCuentas);
        console.log(cuenta)
    }
    const show = () => {
        // console.log(cuenta)
    }
    const handleChangeMonto = (e) => {
        const previewMontos = [...montos];
        const id = e.target.id;
        const newValue = parseFloat(e.target.value.replace(/,/g, ''));
    
        previewMontos[id] = newValue;
    
        // Propagate to all other fields except the first one
        if (id == 0) {
            for (let i = 1; i < agencias[0].length; i++) {
                previewMontos[i] = newValue;
            }
        }
    
        setMontos(previewMontos);
        console.log(montos)
    }
    const handleChangeFirstMonto = (e) => {
       
        const previewMontos = [...montos]
        const id = 0
        previewMontos[id] = parseFloat(e.replace(/,/g, ''))
        setMontos(previewMontos)
    }

    /* The above code is a React component that renders a modal with a form to add a new record. The
    modal has a header with a title and a close button, a body with a form that includes fields for
    selecting the type of record, the account, the concept, the company, the amount, and the type of
    movement. The form also includes a dynamic section that renders multiple forms based on the
    number of agencies. The modal has a footer with a button to save the record. The component
    receives props to control the visibility of the modal, handle changes in the form fields, and
    handle the save */
    return (
        <Modal show={props.show} onHide={props.onHide} size="xl" backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar registro</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>



                    {agencias[0].map((modalMap, id) => (


                        <Form className="mb-3 border-bottom border-dark border-3">
                            <p>{modalMap.agencia}</p>
                            <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                            {(notificacionCuenta[id] !== undefined && notificacionCuenta[id] == 'block') && (
                                <span style={{ color: "red", display: notificacionCuenta[id] }} > ¡Campo vacio!🚨​</span>
                            )}
                            {/* <Form.Control required name="cuenta" type="text" itemID="0" placeholder="xxxxxxxx-xxx" onChange={handleChangeCuenta} value={cuenta[0]} /> */}
                            {cuentas.length > 0 && (
                                <Autocomplete


                                    disablePortal
                                    onChange={handleChangeCuenta}
                                    id={id}
                                    options={cuentas[0][id]}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Cuenta"
                                        id='0'
                                        style={{ width: '100%' }}


                                    />}
                                />
                            )}


                               <Form.Label>Concepto</Form.Label>

                                        {(notificacionConcepto[id] !== undefined && notificacionConcepto[id] == 'block') && (
                                            <span style={{ color: "red", display: notificacionConcepto[0] }} > ¡Campo vacio!🚨​</span>
                                        )}
                                        <Autocomplete

                                            freeSolo={true}
                                            disablePortal
                                            id={id}
                                            onChange={handleChange}
                                            options={props.option[0]}

                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="Conceptos"

                                                style={{ width: '100%' }}
                                            />}
                                        />

                            <Form.Label>Monto</Form.Label>
                            
                            {(notificacionMonto[id] !== undefined && notificacionMonto[id] == 'block') && (
                                            <span style={{ color: "red", display: notificacionMonto[id] }} > ¡Campo vacio!🚨​</span>
                                        )}
                            <CurrencyInput

                                id={id}
                                name="0"
                                className='input-bootstrap mb-3'
                                placeholder="$0.00"
                                decimalsLimit={2}
                                decimalSeparator="."
                                // value={montos[id+1]}
                                onChange={(e) => handleChangeMonto(e)}
                                
                            />
                            <Form.Label className=''>Tipo de movimiento </Form.Label>
                            {(notificacionMovimiento[id] !== undefined && notificacionMovimiento[id] == 'block') && (
                                            <span style={{ color: "red", display: notificacionMovimiento[id] }} > ¡Campo vacio!🚨​</span>
                                        )}
                            <Form.Select onChange={hanldeChangeTipoMovimiento} id={id}>
                                
                                <option value={0}>Seleccione un tipo de movimiento</option>
                                <option value={1}>Cargo</option>
                                <option value={2}>Abono</option>
                            </Form.Select>

                        </Form>

                    ))}

                </Row>
            </Modal.Body>
            <Modal.Footer>

                <Button variant='success' onClick={handleSave}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}