import Swal from "sweetalert2";

export function GIFcarga(show = true){
    if(!show){
        return Swal.close()
    }else{
        return(
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            })
        )
    }

  
}