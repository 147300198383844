import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const getEmpresas = async(data) => {
    const response = await fetch(`${BASE_URL}/partidas/getEmpresas`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json"
        },
   })
   const respuesta = await response.json()
   return respuesta
}
export const getEmpresasAll = async(data) => {
    const response = await fetch(`${BASE_URL}/partidas/getEmpresasAll`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json"
        },
   })
   const respuesta = await response.json()
   return respuesta
}


export const getEmpresasOperativas = async(data) => {
    const response = await fetch(`${BASE_URL}/partidas/getEmpresasOperativas`,{
        "method": "GET",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json"
        },
   })
   const respuesta = await response.json()
   return respuesta
}

export const getAgenciasXEmpresa = async(data) =>{
    const response = await fetch(`${BASE_URL}/partidas/getAgencias/${data.idEmpresa}`,{
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const respuesta = await response.json()
    return respuesta
}

export const getAgencias = async(data) => {
    const response = await fetch(`${BASE_URL}/geolocalizacion/agencias`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const respuesta = await response.json()
    return respuesta
}
export const getEmpresadeAgencia = async(data) => {
    const response = await fetch(`${BASE_URL}/geolocalizacion/empresasdeAgencia/${data.agencia}`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const respuesta = await response.json()
    return respuesta

}

export const getTipoEmpresa = async(data) => {
    const response = await fetch(`${BASE_URL}/geolocalizacion/tipoEmpresa/${data.empresa}`,{
        method: "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        }
    })
    const respuesta = await response.json()
    return respuesta
}
