import { Button, Container, Form, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import React, { useEffect, useState } from "react";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { Autocomplete, TextField } from "@mui/material";
import { getCuentaEmpresas, getCuentaEmpresasMayor } from "../../services/partidas/partidas";
import { getCuentasenRango, movimientosCuentas, movimientosCuentasRango } from "../../services/reportes/reportesCuentas";
import '../../css/botonRefresh.css'
import Swal from "sweetalert2";
import { AiFillFileExcel, AiOutlineArrowLeft } from "react-icons/ai";
import * as XLSX from 'xlsx';
import { GIFcarga } from "../../utils/GIFcarga";
import { useNavigate } from "react-router-dom";
import { PaginationControls } from "../../components/UTILS/PaginationControls";

export function ReporteMovimientosRanged() {
    const [fechaInicio, setFechaInicio] = useState(null)
    const [fechaFinal, setFechaFinal] = useState(null)
    const [nombreEmpresa, setNombreEmpresa] = useState(null)
    const [idAgencia, setIdAgencia] = useState(null)
    const [idEmpresa, setIdEmpresa] = useState(null)
    const [cuenta, setCuenta] = useState(null)
    const [data, setData] = useState([])
    const [showData, setShowData] = useState(false)
    const [totalCargos, setTotalCargos] = useState(0)
    const [totalAbonos, setTotalAbonos] = useState(0)
    const [cuentaInicial, setCuentaInicial] = useState(null)
    const [cuentaFinal, setCuentaFinal] = useState(null)
    const [cuentasMayor, setCuentasMayor] = useState([[]])
    const [page, setPage] = useState(1)
    const navigate = useNavigate()


    useEffect(() => {
        async function getCuentasMayor(){
          const token = localStorage.getItem('token')
          const object = {
              token: token,
              empresa: idEmpresa,
              agencia: ''
          }
          const cuentas = await getCuentaEmpresasMayor(object)
          let options = []
          cuentas.map(((index, i) => {
              options[i] =
              {
                  label: index.nombre_cuenta + "-" + index.numero_cuenta,
                  value: index.numero_cuenta
              }

          }))
          setCuentasMayor([options])
        }
        getCuentasMayor()

    }, [idEmpresa])

    
  const handleDownloadCSV = async () => {
    GIFcarga(true)
    const objectCuentas = {
      cuentaInicial: cuentaInicial,
      cuentaFinal: cuentaFinal,
      empresa: idEmpresa,
      page: null,
      token: localStorage.getItem('token')
    }
    const cuentas = await getCuentasenRango(objectCuentas)
    console.log(cuentas)
    const datos = []
    for(let i = 0; i < cuentas.length; i++){
      const object = {
        cuenta: cuentas[i],
        fechaInicio: fechaInicio,
        fechaFinal: fechaFinal,
        agencia: idAgencia,
        empresa: idEmpresa,
        token: localStorage.getItem('token')
      }
      const result = await movimientosCuentasRango(object)
      if(result != null){
          datos.push(result)
      }
    }
    // Crear un libro de trabajo
    const workbook = XLSX.utils.book_new();
    const sheetName = 'CarteraGeneral';
  // Array para almacenar todas las filas combinadas
// Array para almacenar todas las filas combinadas
const combinedData = [];

// Itera sobre cada cuenta y sus movimientos
for (let i = 0; i < datos.length; i++) {
    const cuentaInfo = datos[i].cuenta;
    const movimientos = datos[i].movimientos;

    // Agrega una fila para el encabezado de la cuenta
    combinedData.push({
        columna_1: `Cuenta: ${cuentaInfo.cuenta}`,
        });
        combinedData.push({
          columna_1: `Cuenta: ${cuentaInfo.nombre}`,
      });
      combinedData.push({
        columna_1: `Cuenta: ${cuentaInfo.saldo_inicial}`,
    });
    combinedData.push({
      columna_1: `Cuenta: ${cuentaInfo.saldo_final}`,
    });
   

    // Agrega un encabezado para los movimientos
    combinedData.push({
        columna_1: 'Cuenta',
        columna_2: 'Movimiento',
        columna_3: 'Agencia',
        columna_4: 'Cargo',
        columna_5: 'Abono',
        columna_6: 'Saldo',
        columna_7: 'Fecha Movimiento',
    });

    // Agrega filas para cada movimiento
    for (let j = 0; j < movimientos.length; j++) {
        combinedData.push({
          columna_1: movimientos[j].cuenta,
          columna_2: movimientos[j].movimiento,
          columna_3: movimientos[j].agencia.agencia,
          columna_4: movimientos[j].tipo_movimiento == 1 ? movimientos[j].monto : 0.00,
          columna_5: movimientos[j].tipo_movimiento == 2 ? movimientos[j].monto : 0.00,
          columna_6: movimientos[j].saldo,
          columna_7: movimientos[j].fecha_ingreso,
        });
    }
    combinedData.push({
      columna_1: "Subtotal",
      columna_2: `Total cargos ${datos[i].subtotales.total_cargo}`,
      columna_3: `Total abonos ${datos[i].subtotales.total_abono}`,
    });

    // Agrega una fila vacía entre cada cuenta
    combinedData.push({});
}

// Crea una hoja de cálculo a partir del array combinado
const worksheet = XLSX.utils.json_to_sheet(combinedData);

// Agrega la hoja de cálculo al libro de Excel
XLSX.utils.book_append_sheet(workbook, worksheet, 'Cuentas y Movimientos');
    // const worksheet = XLSX.utils.json_to_sheet(datos);
    // // Agrega la hoja de cálculo al libro de Excel
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

    // Genera el archivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crea un objeto Blob para el archivo Excel
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Crea un objeto URL para el archivo Excel
    const excelUrl = URL.createObjectURL(blob);

    // Crea un enlace para la descarga del archivo Excel
    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = `MovimientosCuenta.xlsx`;

    // Dispara el evento de clic para descargar el archivo
    link.click();
    GIFcarga(false)

  };

    const sendBuscar = async (e) => {
        e.preventDefault()

         if( (cuentaInicial == null || cuentaFinal == null)){
          alert('Seleccione una cuenta inicial y una cuenta final')
          Swal.close()
          return
        }else{
          if(idAgencia == 'todas'){
              setIdAgencia(null)
          }

        //aca va el codigo para buscar por rango de cuentas
        GIFcarga(true)
        const objectCuentas = {
          cuentaInicial: cuentaInicial,
          cuentaFinal: cuentaFinal,
          empresa: idEmpresa,
          page: page,
          token: localStorage.getItem('token')
        }
        const cuentas = await getCuentasenRango(objectCuentas)
        console.log(cuentas)
        const datos = []
        for(let i = 0; i < cuentas.length; i++){
          const object = {
            cuenta: cuentas[i],
            fechaInicio: fechaInicio,
            fechaFinal: fechaFinal,
            agencia: idAgencia,
            empresa: idEmpresa,
            token: localStorage.getItem('token')
          }
          const result = await movimientosCuentasRango(object)
        if(result != null){
            datos.push(result)
        }
        }
        console.log(datos)
       setData(datos)
       setShowData(true)
       GIFcarga(false)

        
      
      
    }

    }
    const handleChange = (e) => {
        console.log(e)
        if(!isNaN(e)){
        setIdEmpresa(e)
        }else{
        setIdEmpresa(0)
        }
    }

    const changePagina = async(e) => {
        await setPage(e)
        if( (cuentaInicial == null || cuentaFinal == null)){
            alert('Seleccione una cuenta inicial y una cuenta final')
            Swal.close()
            return
          }else{
            if(idAgencia == 'todas'){
                setIdAgencia(null)
            }
  
          //aca va el codigo para buscar por rango de cuentas
          GIFcarga(true)
          const objectCuentas = {
            cuentaInicial: cuentaInicial,
            cuentaFinal: cuentaFinal,
            empresa: idEmpresa,
            page: e,
            token: localStorage.getItem('token')
          }
          const cuentas = await getCuentasenRango(objectCuentas)
          console.log(cuentas)
          const datos = []
          for(let i = 0; i < cuentas.length; i++){
            const object = {
              cuenta: cuentas[i],
              fechaInicio: fechaInicio,
              fechaFinal: fechaFinal,
              agencia: idAgencia,
              empresa: idEmpresa,
              token: localStorage.getItem('token')
            }
            const result = await movimientosCuentasRango(object)
            if(result != null){
                datos.push(result)
            }
          }
         setData(datos)
         setShowData(true)
         GIFcarga(false)
        }
    }

    const handleChangeCuenta = (e, value) => {
        setCuenta(value.value)
      
    }
    const handleChangeCuentaInicial = (e, value) => {
      setCuentaInicial(value.value)
    }
    const handleChangeCuentaFinal = (e, value) => {
      setCuentaFinal(value.value)
    }

    const changeAgencia = (e) => {
      console.log(e)
      if(e == 'todas'){
        setIdAgencia(null)
      }else{
        setIdAgencia(e)
      }
    }
    const reload = () => {
      sendBuscar()
    }
    const goBack = () => {
      setShowData(false)
      
    }
    const handleChangeSearch = (e) => {
        navigate('/reporteMovimientosCuentas')
    }

    if(showData){
        return(
        <>
        <NavBar />
        
        <div className="shadow" style={{ margin: "30px" }}>
          
          <div className="container mt-4">
            
          <Button onClick={goBack} style={{ borderRadius: '40%', width: '50px', height: '50px' }}>
                        <AiOutlineArrowLeft style={{ fontSize: '12px' }} ></AiOutlineArrowLeft>
                    </Button>
            <div className="row">
              
              <div className="col-md-12 mx-auto text-center">
                <div className="col-md-1 text-right">
                  <button type="button" className="btn btn-success" onClick={sendBuscar}>Recargar</button>
                </div>
                
                <h1>Reporte de movimientos de cuentas</h1>
                <h3>Empresa:{nombreEmpresa}</h3>
                
              </div>
            </div>

            <div className="row mt-4 mx-auto text-center unshow">
              <div className="col-md-6">
                <div className="">
                  {/* Contenido para la primera columna */}
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Desde:</Form.Label>
                    <p>{fechaInicio}</p>
                  </Form.Group>


                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Hasta:</Form.Label>
                    <p>{fechaFinal}</p>
                  </Form.Group>
                </div>
             
              </div>

              <div className="col-md-6">
                <div className="">
                  <Button variant="success" type="submit" className='my-2' onClick={handleDownloadCSV} >
                    <AiFillFileExcel /> Descargar excel
                  </Button>

                </div>
              </div>
             





            </div>
          </div>
          <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
          <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
            <Table className="my-table printTable" >
              <thead>
                <tr>
                  <th>Agencias</th>
                  <th style={{ width: "200px" }}>Cuenta</th>
                  <th >Fecha</th>
                  <th>Movimiento</th>
                  <th>Cargo</th>
                  <th>Abono</th>
                  <th>Saldo</th>

                </tr>
              </thead>
              <tbody>

            {data.map((props) => (
                <React.Fragment>
                    <tr>
                    <td colSpan={2}>{props.cuenta.nombre}</td>
                    <td colSpan={3}>{props.cuenta.cuenta}</td>
                    <td>Saldo inicial: {props.cuenta.saldo_inicial.toFixed(2)}</td>
                    <td>Saldo final: {props.cuenta.saldo_final.toFixed(2)}</td>
                    </tr>
                    {props.movimientos.map((index) => (
                    <tr key={index.id}>
                        <td>{index.agencia.agencia}</td>
                        <td>{index.cuenta}</td>
                        <td>{index.fecha_ingreso}</td>
                        <td>{index.movimiento}</td>
                        <td>{index.tipo_movimiento == 1 ? index.monto.toFixed(2) : 0.00}</td>
                        <td>{index.tipo_movimiento == 2 ? index.monto.toFixed(2) : 0.00}</td>
                        <td>{index.saldo.toFixed(2)}</td>
                    </tr>
                    ))}
                    <tr>
                    <td></td>
                    <td colSpan={2}>Subtotales</td>
                    <td colSpan={2}>Cargo:{props.subtotales.total_cargo.toFixed(2)}</td>
                    <td colSpan={2}>Abono:{props.subtotales.total_abono.toFixed(2)}</td>
                    </tr>
                </React.Fragment>
                ))}

              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totalCargos.toFixed(2)}</td>
                  <td>{totalAbonos.toFixed(2)}</td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="mx-auto unshow">
              <PaginationControls page={page} onPageChange={(e) => changePagina(e)} />
            </div>
          </div>

        </div>
        </>
        )
    }else{
    return (
        <>
        <NavBar />
      <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
          <Form onSubmit={sendBuscar}>
              <center> <h2>Reporte de movimientos de cuentas por empresa</h2> </center>
              <Form.Check 
                  type="switch"
                  id="custom-switch"
                  label="Busqueda por rango de cuenta"
                  checked
                  onChange={handleChangeSearch}
              />
              <EmpresasSelect
                  onChange={(e) => handleChange(e)}
                  empresa={(e) => setNombreEmpresa(e)}
              />
            
             
                  <>
                    <Form.Label style={{ marginTop: "10px" }}>Cuenta contable inicial</Form.Label>
                      <Autocomplete
                          disablePortal
                          onChange={(e, value) => handleChangeCuentaInicial(e, value)}
                          id='cuenta-inicio'
                          options={cuentasMayor[0]}
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Cuenta Inicial" style={{ width: '100%' }} />}
                      />
                      <Form.Label style={{ marginTop: "10px" }}>Cuenta contable final</Form.Label>
                      <Autocomplete
                          disablePortal
                          onChange={(e, value) => handleChangeCuentaFinal(e, value)}
                          id='cuenta-final'
                          options={cuentasMayor[0]}
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Cuenta Final" style={{ width: '100%' }} />}
                      />
                  </>

              
              <Form.Group>
                  <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia de inicio</Form.Label>
                  <Form.Control type="date" onChange={(e) => setFechaInicio(e.target.value)} />
                  <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia final</Form.Label>
                  <Form.Control type="date" onChange={(e) => setFechaFinal(e.target.value)} />
              </Form.Group>
              <Button variant="primary" type="submit" className='my-2'>
                  Buscar
              </Button>
          </Form>
      </Container>
  </>
    )
}
}