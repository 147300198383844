
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const getRoles = async(token)=>{
    const response = await fetch(`${BASE_URL}/rolxpermiso/roles`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    })
    const data = await response.json();
    return data
}

export const getPermisos = async(data)=>{
    const response = await fetch(`${BASE_URL}/rolxpermiso/getPermisosAll?rol=${data.rol}`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
          }
    })
    const res = await response.json();
    return res
}
export const sendPermisos = async(data) =>{
  
    const response = await fetch(`${BASE_URL}/rolxpermiso/guardarPermisos`,{
        method: "POST",
        headers:{
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            objectChecked: data.objectChecked,
            objectUnchecked: data.objectUnchecked
        })
    })
    const res = await response
    return res
}

export const getRol = async(token)=>{

    const response = await fetch(`${BASE_URL}/rolxpermiso/getRol`, {
        "method": "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    })
    const res = await response.json();
    return res
}
