import { NavBar } from "../../components/navbar";
import '../../css/tableBalanza.css';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { useState,useEffect } from "react";
import '../../css/Tablas.css';
import Button from 'react-bootstrap/Button';
import {AiOutlineSearch} from 'react-icons/ai';
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { editarSubTipoMovBoveda, eliminarSubTipoMovBoveda, getCuentasGenerales, getCuentasGeneralesOP, getSubtiposMovBoveda, getSubtiposMovBovedaOp, getTiposMovBov, guardarSubTipoMovBoveda } from "../../services/subTiposMovBoveda/subtiposMovBoveda";
import { Modal, ModalFooter } from "react-bootstrap";
import { ModalBody, ModalHeader, Row } from "react-bootstrap";
import { getCuentaEmpresas } from "../../services/partidas/partidas";
import { AutocompleteAlter } from "../../components/UTILS/AutoCompleteCuentasEmpresas";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {AiFillQuestionCircle} from 'react-icons/ai'
import Swal from 'sweetalert2'
import { GetCuentasGeneralesOperativas } from "../../services/CuentaContable/CuentaGeneral";
export function SubTiposBovOp (){
    const [search, setSearch] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [nombreMov, setNombreMov] = useState('')
    const [tipoMov, setTipoMov] = useState([])
    const [cuentas, setCuentas] = useState([[]])
    const [idTipoMov, setIdTipoMov] = useState(0)  
    const [cuenta, setCuenta] = useState(null) 
    const [helper, setHelper] = useState(false)
    const [showModalEditar, setShowModalEditar] = useState(false)
    const [nombreEditar, setNombreEditar] = useState('')
    const [tipoMovEditar, setTipoMovEditar] = useState(null)
    const [cuentaEditar, setCuentaEditar] = useState(null)
    const [contraCuentaEditar, setContraCuentaEditar] = useState(null)
    const [idMovEditar, setIdMovEditar] = useState(null)
    const [showTooltip, setShowTooltip] = useState(false)
    const [contraCuenta, setContraCuenta] = useState(null)


    const handleMouseEnter = () => {
      setShowTooltip(true);
    };
  
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    useEffect(() => {
        const fetchCuentas = async () => {
            const object = {
                token: localStorage.getItem('token')
            }
            const subTipos = await getSubtiposMovBovedaOp(object)
            setData(subTipos)
            const res = await getCuentasGeneralesOP(object)
            let options = []
            res.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta+"-"+index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
            const tipoBov = await getTiposMovBov(object)
            setTipoMov(tipoBov)
        }
        fetchCuentas()
       
    }, [page, helper])
    const handleSearch = (value) => {
        console.log("hello")
    }
    const handleGuardarMov = async () => {
        if(nombreMov == '' || idTipoMov == 0 || cuenta == null || contraCuenta == null){
            alert("Llena todos los campos")
        }else{
            const object = {
                token: localStorage.getItem('token'),
                nombre: nombreMov,
                id_tipo_movimiento: idTipoMov,
                cuenta: cuenta,
                contra_cuenta: contraCuenta,
                tipo_empresa: 2
            }
            const res = await guardarSubTipoMovBoveda(object)
          
        if(res.status === 200){
            alert("Subtipo de movimiento agregado con exito")
            setShowModal(false)
            setCuenta(null)
            setContraCuenta(null)
            setHelper(!helper)
        }else{
            alert("Error al agregar el subtipo de movimiento")
        }
        }
    }
    const loadEditarSubtipo = (nombre, id_movimiento, id_subTipo) => {
        setNombreEditar(nombre)
        setTipoMovEditar(id_movimiento)
        setIdMovEditar(id_subTipo)
        setShowModalEditar(true)
    }
    const handleGuardarMovEditado = async () => {
        if(nombreEditar == '' || tipoMovEditar == 0){
            alert("Llena todos los campos")
        }else{
            const object = {
                token: localStorage.getItem('token'),
                nombre: nombreEditar,
                id_tipo_movimiento: tipoMovEditar,
                cuenta: cuentaEditar,
                contra_cuenta: contraCuentaEditar,
                id_subtipo_movimiento: idMovEditar,
               
            }
            const res = await editarSubTipoMovBoveda(object)
        if(res.status === 200){
            alert("Subtipo de movimiento editado con exito")
            setShowModalEditar(false)
            setCuentaEditar(null)
            setContraCuentaEditar(null)
            setHelper(!helper)
        }else{
            alert("Error al editar el subtipo de movimiento")
        }
        }
    }
    const eliminarSubtipo = async(id_subtipo) => {
       Swal.fire({
        title: '¿Estas seguro de eliminar este subtipo de movimiento?',
        text: "No podras revertir esta acción",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
       }).then(async(result) => {
        if (result.isConfirmed) {
            const object = {
                token: localStorage.getItem('token'),
                id_subtipo_movimiento: id_subtipo
            }
            const res = await eliminarSubTipoMovBoveda(object)
            if(res.status === 200){
               Swal.fire(
                'Eliminado!',
                'El subtipo de movimiento ha sido eliminado.',
                'success'
                )
                setHelper(!helper)
                
            }else{
                Swal.fire(
                    'Error!',
                    'El subtipo de movimiento no ha sido eliminado.',
                    'error'
                    )
            }
        }
       })
    }
    return(
        <>
        <NavBar></NavBar>

        <div className="shadow" style={{ margin: '30px auto', maxWidth: '90%', textAlign: 'center' }}>
        <h1 style={{marginBottom:"20px", marginTop:"20px"}}>Subtipos de movimientos de boveda</h1>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft:"10%" }}>
        <Stack direction="horizontal" >
        {/* <Form.Control className="Busqueda" placeholder="Búsqueda de cuenta" style={{ width: "500px" }} value={search} onChange={e => handleSearch(e.target.value.toLowerCase())} />
        <Button onClick={handleSearch}><AiOutlineSearch /></Button> */}
      
        <Button variant="success" onClick={() => setShowModal(true)}>Agregar</Button>
        </Stack>
        <br />
        </div>
        <Table className="my-table" style={{ margin: '20px auto' }}  >
        <thead>
            <tr>
                <th >Nombre</th>
                <th >Tipo de movimiento</th>
                <th>Cuenta predeterminada</th>
                <th>Contra cuenta predeterminada</th>
                <th>Accion</th>
              
            </tr>
        </thead>
        <tbody>
        {data.map((props) => (
            <tr>
                <td>{props.nombre}</td>
                <td>{props.Tipos_Movimiento.nombre}</td>
                <td>{props.cuenta_predeterminada === null ? <>Sin cuenta predeterminada</> : <>{props.cuenta_predeterminada}</>}</td>
                <td>{props.contra_cuenta_predeterminada === null ? <>Sin contra cuenta predeterminada</> : <>{props.contra_cuenta_predeterminada}</>}</td>
                <td>
                    <Button variant="warning" style={{marginRight:'5px', marginTop:"5px"}}onClick={() => loadEditarSubtipo(props.nombre, props.Tipos_Movimiento.id_movimiento, props.id_subtipo_movimiento)}>Editar</Button>
                    <Button variant="danger" style={{marginRight:'5px', marginTop:"5px"}}onClick={() => eliminarSubtipo(props.id_subtipo_movimiento)}>Eliminar</Button>
                </td>
            </tr>
        ))}
        </tbody>
    </Table>

    </div>
        {/* modal para agregar un nuevo concepto */}
        <Modal show={showModal} onHide={() => setShowModal(false)} >
          <ModalHeader>
            <Modal.Title>Agregar un subtipo de movimiento de boveda</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >

            <Form.Group>
        <Form.Label>Digita el nombre del subtipo</Form.Label>
        <Form.Control type="text" onChange={(e) => {setNombreMov(e.target.value)}}/> 
        <Form.Control.Feedback type="invalid">
          Ingresa solo números
        </Form.Control.Feedback>
      </Form.Group>

        <Form.Group>
        <Form.Label>Selecciona el tipo de movimiento</Form.Label>
        <Form.Select onChange={(e) => {setIdTipoMov(e.target.value)}}>
          <option value={0}>Ingresar movimiento</option>
          {tipoMov.map((props) => (
            <option value={props.id_movimiento}>{props.nombre}</option>
           ))}
        </Form.Select>
        </Form.Group>

        <Form.Group>
        <Form.Label>Selecciona la cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setCuenta(e)}

        />

        <Form.Label>Selecciona la contra cuenta predeterminada</Form.Label>
        <AutocompleteAlter
            cuentas={cuentas[0]}
            onChange={(e) => setContraCuenta(e)}

        />

        </Form.Group>



             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleGuardarMov()}>
                Guardar
                </Button>
            </ModalFooter>
        </Modal>
        {/* FIN */}

        {/* MODAL PARA EDITAR CUENTA */}
        <Modal show={showModalEditar} onHide={() => setShowModalEditar(false)} >
          <ModalHeader>
            <Modal.Title>Editar subtipo de movimiento de boveda</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <div >

            <Form.Group>
        <Form.Label>nombre del subtipo de movimiento de boveda</Form.Label>
        <Form.Control type="text" value={nombreEditar} onChange={(e) => {setNombreEditar(e.target.value)}}/> 
        <Form.Control.Feedback type="invalid">
          Ingresa solo números
        </Form.Control.Feedback>
      </Form.Group>

        <Form.Group>
        <Form.Label>Selecciona el tipo de movimiento</Form.Label>
        <Form.Select onChange={(e) => {setTipoMovEditar(e.target.value)}}>
          <option value={0}>Ingresar movimiento</option>
          {tipoMov.map((props) => (
            <option value={props.id_movimiento} selected={props.id_movimiento === tipoMovEditar}>{props.nombre}</option>
           ))}
        </Form.Select>
        </Form.Group>

        <Form.Group>
        <Form.Label style={{margin:"10px"}}>Selecciona la cuenta predeterminada</Form.Label>
        
        <OverlayTrigger
        style={{margin:"10px"}}
        placement="top"
        overlay={<Tooltip id="tooltip">Al dejar este campo vacio, tomara la cuenta predeterminada anterior</Tooltip>}
        //show={showTooltip}
      >
        <Button variant="success"> <AiFillQuestionCircle/> </Button>
        </OverlayTrigger>
        <AutocompleteAlter
                       cuentas={cuentas[0]}
                       onChange={(e) => setCuentaEditar(e)}

        />

        </Form.Group>

        <Form.Group>
        <Form.Label style={{margin:"10px"}}>Selecciona la cuenta predeterminada</Form.Label>
        
        <OverlayTrigger
        style={{margin:"10px"}}
        placement="top"
        overlay={<Tooltip id="tooltip">Al dejar este campo vacio, tomara la contra cuenta predeterminada anterior</Tooltip>}
        //show={showTooltip}
      >
        <Button variant="success"> <AiFillQuestionCircle/> </Button>
        </OverlayTrigger>
        <AutocompleteAlter
                       cuentas={cuentas[0]}
                       onChange={(e) => setContraCuentaEditar(e)}

        />

        </Form.Group>



             </div>
          </ModalBody>

            <ModalFooter>
                <Button variant="secondary" onClick={() => setShowModalEditar(false)}>
                Cerrar
                </Button>
                <Button variant="primary" onClick={() => handleGuardarMovEditado()}>
                Guardar
                </Button>
            </ModalFooter>
        </Modal>
        {/* FIN */}
        </>
    )
}