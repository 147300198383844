import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getPermisos, sendPermisos } from '../../services/users/rolesXPermisos';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';
export function AsignarPermisosModal(props){
    const[permisos, setPermisos] = useState([])
    const [col1, setCol1] = useState([])
    const [col2, setCol2] = useState([])
    const [atribute, setAtribute] = useState([])
    const token = localStorage.getItem('token')
    
    useEffect(() => {
        const traerPermisos = async () => {
            try {
                const object = {
                    token: token,
                    rol: props.idRol
                }
                const data = await getPermisos(object);
                let atributes = []
                let index = 0
                data.forEach(element => {
                   if(element.rolXPermisos.length === 0){
                   atributes[index] = false
                   }else{
                    atributes[index] = true
                   }
                   index +=1
                });
               
                setAtribute(atributes)
                setPermisos(data);
              
            } catch (error) {
             

            }
        };
        traerPermisos();
    }, [props.show]);
    
    const handleChange = (e) => {
      const helper = [...atribute]
      helper[e.target.id-1] =  !helper[e.target.id-1]
      setAtribute(helper)
    }
    const handleSend = async() => {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      let objectChecked = []
      let objectUnchecked = []
      checkboxes.forEach((checkbox, index) => {
        
        const key = checkbox.getAttribute('identifier');
        const label = checkbox.nextElementSibling.textContent;
        const isChecked = checkbox.checked;
        if(isChecked){
          const object = {rol: props.idRol, permisos: key}
          objectChecked.push(object)
        }else{
          const object = {rol: props.idRol, permisos: key}
          objectUnchecked.push(object)
        }

      });
        const object = {
          token: token,
          objectChecked: objectChecked,
          objectUnchecked: objectUnchecked
        }
        const response = await sendPermisos(object)
       if(response.status === 200){
        Swal.fire({
          title: 'Exito',
          text: 'Registro añadido exitosamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
      })
      props.onHide()
       }else{
        Swal.fire({
          title: 'Fallo',
          text: 'Hubo un problema al ingresar los permisos',
          icon: 'error',
          confirmButtonText: 'Aceptar'
      })
       }
    }
    return (
        <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Permisos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row" >
                 {permisos.map((props, index) => (
                    
                    <>

                     <div className="col-sm-4">
                        <Form.Check // prettier-ignore
                         type={'checkbox'}
                         id={index+1}
                         identifier={props.id_permiso}
                         key={props.id_permiso}
                         label={props.descripcion}
                         checked={atribute[index]}
                        onChange={(e) => {handleChange(e)}}
                       />
                    </div>

                    <br/>
                    </>
                    ))}
                    </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleSend}>
            Guardar
          </Button>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
        
        </Modal.Footer>
      </Modal>
    )
}