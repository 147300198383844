import { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import {Logo} from '../../components/UTILS/logo'
import { login } from '../../services/users/login';
import { Modal_load } from '../../components/UTILS/modal_load';
import {useNavigate} from 'react-router-dom'
import LoginModule from './Login.module.css'
import Modal from 'react-bootstrap/Modal';
import {BiErrorAlt} from 'react-icons/bi'
export function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [alerta, setAlerta] = useState('')
  const [showAlerta, setShowAlerta] = useState(false)
  const [recuerdame, setRecuerdame] = useState(false)
  const navigate = useNavigate();

  const handleLogin = async(event) => {
    setModalShow(true)
    event.preventDefault();
    var object = {username, password, recuerdame}
    const data = await login(object)

   
   if(data.status >= 401 || data.status <= 500){
   
    setAlerta('Credenciales erroneas')
    setModalShow(false)
    setShowAlerta(true)
   }else{
     localStorage.setItem("token", data);
     setAlerta('')
     setModalShow(false)
     navigate('/')
   }
  };
  const handleRecuerdame = (e) => {
    if(e.target.checked == true){
      setRecuerdame(true)
    }else{
      setRecuerdame(false)
    }
  }

  return (
<div>
<Modal_load
        show={modalShow}
        texto="Comprobando informacion"
        onHide={() => setModalShow(false)}
        />
    <Modal
          show={showAlerta}
          size="sm"
          centered
        >
          <Modal.Body className="text-center">
            <h4>{alerta}</h4>
            <BiErrorAlt color='red' size={50} className="my-2"></BiErrorAlt><br />
            <Button variant="primary" onClick={()=> setShowAlerta(false)}>
            Cerrar
          </Button>
          </Modal.Body>
        </Modal>
<div class={LoginModule.loginContainer}>
	<form class={LoginModule.formLogin}  onSubmit={handleLogin}>
		<ul class={LoginModule.loginNav}>
			<li class={LoginModule.loginNavActive}>
				<a href="#">Contabilidad Integral Altercredit</a>
			</li>
		</ul>
		<label for="login-input-user" class={LoginModule.login__label}>
			Usuario
		</label>
		<input id="username" class={LoginModule.login__input} type="text"  onChange={(e) => setUsername(e.target.value)}/>
		<label for="login-input-password" class={LoginModule.login__label}>
			Contraseña
		</label>
		<input id="password" class={LoginModule.login__input} type="password" onChange={(e) => setPassword(e.target.value)}/>
		<label for="login-sign-up" class={LoginModule.login__label__checkbox}>
			<input id="login-sign-up" type="checkbox" class={LoginModule.login__input__checkbox} onChange = {(e) => handleRecuerdame(e)}/>
			Recuerdame
		</label>
		<button class={LoginModule.login__submit} id="login">Iniciar sesion</button>
	</form>
	{/* <a href="#" class={LoginModule.login__forgot}>¿Olvidaste tu contraseña?</a> */}
</div>
</div>

  );
}

