const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const CarteraGeneral = async (data) => {
    const response = await fetch(`${BASE_URL}/InfoRed/carteraGeneral`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        inicio: data.inicio,
        fin: data.fin,
        finReal: data.finReal
      })
    });
    const res = await response.json();
    return res
  };

  export const CarteraGeneralEmpleados = async (data) => {
    const response = await fetch(`${BASE_URL}/InfoRed/carteraGeneralEmpleados`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        inicio: data.inicio,
        fin: data.fin,
        finReal: data.finReal
      })
    });
    const res = await response.json();
    return res
  };

  export const CarteraGeneralVencidos = async (data) => {
    const response = await fetch(`${BASE_URL}/InfoRed/carteraGeneralVencidos`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        inicio: data.inicio,
        fin: data.fin,
        finReal: data.finReal,
        tipo: data.tipo
      })
    });
    const res = await response.json();
    return res
  };

  export const eliminarCreditos = async (data) => {
    const response = await fetch(`${BASE_URL}/InfoRed/eliminarCreditos`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${data.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        datos: data.data
      })
    });
    const res = await response.status;
    return res
  };