import { NavBar } from "../../components/navbar"
import { useState,useEffect } from "react"
import '../../css/Tablas.css'
import '../../css/Modal.css'
import '../../css/favoritos.css'
import '../../css/selectedDesigns.css'
import { getPaginas } from '../../services/menu_favoritos/GetPaginas.js'
import { getFavoritos } from "../../services/menu_favoritos/GetFavoritos"
import Button from "react-bootstrap/esm/Button"
import { UpdateFavorite } from "../../services/menu_favoritos/UpdateFavorite"
import Swal from "sweetalert2";
import { BotonSave } from "../../components/UTILS/BotonSave"


export function Menu_favoritos(){
  const token = localStorage.getItem('token')
  const [paginas, setPaginas]= useState([])
  const [selectOption1, setSelectOption1] = useState('')
  const [selectOption2, setSelectOption2] = useState('')
  const [selectOption3, setSelectOption3] = useState('')
  const [selectOption4, setSelectOption4] = useState('')
  const tarjetas = ["Primer tarjeta","Segunda tarjeta", "tercer tarjeta", "cuarta tarjeta"]
  const SelectOption = [selectOption1,selectOption2,selectOption3,selectOption4]
  const SetSelectOption = [setSelectOption1,setSelectOption2,setSelectOption3,setSelectOption4]

  useEffect(() => {
    const pagina = async() =>{
      try{
        const data = await getPaginas(token)
        setPaginas(data)
      } catch(error){
       
      }
    }
    pagina()

    const favoritos = async() =>{
      try{
        const dataFavoritos = await getFavoritos(token)
        setSelectOption1(dataFavoritos[0].id_pagina1)
        setSelectOption2(dataFavoritos[0].id_pagina2)
        setSelectOption3(dataFavoritos[0].id_pagina3)
        setSelectOption4(dataFavoritos[0].id_pagina4)
      } catch(error){

      }
    }
    favoritos()
  },[])


  const editFavoritos = async() => {
    if (selectOption1 === selectOption2 || selectOption1 === selectOption3 || selectOption1 === selectOption4 ||
        selectOption2 === selectOption3 || selectOption2 === selectOption4 ||
        selectOption3 === selectOption4) {
      Swal.fire({
        title: 'Error',
        text: 'Las opciones no pueden ser iguales',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return; // Detener la ejecución de la función si las opciones son iguales
    }

    var object = {token, Option1: selectOption1, Option2: selectOption2, Option3: selectOption3, Option4: selectOption4}


      const data = await UpdateFavorite(object)
      if(data.status == 200){
        Swal.fire({
          title: 'Operacion completada',
          text: 'Se ha modificado los favoritos',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            // Redireccionar a una página específica
            window.location.href = '/';
          }
        });
      }else{
        Swal.fire({
          title: 'Operacion incompleta',
          text: 'La operacion no pudo completarse',
          icon: 'danger',
          confirmButtonText: 'Aceptar'
        });
        
      }
    
  }

  

  return(
    <>
      <NavBar></NavBar>
      <body><br/><br/>
        <div className="Encabezado" >
          <h1>Favoritos</h1>
        </div><br/>
        <div className="favoritos">
          <p>Seleccion de las tajetas favoritas a mostrar en pantalla principal</p>
          {tarjetas.map((item,index) => (
            <>
              <div className="seleccion">
                <p>{item}</p>
                </div>

                <div className="opciones">
                <select value={SelectOption[index] } onChange={(e)=> {SetSelectOption[index](e.target.value)}}>
                {
                paginas.map((props) => (
                <option key={props.id_pagina} value={props.id_pagina}>{props.nombre}</option>
                ))
                }
                </select>
              </div><br/><br/><br/><br/>

            </>
          ))}
          <br/><br/>
          <div style={{ width: "500px"}}>
            <BotonSave metodo={editFavoritos}></BotonSave>
          </div>
        </div>
      </body>
    </>
  );
}