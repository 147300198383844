import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

export function CardComponent({ cardImagen, cardProfile, cardName, cardBio, URL}) {
  const [effect, setEffect] = useState('zoom');
  const navigate = useNavigate();

  const handleToggleEffect = (event) => {
    const { target } = event;
    setEffect(target.dataset.effect);

    toggleEffect(event);
  };
 
  const toggleEffect = (event) => {
    const target = event.target;
    const effect = target.dataset.effect;
       
    target.classList.add('active');
    const card = document.querySelector('.card');
    card.dataset.effect = effect;
  };
  
  const handleCardClick = () => {
    navigate(URL);
  };
      

  return (
    <section className="wrapper">
      <div className="card-link" onClick={handleCardClick}>
        <div className={`card`} data-effect={'effect'}>
          <figure className="card__image">
            <img src={cardImagen} alt="Short description" />
          </figure>
          <div className="card__header">
            <figure className="card__profile">
              <img src={cardProfile} alt="Short description" />
            </figure>
          </div>
          <div className="card__body">
            <h3 className="card__name">{cardName}</h3>
            <p className="card__bio">{cardBio}</p>
          </div>
        </div>
      </div>
    </section>
  );
  }