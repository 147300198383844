import { getAgencias } from "../../services/geolocalizacion/places";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { propTypes } from "react-bootstrap/esm/Image";



export function AgenciasSelect(props){
    const [agencias, setAgencias] = useState([[]])
    const [idAgencias, setIdAgencias] = useState('')

    useEffect(() => {
    const traerAgencias = async () => {
        const token = localStorage.getItem('token');
        const object = {
          token: token
        }
        const res = await getAgencias(object);
        
        setAgencias(res)
  
      }
  
      traerAgencias()
    }, [])

    const handleChange = (e) => {
        setIdAgencias(e.target.value);
        props.onChange(e.target.value)
    }

    return (
        <>
       
           <Form.Select aria-label="Default select example"  onChange={handleChange}>
                        <option key={0}>Seleccione una agencia</option>
                       {agencias.map((item) => (
                        <option value={item.id_agencia} key={item.id_agencia}>{item.agencia}</option>  
                      ))}
            </Form.Select>
        </>
        )
}