import '../../css/BtnSave.css';

export function BotonSave({ metodo }){
    const handleClick = () => {
        if(metodo){
            metodo();
        }
    }
    return(
        <div className="social-btns">
            <a className="btn save" onClick={handleClick}>
                <i className="btn-save">Guardar</i>
            </a>
        </div>
    );
}