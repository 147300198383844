import { NavBar } from "../../components/navbar";
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import '../../css/Tablas.css';
import '../../css/Modal.css';

import { getSolicitudes, setAprobar } from "../../services/CuentaContable/SolicitarCambioSaldo";

export default function RevisarCambioSaldo(){
    const [aprobadas, setAprobadas] = useState([])
    const [noAprobadas, setNoAprobadas] = useState([])
    const token = localStorage.getItem('token')
    const [helper, setHelper] = useState(false)
    const [show, setShow] = useState(true)
    useEffect(() => {
     
        const solicitudes = async() => {
            const object = {
                token: token,
            }
            const res = await getSolicitudes(object)
            const solicitudes = await res.json()
         
            if(res.status === 200){
            setAprobadas(solicitudes.solicitudes_aprobadas)
            aprobadas.forEach(element => {
                if(element.cambio_saldo.estado !=4){
                    element.comentarioEstado = 'Pendiente'
                }else{
                    element.comentarioEstado = 'Aprobado'
                }
            });
           
            setNoAprobadas(solicitudes.solicitudes_sin_aprobar)
            setShow(true)
          }else{
            setShow(false)
          }
        }

        solicitudes()
        
      }, [helper]);

      const handleAprobar = async(id_solicitud) => {
       
        Swal.fire({
            title: 'Alerta',
            text: 'Se aprobara este cambio',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
          }).then(async (result) => {
            if(result.isConfirmed){
            const object = {
                token: token,
                id_solicitud: id_solicitud
            }
              const res = await setAprobar(object)
              const response = await res
              if(response.status === 200){
                Swal.fire({
                    title: 'Exito',
                    text: 'Se aprobo este cambio',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                  })
                  setHelper(!helper)
              }else{
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrio un error inesperado',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                  })
              }
            }
          })
      }
      if (show) {
        
      
    return(
        <>
        <NavBar></NavBar>
        <div className="Encabezado" style={{textAlign: "center"}}>
          <h1 style={{color: "white", marginTop: "35px"}}>Solicitudes de cambio de saldo</h1>
        </div><br/>
        <div className="Busqueda"><br/>
          <div style={{marginLeft: "24px"}}>
            <Stack direction="horizontal" gap={2}>

               
            </Stack><br/>
            <Table className="my-table">
              <thead>
                <tr>
                  <th>Usuario solicitando</th>
                     <th>Numero de cuenta</th>
                     <th>Nombre de la cuenta</th>
                     <th>Nuevo saldo</th>
                     <th>Fecha que lo solicito</th>
                     <th>Estado</th>
                  <th style={{width: "300px"}}>Acciones</th>
                 
                </tr>
              </thead>
              <tbody>
                
                {aprobadas.map((props) => (
                  <tr>
                    <td>{props.cambio_saldo.login.username}</td>
                    <td>{props.cambio_saldo.cuentum.numero_cuenta}</td>
                    <td>{props.cambio_saldo.cuentum.nombre_cuenta}</td>
                    <td>${props.cambio_saldo.saldo_solicitando}</td>
                    <td>{props.cambio_saldo.fecha_solicitar}</td>
                    {props.cambio_saldo.estado != 4  ?  (
                    <>
                    <td>Pendiente</td>
                    </>
                ):(<>
                    <td>Aprobado</td>
                    </>)}
                    
                 
                    <td >
                    <Button variant="success" disabled>Aprobado</Button>
                    <span style={{ margin: '0 5px' }}></span>
                   
                    </td>
                  </tr>
                ))}
                 {noAprobadas.map((props) => (
                  <tr>
                    <td>{props.login.username}</td>
                    <td>{props.cuentum.numero_cuenta}</td>
                    <td>{props.cuentum.nombre_cuenta}</td>
                    <td>${props.saldo_solicitando}</td>
                    <td>{props.fecha_solicitar}</td>
                    {props.estado != 4  ?  (
                    <>
                    <td>Pendiente</td>
                    </>
                ):(<>
                    <td>Aprobado</td>
                    </>)}
                    
                 
                    <td >
                    <Button variant="primary" onClick={() => {handleAprobar(props.id)}}>Aprobar</Button>
                    <span style={{ margin: '0 5px' }}></span>
                   
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> 
          </div>
        </div>
        </>
    )
  }else{
    return(
      <>
      <NavBar></NavBar>
      <p style={{ fontSize: '24px', color: 'white', textAlign: 'center', margin: '20px' }}>
    Actualmente su usuario no puede aprobar cambios de saldos en la cuenta, si cree que es un error comuníquese con su superior
  </p>
      </>
    )
      }
}