import { Button, Container, Form } from "react-bootstrap";
import { EmpresasSelect } from "./EmpresasSelect";
import { EmpresaXAgenciaSelect } from "./EmpresaXAgenciaSelect";
import { useState } from "react";

export function Buscador(props){
    const [agencia, setAgencia] = useState(0)
    const {setBuscar,setempresa, setNombreEmpresa, setagencia, setfechainicio, setfechafinal, empresa, titulo, mostrarTodas} = props
    
    const search = (e) => {
        e.preventDefault()
        setBuscar()
    }
    return (
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
        <Form onSubmit={(e) => search(e)}>

          <center> <h2>{titulo}</h2> </center>
        {/* onChange es una funcion del componente no un evento como tal de la etiqueta */}
        
          <EmpresasSelect
            onChange={(e) => setempresa(e)}
            empresa={(e) => setNombreEmpresa(e)}
          />

           <EmpresaXAgenciaSelect
                    IdEmpresa = {empresa}
                    onChange= {(e) => setagencia(e)}
                    setNombreAgencia = {(e) => setAgencia(e)}
                    getNombreAgencia = {false}
                    showlabel = {true}
                    showTodas = {mostrarTodas}
          />





          <Form.Group >
            <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia de inicio</Form.Label>
            <Form.Control type="date" onChange={(e) => setfechainicio(e.target.value)} />

            <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia final</Form.Label>
            <Form.Control type="date" onChange={(e) => setfechafinal(e.target.value)} />
          </Form.Group>


          <Button variant="primary" type="submit" className='my-2' >
            Buscar
          </Button>

        </Form>
      </Container>
    )
}