// store/reducers/exampleReducer.js
const initialState = {
    agencia: 0,
    fecha: ""
  };
  
  const agenciaReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CHANGE':
        return {
          ...state,
          agencia: action.payload.agencia,
          fecha: action.payload.fecha,
          id_empresa: action.payload.id_empresa
        };
        default:
            return state;
        
    }
  };
  
  export default agenciaReducer;