import React, { useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';


export const ProtectedRoute = ({component: Component, rolesPermitidos}) => {
  const history = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      history('/login');
    } else {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token');
        history('/login');
      }
      if(rolesPermitidos !== undefined){
        if(decodedToken.user.rol !== rolesPermitidos){
          history('/')
        }
      }
    }
  }, [history]);

  return <Component></Component>;
};

