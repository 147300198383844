import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL

export const UpdateFavorite = async(data) => {
    console.log(data)
    const response = await fetch(`${BASE_URL}/Menu_favoritos/UpdateFavorite`,{
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${data.token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id_pagina1: data.Option1,
            id_pagina2: data.Option2,
            id_pagina3: data.Option3,
            id_pagina4: data.Option4
        })
    })
    
    const res = await response;
    return res
}