import { useState } from "react";
import { NavBar } from "../../components/navbar";
import { Buscador } from "../../components/UTILS/Buscador";
import { getCargosAbonos } from "../../services/reportes/reporteCuadratura";
import { Button, Table } from "react-bootstrap";
import Swal from "sweetalert2";
export function CuadraturaMovimientos() {

    const [buscar, setBuscar] = useState(true);
    const [empresa, setEmpresa] = useState(0);
    const [agencia, setAgencia] = useState(0);
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");
    const [data, setData] = useState({});
    const [nombreEmpresa, setNombreEmpresa] = useState("");

    const handlerBuscar = async () => {
        const object = {
            empresa: empresa,
            agencia: agencia,
            inicio: fechaInicio,
            fin: fechaFinal,
            token: localStorage.getItem('token')
        }
        const data = await getCargosAbonos(object)
        setData(data.object)
        setBuscar(false)
        console.log(data.object)
    }
    const recargar = () => {
      Swal.fire({
        title: 'Cargando',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      handlerBuscar()
      Swal.close()
    }


    if(buscar){
    return(
        <>
              <NavBar />
            <Buscador 
                setempresa = {(e) => {setEmpresa(e)}}
                setagencia = {(e) => {setAgencia(e)}}
                setfechainicio = {(e) => {setFechaInicio(e)}}
                setfechafinal = {(e) => {setFechaFinal(e)}}
                setNombreEmpresa = {(e) => {setNombreEmpresa(e)}}
                titulo = {"Cuadratura de Movimientos"}
                empresa = {empresa}
                setBuscar = {handlerBuscar}
                mostrarTodas = {true}
            />
        </>
    )
    }else{
    
    return(
        <>
              <NavBar />
              <h1>Cuadratura de movimientos cargos y abonos</h1>
         <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
              <Button variant="success" style={{ marginTop: "15px", marginLeft: "40px" }} onClick={() => recargar()}>Recargar</Button>
              <Button variant="primary" style={{ marginTop: "15px", marginLeft: "40px" }} onClick={() =>  setBuscar(true)}>Regresar</Button>
          <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
            <Table className="my-table printTable">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th style={{ width: "200px" }}>Cargo</th>
                  <th >Abono</th>
                  <th>Diferencia</th>
                </tr>
              </thead>
              <tbody>

                {data.cargos.map((props, index) => (
                    <tr>
                      <td>{props.Dia}</td>
                      <td style={{ textAlign: 'right' }}>{props.SumaMontos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'right' }}>{data.abonos[index].SumaMontos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'right' }}>{(props.SumaMontos - data.abonos[index].SumaMontos).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                     
                    </tr>
                  ))}
                
              </tbody>
            </Table>

          </div>
        </>
    )
}
}