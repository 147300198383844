import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Form } from 'react-bootstrap';
import { getConceptos } from '../../services/concepto/getConceptos'
import { getsingleRegistro } from '../../services/partidas/partidas';
import { updateRegistro, getCuenta } from '../../services/partidas/partidas';
import Swal from 'sweetalert2';
export const EditarModal = (props) => {
    const token = localStorage.getItem('token');
    const [option, setOptions] = useState([[]])
    const [tipoMovimiento, setTipoMovimiento] = useState()
    const [registro, setRegistro] = useState([[]])
    const [concepto, setConcepto] = useState("")
    const [cuenta, setCuenta] = useState("")
    const [monto, setMonto] = useState("")
    const [cuentas, setCuentas] = useState([[]])

    useEffect(() => {
        const fetchDataCuenta = async () => {
            const cuentas = await getCuenta(token)
            let options = []
            cuentas.map(((index, i) => {
                options[i] =
                {
                    label: index.nombre_cuenta + "-" + index.numero_cuenta,
                    value: index.numero_cuenta
                }

            }))
            setCuentas([options])
        }
        fetchDataCuenta()
    }, [])

    const handleChange = (e, value) => {
       
        setConcepto(value.label)
    }
    const handleChangeCuenta = (key, value) => {

       
        const previewCuentas = value.value
        setCuenta(previewCuentas)
    }
    const handleChangeConcepto = (e) => {
        setConcepto(e.target.value)
    }

    const handleChangeMonto = (e) => {
        setMonto(e.target.value)
    }
    const handleChangeTipoMovimiento = (e) => {
        setTipoMovimiento(e.target.value)
    }
    const handleSave = async () => {
        const object = {
            agencia: props.agencia,
            cuenta_contable: cuenta,
            monto: monto,
            id_tipos_movimientos: tipoMovimiento,
            concepto: concepto,
            id_registro: props.id
        }
        const data = { token, object }
        const call = await updateRegistro(data)
        if (call === 200) {
            Swal.fire({
                title: 'Exito',
                text: 'Se edito exitosamente',
                icon: 'success',
                allowOutsideClick: false,
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    props.save()
                };
            });;
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Se produjo un error',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }

    }
    useEffect(() => {
        const fetchDataconcepto = async () => {
            const conceptos = await getConceptos(token)
            let options = []
            conceptos.map(((index, i) => {
                options[i] =
                    { label: index.descripcion }

            }))
            setOptions([options])

        }
        fetchDataconcepto()
        const fetchDataRegistro = async () => {
            if (props.agencia != 0) {
                const object = { idAgencia: props.agencia, idRegistro: props.id, token: token }
                const registro = await getsingleRegistro(object)
                setRegistro(registro)
                setCuenta(registro[0].cuenta_contable)
                setConcepto(registro[0].concepto)
                setMonto(registro[0].monto)
                setTipoMovimiento(registro[0].id_tipos_movimientos)
            }
        }
        fetchDataRegistro()
      
    }, [props.id])

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar registro #{props.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label className='mt-2'>Cuenta contable</Form.Label>
                        <Autocomplete


                            disablePortal
                            onChange={handleChangeCuenta}
                            id={props.id + 1}
                            options={cuentas[0]}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Cuenta"
                                id='0'
                                style={{ width: '100%' }}


                            />}
                        />
                        <Form.Label>Concepto</Form.Label>
                        <Autocomplete

                            freeSolo={true}
                            disablePortal
                            id={props.id + 1}
                            onChange={handleChange}
                            value={concepto}
                            options={option[0]}
                            sx={{ width: 300 }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Conceptos"
                                onChange={handleChangeConcepto}
                            />}
                        />
                        <Form.Label>Monto</Form.Label>
                        <Form.Control type="number" name='monto' placeholder="$$$" onChange={handleChangeMonto} itemID={props.id + 1} value={monto} />
                        <Form.Label className='mt-3'>Tipo de movimiento</Form.Label>
                        <Form.Select onChange={handleChangeTipoMovimiento} value={tipoMovimiento}>
                            <option value="">Seleccione una opcion</option>
                            <option value={1}>Cargo</option>
                            <option value={2}>Abono</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSave} >
                    Guardar cambios
                </Button>
            </Modal.Footer>
        </Modal>
    )
}