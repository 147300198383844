import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { getTipos, getCuenta, getCuentaEmpresas} from "../../services/partidas/partidas";
import { Form } from 'react-bootstrap';
export function AutocompleteAlter(props){
    const [cuentas, setCuentas] = useState([])
    const [cuenta, setCuenta] = useState('')
    const token = localStorage.getItem('token');


    const handleChangeCuenta = (key, value) => {

        const previewCuentas = [...cuenta]
        const id = (key.target.id)
        const index = id.split('-')
        previewCuentas[index[0]] = value.value
        setCuenta(previewCuentas)
        props.onChange(previewCuentas)

    }

    return(
        <>
       
        <Autocomplete
        disablePortal
        onChange={handleChangeCuenta}
        id='0'
        options={props.cuentas}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Cuenta"
            id='0'
            style={{ width: '100%' }}
        />}
    />
    </>
    )

}