import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export const LoginRedirect = ({ component: Component }) => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          navigate('/'); // redirige a la página de inicio de la aplicación si el token es válido
        } else {
          localStorage.removeItem('token');
          navigate('/login'); // redirige a la página de inicio de sesión si el token no es válido
        }
      } else {
        navigate('/login'); // redirige a la página de inicio de sesión si no hay token
      }
    }, [navigate]);
  
    return <Component />;
  };
