import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { traerUsuarios } from '../../services/users/getUsuarios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Form } from 'react-bootstrap';
import { insert_usuarios } from '../../services/CuentaContable/SolicitarCambioSaldo';
import Swal from 'sweetalert2';
import { traer_usuarios } from "../../services/CuentaContable/SolicitarCambioSaldo";

export function ModalAgregarUsuario(props){
  const token = localStorage.getItem('token')
  const [users, setUsers] = useState([])
  const [showLabels, setShowLabels] = useState([[]])
  const [idUser, setIduser] = useState(0)
  const [helper, setHelper] = useState(false)
  useEffect(() => {
     
      const getUsers = async() => {
      
        const res = await traerUsuarios(token)

        const object = {
          token: token
        }
        const response = await traer_usuarios(object)
        const resultado = await response.json()
       

        setUsers(await res)
       
        if(resultado.length < 3){
          setHelper(true)
        }else{
          setHelper(false)
        }
        const labels = []
        users.forEach((element, index) => {
          labels[index] = {label: element.username, id:element.id_usuario}
        });
        setShowLabels(labels)
      }
      getUsers()
    
  }, [props.show]);


  const handleChangeUser = (key, value) => {
    setIduser(value.id)

}
  const saveChanges = async() => {
    if(idUser === 0 ){
      Swal.fire({
        title: 'Error',
        text: 'Elija un usuario',
        icon: 'error',
        confirmButtonText: 'Aceptar'
    })
    }else{
  
    const object = {
      token: token,
      idUsuario: idUser
    }
    const res = await insert_usuarios(object)
    const response = (await res)
    if(response.status === 200){
      Swal.fire({
        title: 'Exito',
        text: 'Se inserto el usuario con exito',
        icon: 'success',
        confirmButtonText: 'Aceptar'
    })
    props.onHide()
    }else if(response.status === 406){
      Swal.fire({
        title: 'Error',
        text: 'Este usuario ya es parte de la lista',
        icon: 'error',
        confirmButtonText: 'Aceptar'
    })
    props.onHide()
    }else{
      Swal.fire({
        title: 'Error',
        text: 'Se produjo un error',
        icon: 'error',
        confirmButtonText: 'Aceptar'
    })
    }
  }
  }
    if(helper === false){
      return(
      <>
      <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Ya no es posible agregar usuarios, si desea agregar uno, primero elimine uno de los existentes
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cerrar
        </Button>
       
      </Modal.Footer>
    </Modal>
      </>
      )
    }else{
    return (
        <>
        <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Label>Usuario</Form.Label>
        {showLabels.length !== 0 ? (
            <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={showLabels}
          onChange={handleChangeUser}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="User" />}
        />
        ): (<></>)}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cerrar
          </Button>
          <Button variant="primary"  onClick={saveChanges}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
    }
}