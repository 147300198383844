import {API_production} from "../general/link";
import { API_DEV } from "../general/link";
const MODE = process.env.NODE_ENV
let BASE_URL;

BASE_URL = process.env.REACT_APP_API_URL


export const getMovimientosCuenta = async (data) => {
    const response = await fetch(`${BASE_URL}/reportes/reporteLibroMayor`,{
        "method": "POST",
      headers: {
          "Authorization": `Bearer ${data.token}`,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
             mes: data.mes,
             empresa: data.empresa,
             page: data.page,
             cuenta: data.cuenta,
             agencia: data.agencia,
         })
   })
   const respuesta = await response.json()
   return respuesta
}