import { NavBar } from "../../components/navbar";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { getRoles } from "../../services/users/rolesXPermisos";
import { AsignarPermisosModal } from "../../components/rolComponents/asignarPermisosModal";
export function ControlRoles(){
    const [roles, setRoles] = useState([])
    const token = localStorage.getItem('token')
    const [modalShow, setModalShow] = useState(false)
    const [idrol, setIdRol] = useState(0)

    useEffect(() => {

        const traerRoles = async () => {
            try {
                const data = await getRoles(token);
               
                setRoles(data);
              
            } catch (error) {
                console.log("error" + error);

            }
        };
        traerRoles();
    }, []);
    function agregarPermiso(idRol){
        setIdRol(idRol)
        setModalShow(true)
    }
    return (
        <>
        <NavBar></NavBar>

        <AsignarPermisosModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        idRol= {idrol}
        />

       <center> <h2 style={{marginTop:'2%'}}>Asignar permisos a roles</h2> </center>
        <div className="d-flex justify-content-center">
        <Table striped bordered hover className="table-secondary mx-5 shadow-lg ">
            <thead style={{marginTop:'20px'}}>
                <th>Rol</th>
                <th>Accion</th>
            </thead>
            <tbody>
            {roles.map((props) => (
                            <tr>
                                <td>{props.rol}</td>
                              
                               
                                <td>

                                    <Button variant="success" onClick={()=>agregarPermiso(props.id_rol) }>Agregar permiso</Button>
                                </td>
                            </tr>
                    ))}
            </tbody>
        </Table>
        </div>
        </>
    )
}