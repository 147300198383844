import React,{useEffect, useState, useRef} from "react";
import { NavBar } from "../../components/navbar";
import { Container, Form, Button } from "react-bootstrap";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import Logo from "../../components/UTILS/logo";
import Table from 'react-bootstrap/Table';
import '../../css/tableBalanza.css';
import '../../css/Modal.css';
import '../../css/printTable.css';
import { getRegistrosBalance, getRegistrosBalanceAgencia } from "../../services/reportes/reportesBalanza";
import { PaginationControls } from "../../components/UTILS/PaginationControls";
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from "react-to-print";
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { EmpresaXAgenciaSelect } from "../../components/UTILS/EmpresaXAgenciaSelect";
import { getRegistro } from "../../services/CuentaContable/CuentaGeneral";

export function BalanzaAgencia() {
  const [hasdata, setHasdata] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [page, setPage] = useState(1);
  const [empresa, setEmpresa] = useState(null);
  const [datos, setDatos] = useState([]);
  const [nombreEmpresa, setNombreEmpresa] = useState(null);
  const tableRef = useRef();
  const [isTableReady, setIsTableReady] = useState(false);
  const [idAgencia, setIdAgencia] = useState(null)
  const [agencia, setAgencia] = useState(null)

  useEffect(() => {
    if (datos.length > 0) {
      setIsTableReady(true);
    }
  }, [datos]);

  const handleDownloadPDF = () => {
    window.print();
  };

  const handleDownloadCSV = async () => {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const object = {
      empresa: empresa,
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
      page: 0,
      token: localStorage.getItem("token"),
      agencia: idAgencia
    };

    const result = await getRegistrosBalanceAgencia(object);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(result);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const excelUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = excelUrl;
    let mes = fechaFinal.substring(0, 7);
    link.download = `BALANZA-${agencia}-${mes}.xlsx`;
    link.click();
    Swal.close();
  };

  const sendBuscar = async (e) => {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    e.preventDefault();
    const object = {
      empresa: empresa,
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
      page: page,
      token: localStorage.getItem("token"),
      agencia: idAgencia
    };
    const setData = await getRegistrosBalanceAgencia(object);
    setHasdata(true);
    setDatos(setData);
    Swal.close();
  };

  const changePagina = async (e) => {
    await setPage(e);
    const object = {
      empresa: empresa,
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
      page: e,
      token: localStorage.getItem("token"),
      agencia: idAgencia
    };
    const setData = await getRegistrosBalanceAgencia(object);
    setHasdata(true);
    setDatos(setData);
  };

  const handleChange = (e) => {
    setEmpresa(e);
  };

  if (hasdata) {
    return (
      <>
        <NavBar />
        <div className="shadow" style={{ margin: "30px" }}>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-12 mx-auto text-center">

                <h1>Reporte de balanza de comprobacion al {fechaFinal}</h1>
                <h3>Empresa:{nombreEmpresa}</h3>
              </div>
            </div>

            <div className="row mt-4 mx-auto text-center unshow">
              
              {/* <div className="col-md-6">
                <div className="">
                  
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Desde:</Form.Label>
                    <p>{fechaInicio}</p>
                  </Form.Group>


                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Hasta:</Form.Label>
                    <p>{fechaFinal}</p>
                  </Form.Group>
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="">
                  <Button variant="success" type="submit" className='my-2' onClick={handleDownloadCSV} >
                    <AiFillFileExcel /> Descargar excel
                  </Button>

                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <Button variant="danger" type="submit" className='my-2' onClick={handleDownloadPDF} >
                    <AiFillFilePdf /> Descargar PDF
                  </Button>

                </div>
              </div>





            </div>
          </div>
          <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
          <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
            <Table className="my-table printTable" ref={tableRef}>
              <thead>
                <tr>
                  <th>Cuenta</th>
                  <th style={{ width: "200px" }}>Descripcion</th>
                  <th >Saldo anterior</th>
                  <th>Cargos</th>
                  <th>Abonos</th>
                  <th>Saldo actual</th>

                </tr>
              </thead>
              <tbody>

                {datos

                  .map((props) => (
                    <tr>
                      <td>{props.cuenta}</td>
                      <td>{props.nombre}</td>
                      <td style={{ textAlign: 'right' }}>{props.saldo_inicial.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'right' }}>{props.cargos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'right' }}>{props.abonos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'right' }}>{props.saldo_final.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>

          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="mx-auto unshow">
              <PaginationControls page={page} onPageChange={(e) => changePagina(e)} />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <NavBar />
        <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
          <Form onSubmit={sendBuscar}>

            <center> <h2>Reporte de balance por agencia</h2> </center>

            <EmpresasSelect
              onChange={(e) => handleChange(e)}
              empresa={(e) => setNombreEmpresa(e)}
            />
             <EmpresaXAgenciaSelect
                      IdEmpresa = {empresa}
                      onChange= {(e) => setIdAgencia(e)}
                      getNombreAgencia = {true}
                      setNombreAgencia = {(e) => setAgencia(e)}
                      showlabel = {true}
                    
                        showTodas = {false}
            />





            <Form.Group >
              <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia de inicio</Form.Label>
              <Form.Control type="date" onChange={(e) => setFechaInicio(e.target.value)} />

              <Form.Label style={{ marginTop: "10px" }}>Seleccione el dia final</Form.Label>
              <Form.Control type="date" onChange={(e) => setFechaFinal(e.target.value)} />
            </Form.Group>


            <Button variant="primary" type="submit" className='my-2' >
              Buscar
            </Button>

          </Form>
        </Container>
      </>

    )
  }

}