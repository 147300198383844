import { NavBar } from "../../components/navbar"
import LineChart from "../../components/Graficos/LineChart"
import { getRegistrosBalanceAnual } from "../../services/reportes/reportesBalanza";
import { useEffect, useState, useRef } from "react";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import { propTypes } from "react-bootstrap/esm/Image";
import { Form, Row, Col } from 'react-bootstrap';


export function GraficoBalanza() {
    const [empresa, setEmpresa] = useState(null)
    const [nombreEmpresa, setNombreEmpresa] = useState(null)

    const data1 = [20, 40, 60, 80, 70, 90, 60, 80, 70, 50, 40, 30]; // Datos para la primera línea
    const data2 = [10, 30, 50, 70, 40, 80, 50, 70, 60, 40, 30, 20]; // Datos para la segunda línea
    const data3 = [0,0,0,0,0,41,25,26,18,20,30,41];

    const handleChange = (e) => {
        console.log(e)
        setEmpresa(e)
    }

    

    // const sendBuscar = async (e) => {
    // const object = {
    //     empresa: empresa,
    //     fecha: fecha,
    //     page: page,
    //     token: localStorage.getItem("token")
    // }
    
    // const setData = await getRegistrosBalanceAnual(object)
    // }

    return(
        <>
            <NavBar></NavBar>
            <div>
                <h1>Gráfico de Balanza</h1>
                <Row className="justify-content-center">
                    <Col xs={2}>
                        <EmpresasSelect
                            onChange={(e) => handleChange(e)}
                            empresa={(e) => setNombreEmpresa(e)}
                        />
                    </Col>
                    <Col xs={2}>
                        <Form.Label style={{marginTop:"10px"}}>Tipo de Cuenta</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={handleChange}>
                            <option disabled selected>Seleccione una cuenta</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={9}>9</option>
                        </Form.Select>
                    </Col>
                    <Col xs={2}>
                        <Form.Label style={{marginTop:"10px"}}>Nivel</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={handleChange}>
                            <option disabled selected>Seleccione una nivel</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </Form.Select>
                    </Col>
                    <Col xs={2}>
                        <Form.Label style={{marginTop:"10px"}}>Desde:</Form.Label>
                    </Col>
                </Row>

            </div>
            <div style={{marginLeft: '150px'}}>
                <LineChart datasets={[data1, data2, data3]} width={1000} height={500} />
            </div>
        </>
     
    )
}