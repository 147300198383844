import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { NavBar } from "../../components/navbar";
import { EmpresasSelect } from "../../components/UTILS/EmpresasSelect";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { BsFileExcel, BsFileExcelFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { IngresarDatos } from "../../components/Planillas/IngresarDatos";
import { getConciliaciodosXMes, guardarConciliacionBancos } from "../../services/bancos/bancos";
import Swal from "sweetalert2";

export function Conciliacion() {
    const [fecha, setFecha] = useState(null);
    const [page, setPage] = useState(1);
    const [empresa, setEmpresa] = useState(null);
    const [nombreEmpresa, setNombreEmpresa] = useState(null);
    const [buscador, setBuscador] = useState(false);
    const [showExcel, setShowExcel] = useState(false);
    const [showGoBack, setShowGoBack] = useState(false)
    const [verificacionVacio, setVerificacionVacio] = useState(false);
    const [data, setData] = useState([]);


    const descargarPlantilla = () => {
        Swal.fire({
            title: 'Cargando...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          const object = {
            IDENTIFICATIVO: 'XXXXX',
            DESCRIPCION:"DATO DE PRUEBA",
            CARGO: '999',
            ABONO: '999',
            FECHA_INGRESO: 'DD/MM/AAAA',
          }
          const objectArray = [object]; // Debe ser un array de objetos
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(objectArray);

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabla');
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const excelUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = excelUrl;
          link.download = `PLANTILLA.xlsx`;
          link.click();
          Swal.close();
    }

    const searchData = async () => {
        const object = {
            mes: fecha,
            id_empresa: empresa,
            token: localStorage.getItem("token"),
        }
        const res = await getConciliaciodosXMes(object);
        const datos = await res.json();
        console.log(res.status);
        if(res.status === 200){
                setBuscador(true);
            if(datos.length === 0){
                setVerificacionVacio(true);
            }else{
                setVerificacionVacio(false);
                setData(datos);
            }
        }
    }

    const sendBuscar = async (e) => {
        e.preventDefault();
        await searchData();
       
    }

    const handleChange = (e) => {
        setEmpresa(e);
    }
    const recieveData = async(data) => {
        console.log(data);
        //setBuscador(false);
        
        const object = {
            mes: fecha,
            id_empresa: empresa,
            token: localStorage.getItem("token"),
            data: data
        }
        const res = await guardarConciliacionBancos(object);
        if(res.status === 200){
            Swal.fire({
                icon: 'success',
                title: 'Excel cargado con exito',
                showConfirmButton: false,
                timer: 1500
              })
              setShowExcel(false);
              await searchData();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Error',
                showConfirmButton: false,
                timer: 1500
              })
        }
            
    }
    if(buscador){
        return (
            <>
            <NavBar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: "15px", padding: "10px",backgroundColor: "white", borderRadius: "10px"}}>
                <center> <h2 style={{padding: "10px"}}>Conciliacion bancaria mensual - {fecha}</h2> 
                <h3>{nombreEmpresa}</h3>
                </center>
                <center>    <Button variant="success" onClick={() => setShowExcel(true)}> <FaFileExcel /> Importar desde excel</Button> 
                </center>
            </div>
            <div className="Busqueda" style={{ marginTop: "15px" }}  ><br />
            <Table className="my-table printTable">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Debe</th>
                        <th>Haber</th>
                    </tr>
                </thead>
                <tbody>
                    {verificacionVacio === true ?
                    <th colSpan={4}> <center>No se han ingresado datos</center></th>    
                        :
                    data.map((item, index) => {
                        return (
                            <>
                                <tr>
                                    <td>{item.mes}</td>
                                    <td>{item.concepto}</td>
                                    <td>${item.tipo_movimiento == 1? item.monto : "0.0"}</td>
                                    <td>${item.tipo_movimiento == 2? item.monto : "0.0"}</td>
                                </tr>
                            </>
                        )
                    })
                }
                 
                </tbody>
            </Table>
            </div>
            <Modal show={showExcel} onHide={() => setShowExcel(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Importar desde excel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IngresarDatos send={recieveData} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowExcel(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }else{
    return (
        <>
          <NavBar />
          <Container className="bg-light d-flex justify-content-center align-items-center" style={{ height: "100%", width: "800px", padding: "50px", borderRadius: "30px", marginTop: "5%" }}>
            <Form onSubmit={sendBuscar}>
  
              <center> <h2>Conciliacion bancaria mensual </h2> </center>
  
              <EmpresasSelect
                onChange={(e) => handleChange(e)}
                empresa={(e) => setNombreEmpresa(e)}
              />
  
  
  
  
  
                <Form.Group >
                        <Form.Label>Seleccione el mes</Form.Label>
                        <Form.Control type="month" onChange={(e) => setFecha(e.target.value)} />
                    </Form.Group>
  
  
              <Button variant="primary" type="submit" className='my-2' >
                Buscar
              </Button>
              <Button style={{marginLeft: "10px"}} variant="primary" onClick={() => {descargarPlantilla()}}> <BsFileExcelFill /> Descargar plantilla de excel</Button>
  
            </Form>
          </Container>

         
        </>
  
      )
    }
}